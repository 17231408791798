import React from 'react'
import OnBoardingStepLayout from '../choose-business-type/layout'
import styles from './index.module.scss';
import success_img from '$assets/img/onboarding_success.png';
import { useNavigate } from 'react-router-dom';

export const OnBoardingWelcomePage = () => {
  //hooks
  const navigate = useNavigate();
  return (
    <OnBoardingStepLayout step={3}>
        <div className={styles.content}>
            <div className={styles.card}>
                <img src={success_img} alt="" />

                <h1 className="py-3">Welcome to BizPro</h1>
                <p>This is your first time here. Complete the following few steps to get started with your business!</p>

                <button className="btn btn-primary mt-3" onClick={() => navigate("/onboarding/business-type")}>
                    <span className="px-3">Next</span>
                </button>
            </div>
        </div>
    </OnBoardingStepLayout>
  )
}
