@import '$assets/scss/main.scss';

@mixin icon-button {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.viewButton {
    @include icon-button;

    background-color: #F2FFF9;
}

.editButton {
    @include icon-button;

    background-color: #ECF4FF;
}

.deleteButton {
    all: unset;
    @include icon-button;

    background-color: #FFF5F5;
}

.addButton {
    all: unset;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    gap: 8px;

    background: $primary-color;
    border-radius: 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */


    /* Base Color/ #00 */

    color: #FFFFFF;

    &:hover {
        cursor: pointer;
        color: #FFFFFF;
    }
}