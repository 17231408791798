@import "$assets/scss/main.scss";

.inputGroup {
    label {
        /* Body Text 14 px/H2 */
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */


        color: #000000;


        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    input {
        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 16px;
        gap: 19px;

        // width: 414px;
        // height: 40px;

        background: #FFFFFF;
        /* Primary Color/#100 */

        border: 1px solid #EDEDF5;
        border-radius: 8px;

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;

        &::placeholder {
            /* Body Text 14 px/H2 */
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */


            /* Base Color /Dark #400 */
            color: #A5A6AE;


            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

    
}
