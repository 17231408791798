import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
    inventoryStoresCreate,
    inventoryStoresGet,
    inventoryStoresUpdate
} from "../../../../../application/features/inventory";
import CreateUpdateForm from "../../../../components/form/createUpdateForm";
import CustomInput from "../../../../atomic/input";

const StoresFormPage = () => {
    //state
    const [errorMessage, setErrorMessage] = useState();

    const { store_id } = useParams();
    const product_categories = useSelector(state => state.inventory.stores);

    const dispatch = useDispatch();

    // const isCreating = useSelector(state => state.inventory.stores.isCreating);
    // const created = useSelector(state => state.inventory.stores.creatingSuccessful);
    // const updated = useSelector(state => state.inventory.stores.updatingSuccessful);
    // const creatingError = useSelector(state => state.inventory.stores.updatingError);
    // const updatingError = useSelector(state => state.inventory.stores.updatingError);
    // const units = useSelector(state => state.inventory.stores.data);


    useEffect(() => {
        console.log('init');
        dispatch(inventoryStoresGet());
    }, []);
    return (
        <div>
            {errorMessage && <div className="alert alert-danger mb-2">{errorMessage}</div>}
            <CreateUpdateForm resource={product_categories}  title={"Store"} item_id={store_id} createThunk={inventoryStoresCreate} updateThunk={inventoryStoresUpdate}>
                <CustomInput name='name' label='Store Name' placeholder='Name'  required />
                <CustomInput name='location' label='Store Location' placeholder='Location'  required />

            </CreateUpdateForm>
        </div>
    );
};

export default StoresFormPage;
