import PropTypes from "prop-types";

export const ExcelIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#dddddd" width={props.width ?? 24} height={props.height ?? 24} {...props}>
            <path fill={props.color ?? "#777"} fillOpacity="1" strokeWidth="0.2" strokeLinejoin="round"
                  d="M 16.1996,16.9981L 14.1996,16.9981L 11.9996,13.1981L 9.79959,16.9981L 7.79959,16.9981L 10.9996,11.9981L 7.79959,6.99807L 9.79959,6.99807L 11.9996,10.7981L 14.1996,6.99807L 16.1996,6.99807L 12.9996,11.9981M 18.9996,2.99807L 4.9996,2.99807C 3.89459,2.99807 2.9996,3.89406 2.9996,4.99807L 2.9996,18.9981C 2.9996,20.1021 3.89459,20.9981 4.9996,20.9981L 18.9996,20.9981C 20.1036,20.9981 20.9996,20.1021 20.9996,18.9981L 20.9996,4.99807C 20.9996,3.89406 20.1036,2.99807 18.9996,2.99807 Z "/>
        </svg>
    );
}
ExcelIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string
}
