import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";
import {login} from "./authentication";

// Action Constants
const BUSINESS_TYPES_BUSINESS_TYPES_GET = 'businessTypes/businessTypes/GET_BUSINESS_TYPES';
const BUSINESS_TYPES_BUSINESS_TYPE_SET_ACTIVE = 'businessTypes/businessTypes/SET_ACTIVE_BUSINESS_TYPES';

// Actions
export const businessTypesBusinessTypesGet = createAsyncThunk(
    BUSINESS_TYPES_BUSINESS_TYPES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.businessTypesGet, data, token, store);
    }
);

export const businessTypesBusinessTypeSetActive = createAsyncThunk(
    BUSINESS_TYPES_BUSINESS_TYPE_SET_ACTIVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.businessTypeSetActive, data.payload, token, store);
    }
);

// Combined thunks
const businessTypesResourceThunks = {
    get: businessTypesBusinessTypesGet,
    setActive: businessTypesBusinessTypeSetActive
}

//initial state
const initialState = {
    businessTypes: restResourceInitialState,
}

//redux toolkit slice
const businessTypesSlice = createSlice({
    'name': 'businessTypes',
    initialState,
    reducers: {
    },
    extraReducers: {
        ...generateResourceExtraReducers(businessTypesResourceThunks, "businessTypes"),
    },
});

//exports
export default businessTypesSlice.reducer;
