import React from "react";

export const ConnectionStatus = (props) => {
    const componentStyle = {
        backgroundColor: "red",
        color: "#ffffff",
        width: "96% !important",
        maxWidth: "700px",
        position: "fixed",
        height: "28px",
        padding: ".1rem 1rem",
        margin: 'auto',
        textAlign: 'center',
        borderRadius: "0 0 20px 20px",
        zIndex: 100,
        left: 0,
        right: 0,
        opacity: "50%",
        transition: "all 1s"
    }
    return (
        <div style={componentStyle} className={"w-100"}>
            You seem to be offline. Please check your internet connection!
        </div>
    );
}
