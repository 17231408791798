import React from "react";
import PropTypes from "prop-types";

export const ExpandIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#dddddd" width={props.width ?? 20} height={20}>
            {
                (props.expanded) ?
                    <path fillRule="evenodd" d="M8,17.4142136 L3.70710678,21.7071068 L2.29289322,20.2928932 L6.58578644,16 L3,16 L3,14 L10,14 L10,21 L8,21 L8,17.4142136 Z M17,17 L17,12 L19,12 L19,19 L12,19 L12,17 L17,17 Z M7,7 L7,12 L5,12 L5,5 L12,5 L12,7 L7,7 Z M21,8 L21,10 L14,10 L14,3 L16,3 L16,6.58578644 L20.2928932,2.29289322 L21.7071068,3.70710678 L17.4142136,8 L21,8 Z"/>
                    :
                    <path d="M21 15.344L18.879 17.465 15.707 14.293 14.293 15.707 17.465 18.879 15.344 21 21 21zM3 8.656L5.121 6.535 8.293 9.707 9.707 8.293 6.535 5.121 8.656 3 3 3zM21 3L15.344 3 17.465 5.121 14.293 8.293 15.707 9.707 18.879 6.535 21 8.656zM3 21L8.656 21 6.535 18.879 9.707 15.707 8.293 14.293 5.121 17.465 3 15.344z"/>
            }
        </svg>
    );
}
ExpandIcon.propTypes = {
    expanded: PropTypes.bool
}





