import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import {
    productsProductPackagesCreate,
    productsProductPackagesDelete,
    productsProductPackagesGet,
    productsProductPackagesRetrieve,
    productsProductPackagesUpdate
} from "../features/products";
import i18n from "i18next";

/**
 * @inheritDoc
 */
export class  ProductPackagesResource extends GalaxyResource {
    resourceActions = {
        fetch: productsProductPackagesGet,
        retrieve: productsProductPackagesRetrieve,
        create: productsProductPackagesCreate,
        update: productsProductPackagesUpdate,
        delete: productsProductPackagesDelete,
    };
    stateName = 'products.product_packages';
    columns = [
        {
            name: '#',
            center: false,
            width: '40px',
            cell: (row, index) => (index + 1),
        },
        {
            name: i18n.t('Name'),
            selector: (product_package) => product_package.name,
            sortable: true,
        },
        {
            name: i18n.t('Product'),
            selector: (product_package) => product_package.product ? product_package.product.name : '',
            sortable: true,
        },
        {
            name: i18n.t('Unit'),
            selector: (product_package) => product_package.unit ? product_package.unit.name : '',
            sortable: true,
        },
        {
            name: i18n.t('Unit Count'),
            selector: (product_package) => product_package.unit_count,
            sortable: true,
        },
    ];
    permissions = {
        view: ['View Products'],
        create: ['Create Products'],
        update: ['Create Products'],
        delete: ['Delete Products'],
    }
}

export default ProductPackagesResource;
