import React from 'react';
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
    inventoryDisposalsCreate,
    inventoryDisposalsGet, inventoryDisposalsRetrieve,
    inventoryDisposalsUpdate
} from "../../../../../application/features/inventory";
import CreateUpdateForm from "../../../../components/form/createUpdateForm";
import CustomInput from "../../../../atomic/input";
import {Spinner} from "../../../../atomic/spinner";
import {GeneralErrorPage} from "../../../../components/templates/error-pages/general-error-page";

const InventoryDisposalViewPage = () => {
    //state
    const [errorMessage, setErrorMessage] = useState();

    const { inventory_disposal_id } = useParams();
    const [disposal, setDisposal] = useState(null);

    const disposals = useSelector(state => state.inventory.disposals.data);
    const dispatch = useDispatch();
    const isFetching = useSelector(state => state.inventory.disposals.isFetching);
    const isCreating = useSelector(state => state.inventory.disposals.isCreating);

    useEffect(() => {
        console.log('init');
        dispatch(inventoryDisposalsRetrieve({id: inventory_disposal_id}));
    }, []);

    useEffect(() => {
        if(disposals && disposals.length) {
            setDisposal(disposals.find(e => e.id == inventory_disposal_id) ?? null);
        }
    }, [inventory_disposal_id])

    if(isFetching) {
        return <Spinner />
    }
    if(disposal === null && !isFetching) {
        return <GeneralErrorPage title={'Not found'} subTitle={"This document was not found"} />
    }
    return (
        <div className={'card'}>
            {errorMessage && <div className="alert alert-danger mb-2">{errorMessage}</div>}
            <div className={'card-body table-responsive'}>
                <table className={'table custom-table'}>
                    <tbody>
                    <tr>
                        <td>Date</td>
                        <td>{disposal.date}</td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td>{disposal.description}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InventoryDisposalViewPage;
