import React, {useRef} from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import Currency from "../../../../atomic/formatting/currency";
import DateFormat from "../../../../atomic/formatting/date-format";
import {GeneralErrorPage} from "../../../../components/templates/error-pages/general-error-page";
import {salesSalesRetrieve} from "../../../../../application/features/sales";
import Button from "../../../../atomic/button";
import {TraReceipt} from "../../../../components/templates/receipts/tra-receipt";
import ReactToPrint, {useReactToPrint} from "react-to-print";
import {SaleReceipt} from "./sale-receipt";

const SaleViewPage = () => {

    const {sale_id} = useParams();
    const dispatch = useDispatch();
    // redux
    const sales = useSelector(state => state.sales.sales.data);
    const sale = useSelector(state => state.sales.sales.data.find(e => e.id == sale_id));
    const company = useSelector(state => state.settings.metadata.data.company);


    useEffect(() => {
        dispatch(salesSalesRetrieve({id: sale_id}));
    }, []);

    const componentRef = useRef();
    const component2Ref = useRef();

    if (!sale) {
        return <GeneralErrorPage fullPage={false} title={'Sale not found'}/>;
    }


    const print = (div) => {
        let printContents = document.getElementById(div).innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    // Should stay last
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handlePrint2 = useReactToPrint({
        content: () => component2Ref.current,
    });
    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-xl-9">
                                <SaleReceipt sale={sale} ref={componentRef}/>
                                <button className={"btn"} onClick={handlePrint}>Print</button>
                            </div>
                            <div className="d-none d-xl-block col-3">
                                <TraReceipt sale={sale} ref={component2Ref}/>
                                <button className={"btn"} onClick={handlePrint2}>Print</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SaleViewPage;
