.login {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;

    min-width: 360px;
    transition: all 0.5s ease-in-out;
    background-color: #fff;
    padding: 2rem;
    border-radius: 1rem;

    >h1 {
        color: #212121;
        font-weight: 600;
        font-size: 1.8rem;
    }
    >input {
        border: 1px solid #212121;

    }
}
