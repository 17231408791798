import React from 'react'

export const IconMenu = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
        <g>
            <path d="M4 6c0-.56.44-1 1-1h14c.55 0 1 .44 1 1 0 .55-.45 1-1 1H5c-.56 0-1-.45-1-1Z"></path>
            <path d="M4 18c0-.56.44-1 1-1h14c.55 0 1 .44 1 1 0 .55-.45 1-1 1H5c-.56 0-1-.45-1-1Z"></path>
            <path d="M5 11c-.56 0-1 .44-1 1 0 .55.44 1 1 1h8c.55 0 1-.45 1-1 0-.56-.45-1-1-1H5Z"></path>
        </g>
    </svg>
  )
}
