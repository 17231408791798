import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import CustomInput from '../../../../atomic/input';
import CreateUpdateForm from "../../../../components/form/createUpdateForm";
import {productsProductUnitsCreate, productsProductUnitsUpdate} from "../../../../../application/features/products";

export const ProductUnitsFormPage = (props) => {
    //hooks
    const { unit_id: routeUnitId } = useParams();
    const { unit_id: propsUnitId } = useParams();

    //state
    const [unit_id, setUnitId] = useState();

    //redux

    const units_resource = useSelector(state => state.products.product_units);

    //effects
    useEffect(() => {
        if (!routeUnitId && !propsUnitId) return;
        if (routeUnitId) {
            setUnitId(routeUnitId);
        }
        if (propsUnitId) {
            setUnitId(propsUnitId);
        }
    }, [routeUnitId, propsUnitId])


    return (
        <div>
            <CreateUpdateForm resource={units_resource}  title={"Product Unit"} item_id={unit_id} createThunk={productsProductUnitsCreate} updateThunk={productsProductUnitsUpdate}>
                <CustomInput name='name' label='Unit Name' placeholder='unit name' />
                <div className="mb-2"></div>
                <CustomInput name='abbreviation' label='Abbreviation' placeholder='Unit Abbreviation' />
                <div className="mb-2"></div>
            </CreateUpdateForm>
        </div>
    );
}
