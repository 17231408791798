import authentication from './features/authentication';
import inventory from './features/inventory';
import products from "./features/products";
import purchases from "./features/purchases";
import sales from "./features/sales";
import customers from "./features/customers";
import users from "./features/users";
import returns from "./features/returns";
import app from './features/app';
import expenses from "./features/expenses";
import dashboard from "./features/dashboard";
import accounts from "./features/accounts";
import messages from "./features/messages";
import settings from "./features/settings";
import reports from "./features/reports";
import onboarding from "./features/onboarding";
import organizations from './features/organizations';
import notifications from "./features/notifications";
import businessTypes from "./features/busines-types";

export default {
    app,
    accounts,
    inventory,
    authentication,
    products,
    sales,
    purchases,
    expenses,
    customers,
    users,
    returns,
    dashboard,
    messages,
    settings,
    reports,
    onboarding,
    organizations,
    notifications,
    businessTypes,
};
