import React from "react";
import * as PropTypes from "prop-types";
import styles from './general-error-page.module.scss';
import fourZeroFour from '$assets/img/illustrations/404.svg';
import completion from '$assets/img/illustrations/completion.svg';
import emptyCart from '$assets/img/illustrations/empty_cart.svg';
import noConnection from '$assets/img/illustrations/no_connection.svg';
import launch from '$assets/img/illustrations/launch.svg';
import noNotification from '$assets/img/illustrations/no_notifications.svg';

export class GeneralErrorPage extends React.Component {
    icons = {
        completion,
        emptyCart,
        noConnection,
        launch,
        noNotification,
    }
    render() {
        return <div className={(styles.errorPage) + (this.props.fullPage ? ' full-page' : '') }>
            <div className={styles.errorContent}>
                <div>
                    <h3>{this.props.title ?? "Error"}</h3>
                    <div className="subtitle">{this.props.subTitle ?? 'Sorry, an error occurred!'}</div>
                </div>
                <div className="p-4">
                    <img src={this.icons[this.props.icon] ?? fourZeroFour} alt="Not Found" className="img-fluid" />
                </div>
                <div >

                </div>
            </div>

        </div>;
    }
}
GeneralErrorPage.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    actionTitle: PropTypes.string,
    actionFunction: PropTypes.any,
    icon: PropTypes.string,
    fullPage: PropTypes.bool,
};
