.appSearch {
    padding: calc(32px / 2) 0;
}

.appSearch input {
    border: none;
    height: 38px;
    padding-left: 40px;
    padding-right: 20px;
    background-color: #f3f3f9;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 30px;
}

.appSearch span {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 0;
    color: #74788d;
}