import {Link} from "react-router-dom";
import React from "react";
import styles from './index.module.scss';
import { IconEdit } from "../../atomic/icons/edit";
import IconBulb from "../icons/bulb";
import IconView from "../icons/view";

export const ViewButton = ({to, alt}) => {
    return (
        <Link to={to ? to : '#'} className={styles.viewButton}><IconView fill="#23955C" height={14} width={14} /></Link>
    )
}
