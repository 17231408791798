{
  "Date": "Date",
  "Description": "Description",
  "Type": "Type",
  "Amount": "Amount",
  "Actions": "Actions",
  "Dashboard": "Dashboard",
  "Sales": "Sales",
  "Products": "Products",
  "Purchases": "Purchases",
  "Expenses": "Expenses",
  "Inventory": "Inventory",
  "Returns":  "Returns",
  "Customers": "Customers",
  "Messaging":  "Messaging",
  "Users": "Users",
  "Accounts":  "Accounts",
  "Reports": "Reports",
  "Settings":  "Settings",
  "System Users": "System Users",
  "General Settings": "General Settings",
  "Sales Window": "Sales Window",
  "Sale Returns": "Sale Returns",
  "System Reports": "System Reports",
  "Monthly Sales": "Monthly Sales",
  "Monthly Waste": "Monthly Waste",
  "Purchases List": "Purchases List",
  "Units": "Units",
  "Categories": "Categories",
  "Product Packages": "Product Packages",
  "Messages List": "Messages List",
  "Send Message": "Send Message",
  "Automated Message": "Automated Message",
  "Inventory Items": "Inventory Items",
  "Inventory Disposals": "Inventory Disposals",
  "Stores": "Stores",
  "Expenses List": "Expenses List",
  "Expenses Types": "Expenses Types",
  "Sales vs Expenses graph": "Sales vs Expenses graph",
  "Product Performance list of 10 items": "Product Performance list of 10 items",
  "Proﬁt in less than 10 months": "Proﬁt in less than 10 months",
  "Customers List": "Customers List",
  "Customer Groups": "Customer Groups",
  "Transactions List": "Transactions List",
  "Monthly Purchases": "Monthly Purchases",
  "Monthly Profit": "Monthly Profit",
  "Monthly Sales Graph": "Monthly Sales Graph",
  "Product Name": "Product Name",
  "Quantity Sold": "Quantity Sold",
  "Month": "Month",
  "Profit": "Profit",
  "Sales Report": "Sales Report",
  "Name": "Name",
  "Quantity": "Quantity",
  "Purchases Report": "Purchases Report",
  "quantity": "Quantity",
  "unit_price": "Unit Price",
  "user_permissions": "User Permissions",
  "role_permissions": "Role Permissions",
  "System General Settings": "System General Settings",
  "Lock screen": "Lock screen",
  "Profile": "Profile",
  "Details": "Details",
  "Account": "Account",
  "Add Customer": "Add Customer",
  "Customer name": "Customer Name",
  "Phone Number": "Phone Number",
  "Customer Name": "Customer Name",
  "Customer": "Customer",
  "Email": "Email",
  "Phone": "Phone",
  "Products List": "Products List",
  "Add Products": "Add Products",
  "Unit": "Unit",
  "Category": "Category",
  "Sale": "Sale",
  "Add Return": "Add Return",
  "Record a New Purchase": "Record a New Purchase",
  "Add Item": "Add Item",
  "Buying Price": "Buying Price",
  "Selling Price": "Selling Price",
  "No Results": "No Results",
  "Product": "Product",
  "Total Amount": "Total Amount",
  "Received Amount": "Received Amount",
  "Returned Amount": "Returned Amount",
  "Save": "Save",
  "Enter phone number": "Enter phone number",
  "About": "About",
  "Product Categories": "Product Categories",
  "Search Products": "Search Products",
  "Show Selector": "Show Selector",
  "Items": "Items",
  "Qty": "Qty",
  "Reason": "Reason",
  "Available Quantity": "Available Quantity",
  "Add Expense": "Add Expense",
  "Add Expense Type": "Add Expense Type",
  "Expense Type name": "Expense type name",
  "Total": "Total",
  "Parent": "Parent",
  "Unit Count": "Unit Count",
  "Location": "Location",
  "Active": "Active",
  "Abbreviation": "Abbreviation",
  "Update": "Update",
  "Getting things ready": "Getting things ready...",
  "Unit Price": "Unit Price",
  "Date of Birth": "Date of Birth",
  "Search": "Search",
  "Failed to connect to BizPro! Please check your network connection and try again": {
    "": "Failed to connect to BizPro! Please check your network connection and try again."
  },
  "Failed to connect to BizPro!": "Failed to connect to BizPro!",
  "Retry": "Retry",
  "Returns Report": "Returns Report"
}
