import DateFormat from "../../../atomic/formatting/date-format";
import Currency from "../../../atomic/formatting/currency";
import Button from "../../../atomic/button";
import React, {forwardRef} from "react";
import {Spinner} from "../../../atomic/spinner";
import {useSelector} from "react-redux";

export const TraReceipt = forwardRef(function TraReceipt({sale}, ref) {
    const company = useSelector(state => state.settings.metadata.data.company);

    if (!sale || !sale.items) {
        return <Spinner/>
    }
    const printTra = (div) => {
        let printContents = document.getElementById(div).innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }
    const pageStyle = "@page { size: 3.5in 8in; size: portrait; }";
    return (
        <div ref={ref}>
            <style>{pageStyle}</style>
            <div id={'traReceipt'} className={'p-1'}
                 style={{border: '1px solid #ccc', fontSize: 'small'}}>
                <div className={'text-center'}>*** START OF RECEIPT ***</div>
                <div className={'text-center'}
                     s>{(company && company.name) ? company.name.toUpperCase() : ''}</div>
                <div className={'text-center'}
                     s>{(company && company.address) ? company.address.toUpperCase() : ''}</div>
                <div>CUSTOMER NAME: <span>{sale.customer_name ?? ''}</span></div>
                <div>CUSTOMER TYPE: NIL</div>
                <br/>
                <div>RECEIPT NUMBER: {sale.id}</div>
                <div>RECEIPT DATE: <span><DateFormat format={'DD-MM-YY'} date={sale.date}/></span>
                </div>
                <div style={{borderBottom: '1px dashed #999'}}></div>

                <div style={{borderBottom: '1px dashed #999'}}></div>
                <div>
                    <table border={0} width={'100%'}>
                        {
                            (sale.items && sale.items.length) ?
                                sale.items.filter(u => u).map((item, i) => (
                                    <>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{item.name ?? 'Item'}</td>
                                            <td> x {item.quantity}</td>
                                            <td align={'top'} rowSpan={2} className={'text-end'}>
                                                <Currency
                                                    value={(item.unit_price * item.quantity)}/></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}> Tax: <Currency
                                                value={(item.tax_percentage * item.unit_price * item.quantity)}/>
                                            </td>
                                        </tr>
                                    </>
                                ))
                                : ''
                        }

                    </table>
                    <div style={{borderBottom: '1px dashed #999'}}></div>
                    <div>TOTAL EXCL TAX <span className="float-end"><Currency
                        value={sale.total_amount}/></span></div>
                    <div style={{borderBottom: '1px dashed #999'}}></div>
                    <div>TAX <span className="float-end"><Currency value={0}/></span></div>
                    <div style={{borderBottom: '1px dashed #999'}}></div>
                    <div>TOTAL INCL TAX <span className="float-end"><strong><Currency
                        value={sale.total_amount}/></strong></span></div>
                    <div style={{borderBottom: '1px dashed #999'}}></div>
                </div>
            </div>
            <div className={'text-end pt-2'}>
                {/*<Button onClick={() => printTra('traReceipt')} label={'Print'}/>*/}
            </div>
        </div>
    )
});
