import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import logo from "$assets/img/datavision-logo.png";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {galaxy_login, login} from '../../../../application/features/authentication';
import {Spinner} from '../../../atomic/spinner';
import {SplashLayout} from '../splash/layout';
import styles from './index.module.scss';
import CustomInput from '../../../atomic/input';
import {onBoardingCheckStatus} from '../../../../application/features/onboarding';
import SoftwareGalaxyLogo from '../../../../assets/img/software_galaxy_logo.svg';

export const LoginPage = () => {
    const navigate = useNavigate();

    //state
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [haveToRedirect, setHaveToRedirect] = useState(false);
    const [directLogin, setDirectLogin] = useState(false);

    //store
    const dispatch = useDispatch();
    const token = useSelector(state => state.authentication.token);
    const user = useSelector(state => state.authentication.user);
    const isLoading = useSelector(state => state.authentication.login.isLoading);
    const loginSuccessful = useSelector(state => state.authentication.login.isSuccessful);
    const error = useSelector(state => state.authentication.login.error);


    const isGalaxyLoading = useSelector(state => state.authentication.galaxy_login.isLoading);
    const galaxyLoginSuccessful = useSelector(state => state.authentication.galaxy_login.isSuccessful);
    const galaxyError = useSelector(state => state.authentication.galaxy_login.error);
    const galaxy_login_url = useSelector(state => state.authentication.galaxy_login.login_url);
    const stateHash = useSelector(state => state.authentication.stateHash);

    //effect
    useEffect(() => {
        //on mount
        if (token && user) navigate('/main');
    }, []);


    useEffect(() => {
        if (!token) return;
        console.log('Token received...');
        // navigate('/main');
    }, [token])


    useEffect(() => {
        if (!user) return;
        console.log('Token received...');
        if (!user.organization) {
            navigate('/choose-organization');
        } else {
            navigate('/main');
        }
    }, [user])


    useEffect(() => {
        if (!galaxy_login_url) return;
        if (galaxyLoginSuccessful !== true) return;
        console.log('Have to login via ', galaxy_login_url);
        setHaveToRedirect(true);
        window.location = galaxy_login_url;
        setTimeout(() => {
            setHaveToRedirect(false);
        }, 5000);
    }, [galaxy_login_url])

    useEffect(() => {
        if (!galaxyLoginSuccessful) return;
        if (!galaxy_login_url) return;
    }, [galaxyLoginSuccessful]);


    useEffect(() => {
        if (!error) {
            setErrorMessage(null);
            return;
        }

        let message = "";
        console.log(error);
        if (!error.errors) {
            message = error.message;
        } else {
            message = error.message ?? "Validation Error";
            let _errors = {};
            for (let key in error.errors) {
                _errors[key] = error.errors[key][0]
            }
            setErrors(_errors);
        }

        console.log(message);
        setErrorMessage(message);
        setTimeout(() => {
            setErrorMessage(null);
        }, 30000);
    }, [error]);

    //handlers
    const handleLogin = () => {
        setErrors({});
        const payload = {
            "email": email,
            "password": password,
        }
        dispatch(login(payload));
    }

    const handleLoginWithGalaxy = () => {
        setErrors({});
        const payload = {state: stateHash}
        dispatch(galaxy_login(payload));
    }

    const toggleLoginMethod = () => {
        setDirectLogin(!directLogin);
    }


    return (
        <SplashLayout>
            <div className={styles.login} style={{display: ( haveToRedirect) ? 'none' : 'block'}}>
                <h1>Sign In</h1>

                <div className={directLogin ? 'd-none' : ''}>
                    <div className="subtitle mb-3" style={{color: "#949494", fontWeight: 300}}>
                        Login with Software Galaxy Account
                    </div>
                    <div className={"row"}>
                        <button disabled={isGalaxyLoading} name={'galaxy_login_btn'}
                                className="btn btn-outline-primary btn-block fa-lg gradient-custom-2 mb-3"
                                onClick={handleLoginWithGalaxy} type="button">

                            {
                                isGalaxyLoading ? <Spinner  />
                            :
                                <div className={'d-flex w-100 align-items-center'}>
                                    <img src={SoftwareGalaxyLogo} className={'img-fluid'} style={{height: "36px"}}
                                         alt=""/>
                                    <div className={'text-center flex-grow-1'}>Log in with Software Galaxy</div>
                                </div>
                            }
                        </button>
                    </div>
                    <div style={{display: isGalaxyLoading ? 'none' : 'block'}}  className="row justify-content-center text-center">
                        <a href="#" onClick={toggleLoginMethod}>Direct Login?</a>
                    </div>
                </div>
                <div className={!directLogin ? 'd-none' : ''}>
                    <div className="subtitle mb-3" style={{color: "#949494", fontWeight: 300}}>Login directly to
                        BizPro
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-12">
                                {galaxyError && <div className='alert alert-danger'>Failed to Login with Galaxy</div>}
                                {errorMessage && <div className='alert alert-danger'>{errorMessage}</div>}
                            </div>
                        </div>
                        <CustomInput className={"mb-2"} label="Email" type="email" name={'email'}
                                     placeholder="Enter your email address"
                                     onChange={(name, val) => setEmail(val)} error={errors.email ?? null}/>
                        <div className={"mt-2"}></div>
                        <CustomInput label="Password" type="password" name={'password'}
                                     placeholder="Enter your password"
                                     onChange={(name, val) => setPassword(val ?? '')} error={errors.password ?? null}/>
                        <button disabled={isLoading} name={'login_btn'}
                                className="btn btn-primary btn-block fa-lg btn-block gradient-custom-2 mb-3 mt-4 w-100"
                                onClick={handleLogin} type="button">Log
                            in {isLoading && <Spinner/>}
                        </button>

                        <p>Did you Forget your password ? <Link to="../password-reset">Reset It</Link></p>
                    </div>
                    <div style={{display: isGalaxyLoading ? 'none' : 'block'}} className="row justify-content-center text-center">
                        <a href="#" onClick={toggleLoginMethod}>Have Software Galaxy Account?</a>
                    </div>
                </div>
            </div>
            <div className={styles.login} style={{display: ( haveToRedirect) ? 'block' : 'none'}}>
                <div className="text-center">
                    <Spinner />
                    <div className="m-2">Redirecting...</div>
                </div>
            </div>
        </SplashLayout>
    );
}
