import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {CustomTable} from "../../../../atomic/table/table";
import {
    productsImagesUpload,
    productsProductImageDelete,
    productsProductRetrieve,
    productsProductsGet
} from "../../../../../application/features/products";
import CustomButton from '../../../../atomic/button';
import CustomInput from '../../../../atomic/input';

const ProductViewPage = () => {
    //state
    const [product, setProduct] = useState();
    const [payload, setPayload] = useState({});

    // Props
    const {product_id} = useParams();

    //redux
    const dispatch = useDispatch();
    const products = useSelector(state => state.products.products.data);
    const uploadResponse = useSelector(state => state.products.product_image.data);
    const isUploading = useSelector(state => state.products.product_image.isUploading);
    const uploadingSuccessful = useSelector(state => state.products.product_image.uploadingSuccessful);
    const isDeletingImage = useSelector(state => state.products.product_image.isDeleting);
    const deletingImageSuccessful = useSelector(state => state.products.product_image.deletingSuccessful);

    // hooks


    //effects
    useEffect(() => {
        if(products === null || products === undefined || products.length == 0){
            dispatch(productsProductsGet());
        }
    }, []);


    useEffect(() => {
        if(!product_id) return;
        setProduct(products.find(e => e.id == product_id) ?? null);
    }, [product_id])

    useEffect(() => {
        if (!uploadResponse) return;
        dispatch(productsProductRetrieve({id: product_id}));
    }, [uploadResponse]);

    //handlers
    const handleChange = (key, value) => {
        setPayload({...payload, [key]: value});
    }

    const handleUpload = () => {
        let _payload = payload;
        if(!_payload.image) return;
        _payload["product_id"] = product_id;
        dispatch(productsImagesUpload(payload));

    }

    const deletePicture = (id) => {
        if(id) {
            dispatch(productsProductImageDelete({id: id}));
        }
    }

    if(!product) return <div>No Data</div>

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-8">
                    <div className="card mb-3">
                        <div className="card-header">Product</div>
                        <div className="card-body">
                            <table className="table table-bordered">
                                <tbody>
                                <tr><td width="30%">Product Name:</td><td>{product.name}</td></tr>
                                <tr><td>Unit Name:</td><td>{product.unit.name}</td></tr>
                                <tr><td>Category:</td><td>{product.category.name}</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="card mb-3">
                            <div className="card-header">Addons</div>
                        <div className="card-body">
                            <CustomTable  isEmpty={(!product.product_addons || product.product_addons.length == 0)}>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (product.product_addons && product.product_addons.length) ?
                                    product.product_addons.filter(u => u).map((addon, i) =>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{addon.name}</td>
                                            <td>{addon.description}</td>
                                        </tr>
                                    ) :
                                        <div className="text-black-50">No Addons</div>
                                }
                                </tbody>
                            </CustomTable>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-header">Packages</div>
                        <div className="card-body">
                            <CustomTable isEmpty={(!product.product_packages || product.product_packages.length == 0)}>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Unit</th>
                                    <th>Count</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (product.product_packages &&product.product_packages.length) ?
                                    product.product_packages.filter(u => u).map((product_package, i) =>
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{product_package.name}</td>
                                        <td>{product_package.unit.name}</td>
                                        <td>{product_package.unit_count}</td>
                                    </tr>
                                ):
                                        <div className="text-black-50">No Packages</div>
                                }
                                </tbody>
                            </CustomTable>
                        </div>
                    </div>

                </div>
                <div className="col-12 col-md-4">
                    <div className="card mb-3">
                            <div className="card-header">Pictures</div>
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="mr-2">
                                <CustomInput type="file" name="image" onChange={handleChange} />
                            </div>
                            <CustomButton isLoading={isUploading} label="Upload" onClick={handleUpload} />
                            </div>
                            <div>
                                {
                                    (product.pictures && product.pictures.length) ?
                                    product.pictures.filter(u => u).map((picture, i) =>
                                    <div className="img-fluid">
                                        <img src={picture.image.path} alt="Image" className="img-fluid"/>
                                        <span style={{position: 'relative', left: '.2rem'}}>
                                            <span onClick={() => deletePicture(picture.id)}>X</span>
                                        </span>
                                    </div>
                                ) :
                                        <div>No Pictures</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductViewPage;
