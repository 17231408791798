import BizManagerApi from "../../api/bizmanager";
import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import {
    usersUserDelete,
    usersUsersGet, usersUserCreate,
} from "../features/users";
import DateFormat from "../../presentation/atomic/formatting/date-format";
import i18n from "i18next";

/**
 * @inheritDoc
 */
export class  UsersResource extends GalaxyResource {
    resourceActions = {
        fetch: usersUsersGet,
        retrieve: null,
        create: usersUserCreate,
        update: null,
        delete: usersUserDelete,
    };
    stateName = 'users.users';
    columns = [
        {
            name: i18n.t('Name'),
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: i18n.t('Email'),
            cell: (row) => <span>{row.email ?? ''}</span>,
            sortable: true,
        },
        {
            name: i18n.t('Phone'),
            cell: (row) => <span>{row.phone ?? ''}</span>,
            sortable: true,
        },
    ];
    permissions = {
        view: ['View Users'],
        create: ['Create Users'],
        update: ['Create Users'],
        delete: ['Delete Users'],
    }
}

export default UsersResource;
