import React from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import IconArrowBack from "../../icons/arrow-back";
import * as PropTypes from "prop-types";

export const TopNavigation = (props) => {
    const {links, basePathLevel = 3, parentPath} = props;
    const location = useLocation();
    const locationParts = location.pathname.split('/').filter(u => u);
    const navigate = useNavigate();
    return <ul className="nav nav-tabs mb-3" >
        {
            (basePathLevel && locationParts.length > (basePathLevel)) ?
                <>
                    <li className="nav-item">
                        <div style={{cursor: 'pointer'}} className="btn btn-outline-primary" onClick={() => navigate(-1)}>
                            <IconArrowBack fill={'#ccc'} height={20} width={20}/>
                        </div>
                    </li>
                </>
                :
                ""
        }
        {

            links.filter(u => u).map((item, i) => (
                <li key={'nav' + i} className="nav-item">
                    <NavLink className="nav-link" end to={item.to}>{item.title}</NavLink>
                </li>
            ))
        }

    </ul>;
}

TopNavigation.propTypes = {
    links: PropTypes.array,
    parentPath: PropTypes.bool,
    basePathLevel: PropTypes.number
};
