import React from 'react'
import styles from "./index.module.scss";

import { IconSearch } from '../../../../atomic/icons/search';

export const AppHeaderSearchInput = () => {
  return (
    <form className={`${styles.appSearch} d-none d-lg-block`}>
        <div className="position-relative">
            <input type="text" className="form-control" placeholder="Search..." />
            <span className="fa fa-search-alt"><IconSearch height="20px" width="20px" /></span>
        </div>
    </form>
  )
}
