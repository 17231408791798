import {useDispatch, useSelector} from "react-redux";
import {CustomTable} from "../../../../atomic/table/table";
import {useEffect, useState} from "react";
import {messagesMessagesGet} from "../../../../../application/features/messages";
import React from "react";
import {useNavigate} from "react-router-dom";
import { AddButton } from '../../../../components/buttons/add-button';

export const MessagesListPage = () => {
    //state
    const [filterPayload, setFilterPayload] = useState({start_date: null, end_date: null});
    //hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //redux
    const messages = useSelector(state => state.messages.messages.data);
    const isFetching = useSelector(state => state.messages.messages.isFetching);


    useEffect(() => {
        // if(messages.length == 0 || true){
        dispatch(messagesMessagesGet(filterPayload));
        // }
    }, []);

    useEffect(() => {
    }, [messages]);

    const showAddForm = () => {
       navigate('../../send');
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-center">
                            <span className="card-title flex-grow-1">Messages</span>
                            <AddButton to="../../send" title="Send an SMS" />
                        </div>
                    </div>
                    <div className="card-body">
                        <CustomTable isLoading={isFetching}>
                            <thead>
                            <tr>
                                <th width={30}>#</th>
                                <th>Phone Number</th>
                                <th>Message</th>
                                <th>Status</th>
                                <th className="col-actions">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                messages.filter(u => u).map((message, i) => (
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{message.phone_number ?? ''}</td>
                                        <td>{message.text ?? ''}</td>
                                        <td><span className="badge bg-secondary">{message.status}</span></td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </CustomTable>
                    </div>
                </div>
            </div>
        </div>
    )
}