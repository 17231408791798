import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "../../../../atomic/spinner";
import styles from './notifications.module.scss';
import React, {useEffect} from "react";
import {notificationsNotificationsGet} from "../../../../../application/features/notifications";
import PageAlert from "../../../../components/notifications/page-alert";

export const NotificationsList = () => {

    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notifications.notifications.data);
    const isFetching = useSelector(state => state.notifications.notifications.isFetching);

    useEffect(() => {
        dispatch(notificationsNotificationsGet());
    }, []);


    return (
        <div className='row'>
            <div className="col-12 text-end mb-2">
            </div>
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-center">
                            <span className="card-title flex-grow-1">Notifications</span>
                        </div>
                    </div>
                    <div className="card-body">
                        {isFetching && <Spinner/>}
                        {
                            (notifications.length == 0) ?
                                <PageAlert title={"No notifications"} text={"You are good to go"}/>
                                :
                                <ul className={styles.notifications}>
                                    {
                                        (notifications.filter(u => u).map((notification, i) => (
                                            <li className={styles.notificationItem}>
                                                <div className="d-flex flex-row">
                                                    <div className={styles.icon} style={{backgroundColor: "#daffda"}}>

                                                    </div>
                                                    <div className={styles.content}>
                                                        <div style={{fontSize: "1.1rem"}}
                                                             className={'text-black-50'}>{notification.data.title}</div>
                                                        <div
                                                            className="small text-black-50">{notification.data.text ?? ''}</div>
                                                    </div>
                                                    <div className={styles.action}>

                                                    </div>
                                                </div>
                                            </li>
                                        )))
                                    }
                                </ul>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
