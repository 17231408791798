import React from 'react';

const IconBankSpending = (props) => {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"  fill="currentColor" {...props}>
            <g id="Frames-24px"><rect fill="none" width="24" height="24"></rect></g>
            <g id="Solid">
                <path d="M22,3H10C8.897,3,8,3.896,8,5v1h2V5h12v2h-7v2h7v6H10v-1H8v1c0,1.103,0.897,2,2,2h6.382l-0.724,1.448   C15.488,18.789,15.146,19,14.764,19h-6.35L6,16.586v-5.172L8.414,9H12v1c0,0.551-0.449,1-1,1H9v2h2c1.654,0,3-1.346,3-3V8   c0-0.552-0.448-1-1-1H8C7.735,7,7.48,7.104,7.293,7.294L5.46,9.126C5.32,9.052,5.169,9,5,9H1c-0.552,0-1,0.448-1,1v8   c0,0.552,0.448,1,1,1h4c0.169,0,0.32-0.052,0.46-0.126l1.833,1.834C7.48,20.894,7.735,21,8,21h6.764   c1.144,0,2.172-0.635,2.683-1.659L18.618,17H22c1.103,0,2-0.897,2-2V5C24,3.896,23.103,3,22,3z M4,17H2v-6h2V17z"></path>
            </g>
    </svg>
    );
};
export default IconBankSpending;
