import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ReportExporter from "../../../components/form/report-exporter/report-exporter";
import BackendContentFilter from "../../../components/form/content-filter";
import moment from "moment/moment";
import {t} from "i18next";
import bizmanager from "../../../../api/bizmanager";
import {CustomTable} from "../../../atomic/table/table";
import {returnsReturnsGet} from "../../../../application/features/returns";
import {reportsSalesGet} from "../../../../application/features/reports";
import DateFormat from "../../../atomic/formatting/date-format";
import Currency from "../../../atomic/formatting/currency";

const ReturnsReportPage = () => {
    const returns = useSelector(state => state.returns.returns.data);
    const isFetching = useSelector(state => state.returns.returns.isFetching);
    const dispatch = useDispatch();
    const downloadUrl = bizmanager.exportReturnsReport;

    //effects
    useEffect(() => {
        dispatch(returnsReturnsGet());
    }, []);
    const fetchData = (payload) => {
        console.log('Payload being sent: ', payload);
        dispatch(returnsReturnsGet({payload}));
    }
    return (
        <div>
            <div className="card">
                <div className="card-header">{t('Returns Report')}</div>
                <div className="card-body">
                    <div className="col-12  mb-2 d-flex flex-row justify-content-between align-items-center gap-1">
                        <div className="flex-grow-1"><h6>{t('Returns')}</h6></div>
                        <ReportExporter downloadUrl={downloadUrl}/>
                        <BackendContentFilter startDate={moment().startOf('year').format('YYYY-MM-DD')}
                                              onSubmit={(data) => fetchData({payload: data})}/>
                    </div>
                    <CustomTable isLoading={isFetching}>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{t('Date')}</th>
                            <th>{t('Description')}</th>
                            <th>{t('Customer')}</th>
                            <th className={'text-end'}>{t('Items')}</th>
                            <th className={"text-end"}>{t('Amount')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            returns.map((returnItem, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td><DateFormat date={returnItem.date}/></td>
                                    <td>{returnItem.description}</td>
                                    <td className="text-end">
                                        <span className="text-black-50">{returnItem.customer ?? 'N/A'}</span>
                                    </td>
                                    <td className={"text-end"}>{returnItem.items.length ?? 1}</td>
                                    <td className="text-end"><Currency value={returnItem.total}/></td>
                                </tr>
                            ))
                        }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={5}>Total</th>
                                <th className={'text-end'}><Currency value={(returns.length ? (returns.reduce((prev, current) => prev + parseFloat(current.total), 0)) : '')}  /></th>
                            </tr>
                        </tfoot>
                    </CustomTable>
                </div>
            </div>
        </div>
    );
};

export default ReturnsReportPage;
