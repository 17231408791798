@import '$assets/scss/main.scss';

.card {
    background: #FFFFFF !important;
    border-radius: 12px !important;
}

.icon {
    height: 50px;
    width: 50px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}

.title {
    // font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Base Color /Dark #700 */
    color: #616371;
}

.value {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;

    /* Base Color /Dark #1000 */

    color: #1E2034;

}