import React from 'react'
import { IconAdd } from '../../atomic/icons/add';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import {useSelector} from "react-redux";

export const AddButton = (props) => {
    const { to, title, permissions = [] } = props;

    const user = useSelector(state => state.authentication.user);

    // Check Permissions
    if (permissions.length && user != null) {
        if (user.rolePermissions != undefined && user.rolePermissions != null) {
            const matched = permissions.filter(value => user.rolePermissions.includes(value));
            if(matched.length == 0) { return ''; }
        }
    }
    return (
        <Link to={to} className={styles.addButton}>
            <IconAdd height="15px" width="15px" fill="#fff" />

            <div>
                {title}
            </div>
        </Link>
    );
}
