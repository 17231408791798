import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import CustomPageAlert from "../../../../../components/notifications/page-alert";
import Currency from "../../../../../atomic/formatting/currency";
import InventoryItemAddons from "./addons";
import {productsProductAddonsGet} from "../../../../../../application/features/products";
import {inventoryItemsRetrieve} from "../../../../../../application/features/inventory";
import {CustomTable} from "../../../../../atomic/table/table";
import i18n from "i18next";

const InventoryItemViewPage = () => {
    //state
    const [isMounted, setIsMounted] = useState(false);
    //
    const { inventory_item_id } = useParams();
    const dispatch  = useDispatch();
    const item = useSelector(state => state.inventory.item.data.find(e => e.id == inventory_item_id));
    const retrievingItem = useSelector(state => state.inventory.item.isRetrieving);
    const retrieveSuccessful = useSelector(state => state.inventory.item.retrievingSuccessful);

    useEffect(() => {
        setIsMounted(true);
        dispatch(productsProductAddonsGet());
        if(inventory_item_id) {
            dispatch(inventoryItemsRetrieve({id: inventory_item_id}));
        }
    }, []);

    useEffect(() => {
        if(!isMounted) return;
        if(!inventory_item_id) return;
    }, [inventory_item_id]);


    useEffect(() => {
        if(!isMounted) return;
    }, [ retrieveSuccessful]);

    const onAddonAdded = () => {
      dispatch(inventoryItemsRetrieve({id: inventory_item_id}));
    }
    const onAddonDeleted = (id) => {
      dispatch(inventoryItemsRetrieve({id: inventory_item_id}));
    }

    if(!item) {
        return <CustomPageAlert title="" text="Invalid Item selected" />
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <CustomTable
                                isLoading={retrievingItem && !retrieveSuccessful}>
                                <tbody>
                                <tr>
                                    <td width="30%">{i18n.t('Product Name')}</td>
                                    <td>{item.product ? item.product.name : ''}</td>
                                </tr>
                                <tr>
                                    <td width="30%">{i18n.t('Available Quantity')}</td>
                                    <td>{item.quantity}</td>
                                </tr>
                                <tr>
                                    <td width="30%">{i18n.t('Buying Price')}</td>
                                    <td><Currency value={item.buying_price ?? ''}/></td>
                                </tr>
                                <tr>
                                    <td width="30%">{i18n.t('Selling Price')}</td>
                                    <td><Currency value={item.selling_price ?? ''}/></td>
                                </tr>
                                </tbody>
                            </CustomTable>
                            </div>
                            <div className="col-12 col-md-4">
                                {
                                    (item.product && item.product.pictures && item.product.pictures.length) ?
                                        <div className="img-fluid">
                                            <img src={item.product.pictures[0].image.path} alt="Image" className="img-fluid"/>
                                        </div>
                                        :
                                        <div>No image</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-3">
                <div className="card">
                    <div className="card-body">
                        {(item && retrieveSuccessful) ?
                        <InventoryItemAddons inventoryItem={item} onAdded={onAddonAdded} onDeleted={onAddonDeleted}/>
                        :
                            ''
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InventoryItemViewPage;
