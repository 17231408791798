import React, {useEffect, useState} from 'react';
import CustomInput from "../../../../atomic/input";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
    productsProductCategoriesCreate,
    productsProductCategoriesGet,
    productsProductCategoriesUpdate
} from "../../../../../application/features/products";
import CustomSelectInput from "../../../../atomic/select";
import CreateUpdateForm from "../../../../components/form/createUpdateForm";

const ProductCategoryFormPage = () => {
    //state
    const [errorMessage, setErrorMessage] = useState();

    const { product_category_id } = useParams();
    const product_categories = useSelector(state => state.products.product_categories);

    const dispatch = useDispatch();

    // const isCreating = useSelector(state => state.products.product_categories.isCreating);
    // const created = useSelector(state => state.products.product_categories.creatingSuccessful);
    // const updated = useSelector(state => state.products.product_categories.updatingSuccessful);
    // const creatingError = useSelector(state => state.products.product_categories.updatingError);
    // const updatingError = useSelector(state => state.products.product_categories.updatingError);
    // const units = useSelector(state => state.products.product_categories.data);


    useEffect(() => {
        console.log('init');
        dispatch(productsProductCategoriesGet());
    }, []);


    return (
        <div>
            {errorMessage && <div className="alert alert-danger mb-2">{errorMessage}</div>}
            <CreateUpdateForm resource={product_categories}  title={"Category"} item_id={product_category_id} createThunk={productsProductCategoriesCreate} updateThunk={productsProductCategoriesUpdate}>
                <CustomInput name='name' label='Category Name' placeholder='Product name'  required />
                {/*<CustomInput name='code' label='Category Code' placeholder='Category name'  required />*/}
                <CustomSelectInput name="parent_category_id" items={[...product_categories.data.filter((c) => { return c.id != product_category_id && c.parent_id == null; })]} label={"Parent Category"} />
            </CreateUpdateForm>
        </div>
    );
};

export default ProductCategoryFormPage;
