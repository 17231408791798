import React, { Children, cloneElement, useEffect, useRef, useState } from 'react'
import chevron from '$assets/icons/chevron-down.svg';
import styles from '../index.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { IconChevronDown } from '../../../../atomic/icons/chevron-down';
import {useSelector} from "react-redux";

const NavigationItemComponent = (props) => {
    //props
    const { icon: Icon, title, href, children, heightChanged, permissions = [] } = props;
    const arrayChildren = Children.toArray(children);

    const user = useSelector(state => state.authentication.user);

    //hooks
    const navigate = useNavigate();

    //ref
    const dropDown = useRef();

    //state
    const [open, setOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [dropdownHeight, setDropdownHeight] = useState(null);
    const [childrenHeight, setChildrenHeight] = useState(0);

    //effects
    useEffect(() => {

    })


    useEffect(() => {
        if (!dropDown.current) return;
        setDropdownHeight(dropDown.current.clientHeight);
    }, [dropDown]);

    useEffect(() => {
        if (!heightChanged) return;

        if (open) {
            heightChanged(dropdownHeight);
        } else {
            heightChanged(-dropdownHeight);
        }
    }, [open])

    //handlers
    const handleHeightChanged = (height) => {
        setDropdownHeight(dropdownHeight + height);
    }

    const activate = (value) => {
        setTimeout(() => {
            setIsActive(value);
        }, 10)

    }

    // Check Permissions
    if (permissions.length && user != null) {
        if (user.rolePermissions != undefined && user.rolePermissions != null) {
            const matched = permissions.filter(value => user.rolePermissions.includes(value));
            if(matched.length == 0) { return ''; }
        }
    }

    // TODO Fix this  block as it shows a warning
    return (
        <div>
            <div className={`${styles.itemWrapper} ${isActive || open ? styles.active : ''}`} onClick={() => navigate(href ?? "#")}>
                {Icon && (
                    <div className={styles.marginRight}>
                        <Icon height="20px" width="20px" className={`${styles.inactiveColor}  ${isActive || open ? styles.active : ''}`} />
                    </div>
                )}
                <div className={`${styles.itemTitle} ${isActive || open ? styles.active : ''}`}>
                    <NavLink to={href ?? "#"} className={({ isActive }) => {
                        activate(isActive);
                        return "";
                    }}>{title}</NavLink>
                </div>
                {children && <IconChevronDown onClick={() => setOpen(!open)} height="6px" className={`${styles.itemChevron} ${open && styles.active}`}  />}
            </div>

            {children && (
                <div className={`pl-3 ${styles.itemChildren}`} style={{ height: open ? dropdownHeight + 20 : 0 }}>
                    <div ref={dropDown}>
                        {Children.map(arrayChildren, (child, index) => {
                            return cloneElement(child, { heightChanged: handleHeightChanged });
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

export default NavigationItemComponent;
