import React from 'react'
import { Route, Routes } from 'react-router'
import { OnBoardingChooseBusinessTypePage } from './choose-business-type'
import { OnBoardingRegisterOrganizationPage } from './register-organization'
import { OnBoardingChooseCategoriesPage } from './choose-category/index';
import { OnBoardingSuccessPage } from './success/index';
import {OnBoardingWelcomePage} from "./welcome";

export const OnBoardingRoutes = () => {
  return (
    <Routes>
        {/*<Route index element={<OnBoardingRegisterOrganizationPage />} />*/}
        <Route index element={<OnBoardingWelcomePage />} />
        <Route path='business-type' element={<OnBoardingChooseBusinessTypePage />} />
        <Route path='categories' element={<OnBoardingChooseCategoriesPage />} />
        <Route path='success' element={<OnBoardingSuccessPage />} />
    </Routes>
  )
}
