import {ViewButton} from "../../presentation/components/buttons/view-button";
import {EditButton} from "../../presentation/components/buttons/edit-button";
import {DeleteButton} from "../../presentation/components/buttons/delete-button";

export class GalaxyResource {
    user = null;
    /**
     * Name of the state storing the resource data (e.g authentication.token)
     * @type String
     */
    stateName = null;
    /**
     * Thunk Actions for the resource
     * @type {{fetch: null, retrieve: null, create: null, update: null, delete: null}}
     */
    resourceActions = {fetch: null, retrieve: null, create: null, update: null, delete: null};

    /**
     * Columns to be used to show the tables (exclude counter)
     * @type [{name: string, selector: (row) {}, sortable: bool}]
     */
    columns = [];

    /**
     * Permissions required to access features of this resource
     * @type {{view: [], create: [], update: [], delete: []}}
     */
    permissions = {view: [], create: [], update: [], delete: []};

    /**
     * Initialize the Object
     */
    constructor(user = null) {
        this.user = user;
    }

    /**
     * Get particular data from the state
     * @param state
     * @param name
     * @returns {*}
     */
    selector = (state, name) => this.#getStateValue(state, (this.stateName ?? '') + '.' + name);

    /**
     * Check permission of performing actions on this resource based on list of user permisions
     * @param action
     * @param userPermissions
     * @returns {boolean}
     */
    can = (action, userPermissions = null) => {
        if(userPermissions == null) {
            userPermissions = this.user ? this.user.rolePermissions ?? null : null;
        }
        const permissionNames = this.permissions[action] ?? null;
        if (userPermissions !== undefined && userPermissions !== null && userPermissions.length > 0) {
            const matched = permissionNames.filter(value => userPermissions.includes(value));
            return matched.length > 0;
        }
        return false;
    }
    /**
     * Dispatch a fetch action
     * @param payload
     * @returns {*}
     */
    fetch = (payload) => {
        if(this.resourceActions.fetch != null) { return this.resourceActions.fetch(payload); }
    }
    /**
     * Dispatch a retrieve action
     * @param payload
     * @returns {*}
     */
    retrieve = (payload) => {
        if(this.resourceActions.retrieve != null) { return this.resourceActions.retrieve(payload); }
    }
    /**
     * Dispatch a create action
     * @param payload
     * @returns {payload}
     */
    create = (payload) => {
        if(this.resourceActions.create != null) { return this.resourceActions.create(payload); }
    }
    /**
     * Dispatch a update action
     * @param payload
     * @returns {*}
     */
    update = (payload) => {
        if(this.resourceActions.update != null) { return this.resourceActions.update(payload); }
    }
    /**
     * Dispatch a delete action
     * @param payload
     * @returns {*}
     */
    delete = (payload) => {
        if(this.resourceActions.delete != null) { return this.resourceActions.delete(payload); }
    }

    #getStateValue = (state, path) => {
        let i;
        if (path.length) {
            path = path.split('.');
            for (i = 0; i < path.length - 1; i++) {
                state = state[path[i]];
            }
            return state[path[i]];
        }
        return null;
    }
}

