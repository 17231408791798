import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {inventoryDisposeStock, inventoryItemsGet} from "../../../../../application/features/inventory";
import CustomInput from "../../../../atomic/input";
import {CustomTable} from '../../../../atomic/table/table';
import Currency from '../../../../atomic/formatting/currency';
import {NumberSpinner} from '../../../../atomic/number-spinner';
import CustomSelectInput from '../../../../atomic/select';
import CustomTextArea from '../../../../atomic/text-area';
import moment from 'moment';
import {Spinner} from '../../../../atomic/spinner';
import i18n, {t} from "i18next";

const InventoryDisposalFormPage = () => {
    //hooks
    const navigate = useNavigate();

    //state
    const [description, setDescription] = useState("");
    const [items, setItems] = useState({});
    const [query, setQuery] = useState("");
    const [selected, setSelected] = useState(0);
    const [total, setTotal] = useState(0);
    const [mounted, setMounted] = useState(false);
    const [descriptionError, setDescriptionError] = useState("");

    //store
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.inventory.stock_disposal.isLoading);
    const isSuccessful = useSelector(state => state.inventory.stock_disposal.isSuccessful);
    const stockItems = useSelector(state => state.inventory.item.data);

    //effects
    useEffect(() => {
        dispatch(inventoryItemsGet());
        setMounted(true);
    }, []);

    useEffect(() => {
        if (!mounted) return;
        dispatch(inventoryItemsGet());
        setDescription("");
        setItems({});
        setQuery("");
        setSelected(0);
        setTotal(0);
        navigate('../');
    }, [isSuccessful]);

    useEffect(() => {
        let count = Object.keys(items).length;
        let total = 0;
        
        for (var i in items) {
            console.log("looping: ", i, items[i]);
            total = total + items[i].quantity
        }
        setTotal(total);
        setSelected(count);
    }, [items]);

    //handlers
    const handleChecked = (item) => {
        if (items[item.id]) {
            const newItems = {...items};
            delete newItems[item.id];
            setItems(newItems);
        } else {
            setItems({...items, [item.id]: {...item, "stock_item_id": item.id, "quantity": 0}});
        }
    }

    const handleUpdateQuantity = (item, value) => {
        if (items[item.id]) {
            setItems({...items, [item.id]: {...items[item.id], "quantity": value}});
        }
    }

    const handleSave = () => {
        if (!description) {
            setDescriptionError("Description is required");
            return;
        }
        let _items = [];
        for (var i in items) {
            _items.push(items[i]);
        }
        let payload = {
            "date": new moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            "description": description,
            "items": _items,
        }

        dispatch(inventoryDisposeStock(payload));
    }
    return (
        <div>
            <div className="card">
                <div className="card-header">Inventory Disposal</div>
                <div className="card-body">
                    <div className="mb-2">
                        <div className="row">
                            <div className="col-lg-4 ml-auto">
                                <CustomInput type='search' name="search" placeholder='Search' onChange={(key, value) => setQuery(value)} />
                            </div>
                        </div>
                    </div>
                    <CustomTable isLoading={false}>
                        <thead>
                        
                            <th>#</th>
                            <th>{t('Product Name')}</th>
                            <th>{i18n.t('Quantity')}</th>
                            <th>{i18n.t('Buying Price')}</th>
                            <th>{i18n.t('Selling Price')}</th>
                            <th>{i18n.t('Qty')}</th>
                            <th>{i18n.t('Reason')}</th>
                            <th className="col-actions"></th>
                        
                        </thead>

                        <tbody>
                        {stockItems.filter(i => !query ? true : i.product && i.product.name.toLowerCase().includes(query)).map((item, i) => (
                            <tr key={"tr-" + i}>
                                <td>{i + 1}</td>
                                <td>{item.product ? item.product.name ?? '' : ''}</td>
                                <td>{item.quantity}</td>
                                <td className="text-end"><Currency value={item.buying_price}/></td>
                                <td className="text-end"><Currency value={item.selling_price}/></td>
                                <td>{items[item.id] && <NumberSpinner max={item.quantity} onChange={(value) => handleUpdateQuantity(item, value)} />}</td>
                                <td style={{'max-width': '100px'}}>
                                    {items[item.id] && (
                                        <CustomSelectInput items={[
                                            {"id": "EXPIRED", name: "Expired"},
                                            {"id": "DEFECTIVE", name: "Defective"},
                                            {"id": "OTHERS", name: "Others"}
                                        ]} />
                                    )}
                                </td>
                                <td>
                                    <input type="checkbox" name="" id="" onChange={(event) => handleChecked(item)} selected={items[item.id] ? true : false}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>

                        <tfoot>
                            <th colSpan="5">{i18n.t('Total')}:</th>
                            <th>{total}</th>
                            <th>Selected:</th>
                            <th>{selected}</th>
                        </tfoot>
                    </CustomTable>
                </div>
            </div>

            <CustomTextArea placeHolder='Description' onChange={(key, value) => setDescription(value)} error={descriptionError} />
            <div className="d-flex">
                <button disabled={isLoading} className='btn btn-primary mt-2 ml-auto' onClick={handleSave}>Save {isLoading && <Spinner />}</button>
            </div>
        </div>
    );
};

export default InventoryDisposalFormPage;
