.errorPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;


  .errorContent {
    background-color: #fefefe;
    padding: 5rem;
    min-width: 50%;
    min-height: 50%;
    border-radius: 1rem;

    h3 {
      color: #272727;
      font-size: 30px;
    }

    h5 {
      color: #272727;
      font-size: 24px;
    }

    img {
      max-height: 100px;
    }
  }
}

.fullPage {
  height: 100vh !important;
}
