import React from 'react'
import {Link} from 'react-router-dom';
import CustomInput from '../../../atomic/input';
import { SplashLayout } from '../splash/layout';
import styles from './index.module.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Spinner } from '../../../atomic/spinner';
import { useState } from 'react';
import { onBoardingCreatePassword, onBoardingRegister } from '../../../../application/features/onboarding';
import SweetAlert from 'react-bootstrap-sweetalert';
import authentication, {authenticateRedirected, updateUserToken} from "../../../../application/features/authentication";
import {useLocation, useParams} from "react-router";
import {IconChevronLeft} from "../../../atomic/icons/chevron-left";

export const AuthenticateRedirected = (props) => {
    // hooks
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [payload, setPayload]  = useState({code: null});

    //state
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [authenticationSuccess, setAuthenticationSuccess] = useState(false);

    // redux
    const dispatch = useDispatch();
    const isAuthenticating = useSelector(state => state.authentication.authenticate_redirected.isLoading);
    const isSuccessful = useSelector(state => state.authentication.authenticate_redirected.isSuccessful);
    const authenticateError = useSelector(state => state.authentication.authenticate_redirected.error);
    const response = useSelector(state => state.authentication.authenticate_redirected.data);
    const loginToken  = useSelector(state => state.authentication.token);
    const stateHash = useSelector(state => state.authentication.stateHash);



    // effects
    useEffect(() => {
      if (loginToken) {
          console.log('Token Changed To ', loginToken);
          console.log('Now redirecting...');
          navigate('/main');
      }
    }, [loginToken]);


    useEffect(() => {
        console.log('The response: ', response);
        if(response != null && response.access_token) {
            dispatch(updateUserToken(response));
            setTimeout(function() {
                navigate('/main');
            }, 100);
        } else {
            console.log('Invalid response');
        }
    }, [response]);


    useEffect(() => {
        const code =  searchParams.get('code');
        if(code && code != null && code.length > 0) {
            // console.log('TK', code);
            const newPayload = {code: code};
            setPayload(newPayload);
            console.log('Payload now: ', payload);
        } else {
            console.log('Token not specified: ' + code);
        }
    }, [searchParams]);


    useEffect(() => {
        if(payload.code != null){
            const payloadClone = payload;
            payloadClone['state'] = stateHash;
            dispatch(authenticateRedirected(payloadClone));
        }
    }, [payload]);



    useEffect(() => {
      if (!authenticateError) return;
      if (!authenticateError.errors) setErrors({});
        console.log('ERRORS ', authenticateError);
      setErrorMessage(authenticateError.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000)

      let _errors = {};
      console.log(authenticateError.errors);
      for (let key in authenticateError.errors) {
        _errors[key] = authenticateError.errors[key][0]
      }
      setErrors(_errors);
    }, [authenticateError]);


    const handleAuthenticateRedirected = (data) => {

    }


    return (
    <div className={styles.wrapper}>
        <div className={styles.content}>
            { authenticateError &&
                <div className="alert alert-danger">{authenticateError.message}</div>
            }
            <div className={styles.card}>
                {isAuthenticating && <Spinner/>}
                Redirecting...
            </div>
        </div>
    </div>
    );
}
