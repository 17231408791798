import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";

// Action Constants
const RETURNS_RETURNS_GET = 'sale_returns/return/GET_SALE_RETURNS';
const RETURNS_RETURN_CREATE = 'sale_returns/return/CREATE_RETURN';
const RETURNS_RETURN_RETRIEVE = 'sale_returns/return/RETRIEVE_RETURN';
const RETURNS_RETURN_UPDATE = 'sale_returns/return/UPDATE_RETURN';
const RETURNS_RETURN_DELETE = 'sale_returns/return/DELETE_RETURN';

// Actions
export const returnsReturnsGet = createAsyncThunk(
    RETURNS_RETURNS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.returns, data.payload ?? null, token, store);
    }
);

export const returnsReturnCreate = createAsyncThunk(
    RETURNS_RETURN_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        console.log(data.payload);
        return await customFetch('POST', BizManagerApi.returnSale, data.payload, token, store);
    }
);

export const returnsReturnUpdate = createAsyncThunk(
    RETURNS_RETURN_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.return(data.id), data.payload, token, store);
    }
);

export const returnsReturnRetrieve = createAsyncThunk(
    RETURNS_RETURN_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.return(data.id), data.payload, token, store);
    }
);

export const returnsReturnDelete = createAsyncThunk(
    RETURNS_RETURN_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.return(data.id), data.payload, token, store);
    }
);


// Combined thunks
const returnsResourceThunks = {
    create: returnsReturnCreate,
    get: returnsReturnsGet,
    retrieve: returnsReturnRetrieve,
    update: returnsReturnUpdate,
    delete: returnsReturnDelete,
}

//initial state
const initialState = {
    returns: restResourceInitialState,
}

//redux toolkit slice
const returnsSlice = createSlice({
    'name': 'returns',
    initialState,
    reducers: {},
    extraReducers: {
        ...generateResourceExtraReducers(returnsResourceThunks, "returns"),
    },
});

//exports
export default returnsSlice.reducer;
