import React from 'react';

function IconReports(props) {
    return (
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="currentColor" {...props}>
            <path d="M20,8l-6-6H6C4.896,2,4,2.896,4,4v16c0,1.104,0.896,2,2,2h12c1.104,0,2-0.896,2-2V8z M9,19H7v-9h2V19z M13,19h-2v-6h2V19z M17,19h-2v-3h2V19z M14,9c-0.553,0-1,0-1,0V4l5,5H14z"></path>
    </svg>
    );
}

export default IconReports;