import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {inventoryItemsDelete, inventoryItemsGet} from "../../../../../../application/features/inventory";
import Currency from "../../../../../atomic/formatting/currency";
import {CustomTable} from "../../../../../atomic/table/table";
import {ViewButton} from "../../../../../components/buttons/view-button";
import {EditButton} from "../../../../../components/buttons/edit-button";
import {DeleteButton} from "../../../../../components/buttons/delete-button";
import CustomConfirmAlert from "../../../../../components/notifications/confirm-alert";
import {AddButton} from '../../../../../components/buttons/add-button';
import i18n from "i18next";

export const InventoryItemListPage = () => {
    //state
    const [showAlert, setShowAlert] = useState(false);
    const [deletingId, setDeletingId] = useState();

    //redux
    const stockItems = useSelector(state => state.inventory.item.data);
    const isFetching = useSelector(state => state.inventory.item.isFetching);
    const isDeleting = useSelector(state => state.inventory.item.isDeleting);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(inventoryItemsGet());
    }, []);


    //handlers
    const handleDelete = () => {
        if(deletingId) {
            const data = {id: deletingId};
            dispatch(inventoryItemsDelete(data));
            setDeletingId(null);
            setShowAlert(false);
        }
    }

    return (
      <div className='row'>
          <div className="col-12 text-end mb-2">
                {/* <Link to="form">
                    <button className="btn btn-outline-primary">Add Items to Inventory</button>
                </Link> */}
          </div>
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <div className="d-flex align-items-center">
                        <span className="card-title flex-grow-1">Items</span>
                        <AddButton permissions={['Create Inventory']} to="form" title={i18n.t('Add Item')} />
                    </div>
                </div>
                <div className="card-body table-responsive">
                    <CustomTable isLoading={isFetching}>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{i18n.t('Product Name')}</th>
                        <th width={50}>{i18n.t('Quantity')}</th>
                        <th>{i18n.t('Buying Price')}</th>
                        <th>{i18n.t('Selling Price')}</th>
                        <th className="col-actions">{i18n.t('Actions')}</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        (stockItems && stockItems.length) ?
                        stockItems.map((item, i) => (
                        <tr key={"tr-" + i}>
                            <td>{i + 1}</td>
                            <td>{item.product ? item.product.name ?? '' : ''}</td>
                            <td>{item.quantity} <span className={'small text-black-50'}>{ (item.product.unit && item.product.unit.abbreviation) ? (item.product.unit.abbreviation ?? '') : ''}</span></td>
                            <td className="text-end"><Currency currency={(item.currency) ? (item.currency.code ?? '') : ''} value={item.buying_price}/></td>
                            <td className="text-end"><Currency currency={(item.currency) ? (item.currency.code ?? '') : ''} value={item.selling_price}/></td>
                            <td>
                                <div className="btn-group btn-group-sm" role="group"
                                     aria-label="Button group with nested dropdown">
                                    <ViewButton to={`${item.id}`} alt={"View"}/>
                                    <EditButton to={`form/${item.id}`} alt={"Edit"}/>
                                    <DeleteButton isDeleting={isDeleting && deletingId === item.id} onClick={() => {
                                        setDeletingId(item.id);
                                        setShowAlert(true)
                                    }}/>
                                </div>
                            </td>
                        </tr>
                    ))
                    : <tr/>
                    }
                    </tbody>
                </CustomTable>
                </div>
            </div>
          
        </div>
          <CustomConfirmAlert isShown={showAlert} title="Delete Product?" text="Are you sure you want to delete the product?" onConfirm={handleDelete} toggleAlert={setShowAlert} />
      </div>
    );
}
