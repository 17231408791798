import React from 'react';

function IconUsers(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
            <g>
                <g>
                    <path
                        d="M9.2,13c-0.7,0-1.5-0.2-2.1-0.6C5.8,11.5,5,9.6,5,7.2C5,4.9,6.9,3,9.2,3s4.2,1.9,4.2,4.2c0,2.4-0.8,4.3-2.2,5.2     C10.7,12.8,9.9,13,9.2,13z M9.2,5C8,5,7,6,7,7.2c0,1.7,0.5,3,1.3,3.5c0.6,0.4,1.3,0.4,1.9,0c0.8-0.5,1.3-1.8,1.3-3.5     C11.4,6,10.4,5,9.2,5z"></path>
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M9.2,21C7.3,21,1,20.8,1,17.6c0-3.4,2.8-4.3,4.7-4.9c0.2-0.1,0.7-0.2,1-1.4l2,0.4c-0.4,1.9-1.4,2.6-2.3,2.9     C3.7,15.4,3,16.1,3,17.6C3,18.2,5.9,19,9.2,19c3.3,0,6.2-0.8,6.2-1.4c0-1.5-0.7-2.2-3.3-3.1c-0.8-0.3-1.9-1-2.3-2.9l2-0.4     c0.2,1.1,0.8,1.3,0.9,1.4c1.9,0.6,4.7,1.6,4.7,4.9C17.4,20.8,11.1,21,9.2,21z"></path>
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M17,12.8c-0.6,0-1.1-0.2-1.6-0.5c-1-0.7-1.6-2-1.6-3.8c0-1.8,1.4-3.2,3.2-3.2s3.2,1.4,3.2,3.2c0,1.8-0.6,3.2-1.6,3.9     C18.1,12.6,17.6,12.8,17,12.8z M17,7.2c-0.7,0-1.2,0.5-1.2,1.2c0,1.1,0.3,1.9,0.7,2.2c0.3,0.2,0.7,0.2,0.9,0     c0.5-0.3,0.8-1.1,0.8-2.2C18.2,7.8,17.7,7.2,17,7.2z"></path>
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M13.5,14.6L12.3,13c0.6-0.5,1.3-0.8,2.2-1c0.1,0,0.3-0.1,0.4-0.7l2,0.4c-0.2,1.1-0.8,1.9-1.8,2.2     C14.5,14,13.9,14.3,13.5,14.6z"></path>
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M17,18.3c0,0-0.3,0-0.7,0l0.1-2c0.3,0,0.5,0,0.6,0c1.7,0,3.6-0.4,4-0.7c0-0.8-0.4-1.2-2.1-1.8c-0.9-0.3-1.6-1.1-1.8-2.2     l2-0.4c0.1,0.6,0.4,0.7,0.5,0.7c1.3,0.4,3.5,1.1,3.5,3.7C23,18.1,18.8,18.3,17,18.3L17,18.3z"></path>
                </g>
            </g>
        </svg>
    );
}

export default IconUsers;