import React from 'react';
import NumberFormat from "react-number-format";

const Currency = ({value, places = 2, currency = ''}) => {
    return (
        <NumberFormat
            value={value}
            className="currency"
            displayType={'text'}
            thousandSeparator={true}
            prefix=''
            decimalScale ={places}
            fixedDecimalScale={true}
            suffix={''}
            renderText={(value, props) => <span className='text-end w-100' {...props}><span className='text-black-50 small'>{currency ?? ''} </span>{value}</span>}
        />
    );
};

export default Currency;
