import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {settingsSettingsGet, settingsSettingUpdate} from "../../../../application/features/settings";
import CustomSelectInput from "../../../atomic/select";
import CustomInput from "../../../atomic/input";
import {Spinner} from "../../../atomic/spinner";
import i18n from "i18next";
import {GeneralErrorPage} from '../../../components/templates/error-pages/general-error-page';

export const GeneralSettingsPage = () => {
    //state
    const [payload, setPayload] = useState([]);
    const [error, setError] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [booted, setBooted] = useState(false);
    //hooks

    const dispatch = useDispatch();

    //redux
    const settings = useSelector(state => state.settings.settings.data);
    const isFetching = useSelector(state => state.settings.settings.isFetching);
    const isCreating = useSelector(state => state.settings.settings.isCreating);
    const isUpdating = useSelector(state => state.settings.settings.isUpdating);
    const fetchingSuccessful = useSelector(state => state.settings.settings.fetchingSuccessful);
    const updatingSuccessful = useSelector(state => state.settings.settings.updatingSuccessful);
    const updatingError = useSelector(state => state.settings.settings.updatingError);

    //eff
    useEffect(() => {
        setBooted(true);
        dispatch(settingsSettingsGet());
    }, []);

    useEffect(() => {
        if (settings) {
            setPayload(settings.filter((item) => {
                return {id: item.id, key: item.key, value: item.value};
            }));
            if (settings.length) {
                const lang = settings.find((i) => i.key === 'LANGUAGE').value;
                if (i18n.language !== lang) {
                    i18n.changeLanguage(lang).then(res => {
                        console.log('LANG', res);

                    });
                }

            }
        }
    }, [settings, fetchingSuccessful]);

    useEffect(() => {
        if (!updatingSuccessful) return;
        console.log('Update Successful')
        const lang = settings.find((i) => i.key === 'LANGUAGE').value;
        console.log('LNG', lang);
        console.log('18', i18n.language);

    }, [updatingSuccessful]);

    useEffect(() => {
        if (!updatingError) return;
        setError(updatingError.message);
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
            setError(0);
        }, 5000)
    }, [updatingError]);


    const handleChange = (key, value) => {
        let p = [...payload];
        p = p.map(e => ((e.key === key) ? ({...e, value: value}) : e));
        setPayload(p);
    }

    const submit = () => {
        if (payload) {
            dispatch(settingsSettingUpdate({payload: {settings: payload}}));
        }
    }

    if (!settings) {
        return <div>Not found</div>
    }

    if (isFetching) {
        return <Spinner/>
    }


    if (!payload.length) {
        return (
            <div>
                <GeneralErrorPage title="" subTitle="There are currently no settings"/>
            </div>
        )
    }


    return (
        <div className="row">
            <div className="col-12 col-xl-8">
                <div className="card">
                    <div className="card-header">{i18n.t('System General Settings')}</div>
                    <div className="card-body">
                        <div>
                            {showAlert && <div className="alert alert-danger">{error}</div>}
                        </div>
                        <ul className="list-group">
                            {
                                settings.map((item, i) => (
                                    <li key={i} className="list-group-item d-flex flex-row">
                                        <span className="flex-grow-1">{item.description ?? item.key}</span>
                                        <span>
                                        {
                                            (item.type === 'OPTION') ?
                                                <CustomSelectInput name={"setting-" + i} value={payload[i].value}
                                                                   items={item.options.map(e => ({id: e, name: e}))}
                                                                   onChange={(key, value) => handleChange(item.key, value)}/>
                                                : (item.type === 'SWITCH') ?
                                                    <CustomInput name={"setting-" + i} value={payload[i].value}
                                                                 onChange={(key, value) => handleChange(item.key, value)}/>
                                                    : <CustomInput name={"setting-" + i} value={payload[i].value}
                                                                   onChange={(key, value) => handleChange(item.key, value)}/>
                                        }
                                        </span>
                                    </li>
                                ))
                            }
                        </ul>
                        <div className="mt-3 text-end">
                            <button onClick={submit} disabled={isCreating || isUpdating}
                                    className="btn btn-primary">{(isCreating || isUpdating) ?
                                <Spinner/> : "Save Settings"}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-xl-4">

            </div>
        </div>
    )
}
