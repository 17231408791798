import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";

// Action Constants
// const SALES_SALES_GET = 'sales/sale/GET_SALES';
const SALES_SALE_CREATE = 'sales/sale/CREATE_SALE';
const SALES_SALE_RETRIEVE = 'sales/sale/RETRIEVE_SALE';
const SALES_SALE_UPDATE = 'sales/sale/UPDATE_SALE';
const SALES_SALE_DELETE = 'sales/sale/DELETE_SALE';

const SALES_PRODUCT_SEARCH = 'sales/product/SEARCH';
const SALES_SALE_RECORD = 'sales/sale/RECORD';
const SALES_SALES_GET = 'sales/sales/GET';

// Actions

export const salesProductSearch = createAsyncThunk(
    SALES_PRODUCT_SEARCH,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.salesProductSearch, data.params, token, store);
    }
);

export const salesSaleRecord = createAsyncThunk(
    SALES_SALE_RECORD,
    async (data, store) => {
        const { token } = store.getState().authentication;
        const { payload } = store.getState().sales;

        //appending to the payload
        let today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const H = String(today.getHours()).padStart(2, '0');
        const i = String(today.getMinutes()).padStart(2, '0');
        const s = String(today.getSeconds()).padStart(2, '0');

        today = yyyy + "-" + mm + "-" + dd + " " + H + ":" + i + ":" + s;
        const newPayload = {...payload, date: today};

        console.log("appending date", newPayload);
        return await customFetch('POST', BizManagerApi.salesRecordSale, newPayload, token, store);
    }
);

export const salesSalesGet = createAsyncThunk(
    SALES_SALES_GET,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.sales, data, token, store);
    }
);
export const salesSalesRetrieve = createAsyncThunk(
    SALES_SALE_RETRIEVE,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.sale(data.id), null, token, store);
    }
);


export const salesSalesDelete = createAsyncThunk(
    SALES_SALE_DELETE,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('Delete', BizManagerApi.sale(data.id),  null, token, store);
    }
);

export const salesSaleUpdate = createAsyncThunk(
    SALES_SALE_UPDATE,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('Delete', BizManagerApi.sale(data.id),  null, token, store);
    }
);


// Combined thunks
const salesResourceThunks = {
    create: null,
    get: salesSalesGet,
    retrieve: salesSalesRetrieve,
    update: null,
    delete: salesSalesDelete,
}

const initialPayload = {
    date: null,
    customer_name: "",
    customer_id: null,
    returned_amount: 0,
    received_amount: 0,
    total_amount: 0,
    offers: [],
    discounts: [],
    items: [],
}

//initial state
const initialState = {
    sales: restResourceInitialState,
    recordSale: {
        isRecording: false,
        recorded: false,
        error: null,
        validationErrors: {},
    },
    search: {
        isSearching: false,
        searched: false,
        error: null,
        results: [],
    },
    payload: initialPayload,
}


//redux toolkit slice
const salesSlice = createSlice({
    'name': 'sales',
    initialState,
    reducers: {
        saleUpdatePayload: (state, action) => {
            state.payload = {...state.payload, [action.payload.key]: action.payload.value};
        },
        saleAppendItemToPayload: (state, action) => {
            state.payload.items.push(action.payload);
        },
        saleRemoveItemFromPayload: (state, action) => {
            state.payload.items.splice(action.payload, 1);
        },
        saleUpdateItemToPayload: (state, action) => {
            state.payload.items[action.payload.index] = {
                ...state.payload.items[action.payload.index], 
                ...action.payload.data,
            };
        },
        saleClearSearchResults: (state, action) => {
            state.search.results = [];
        }
    },
    extraReducers: {
        ...generateResourceExtraReducers(salesResourceThunks, "sales"),
        [salesProductSearch.pending]: (state, action) => {
            state.search.isSearching = true;
            state.search.searched = false,
            state.search.error = null;
            state.search.results = [];
        },
        [salesProductSearch.fulfilled]: (state, action) => {
            state.search.isSearching = false;
            state.search.searched = true,
            state.search.results = action.payload.data;
        },
        [salesProductSearch.rejected]: (state, action) => {
            state.search.isSearching = false;
            state.search.error = action.error;
        },
        [salesSaleRecord.pending]: (state, action) => {
            state.recordSale.isRecording = true;
            state.recordSale.recorded = false;
        },
        [salesSaleRecord.fulfilled]: (state, action) => {
            state.recordSale.isRecording = false;
            state.recordSale.recorded = true;
            state.payload = initialPayload;
            state.search = {
                isSearching: false,
                searched: false,
                error: null,
                results: [],
            };
        },
        [salesSaleRecord.rejected]: (state, action) => {
            state.recordSale.isRecording = false;
            state.recordSale.recorded = false;
            if(action.payload != undefined && action.payload.errors != null) {
                state.error = action.payload;
                state.validationErrors = action.payload.errors;
            } else {
                state.error = action.error;
                state.validationErrors = {};
            }

        },
    },
});

//exports
export const { saleUpdatePayload, saleAppendItemToPayload, saleRemoveItemFromPayload, saleUpdateItemToPayload, saleClearSearchResults } = salesSlice.actions
export default salesSlice.reducer;
