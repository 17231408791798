import {useDispatch, useSelector} from "react-redux";
import avatar from '$assets/img/avatar.svg';
import {useEffect, useState} from "react";
import {Spinner} from "../../../../atomic/spinner";
import CustomInput from "../../../../atomic/input";
import i18n from "i18next";
import CustomDatePicker from "../../../../atomic/datapicker";
import React from "react";
import {updatePassword} from "../../../../../application/features/authentication";

export const UserProfile = () => {
    const [userAvatar, setUserAvatar] = useState(avatar);
    const [showError, setShowError] = useState(false);

    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const isChangingPassword = useSelector(state => state.authentication.update_password.isUpdating);
    const changingPasswordSuccessful = useSelector(state => state.authentication.update_password.updatingSuccessful);
    const changingPasswordFailed = useSelector(state => state.authentication.update_password.updatingError);
    const validationErrors = useSelector(state => state.authentication.update_password.validationErrors);

    const [passwordPayload, setPasswordPayload] = useState({
        old_password: null,
        new_password: null,
        confirm_new_password: null
    });

    useEffect(() => {
        if (user && user.avatar) {
            setUserAvatar(user.avatar);
        }
    }, []);

    useEffect(() => {
        if(changingPasswordFailed){
            setTimeout(() => {
                //
            }, 5000);
        }
    }, [changingPasswordFailed]);

    const handleFormChange = (field, value) => {
        const payload = {...passwordPayload};
        payload[field] = value;
        setPasswordPayload(payload)
    }

    const changePassword = () => {
        const _payload = {...passwordPayload};
        dispatch(updatePassword(_payload));
    }

    if (user == undefined || user == null) {
        return <Spinner/>
    }
    return (
        <div className={"px-5 py-4"}>
            <div className="row">
                <h5 className="col-12">{user.name ?? 'Unspecified'}</h5>
                <hr className={'text-muted'}/>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-md-8">

                            <table className={"table custom-table"}>
                                <tbody>
                                <tr>
                                    <td width={120}>
                                        <div className={'mb-2'}><span>Name</span>:</div>
                                    </td>
                                    <td>
                                        <div className={'form-control'}> {user.name ?? 'No Name'}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={'mb-2'}><span>Email</span>:</div>
                                    </td>
                                    <td>
                                        <div className={'form-control'}> {user.email ?? 'No email'}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={'mb-2'}><span>Phone No</span>:</div>
                                    </td>
                                    <td>
                                        <div className={'form-control'}> {user.phone ?? 'Not specified'}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={'mb-2'}><span>Roles</span>:</div>
                                    </td>
                                    <td>
                                        <div>
                                            {user.role_names.filter(u => u).map((role, i) => (
                                                <span key={i} className={"badge bg-primary px-1 py-2 me-1"}>{role}</span>
                                            ))
                                            }
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-12 col-md-4 text-center py-2" style={{"border": "1px solid #ddd"}}>
                            <img src={userAvatar} height={100}/>
                        </div>
                    </div>
                </div>
                <hr className={'mt-4 text-black-50'}/>
                {
                    (user && (user.galaxy_user_id)) ? '' :
                    <div className="col-12 col-md-6 mt-4">
                        <h5>Change Password</h5>
                        <div>
                            <form>
                                <CustomInput type={'password'} name='old_password' label={"Old Password"}
                                             placeholder={"Old Password"}
                                             onChange={(val) => handleFormChange('old_password', val)} required/>
                                <CustomInput type={'password'} name='new_password' label={"New Password"}
                                             placeholder={"Old Password"}
                                             onChange={(val) => handleFormChange('new_password', val)} required/>
                                <CustomInput type={'password'} name='confirm_new_password' label={"Repeat Password"}
                                             placeholder={"Repeat Password"}
                                             onChange={(val) => handleFormChange('confirm_new_password', val)}
                                             required/>
                                <button type={'button'} className={'btn btn-primary'} disabled={isChangingPassword}
                                        onClick={changePassword}>Change Password
                                </button>
                            </form>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
