import React from 'react'

export const IconNotification = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" {...props} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.9999 4.85455C8.73578 4.85455 6.83064 6.50224 6.56827 8.68728L6.2892 11.0115C6.20373 11.7233 5.89793 12.3932 5.41222 12.9326C4.96495 13.4292 5.32796 14.2065 6.00719 14.2065H15.9925C16.6718 14.2065 17.0348 13.4292 16.5875 12.9326C16.1018 12.3932 15.796 11.7233 15.7105 11.0115L15.4314 8.68728C15.1691 6.50224 13.2639 4.85455 10.9999 4.85455ZM5.20254 8.53247C5.54576 5.67404 8.03802 3.51855 10.9999 3.51855C13.9617 3.51855 16.454 5.67404 16.7972 8.53247L17.0763 10.8567C17.1295 11.2999 17.3199 11.717 17.6223 12.0528C18.8475 13.4133 17.8531 15.5425 15.9925 15.5425H6.00719C4.14662 15.5425 3.15223 13.4133 4.37743 12.0528C4.67984 11.717 4.87024 11.2999 4.92346 10.8567L5.20254 8.53247Z" fill="#414754"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.9999 4.85455C8.73578 4.85455 6.83064 6.50224 6.56827 8.68728L6.2892 11.0115C6.20373 11.7233 5.89793 12.3932 5.41222 12.9326C4.96495 13.4292 5.32796 14.2065 6.00719 14.2065H15.9925C16.6718 14.2065 17.0348 13.4292 16.5875 12.9326C16.1018 12.3932 15.796 11.7233 15.7105 11.0115L15.4314 8.68728C15.1691 6.50224 13.2639 4.85455 10.9999 4.85455ZM5.20254 8.53247C5.54576 5.67404 8.03802 3.51855 10.9999 3.51855C13.9617 3.51855 16.454 5.67404 16.7972 8.53247L17.0763 10.8567C17.1295 11.2999 17.3199 11.717 17.6223 12.0528C18.8475 13.4133 17.8531 15.5425 15.9925 15.5425H6.00719C4.14662 15.5425 3.15223 13.4133 4.37743 12.0528C4.67984 11.717 4.87024 11.2999 4.92346 10.8567L5.20254 8.53247Z" fill="black" fillOpacity="0.2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.854 16.6558C9.854 17.2706 10.367 17.7691 10.9998 17.7691C11.6327 17.7691 12.1457 17.2706 12.1457 16.6558H13.5207C13.5207 18.0085 12.3921 19.1051 10.9998 19.1051C9.60762 19.1051 8.479 18.0085 8.479 16.6558H9.854Z" fill="#414754"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.854 16.6558C9.854 17.2706 10.367 17.7691 10.9998 17.7691C11.6327 17.7691 12.1457 17.2706 12.1457 16.6558H13.5207C13.5207 18.0085 12.3921 19.1051 10.9998 19.1051C9.60762 19.1051 8.479 18.0085 8.479 16.6558H9.854Z" fill="black" fillOpacity="0.2"/>
        <path d="M19.8742 5.06038C19.8742 7.56554 17.78 9.62075 15.1661 9.62075C12.5522 9.62075 10.458 7.56554 10.458 5.06038C10.458 2.55521 12.5522 0.5 15.1661 0.5C17.78 0.5 19.8742 2.55521 19.8742 5.06038Z" fill="#FF830D" stroke="white"/>
    </svg>
  )
}
