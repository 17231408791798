import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";

// Action Constants
const REPORTS_SALES_GET = 'reports/reports/GET_SALES_REPORT';
const REPORTS_PURCHASES_GET = 'reports/reports/GET_PURCHASES_REPORT';
const REPORTS_EXPENSES_GET = 'reports/reports/GET_EXPENSES_REPORT';
const REPORTS_RETURNS_GET = 'reports/reports/GET_RETURNS_REPORT';
const REPORTS_WASTE_GET = 'reports/reports/GET_WASTE_REPORT';
const REPORTS_MONTHLY_SALES_GET = 'reports/reports/GET_MONTHLY_SALES_REPORT';

// Actions
export const reportsSalesGet = createAsyncThunk(
    REPORTS_SALES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.salesReport, null, token, store);
    }
);

export const reportsMonthlySalesGet = createAsyncThunk(
    REPORTS_MONTHLY_SALES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.monthlySalesReport, null, token, store);
    }
);

export const reportsPurchasesGet = createAsyncThunk(
    REPORTS_PURCHASES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.purchasesReport, data.payload, token, store);
    }
);

export const reportsExpensesGet = createAsyncThunk(
    REPORTS_EXPENSES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.expensesReport, data.payload, token, store);
    }
);

// Combined thunks
const reportsSalesThunks = {
    get: reportsSalesGet,
}

const reportsMonthlySalesThunks = {
    get: reportsMonthlySalesGet,
}

const reportsPurchasesThunks = {
    get: reportsPurchasesGet,
}
const reportsExpensesThunks = {
    get: reportsExpensesGet,
}

//initial state
const initialState = {
    monthly_sales_report: restResourceInitialState,
    purchases_report: restResourceInitialState,
    sales_report: restResourceInitialState,
    expenses_report: restResourceInitialState,
}

//redux toolkit slice
const reportsSlice = createSlice({
    'name': 'reports',
    initialState,
    reducers: {},
    extraReducers: {
        ...generateResourceExtraReducers(reportsSalesThunks, "sales_report"),
        ...generateResourceExtraReducers(reportsMonthlySalesThunks, "monthly_sales_report"),
        ...generateResourceExtraReducers(reportsPurchasesThunks, "purchases_report"),
        ...generateResourceExtraReducers(reportsExpensesThunks, "expenses_report"),
    },
});

//exports
export default reportsSlice.reducer;
