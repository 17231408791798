import styles from './blank-page.module.scss';
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
export const BlankPage = (props) => {

    const token = useSelector(state => state.authentication.token);
    const navigate = useNavigate();

    useEffect(() => {
        if (token) return;
        navigate('/authentication/login');
    }, [token]);

    const {children } = props;
    return (
        <div className={(styles.blankPage) + ' full-page' }>
            <div className={styles.blankContent}>
                {children}
            </div>
        </div>
    )
}

BlankPage.defaultProps = {

}
