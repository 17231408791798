import React from 'react'

export const IconCart = (props) => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="currentColor" {...props}>
            <g>
                <path d="M21.822 7.43c-.19-.27-.5-.44-.83-.44H7.32L6.17 4.22c-.32-.75-1.04-1.23-1.846-1.23H1.99v2H4.32l4.744 11.38c.15.37.51.61.923.61h8c.41 0 .79-.26.93-.65l3-8c.11-.31.07-.66-.12-.93Zm-4.52 7.56h-6.64l-2.5-6h11.39l-2.25 6Z"></path>
                <path d="M10.5 18a1.5 1.5 0 1 0 0 3 1.5 1.5 0 1 0 0-3Z"></path>
                <path d="M17.5 18a1.5 1.5 0 1 0 0 3 1.5 1.5 0 1 0 0-3Z"></path>
            </g>
        </svg>
  );
}
