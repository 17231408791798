import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {
    userRolesSynchronize,
    usersPermissionsGet,
    usersUserRetrieve,
    usersUsersGet
} from "../../../../../application/features/users";
import {CustomTable} from "../../../../atomic/table/table";
import {Spinner} from "../../../../atomic/spinner";
import PageAlert from "../../../../components/notifications/page-alert";
import {settingsRolesGet} from "../../../../../application/features/settings";

const UserViewPage = () => {
    //state
    const [user, setUser] = useState();
    const [userRoles, setUserRoles] = useState([]);

    //hooks
    const dispatch = useDispatch();
    const {user_id} = useParams();

    //redux
    const users = useSelector(state => state.users.users.data);
    const isFetchingUsers = useSelector(state => state.users.users.isFetching);
    const isRetrievingUsers = useSelector(state => state.users.users.isRetrieving);

    // const permissions = useSelector(state => state.users.permissions.data);
    const roles = useSelector(state => state.settings.roles.data);
    // const isFetchingRoles = useSelector(state => state.stettings.roles.isFetching);
    // const isRetrievingRoles = useSelector(state => state.settings.roles.isRetrieving);

    useEffect(() => {
        dispatch(settingsRolesGet());
    }, []);
    useEffect(() => {
        dispatch(usersUserRetrieve({id: user_id}));
        dispatch(usersPermissionsGet());
        if (roles == null) {
            dispatch(settingsRolesGet());
        }
    }, [user_id]);

    useEffect(() => {
        if (!users) return;
        setUser(users.find(u => u.id == user_id) ?? null);
    }, [users])

    useEffect(() => {
        if (!user) return;
        setUserRoles(user.role_names)
    }, [user])


    const syncRoles = () => {
        console.log('Syncing...');
        const payload = {
            user_id: user_id,
            roles: userRoles
        };
        console.log('PAYLOAD', payload);
        dispatch(userRolesSynchronize({payload}));
    }

    const handleChange = (role) => {
        let _userRoles = [...userRoles];
        if (_userRoles.indexOf(role) > -1) {
            console.log('Yes');
            _userRoles = _userRoles.filter(i => i != role);
        } else {
            _userRoles.push(role);
        }
        setUserRoles(_userRoles);
        console.log('USER ROLES', _userRoles);

    }

    if (!user) {
        return (
            <PageAlert> User Not found</PageAlert>
        );
    }
    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">&nbsp;</div>
                    <div className="card-body">
                        <CustomTable isLoading={isFetchingUsers || isRetrievingUsers}>
                            <tbody>
                            <tr>
                                <td width="30%">Name</td>
                                <td>{user.name}</td>
                            </tr>
                            <tr>
                                <td>Email Address</td>
                                <td>{user.email ?? ''}</td>
                            </tr>
                            <tr>
                                <td>Phone Number</td>
                                <td>{user.phone ?? ''}</td>
                            </tr>
                            <tr>
                                <td>Roles</td>
                                <td>{
                                    (user.role_names.filter(u => u).map((role, i) => (
                                        <span key={'n' + i} className="me-1 badge bg-primary">{role}</span>
                                    )))
                                }</td>
                            </tr>
                            </tbody>
                        </CustomTable>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-3">
                <div className="card">
                    <div className="card-body">
                        <table className={'table table-bordered'}>
                            <thead>
                            <tr>
                                <th width={20}>#</th>
                                <th>Role</th>
                                <th>Permissions</th>
                                <th width={50}>Assign</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                (roles != null) ?
                                    (roles.filter(u => u).map((role, i) => (
                                        <tr key={'tr' + i}>
                                            <td>{(i + 1)}</td>
                                            <td><span className={'h6'}>{role.name}</span></td>
                                            <td>
                                                {
                                                    ([...role.permissions].map((j) => j.group).filter((x, i, a) => a.indexOf(x) == i).map((permission, j) => (
                                                        <span key={'tr' + i + 'r' + j} className={"me-1"}>{permission} </span>
                                                    )))
                                                }
                                            </td>
                                            <td className={'text-center'} style={{fontSize: "1.1rem"}}>
                                                <span style={{cursor: 'pointer'}}
                                                      onClick={() => handleChange(role.name)}
                                                      className={(userRoles.indexOf(role.name) > -1) ? 'checked' : ''}>
                                                    <span className={(userRoles.indexOf(role.name) > -1) ? 'text-success' : 'text-black-50'}>[{(userRoles.indexOf(role.name) > -1) ? '✓' : '  '}]</span>
                                                </span>
                                            </td>
                                        </tr>
                                    )))
                                    :
                                    <div>Failed to fetch roles</div>
                            }
                            </tbody>
                        </table>
                        <div className={"row"}>
                            <div className="col-6"></div>
                            <div className="col-6 text-end">
                                <button className={'btn btn-primary'} onClick={syncRoles}>Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default UserViewPage;
