import React from 'react';
import {useParams} from "react-router";
import {useSelector} from "react-redux";

const StoreViewPage = () => {
    const { store_id } = useParams();

    const item = useSelector(state => state.inventory.stores.data.find(e => e.id == store_id));
    if(!item) {
        return <div> Item not found!</div>
    }
    return (
        <div className="card">
            <div className="card-body">
                <div>
                    <h4>{item.name}</h4>
                    <hr/>
                    Location: <span>{item.location ?? 'Unknown'}</span>
                </div>
            </div>
        </div>
    );
};

export default StoreViewPage;
