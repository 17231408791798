@import '$assets/scss/main.scss';

.wrapper {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;

    display: flex;
}

.nav {
    width: 250px;
    z-index: $top;

    @media only screen and (max-width: 676px) {
        z-index: $top;
        position: fixed;
        top: 0;
        left: -250px;
        bottom: 0;
        transition: all 0.3s ease-in;

        &.showSideMenu {
            left: 0;
            transition: all 0.3s ease-out;
        }
    }
}

.content {
    flex: 1;
    background-color: $surface-light;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.sideMenuOverlay {
    position: fixed;
    display: none;
    height: 100vh;
    width: 100vw;
    background-color: rgba($color: #000000, $alpha: 0.3);
    z-index: $low;

    @media only screen and (max-width: 676px) { 
        display: block;
    }
}