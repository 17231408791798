import {BlankPage} from "../../../components/templates/layouts/blank-page";
import {useDispatch} from "react-redux";
import {logout} from "../../../../application/features/authentication";
import {useNavigate} from "react-router";

export const SubscriptionExpired = () => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
    }
    return (
        <BlankPage>
            <div>
                <h4>Subscription Expired!</h4>
                <p>
                    Sorry, Your subscription has expired. <br />
                    Please go to <a href={'https://softwaregalaxy.com'}>SoftwareGalaxy</a> and renew your subscription
                </p>
                <div>

                    <button className="btn btn-outline-primary" onClick={handleLogout}>
                        Logout
                    </button>
                </div>
            </div>
        </BlankPage>
    )
}
