import React from 'react';
import bizmanager from "../../../../api/bizmanager";

const downloadUrl = bizmanager.exportMonthlyWasteReport;

const MonthlyWasteReportPage = () => {
    return (
        <div>
            <h5>Monthly Waste Report</h5>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Monty</th>
                    <th>Sales</th>
                    <th>Profit</th>
                    <th></th>
                </tr>
                </thead>
            </table>
        </div>
    );
};

export default MonthlyWasteReportPage;
