import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";
import {organizationsOrganizationSetActive} from "./organizations";

// Action Constants
const SETTINGS_SETTINGS_GET = 'settings/settings/GET_SETTINGS';
const SETTINGS_SETTING_UPDATE = 'settings/settings/SAVE_SETTINGS';
const SETTINGS_SETTING_CREATE = 'settings/settings/CREATE_SETTINGS';
const SETTINGS_SETTING_RETRIEVE = 'settings/settings/RETRIEVE_SETTINGS';
const SETTINGS_SETTING_DELETE = 'settings/settings/DELETE_SETTINGS';

const SETTINGS_METADATA_GET = 'settings/roles/GET_METADATA';
const SETTINGS_ROLES_GET = 'settings/roles/GET_ROLES';

const SETTINGS_PERMISSIONS_GET = 'settings/permissions/PERMISSIONS_GET';

// Actions
export const settingsSettingsGet = createAsyncThunk(
    SETTINGS_SETTINGS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.settings_get, null, token, store);
    }
);

export const settingsSettingCreate = createAsyncThunk(
    SETTINGS_SETTING_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.settings, data.payload, token, store);
    }
);

export const settingsSettingUpdate = createAsyncThunk(
    SETTINGS_SETTING_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.settings_update, data.payload, token, store);
    }
);

export const settingsSettingRetrieve = createAsyncThunk(
    SETTINGS_SETTING_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.setting(data.id), data.payload, token, store);
    }
);

export const settingsSettingDelete = createAsyncThunk(
    SETTINGS_SETTING_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.setting(data.id), data.payload, token, store);
    }
);

// ROLES
export const settingsRolesGet = createAsyncThunk(
    SETTINGS_ROLES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.roles_get, null, token, store);
    }
);

export const settingsPermissionsGet = createAsyncThunk(
    SETTINGS_PERMISSIONS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.get_permissions, null, token, store);
    }
);

export const settingsMetadataGet = createAsyncThunk(
    SETTINGS_METADATA_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.getMetaData, null, token, store);
    }
);


// Combined thunks
const settingsResourceThunks = {
    get: settingsSettingsGet,
    create: settingsSettingCreate,
    update: settingsSettingUpdate,
    retrieve: settingsSettingRetrieve,
    delete: settingsSettingDelete,
    getMetadata: settingsMetadataGet,
}

const metadataResourceThunks = {
    get: settingsMetadataGet,
}

const rolesResourceThunks = {
    get: settingsRolesGet,
    create: null,
    update: null,
    retrieve: null,
    delete: null,
}

const permissionsResourceThunks = {
    get: settingsPermissionsGet,
    create: null,
    update: null,
    retrieve: null,
    delete: null,
}

//initial state
const initialState = {
    settings: restResourceInitialState,
    roles: restResourceInitialState,
    permissions: restResourceInitialState,
    metadata: {...restResourceInitialState, data: {loaded: null, language: 'en'}}
}

//redux toolkit slice
const settingsSlice = createSlice({
    'name': 'settings',
    initialState,
    reducers: {},
    extraReducers: {
        ...generateResourceExtraReducers(settingsResourceThunks, "settings"),
        ...generateResourceExtraReducers(rolesResourceThunks, "roles"),
        ...generateResourceExtraReducers(permissionsResourceThunks, "permissions"),
        ...generateResourceExtraReducers(metadataResourceThunks, "metadata"),
        // [organizationsOrganizationSetActive.fulfilled]: (state, action) => {
        //     state.settings.data = [];
        // },
    },
});

//exports
export default settingsSlice.reducer;
