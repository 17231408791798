import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import {
    expensesExpenseTypesCreate,
    expensesExpenseTypesDelete,
    expensesExpenseTypesGet,
    expensesExpenseTypesRetrieve,
    expensesExpenseTypesUpdate,
} from "../features/expenses";
import DateFormat from "../../presentation/atomic/formatting/date-format";
import Currency from "../../presentation/atomic/formatting/currency";
import i18n from "i18next";

/**
 * @inheritDoc
 */
export class  ExpenseTypesResource extends GalaxyResource {
    resourceActions = {
        fetch: expensesExpenseTypesGet,
        retrieve: expensesExpenseTypesRetrieve,
        create: expensesExpenseTypesCreate,
        update: expensesExpenseTypesUpdate(),
        delete: expensesExpenseTypesDelete,
    };
    stateName = 'expenses.expense_types';
    columns = [
        {
            name: '#',
            center: false,
            width: '40px',
            cell: (row, index) => (index + 1),
        },
        {
            name: i18n.t('Name'),
            grow: 0,
            minWidth: '120px',
            sortField: 'date',
            selector: (row) => <span>{row.name ?? ''}</span>,
            sortable: true,

        },
        {
            name: "Description",
            cell: (row) => <span>{row.description ?? ''}</span>,
            sortable: true,
        },
    ];
    permissions = {
        view: ['View Expenses'],
        create: ['Create Expenses'],
        update: ['Create Expenses'],
        delete: ['Delete Expenses'],
    }
}

export default ExpenseTypesResource;
