import React from 'react'
import { ErrorBoundary } from 'react-error-boundary';
import {BrowserRouter, Routes, Route, Navigate, useNavigate} from 'react-router-dom';

import { authRoutes, AuthRoutes } from './auth/routes';
import { LandingPage } from './landing-page/landing-page';
import { MainRoutes } from './main/routes';
import { SplashPage } from './auth/splash/index';
import { OnBoardingRoutes } from './on-boarding/routes';
import { NotFoundPage } from './not-found';
import {AuthenticateRedirected} from "./auth/authenticate-redirected";
import {LoginPage} from "./auth/login";
import {ChooseOrganization} from "./interrupts/choose-organization/choose-organization";
import {SubscriptionExpired} from "./interrupts/subscription/subscription-expired";
import {NoSubscription} from "./interrupts/subscription/no-subscription";
import {useSelector} from "react-redux";


export const AppRoutes = () => {
  const user = useSelector(state => state.authentication.user);
  if(!user) {
    console.log('No user signed..');
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route index  element={<LoginPage />} />
        <Route path="authentication/*" element={<AuthRoutes />} />
        <Route path="onboarding/*" element={<OnBoardingRoutes />} />
        <Route path='main/*' element={<MainRoutes />} breadcrumb="Dashboard" />
        <Route path='/choose-organization' element={<ChooseOrganization />} breadcrumb="Choose Organization" />
        <Route path='/subscription-expired' element={<SubscriptionExpired />} breadcrumb="Subscription" />
        <Route path='/no-subscription' element={<NoSubscription />} breadcrumb="Subscription" />
        <Route exact path="docs/" to={<Navigate push to={"docs/index.html"} />} />
        <Route path="galaxy-login" element={<AuthenticateRedirected />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
