import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";

// Action Constants
const NOTIFICATIONS_NOTIFICATIONS_GET = 'notifications/notifications/GET_NOTIFICATIONS';
const NOTIFICATIONS_NOTIFICATION_UPDATE = 'notifications/notifications/SAVE_NOTIFICATIONS';
const NOTIFICATIONS_NOTIFICATION_RETRIEVE = 'notifications/notifications/RETRIEVE_NOTIFICATIONS';
const NOTIFICATIONS_NOTIFICATION_DELETE = 'notifications/notifications/DELETE_NOTIFICATIONS';

const NOTIFICATIONS_ROLES_GET = 'notifications/roles/GET_ROLES';

// Actions
export const notificationsNotificationsGet = createAsyncThunk(
    NOTIFICATIONS_NOTIFICATIONS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.notifications, data, token, store);
    }
);
export const notificationsNotificationUpdate = createAsyncThunk(
    NOTIFICATIONS_NOTIFICATION_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.notifications, data.payload, token, store);
    }
);

export const notificationsNotificationRetrieve = createAsyncThunk(
    NOTIFICATIONS_NOTIFICATION_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.notifications_edit(data.id), data.payload, token, store);
    }
);

export const notificationsNotificationDelete = createAsyncThunk(
    NOTIFICATIONS_NOTIFICATION_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.notifications_edit(data.id), data.payload, token, store);
    }
);


// Combined thunks
const notificationsResourceThunks = {
    get: notificationsNotificationsGet,
    create: null,
    update: notificationsNotificationUpdate(),
    retrieve: notificationsNotificationRetrieve,
    delete: notificationsNotificationDelete,
}


//initial state
const initialState = {
    notifications: restResourceInitialState,
}

//redux toolkit slice
const notificationsSlice = createSlice({
    'name': 'notifications',
    initialState,
    reducers: {},
    extraReducers: {
        ...generateResourceExtraReducers(notificationsResourceThunks, "notifications"),
    },
});

//exports
export default notificationsSlice.reducer;
