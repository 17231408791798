import {generateResourceExtraReducers, restResourceInitialState, restThunk} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";
import {organizationsOrganizationSetActive} from "./organizations";


// Action Constants
const PRODUCTS_PRODUCTS_GET = 'products/product/GET_PRODUCTS';
const PRODUCTS_PRODUCT_CREATE = 'products/product/CREATE_PRODUCT';
const PRODUCTS_PRODUCT_RETRIEVE = 'products/product/RETRIEVE_PRODUCT';
const PRODUCTS_PRODUCT_UPDATE = 'products/product/UPDATE_PRODUCT';
const PRODUCTS_PRODUCT_DELETE = 'products/product/DELETE_PRODUCT';

const PRODUCTS_PRODUCT_CATEGORIES_GET = 'products/product_categories/PRODUCT_CATEGORIES_GET';
const PRODUCTS_PRODUCT_CATEGORIES_CREATE = 'products/product_categories/PRODUCT_CATEGORIES_CREATE';
const PRODUCTS_PRODUCT_CATEGORIES_RETRIEVE = 'products/product_categories/PRODUCT_CATEGORIES_RETRIEVE';
const PRODUCTS_PRODUCT_CATEGORIES_DELETE = 'products/product_categories/PRODUCT_CATEGORIES_DELETE';
const PRODUCTS_PRODUCT_CATEGORIES_UPDATE = 'products/product_categories/PRODUCT_CATEGORIES_UPDATE';

const PRODUCTS_PRODUCT_UNITS_GET = 'products/product_units/PRODUCT_UNITS_GET';
const PRODUCTS_PRODUCT_UNITS_CREATE = 'products/product_units/PRODUCT_UNITS_CREATE';
const PRODUCTS_PRODUCT_UNITS_RETRIEVE = 'products/product_units/PRODUCT_UNITS_RETRIEVE';
const PRODUCTS_PRODUCT_UNITS_DELETE = 'products/product_units/PRODUCT_UNITS_DELETE';
const PRODUCTS_PRODUCT_UNITS_UPDATE = 'products/product_units/PRODUCT_UNITS_UPDATE';

const PRODUCTS_PRODUCT_PACKAGES_GET = 'products/product_packages/PRODUCT_PACKAGES_GET';
const PRODUCTS_PRODUCT_PACKAGES_CREATE = 'products/product_packages/PRODUCT_PACKAGES_CREATE';
const PRODUCTS_PRODUCT_PACKAGES_RETRIEVE = 'products/product_packages/PRODUCT_PACKAGES_RETRIEVE';
const PRODUCTS_PRODUCT_PACKAGES_DELETE = 'products/product_packages/PRODUCT_PACKAGES_DELETE';
const PRODUCTS_PRODUCT_PACKAGES_UPDATE = 'products/product_packages/PRODUCT_PACKAGES_UPDATE';

const PRODUCTS_PRODUCT_ADDONS_GET = 'products/product_addons/PRODUCT_ADDONS_GET';
const PRODUCTS_PRODUCT_ADDONS_CREATE = 'products/product_addons/PRODUCT_ADDONS_CREATE';
const PRODUCTS_PRODUCT_ADDONS_RETRIEVE = 'products/product_addons/PRODUCT_ADDONS_RETRIEVE';
const PRODUCTS_PRODUCT_ADDONS_DELETE = 'products/product_addons/PRODUCT_ADDONS_DELETE';
const PRODUCTS_PRODUCT_ADDONS_UPDATE = 'products/product_addons/PRODUCT_ADDONS_UPDATE';

const PRODUCTS_IMAGES_UPLOAD = 'products/images/UPLOAD';
const PRODUCTS_IMAGES_DELETE = 'products/images/DELETE';

//Actions
export const productsProductsGet = createAsyncThunk(
    PRODUCTS_PRODUCTS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        console.log('DATA', data);
        // const page = (data && data.page !== undefined && data.page !== null) ? `?page=${data.page}` : '';
        return await customFetch('GET', BizManagerApi.products , data, token);
    }
);

export const productsProductCreate = createAsyncThunk(
    PRODUCTS_PRODUCT_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.products, data.payload, token, store);
    }
);

export const productsProductUpdate = createAsyncThunk(
    PRODUCTS_PRODUCT_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.product_update, data.payload, token, store);
    }
);


export const productsProductRetrieve = createAsyncThunk(
    PRODUCTS_PRODUCT_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.product(data.id), data.payload, token, store);
    }
);

export const productsProductDelete = createAsyncThunk(
    PRODUCTS_PRODUCT_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.product(data.id), data.payload, token, store);
    }
);

// PRODUCT CATEGORIES

export const productsProductCategoriesGet = createAsyncThunk(
    PRODUCTS_PRODUCT_CATEGORIES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.product_categories, data, token, store);
    }
);
export const productsProductCategoriesCreate = createAsyncThunk(
    PRODUCTS_PRODUCT_CATEGORIES_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.product_categories, data.payload, token, store);
    }
);
export const productsProductCategoriesRetrieve = createAsyncThunk(
    PRODUCTS_PRODUCT_CATEGORIES_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.product_category(data.id), data.payload, token, store);
    }
);
export const productsProductCategoriesDelete = createAsyncThunk(
    PRODUCTS_PRODUCT_CATEGORIES_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.product_category(data.id), null, token, store);
    }
);
export const productsProductCategoriesUpdate = createAsyncThunk(
    PRODUCTS_PRODUCT_CATEGORIES_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.product_category(data.id), data.payload, token, store, store);
    }
);


/// PRODUCT UNITS

export const productsProductUnitsCreate = createAsyncThunk(
    PRODUCTS_PRODUCT_UNITS_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.units, data.payload, token, store);
    }
);

export const productsProductUnitsGet = createAsyncThunk(
    PRODUCTS_PRODUCT_UNITS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.units, null, token, store);
    }
);

export const productsProductUnitsRetrieve = createAsyncThunk(
    PRODUCTS_PRODUCT_UNITS_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.unit(data.id), null, token, store);
    }
);

export const productsProductUnitsUpdate = createAsyncThunk(
    PRODUCTS_PRODUCT_UNITS_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.unit(data.id), data.payload, token, store);
    }
);

export const productsProductUnitsDelete = createAsyncThunk(
    PRODUCTS_PRODUCT_UNITS_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.unit(data.id), null, token, store);
    }
);

/// PRODUCT PACKAGES

export const productsProductPackagesCreate = createAsyncThunk(
    PRODUCTS_PRODUCT_PACKAGES_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.product_packages, data.payload, token, store);
    }
);

export const productsProductPackagesGet = createAsyncThunk(
    PRODUCTS_PRODUCT_PACKAGES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.product_packages, data, token, store);
    }
);

export const productsProductPackagesRetrieve = createAsyncThunk(
    PRODUCTS_PRODUCT_PACKAGES_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.product_package(data.id), null, token, store);
    }
);

export const productsProductPackagesUpdate = createAsyncThunk(
    PRODUCTS_PRODUCT_PACKAGES_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.product_package(data.id), data.payload, token, store);
    }
);

export const productsProductPackagesDelete = createAsyncThunk(
    PRODUCTS_PRODUCT_PACKAGES_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.product_package(data.id), null, token, store);
    }
);


/// PRODUCT ADDONS

export const productsProductAddonsCreate = createAsyncThunk(
    PRODUCTS_PRODUCT_PACKAGES_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.product_packages, data.payload, token, store);
    }
);

export const productsProductAddonsGet = createAsyncThunk(
    PRODUCTS_PRODUCT_ADDONS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.product_addons, null, token, store);
    }
);

export const productsProductAddonsRetrieve = createAsyncThunk(
    PRODUCTS_PRODUCT_ADDONS_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.product_addon(data.id), null, token, store);
    }
);

export const productsProductAddonsUpdate = createAsyncThunk(
    PRODUCTS_PRODUCT_ADDONS_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.product_addon(data.id), data.payload, token, store);
    }
);

export const productsProductAddonsDelete = createAsyncThunk(
    PRODUCTS_PRODUCT_ADDONS_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.product_addon(data.id), null, token, store);
    }
);


// IMAGES
export const productsImagesUpload = createAsyncThunk(
    PRODUCTS_IMAGES_UPLOAD, 
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.product_upload_image, data, token, store);
    }
);

export const productsProductImageDelete = createAsyncThunk(
    PRODUCTS_IMAGES_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.delete_product_picture(data.id), null, token, store);
    }
);

// Combined thunks
const productsResourceThunks = {
    create: productsProductCreate,
    get: productsProductsGet,
    retrieve: productsProductRetrieve,
    update: productsProductUpdate,
    delete: productsProductDelete,
}
const productCategoriesResourceThunks = {
    create: productsProductCategoriesCreate,
    get: productsProductCategoriesGet,
    retrieve: productsProductCategoriesRetrieve,
    update: productsProductCategoriesUpdate,
    delete: productsProductCategoriesDelete,
}
const productUnitsResourceThunks = {
    create: productsProductUnitsCreate,
    get: productsProductUnitsGet,
    retrieve: productsProductUnitsRetrieve,
    update: productsProductUnitsUpdate,
    delete: productsProductUnitsDelete,
}
const productPackagesResourceThunks = {
    create: productsProductPackagesCreate,
    get: productsProductPackagesGet,
    retrieve: productsProductPackagesRetrieve,
    update: productsProductPackagesUpdate,
    delete: productsProductPackagesDelete,
}

const productAddonsResourceThunks = {
    create: productsProductAddonsCreate,
    get: productsProductAddonsGet,
    retrieve: productsProductAddonsRetrieve,
    update: productsProductAddonsUpdate,
    delete: productsProductAddonsDelete,
}

//initial state
const initialState = {
    products: restResourceInitialState,
    product_categories: restResourceInitialState,
    product_units: restResourceInitialState,
    product_packages: restResourceInitialState,
    product_addons: restResourceInitialState,
    product_image: {
        isUploading: false,
        uploadingError: null,
        uploadingSuccessful: null,

        isDeleting: false,
        deletingSuccessful: false,
        deletingError: null,

        data: [],
    }
}

//redux toolkit slice
const productsSlice = createSlice({
    'name': 'products',
    initialState,
    reducers: {},
    extraReducers: {
        ...generateResourceExtraReducers(productsResourceThunks, "products"),
        ...generateResourceExtraReducers(productCategoriesResourceThunks, "product_categories"),
        ...generateResourceExtraReducers(productUnitsResourceThunks, "product_units"),
        ...generateResourceExtraReducers(productPackagesResourceThunks, "product_packages"),
        ...generateResourceExtraReducers(productAddonsResourceThunks, "product_addons"),
        [productsImagesUpload.pending]: (state, action) => {
            state.product_image.isUploading = true;
            state.product_image.uploadingSuccessful = false;
            state.product_image.uploadingError = null;
            state.product_image.data = [];
        },
        [productsImagesUpload.fulfilled]: (state, action) => {
            state.product_image.isUploading = false;
            state.product_image.uploadingSuccessful = true;
            state.product_image.data = action.payload;
            state.product_image.uploadingError = null;

            const index = state.products.data.findIndex(x => x.id == action.meta.arg.id);
            if (index > -1) {
                state.products.data[index] =  action.payload.data;
            } else {
            }
        },
        [productsImagesUpload.rejected]: (state, action) => {
            state.product_image.isUploading = false;
            state.product_image.uploadingSuccessful = false;
            state.product_image.uploadingError = null;
        },

        [productsProductImageDelete.pending]: (state, action) => {
            state.product_image.isDeleting = true;
            state.product_image.deletingSuccessful = false;
            state.product_image.deletingError = null;
            state.product_image.data = [];
        },
        [productsProductImageDelete.fulfilled]: (state, action) => {
            state.product_image.isDeleting = false;
            state.product_image.deletingSuccessful = true;
            state.product_image.data = action.payload;
            state.product_image.deletingError = null;
        },
        [productsProductImageDelete.rejected]: (state, action) => {
            state.product_image.isDeleting = false;
            state.product_image.deletingSuccessful = false;
            state.product_image.deletingError = null;
        },

        [organizationsOrganizationSetActive.fulfilled]: (state, action) => {
            state.products.data = [];
        },
    },
});

//exports
export default productsSlice.reducer;
