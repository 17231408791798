import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";

// Action Constants
const ACCOUNTS_TRANSACTIONS_GET = 'accounts/transactions/GET_TRANSACTIONS';
const ACCOUNTS_TRANSACTION_CREATE = 'accounts/transactions/CREATE_TRANSACTION';
const ACCOUNTS_TRANSACTION_RETRIEVE = 'accounts/transactions/RETRIEVE_TRANSACTION';
const ACCOUNTS_TRANSACTION_UPDATE = 'accounts/transactions/UPDATE_TRANSACTION';
const ACCOUNTS_TRANSACTION_DELETE = 'accounts/transactions/DELETE_TRANSACTION';

// Actions
export const accountsTransactionsGet = createAsyncThunk(
    ACCOUNTS_TRANSACTIONS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.transactions_get, data.payload, token, store);
    }
);
//
// export const accountsReturnCreate = createAsyncThunk(
//     ACCOUNTS_TRANSACTION_CREATE,
//     async(data, store) => {
//         const { token } = store.getState().authentication;
//         return await customFetch('POST', BizManagerApi.accounts, data.payload, token, store);
//     }
// );
//
// export const accountsReturnUpdate = createAsyncThunk(
//     ACCOUNTS_TRANSACTION_RETRIEVE,
//     async(data, store) => {
//         const { token } = store.getState().authentication;
//         return await customFetch('PUT', BizManagerApi.return(data.id), data.payload, token, store);
//     }
// );

export const accountsReturnRetrieve = createAsyncThunk(
    ACCOUNTS_TRANSACTION_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.transactions(data.id), data.payload, token, store);
    }
);
//
// export const accountsReturnDelete = createAsyncThunk(
//     ACCOUNTS_TRANSACTION_DELETE,
//     async(data, store) => {
//         const { token } = store.getState().authentication;
//         return await customFetch('DELETE', BizManagerApi.return(data.id), data.payload, token, store);
//     }
// );


// Combined thunks
const accountsTransactionsResourceThunks = {
    create: null,
    get: accountsTransactionsGet,
    retrieve: accountsReturnRetrieve,
    update: null,
    delete: null,
}
const accountsAccountsResourceThunks = {
    create: null,
    get: null,
    retrieve: null,
    update: null,
    delete: null,
}

//initial state
const initialState = {
    accounts: restResourceInitialState,
    transactions: restResourceInitialState,
}

//redux toolkit slice
const accountsSlice = createSlice({
    'name': 'accounts',
    initialState,
    reducers: {},
    extraReducers: {
        ...generateResourceExtraReducers(accountsAccountsResourceThunks, "accounts"),
        ...generateResourceExtraReducers(accountsTransactionsResourceThunks, "transactions"),
    },
});

//exports
export default accountsSlice.reducer;
