import React from 'react';
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    clearExpensesValidationErrors,
    expensesExpenseCreate, expensesExpenseTypesGet,
    expensesExpenseUpdate
} from "../../../../../application/features/expenses";
import moment from "moment";
import CustomTextArea from "../../../../atomic/text-area";
import Currency from "../../../../atomic/formatting/currency";
import {Spinner} from "../../../../atomic/spinner";
import CustomModal from "../../../../components/modals/modal";
import ExpenseItemForm from "./form/expense-item-form";
import CustomSelectInput from "../../../../atomic/select";
import i18n from "i18next";

const ExpenseFormPage = () => {
    // State
    const [showForm, setShowForm] = useState(false);
    const [payload, setPayload] = useState({description: null, expense_type_id: null, items: []});
    const [mounted, setMounted] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const { expense_id } = useParams();
    const navigate = useNavigate();

    const expenses = useSelector(state => state.expenses.expenses.data);
    const expense_types = useSelector(state => state.expenses.expense_types.data);
    const isCreating = useSelector(state => state.expenses.expenses.isCreating);
    const isUpdating = useSelector(state => state.expenses.expenses.isUpdating);
    const created = useSelector(state => state.expenses.expenses.creatingSuccessful);
    const updated = useSelector(state => state.expenses.expenses.updatingSuccessful);
    const creatingError = useSelector(state => state.expenses.expenses.creatingError);
    const updatingError = useSelector(state => state.expenses.expenses.updatingError);
    const validationErrors = useSelector(state => state.expenses.expenses.validationErrors);

    const dispatch = useDispatch();

    const enableEditingItems = false; // TODO should be enabled after feature is implemented

    //effects
    useEffect(() => {
        setErrorMessage("");
        setMounted(true);
        console.log(expense_types);
        if(expense_types.length < 1) {
            dispatch(expensesExpenseTypesGet());
        }
    }, [])

    useEffect(() => {
        console.log("Created effect triggered", created);
        if (!mounted) return;
        if (!created) return;
        navigate('../');
    }, [created]);

    useEffect(() => {
        console.log("Updated effect triggered", updated);
        if (!mounted) return;
        if (!updated) return;
        navigate('../');
    }, [updated]);

    useEffect(() => {
        if (!expense_id || !expenses) return;
        const expense = expenses.filter(u => u).find(u => u.id == expense_id);
        if (!expense) return;
        setPayload({date: expense.date, expense_type_id: expense.expense_type_id, description: expense.description, items: expense.items});
    }, [expense_id, expenses]);

    useEffect(() => {
        if (!updatingError && !creatingError) return;
        console.log(creatingError);
        if (expense_id && !updatingError) return;

        let error;
        if (expense_id) {
            error =  updatingError;
        } else {
            error =  creatingError;
        }

        if (error && error.message) {
            setErrorMessage(error.message);
            setTimeout(() => setErrorMessage(""), 60000);
        }

    }, [updatingError, creatingError, validationErrors, expense_id])


    const toggleForm = () => {
        setShowForm(!showForm);
    }

    const saveItem = (itemPayload) => {
        console.log(itemPayload);
        if(itemPayload){
            const p = {...payload};
            p.items = [...p.items, itemPayload];
            setPayload(p);
            setShowForm(false);
        }
        console.log(payload);
    }

    const removeItem = (item) => {
        const p = {...payload};
        p.items = p.items.filter((i) => i != item);
        setPayload(p);
        setShowForm(false);
    }

    const handleChange = (name, value) => {
        const p = {...payload};
        p[name] = value;
        setPayload(p);
        dispatch(clearExpensesValidationErrors());
    }

    const submit = () => {
        if(true) {
            setErrorMessage("");
            if(!expense_id){
                payload.date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                const data = {payload: payload};
                dispatch(expensesExpenseCreate(data))
            } else {
                const data = {payload: {id: expense_id, ...payload}};
                dispatch(expensesExpenseUpdate(data))
            }
        }
    }
    return (
        <div className={'card'}>
            <div className={'card-body'}>
                {errorMessage && <div className="alert alert-danger mb-2">{errorMessage}</div>}
                <div>
                    <h5>{expense_id ? "Update Expense" : "Record a new Expense"}</h5>
                </div>
                <div>
                    <CustomTextArea name={"description"} label="Description" value={payload.description ?? ''}
                                    onChange={handleChange}
                                    error={(validationErrors.hasOwnProperty("description")) ? validationErrors["description"][0] ?? null : null}
                                    placeHolder={'A general description of your expense'}/>
                    <div className="mt-2"></div>
                    <CustomSelectInput label="Expense Type" name="expense_type_id" items={expense_types}
                                       value={payload.expense_type_id} onChange={handleChange}
                                       error={(validationErrors.hasOwnProperty("expense_type_id")) ? validationErrors["expense_type_id"][0] ?? null : null}/>
                    <div>
                        <div className="d-flex align-content-stretch mb-1 mt-3">
                            <div className="flex-grow-1">
                                Items
                                <div className="text-black-50 small">A list of individual items included in your
                                    purchases</div>
                            </div>

                            <div className="flex-grow-0">
                                <button disabled={(expense_id) ? !enableEditingItems : false}
                                        className="btn btn-outline-primary" onClick={() => setShowForm(true)}>+ Add Item
                                </button>
                            </div>
                        </div>
                        <table className="table table-bordered table-sm">
                            <thead>
                            <tr>
                                <th width="30">#</th>
                                <th>Item</th>
                                <th>Quantity</th>
                                <th>Unit Amount</th>
                                <th>Total</th>
                                <th width="25"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {payload.items.map((item, i) => (
                                <tr key={"tr-" + i}>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.quantity}</td>
                                    <td className="text-end"><Currency value={item.unit_amount}/></td>
                                    <td className="text-end"><Currency value={(item.quantity * item.unit_amount)}/></td>
                                    <td>
                                        {((expense_id ? enableEditingItems : true)) &&
                                            <button onClick={() => removeItem(item)}
                                                    className="btn btn-sm btn-danger">x</button>}
                                    </td>
                                </tr>
                            ))
                            }
                            </tbody>
                            <tfoot>
                            <tr>
                                <th colSpan={4}>Total</th>
                                <th colSpan={2} className="pr-4 text-end"><Currency
                                    value={payload.items.reduce((prev, current) => prev + (current.quantity * current.unit_amount), 0)}/>
                                </th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div>
                        <button disabled={isCreating || isUpdating} className="btn btn-primary"
                                onClick={submit}>{(isCreating || isUpdating) ?
                            <Spinner/> : (expense_id ? i18n.t('Update') : i18n.t('Save'))}</button>
                    </div>
                </div>

                <CustomModal size={'small'} bodyPadding={'1rem'} showHeader={true} title="New Expense Item" show={showForm} handleClose={() => setShowForm(false)}>
                    <ExpenseItemForm onSave={saveItem}/>
                </CustomModal></div>
        </div>
    );
};

export default ExpenseFormPage;
