import {handleInterrupt, setConnectionStatus, updateNetworkUnauthorized} from "../features/app";

export const networkMiddleware = store => next => action => {
    if (action.error && action.error.message) {
        console.log('ACTION ERROR: ', action);
        if(action.error.message === 'UNAUTHORIZED') {
            store.dispatch(updateNetworkUnauthorized(true));
        }
        if(action.payload && action.payload.interrupt) {
            store.dispatch(handleInterrupt(action.payload));
        }
        // Handle internet availability status
        if(action.error.message === 'CONNECTION_FAILED') {
            store.dispatch(setConnectionStatus({status: false}));
            setTimeout(function() {
                store.dispatch(setConnectionStatus({status: true}));
            }, 5000);
        } else {
            store.dispatch(setConnectionStatus({status: false}));
            setTimeout(function() {
                store.dispatch(setConnectionStatus({status: true}));
            }, 5000);
        }
    }

    // TODO centralize the translation of errors to more friendly texts
    next(action);
}
