import React from 'react';
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {productsProductUnitsGet} from "../../../../../application/features/products";
import PageAlert from "../../../../components/notifications/page-alert";

const CustomerViewPage = () => {
    //state
    const [customer, setUnit] = useState(null);

    //hooks
    const { customer_id } = useParams();

    //redux
    const dispatch = useDispatch();
    const customers = useSelector(state => state.customers.customers.data);

    useEffect(() => {
        dispatch(productsProductUnitsGet());
    }, []);

    //effect
    useEffect(() => {
        if (!customer_id) return;
        const data = {
            id: customer_id,
        }
        setUnit(customers.find(u => u.id == customer_id));
    }, [customer_id]);

    if (!customer) {
        return <PageAlert title={""} text="Ooops! Unit not found" />
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">&nbsp;</div>
                <div className="card-body">
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <td width="30%">Name</td><td>{customer.name}</td>
                        </tr>
                        <tr>
                            <td>Date of Birth</td><td>{customer.birthdate ?? ''}</td>
                        </tr>
                        <tr>
                            <td>Phone number</td><td>{customer.phone ?? ''}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
};

export default CustomerViewPage;
