import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import CreateUpdateForm from "../../../../components/form/createUpdateForm";
import {
    messagesAutomatedMessageCreate,
    messagesAutomatedMessagesGet,
    messagesAutomationFactorsGet
} from "../../../../../application/features/messages";
import {useParams} from "react-router";
import CustomSelectInput from "../../../../atomic/select";
import CustomTextArea from "../../../../atomic/text-area";
import {customersCustomersGet} from "../../../../../application/features/customers";
import CustomInput from "../../../../atomic/input";

const AutomatedMessageFormPage = () => {
    //state

    const {automated_message_id} = useParams();

    //hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // redux
    const automated_messages_resource = useSelector(state => state.messages.automated_messages);
    const automation_factors = useSelector(state => state.messages.automation_factors.data);
    const customers = useSelector(state => state.customers.customers.data);

    useEffect(() => {
        dispatch(customersCustomersGet());
        dispatch(messagesAutomationFactorsGet());
    }, []);


    return (
        <div>
            <CreateUpdateForm title="Automated Message" item_id={automated_message_id} resource={automated_messages_resource} updateThunk={messagesAutomatedMessagesGet} createThunk={messagesAutomatedMessageCreate}>
                <CustomSelectInput label="Automation Factor" name="automation_factor_id" items={automation_factors} />
                <CustomSelectInput label="Automation Condition" name="automation_condition" items={[{id: 'EQUALS', name: "EQUALS"}]} value={'EQUALS'} />
                <CustomInput label="Automation Value" name="automation_value" />
                <CustomSelectInput label="Customer" name="customer_id" items={[{id: '', name: 'None'}, ...customers]} />
                <CustomInput label="Phone Numbers" name="numbers"  />
                <CustomTextArea label="Message" name="message" />
            </CreateUpdateForm>
        </div>
    );
};

export default AutomatedMessageFormPage;
