import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {usersUserDelete, usersUsersGet} from "../../../../../application/features/users";
import {Link} from "react-router-dom";
import {CustomTable} from "../../../../atomic/table/table";
import {ViewButton} from "../../../../components/buttons/view-button";
import {EditButton} from "../../../../components/buttons/edit-button";
import {DeleteButton} from "../../../../components/buttons/delete-button";
import CustomConfirmAlert from "../../../../components/notifications/confirm-alert";
import { AddButton } from "../../../../components/buttons/add-button";
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import SalesResource from "../../../../../application/resources/sales";
import UsersResource from "../../../../../application/resources/users";
import i18n from "i18next";

export const UsersListPage = () => {
    // satate
    const [showAlert, setShowAlert] = useState(false);
    const [deletingId, setDeletingId] = useState(null);

    //redux
    const dispatch = useDispatch();
    const isFetching = useSelector(state => state.users.users.isFetching);
    const isDeleting = useSelector(state => state.users.users.isDeleting);
    const users = useSelector(state => state.users.users.data);
    const user = useSelector(state => state.authentication.user);

    //effects
    useEffect(() => {
        dispatch(usersUsersGet());
    }, []);

    useEffect(() => {
        console.log('USERS', users);
    }, [users]);

    //handlers
    const handleDelete = () => {
        if(deletingId) {
            const data = {id: deletingId};
            dispatch(usersUserDelete(data));
            setDeletingId(null);
            setShowAlert(false);
        }
    }

    return (
        <div>
            {/* <div className='row'>
                <div className="col text-end">
                    <Link to="form" className='btn btn-outline-primary '>Add New User</Link>
                </div>
            </div> */}

            <div className="row mt-3">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="d-flex align-items-center">
                                <div className="card-title flex-grow-1">{i18n.t('System Users')}</div>
                                <AddButton permissions={['Create Users']} to="form" title="Add New User"  />
                            </div>
                        </div>
                        <div className="card-body">
                            {/*<CustomTable isLoading={isFetching}>*/}
                            {/*    <thead>*/}
                            {/*    <tr>*/}
                            {/*        <th width={30}>#</th>*/}
                            {/*        <th>Name</th>*/}
                            {/*        <th>Email</th>*/}
                            {/*        <th>Phone</th>*/}
                            {/*        <th className="col-actions">Actions</th>*/}
                            {/*    </tr>*/}
                            {/*    </thead>*/}

                            {/*    <tbody>*/}
                            {/*    {users.filter(u => u).map((user, i) => (*/}
                            {/*        <tr key={i} className={(isDeleting && deletingId == user.id) ? "deleting" : ""}>*/}
                            {/*            <td>{i + 1}</td>*/}
                            {/*            <td>{user.name ?? ''}</td>*/}
                            {/*            <td>{user.email}</td>*/}
                            {/*            <td>{user.phone ?? ''}</td>*/}
                            {/*            <td>*/}
                            {/*                <div className="btn-group btn-group-sm" role="group"*/}
                            {/*                     aria-label="Button group with nested dropdown">*/}
                            {/*                    <ViewButton to={`${user.id}`} alt={"View"}/>*/}
                            {/*                    <EditButton to={`form/${user.id}`} alt={"Edit"}/>*/}
                            {/*                    {*/}
                            {/*                        (user.id != 1 && user.galaxy_auth_id < 1) &&*/}
                            {/*                        <DeleteButton isDeleting={isDeleting && deletingId === user.id} onClick={() => { setDeletingId(user.id); setShowAlert(true) }}/>*/}
                            {/*                    }*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*    ))}*/}
                            {/*    </tbody>*/}
                            {/*</CustomTable>*/}

                            <div className="card-body">
                                <GalaxyResourceTable deletable={(row) => (row.galaxy_user_id ? false : (row.id !== user.id))} resource={new UsersResource()}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<CustomConfirmAlert isShown={showAlert} title="Delete User?" text="Are you sure you want to delete the user?" onConfirm={handleDelete} toggleAlert={setShowAlert} />*/}
        </div>
    );
}
