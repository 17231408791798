import React from "react";
import {Navigate, Routes} from "react-router-dom";
import {Route} from "use-react-router-breadcrumbs";
import {ExpensesListPage} from "./expenses/expenses-list-page";
import ExpenseView from "./expenses/expense-view";
import ExpenseFormPage from "./expenses/expense-form";
import ExpenseTypesListPage from "./expense-types/list";
import ExpenseTypeFormPage from "./expense-types/form";
import ExpenseTypeViewPage from "./expense-types/view";
import {useTranslation} from "react-i18next";
import {TopNavigation} from "../../../components/app/top-navigation/top-navigation";

export const ExpensesRoutes = () => {
    const {i18n, t} = useTranslation();
    const topLinks = [
        {
            title: t('Expenses List'),
            to: 'expenses',
            feature: 'MANAGE_EXPENSES'
        },
        {
            title: t('Expenses Types'),
            to: 'expenses-types',
            feature: 'MANAGE_EXPENSES'
        }
    ];
    return (
        <div>
            <h3>{i18n.t('Expenses')}</h3>
            <TopNavigation links={topLinks} basePathLevel={3} />

            <Routes>
                <Route index element={<Navigate to="expenses" />}/>
                <Route path='expenses/*'>
                    <Route path="form" element={<ExpenseFormPage/>}/>
                    <Route path="form/:expense_id" element={<ExpenseFormPage/>}/>
                    <Route path=":expense_id" element={<ExpenseView/>}/>
                    <Route index element={<ExpensesListPage/>}/>
                </Route>
                <Route path='expenses-types/*'>
                    <Route path="form" element={<ExpenseTypeFormPage/>}/>
                    <Route path="form/:expense_type_id" element={<ExpenseTypeFormPage/>}/>
                    <Route path=":expense_type_id" element={<ExpenseTypeViewPage/>}/>
                    <Route index element={<ExpenseTypesListPage/>}/>
                </Route>
            </Routes>
        </div>
    )
}
