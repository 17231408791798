import React, {useEffect, useState} from 'react'
import OnBoardingStepLayout from '../choose-business-type/layout';
import styles from './index.module.scss';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    onBoardingGetProductCategories,
    onBoardingUpdateProductCategories
} from "../../../../application/features/onboarding";
import {Spinner} from "../../../atomic/spinner";
import {Children, cloneElement} from "react";

export const OnBoardingChooseCategoriesPage = (props) => {
    const [payload, setPayload] = useState({categories: []});
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.onboarding.get_product_categories.isLoading);
    const fetchError = useSelector(state => state.onboarding.get_product_categories.error);
    const productCategories = useSelector(state => state.onboarding.get_product_categories.data);

    const isUpdating = useSelector(state => state.onboarding.update_product_categories.isFetching);
    const updateSuccessful = useSelector(state => state.onboarding.update_product_categories.isSuccessful);
    const updateError = useSelector(state => state.onboarding.update_product_categories.error);
    const selectedProductCategories = useSelector(state => state.onboarding.update_product_categories.data);


    useEffect(() => {
        dispatch(onBoardingGetProductCategories());
    }, []);


    useEffect(() => {
        console.log(productCategories);
    }, [productCategories]);


    useEffect(() => {
        if (updateSuccessful) {
            if (selectedProductCategories) {
                navigate("../success");
            }
        }
    }, [updateSuccessful]);

    useEffect(() => {
        if(fetchError) {
            setError(fetchError.message);
            setTimeout(() => {
                setError(null);
            }, 4000)
        }
        if(updateError) {
            setError(updateError.message);
            setTimeout(() => {
                setError(null);
            }, 4000)
        }
    }, [fetchError, updateError]);



    const handleSubmit = () => {
        dispatch(onBoardingUpdateProductCategories(payload));
    }

    const shouldCheck = (category) => {
        if(payload.categories.length == 0){
            return false;
        }
        return payload.categories.filter((item) => item == category.id) > 0;
    }

    const categoryChecked = (category)  => {
        console.log(payload);
        const newPayload = {... payload};
        if(payload.categories.indexOf(category.id) != -1) {
            newPayload.categories = payload.categories.filter(i => i != category.id);
        } else {
            console.log('SHould Push');
            newPayload.categories.push(category.id);
        }
        setPayload(newPayload);
    }

    return (
        <OnBoardingStepLayout step={2}>
            <div className={styles.header}>
                <h1>Choose Categories</h1>
                <p>Choose relevant categories for your business type that can help you organize inventory, generate reports, improve the customer experience, and make data-driven decisions.</p>
            </div>


            <div className={styles.content}>
                <div className={styles.card}>
                    {error && <div className="alert alert-danger my-2">{error}</div>}
                    <div className="text-center">{(isLoading ? <Spinner/> : '')}</div>
                    <div className={styles.grid}>
                        {(productCategories && productCategories.length > 0) ?
                            productCategories.filter(u => u).map((category, i) => (
                                    <div key={i} className="form-check">
                                        <input checked={shouldCheck(category)} onChange={(event) => categoryChecked(category)} className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            {category.name ?? 'Unnamed'}
                                        </label>
                                    </div>
                                )
                            )
                            :
                            ''
                        }
                        {/*<div className="form-check">*/}
                        {/*    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>*/}
                        {/*    <label className="form-check-label" htmlFor="flexCheckDefault">*/}
                        {/*        Food*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        
                        {/*<div className="form-check">*/}
                        {/*    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>*/}
                        {/*    <label className="form-check-label" htmlFor="flexCheckDefault">*/}
                        {/*        Utencils*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        
                        {/*<div className="form-check">*/}
                        {/*    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>*/}
                        {/*    <label className="form-check-label" htmlFor="flexCheckDefault">*/}
                        {/*        Beverage*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        
                        {/*<div className="form-check">*/}
                        {/*    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>*/}
                        {/*    <label className="form-check-label" htmlFor="flexCheckDefault">*/}
                        {/*        Packets*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        
                        {/*<div className="form-check">*/}
                        {/*    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>*/}
                        {/*    <label className="form-check-label" htmlFor="flexCheckDefault">*/}
                        {/*        Food*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        
                        {/*<div className="form-check">*/}
                        {/*    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>*/}
                        {/*    <label className="form-check-label" htmlFor="flexCheckDefault">*/}
                        {/*        Utencils*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        
                        {/*<div className="form-check">*/}
                        {/*    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>*/}
                        {/*    <label className="form-check-label" htmlFor="flexCheckDefault">*/}
                        {/*        Beverage*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        
                        {/*<div className="form-check">*/}
                        {/*    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>*/}
                        {/*    <label className="form-check-label" htmlFor="flexCheckDefault">*/}
                        {/*        Packets*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        
                        {/*<div className="form-check">*/}
                        {/*    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>*/}
                        {/*    <label className="form-check-label" htmlFor="flexCheckDefault">*/}
                        {/*        Food*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        
                        {/*<div className="form-check">*/}
                        {/*    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>*/}
                        {/*    <label className="form-check-label" htmlFor="flexCheckDefault">*/}
                        {/*        Utencils*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        
                        {/*<div className="form-check">*/}
                        {/*    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>*/}
                        {/*    <label className="form-check-label" htmlFor="flexCheckDefault">*/}
                        {/*        Beverage*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                        
                        {/*<div className="form-check">*/}
                        {/*    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>*/}
                        {/*    <label className="form-check-label" htmlFor="flexCheckDefault">*/}
                        {/*        Packets*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                    </div>
                </div>

                <div className="py-4 text-center">
                    <button className="btn btn-primary" onClick={handleSubmit}>
                        <span className="px-3">Next</span>
                    </button>
                </div>
            </div>
        </OnBoardingStepLayout>
    )
}
