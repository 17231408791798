import React, {useEffect, useState} from 'react';
import CustomSelectInput from "../../../../atomic/select";
import {useDispatch, useSelector} from "react-redux";
import {salesSalesGet} from "../../../../../application/features/sales";
import CustomTextArea from "../../../../atomic/text-area";
import {Spinner} from "../../../../atomic/spinner";
import {CustomTable} from "../../../../atomic/table/table";
import Currency from "../../../../atomic/formatting/currency";
import CustomInput from "../../../../atomic/input";
import { returnsReturnCreate } from '../../../../../application/features/returns';
import moment from 'moment';
import {useNavigate} from "react-router-dom";

const ReturnsFormPage = () => {
    // State
    const [payload, setPayload] = useState({sale_id: null, date: null, description: '', items: []});
    const [selectedSale, setSelectedSale] = useState(null);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [booted, setBooted] = useState(false);

    const navigate = useNavigate();
    //redux
    const sales = useSelector(state => state.sales.sales.data);
    const isFetching = useSelector(state => state.sales.sales.isFetching);
    const isCreating = useSelector(state => state.returns.returns.isCreating);
    const creatingSuccessful = useSelector(state => state.returns.returns.creatingSuccessful);
    const creatingError = useSelector(state => state.returns.returns.creatingError);

    const dispatch = useDispatch();

    //effects
    useEffect(() => {
        setBooted(true);
        dispatch(salesSalesGet());
        console.log(sales);
    }, []);
    //effects
    useEffect(() => {
        if(!creatingSuccessful) return;
        if(payload) {
            setTimeout(function() {
                setPayload({sale_id: null, date: null, description: '', items: []});
            }, 300);
        }
        if(payload.items) return;
        navigate('../');
    }, [creatingSuccessful]);

    //effects
    useEffect(() => {
        if (!creatingError) return;
        if (!creatingError.errors) setErrors({});
        setErrorMessage(creatingError.message);
        setTimeout(() => {
            setErrorMessage("");
        }, 5000)

        let _errors = {};
        console.log(creatingError.errors);
        for (let key in creatingError.errors) {
            _errors[key] = creatingError.errors[key][0]
        }
        setErrors(_errors);
    }, [creatingError]);

    useEffect(() => {
        if(!selectedSale) return;
        console.log('SELECTED SALE', selectedSale);
        const items = [...selectedSale.items].map(i => ({...i, original_quantity: i.quantity, sale_item_id: i.id, quantity: 0, unit_amount: i.unit_amount, reason: null,}));
        console.log('ITEMS NOW', items);
        let p = {...payload};
        p.items = items;
        setPayload(p);
        setTimeout(() => {
            console.log(payload);
        }, 50);
    }, [selectedSale]);



    const handleChange = (key, value) => {
        const p = {...payload};
        p[key] = value;
        setPayload(p);
        if(key == 'sale_id') {
            const sale = sales.find(sale => sale.id == value);
            if(!sale) return;
            setSelectedSale(sale);
        }
    }

    const handleItemChange = (item, key, value) => {
        console.log("handling change", key, value);
        const p = {...payload};
        let payloadIndex = p['items'].findIndex((i) => i.id == item.id);
        let payloadItem =  p['items'].find((i) => i.id == item.id);
        if(payloadItem) {
            p['items'][payloadIndex] = {...payloadItem, [key]: value};
            console.log(p);
            setPayload(p);
        } 
    }

    const handleSave = () => {
        setErrors({});
        setErrorMessage('');
        const newPayload = {...payload, date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}
        console.log('PYLD', newPayload);
        dispatch(returnsReturnCreate({payload: newPayload}));
    }

    return (
        <div className="card">
            <div className={'card-body'}>
                {isFetching && <Spinner/>}
                {errorMessage && <div className="alert alert-danger mb-2">{errorMessage}</div>}
                <form>
                    <CustomSelectInput label={"Sale"} name={'sale_id'} items={sales.map(sale => ({
                        id: sale.id,
                        name: `${sale.date} ${sale.customer_name} ${parseFloat(sale.total_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    }))} value={payload.sale_id} onChange={handleChange} error={errors.sale_id ?? null}/>
                    <div className="mb-2"></div>
                    <CustomTextArea label={"description"} name={"description"} value={payload.description}
                                    onChange={handleChange} error={errors.description ?? null}/>
                </form>
                <div className="items mt-3">
                    <div className="row">
                        <div className="col-12"><CustomTable>
                            <tr>
                                <th width={30}>#</th>
                                <th>Product</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Total Price</th>
                                <th width={150}>Return Quantity</th>
                                <th width={150}>Return Reason</th>
                                <th width={100}></th>
                            </tr>
                            {
                                (payload.items.length > 0) ?
                                    [...payload.items].map((item, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.original_quantity}</td>
                                            <td><Currency value={item.unit_price}/></td>
                                            <td><Currency value={item.unit_price * item.quantity}/></td>
                                            <td><CustomInput max={item.original_quantity} min={0} type={'number'}
                                                             name={`quantity[${i}]`} value={item.quantity}
                                                             onChange={(key, value) => handleItemChange(item, 'quantity', value)}/>
                                            </td>
                                            <td><CustomSelectInput name={`reason[${i}]`} items={[{
                                                id: 'RETURN',
                                                name: 'RETURN'
                                            }, {id: 'DEFECTIVE', name: 'DEFECTIVE'}]}
                                                                   onChange={(key, value) => handleItemChange(item, 'reason', value)}
                                                                   value={item.reason}/></td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <div></div>
                                    </tr>
                            }
                        </CustomTable>

                            <button disabled={isCreating} className='btn btn-primary mt-2 '
                                    onClick={handleSave}>Save {isCreating && <Spinner/>}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReturnsFormPage;
