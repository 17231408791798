import {Link} from "react-router-dom";
import React from "react";
import editIcon from '$assets/icons/edit.svg';
import styles from './index.module.scss';
import { IconEdit } from "../../atomic/icons/edit";

export const EditButton = ({to, alt}) => {
    return (
        <Link to={to} className={styles.editButton}><IconEdit height="12px" width="12px" fill="#2F80ED" /></Link>
    )
}