import React from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import CreateUpdateForm from "../../../../components/form/createUpdateForm";
import {
    customersCustomerCreate, customersCustomerGroupCreate,
    customersCustomerGroupUpdate,
    customersCustomerUpdate
} from "../../../../../application/features/customers";
import CustomInput from "../../../../atomic/input";
import CustomTextArea from "../../../../atomic/text-area";

const CustomersGroupFormPage = () => {
    const { customer_group_id } = useParams();
    const customer_groups = useSelector(state => state.customers.customer_groups);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log('init');
    }, []);

    return (
        <CreateUpdateForm resource={customer_groups}  title={"Customer"} item_id={customer_group_id} createThunk={customersCustomerGroupCreate} updateThunk={customersCustomerGroupUpdate}>
            <CustomInput name='name' label='Group Name' placeholder='Group Name'  required />
            <CustomTextArea name='description' label='Description' min="0" placeholder='Description'  />
        </CreateUpdateForm>
    );
};

export default CustomersGroupFormPage;
