import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import Currency from "../../../atomic/formatting/currency";
import {dashboardStatisticsGet} from "../../../../application/features/dashboard";
import StatsCard from "../../../components/cards/stats-card";
import moment from "moment";
import MonthlySalesChart from "./charts/monthly-sales-chart";
import MonthlyPurchasesChart from "./charts/monthly-purchases-chart";
import {CustomTable} from "../../../atomic/table/table";
import {useTranslation} from "react-i18next";
import {IconShoppingBag} from '../../../atomic/icons/shopping-bag';
import {IconPurchaseTag} from '../../../atomic/icons/purchase-tag';
import IconAccounts from '../../../atomic/icons/accounts';
import {ExpandIcon} from "../../../components/icons/expand";

export const DashboardPage = () => {
  //state
  const [monthlySalesAmount, setMonthlySalesAmount] = useState(0);
  const [monthlyPurchasesAmount, setMonthlyPurchasesAmount] = useState(0);
  const [monthlyProfitAmount, setMonthlyProfitAmount] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(moment(new Date()).format('MMMM'));
  const [chart1Expanded, setChart1Expanded] = useState(false);
  const [chart2Expanded, setChart2Expanded] = useState(false);


  const isFetching = useSelector(state => state.dashboard.statistics.isFetching);
  const statisticsFetched = useSelector(state => state.dashboard.statistics.fetchingSuccessful);
  const monthlySales = useSelector(state => state.dashboard.statistics.data.monthly_sales);
  const monthlyPurchases = useSelector(state => state.dashboard.statistics.data.monthly_purchases);
  const monthlyProfit = useSelector(state => state.dashboard.statistics.data.monthly_profit);
  const productPerformance = useSelector(state => state.dashboard.statistics.data.product_performance);


  const dispatch = useDispatch();
  const {i18n, t} = useTranslation();

  useEffect(() => {
    dispatch(dashboardStatisticsGet());
  }, []);

  useEffect(() => {
    if(monthlySales){
      setMonthlySalesAmount(monthlySales[currentMonth]);
    }
  }, [monthlySales]);

  useEffect(() => {
    if(monthlyPurchases){
      setMonthlyPurchasesAmount(monthlyPurchases[currentMonth]);
    }
  }, [monthlyPurchases]);

  useEffect(() => {
    if(monthlyProfit){
      setMonthlyProfitAmount(monthlyProfit[currentMonth]);
    }
  }, [monthlyProfit]);

  if(!statisticsFetched){
  }

  return (
      <div>
        <div className="row">
          <div className="col-12 col-md-4 col-l-4">
            <div className="my-3">
              <StatsCard value={monthlySalesAmount ?? 0} title={t('Monthly Sales')} icon={IconShoppingBag} color="#F2994A" />
            </div>
          </div>

          <div className="col-12 col-md-4 col-l-4">
            <div className="my-3">
              <StatsCard value={monthlyPurchasesAmount ?? 0} title={t('Monthly Purchases')} icon={IconPurchaseTag} color="#EF5DA8" />
            </div>
          </div>


          <div className="col-12 col-md-4 col-l-4">
            <div className="my-3">
              <StatsCard value={monthlyProfitAmount ?? 0} title={t('Monthly Profit')} icon={IconAccounts} />
            </div>
          </div>


          <div className="col-12 col-md-6">
            <div className={chart1Expanded ? 'card my-3 expandable expanded' : 'card my-3 expandable'}>
              <span onClick={() => setChart1Expanded(!chart1Expanded)} className={"expander-icon"} style={{position: 'absolute', right: '1px'}}>
                <ExpandIcon expanded={chart1Expanded} width={"30"} />
              </span>
              <div className="card-body">
                <div className="d-flex flex-row">
                  <p className="text-muted fw-medium">{i18n.t('Monthly Sales Graph')}</p>
                </div>
                <div>
                  <MonthlySalesChart monthlySales={monthlySales} />
                </div>
              </div>
            </div>
          </div>


          <div className="col-12 col-md-6">
            <div className={ chart2Expanded ? 'card my-3 expandable expanded' : 'card my-3 expandable'}>
              <span onClick={() => setChart2Expanded(!chart2Expanded)} className={"expander-icon"} style={{position: 'absolute', right: '1px'}}>
                <ExpandIcon expanded={chart2Expanded} />
              </span>
              <div className="card-body">
                <div className="d-flex flex-row">
                  <p className="text-muted fw-medium">{t('Sales vs Expenses graph')}</p>
                </div>
                <div>
                  <MonthlyPurchasesChart monthlyPurchases={monthlyPurchases} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="card my-3">
              <div className="card-body">
                <div className="d-flex flex-row">
                  <h3 className="">{t('Product Performance list of 10 items')}</h3>
                </div>
                
                <CustomTable isLoading={isFetching}>
                  <thead>
                  <tr>
                    <th>{i18n.t('Product Name')}</th>
                    <th>{i18n.t('Quantity Sold')}</th>
                    <th>{i18n.t('Monthly Profit')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    (productPerformance &&  productPerformance.length) ?
                    productPerformance.filter(u=>u).map((product, i) => (
                        <tr key={i}>
                          <td>{product.name}</td>
                          <td>{product.quantity_sold}<span className="small text-black-50">{product.unit.abbreviation}</span></td>
                          <td><Currency value={product.profit} /></td>
                        </tr>
                    ))
                        :
                        <tr><td colSpan={3}>No Data</td></tr>
                  }
                  </tbody>
                </CustomTable>
              </div>
            </div>
          </div>


          {/* <div className="col-12 col-md-6 col-xl-4">
            <div className="card my-3">
              <div className="card-body">
                <div className="d-flex flex-row">
                  <p className="text-muted fw-medium">{t('Monthly Sales')}</p>
                </div>
                <CustomTable isLoading={isFetching}>
                  <thead>
                  <tr>
                    <th>{i18n.t('Month')}</th>
                    <th>{i18n.t('Sales')}</th>
                    <th>{i18n.t('Profit')}</th>
                  </tr>
                  </thead>
                  <tbody>
                    {
                      (monthlySales) ?
                      Object.values(monthlySales).reverse().map((sale, i) => (
                          <tr key={i}>
                            <td>{Object.keys(monthlySales)[i]}</td>
                            <td className="text-end"><Currency value={sale} /></td>
                            <td className="text-end"><Currency value={Object.values(monthlyProfit).reverse()[i]} /></td>
                          </tr>
                      ))
                          :
                          <div></div>
                    }
                  </tbody>
                </CustomTable>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <div className="card my-3">
              <div className="card-body">{t('Proﬁt in less than 10 months')}</div>
            </div>
          </div> */}
      </div>
      </div>
  )
}
