import {NavLink, Routes} from "react-router-dom";
import {Route} from "use-react-router-breadcrumbs";
import React from "react";
import {UsersListPage} from "./users/users-list-page";
import UserViewPage from "./users/user-view";
import UserFormPage from "./users/user-form-page";
import {useTranslation} from "react-i18next";
import {UserPermissions} from "./permissions/user-permissions";

export const UsersRoutes = () => {
    const {i18n, t} = useTranslation();
    return (
        <div>
            <h3>Users</h3>
            <ul className="nav nav-tabs mb-3">
                <li className="nav-item">
                    <NavLink className="nav-link" end to="">{t('System Users')}</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" end to="permissions">{i18n.t('role_permissions')}</NavLink>
                </li>
            </ul>

            <Routes>
                <Route path='users/*'>
                    <Route index element={<UsersListPage/>}/>
                </Route>
                <Route path=":user_id" element={<UserViewPage/>}/>
                <Route path="form" element={<UserFormPage/>}/>
                <Route path="form/:user_id" element={<UserFormPage/>}/>
                <Route path="permissions" element={<UserPermissions/>}/>
                <Route path="permissions:user_id" element={<UserPermissions/>}/>
                <Route index element={<UsersListPage/>}/>
            </Routes>
        </div>
    )
}
