import React from 'react'
import { Routes, NavLink, Navigate } from 'react-router-dom';
import { Route } from 'use-react-router-breadcrumbs';
import {CustomersListPage} from "./customers/customers-list-page";
import CustomerViewPage from "./customers/customer-view";
import CustomerFormPage from "./customers/customers-form";
import CustomerGroupsListPage from "./customer-groups/customer-groups-list";
import CustomersGroupFormPage from "./customer-groups/customers-groups-form";
import CustomersGroupViewPage from "./customer-groups/customers-group-view";
import {useTranslation} from "react-i18next";
import {TopNavigation} from "../../../components/app/top-navigation/top-navigation";

export const CustomersRoutes = () => {
    const {i18n, t} = useTranslation();
    const topLinks = [
        {
            title: t('Customers List'),
            to: 'customers'
        },
        {
            title: t('Customer Groups'),
            to: 'groups'
        }
    ];
    return (
        <div>
            <h3>Customers</h3>
            <TopNavigation links={topLinks} basePathLevel={3} />

            <Routes>
                <Route index element={<Navigate to="customers" />}/>
                <Route path='customers/*'>
                    <Route path="" element={<CustomersListPage/>}/>
                    <Route path="form" element={<CustomerFormPage/>}/>
                    <Route path="form/:customer_id" element={<CustomerFormPage/>}/>
                    <Route path=":customer_id" element={<CustomerViewPage/>}/>
                    <Route index element={<CustomersListPage/>}/>
                </Route>
                <Route path='groups/*'>
                    <Route path="" element={<CustomerGroupsListPage/>}/>
                    <Route path="form" element={<CustomersGroupFormPage/>}/>
                    <Route path="form/:customer_group_id" element={<CustomersGroupFormPage/>}/>
                    <Route path=":customer_group_id" element={<CustomersGroupViewPage/>}/>
                    <Route index element={<CustomerGroupsListPage/>}/>
                </Route>
            </Routes>
        </div>
    );
}
