import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { LoginPage } from './login/index';
import {PasswordResetPage} from "./password-reset";
import { RegisterPage } from './register';
import {RequestOtpPage} from "./request-otp";
import { SplashPage } from './splash/index';
import {AuthenticateRedirected} from "./authenticate-redirected";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route index element={<SplashPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="password-reset" element={<PasswordResetPage />} />
      <Route path="otp-confirm" element={<RequestOtpPage />} />
      <Route path="register" element={<RegisterPage />} />
    </Routes>
  )
}
