import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";


const ProductPackageViewPage = () => {


    const {product_package_id} = useParams();
    const dispatch = useDispatch();
//redux
    const product_package = useSelector(state => state.products.product_packages.data.find(u => u.id == product_package_id));

    useEffect(() => {
        // if(product_package == null) {
        //     dispatch(productsProductPackagesRetrieve(product_package_id))
        // }
    }, []);


    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">Product Package</div>
                        <div className="card-body">
                            <table className="table table-bordered">
                                <tbody>
                                <tr>
                                    <td width="30%">Name</td>
                                    <td>{product_package.name}</td>
                                </tr>
                                <tr>
                                    <td>Product Name</td>
                                    <td>{product_package.product.name}</td>
                                </tr>
                                <tr>
                                    <td>Unit Name</td>
                                    <td>{product_package.unit.name}</td>
                                </tr>
                                <tr>
                                    <td>Unit Count</td>
                                    <td>{product_package.unit_count}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <div className="col-12 col-md-4">

                </div>
            </div>
        </div>
    );
};

export default ProductPackageViewPage;
