import React from 'react';

const AutomatedMessageViewPage = () => {
    return (
        <div>
            View
        </div>
    );
};

export default AutomatedMessageViewPage;
