import React from "react";

export const IconDelete = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(1.40625 0) scale(.46875)">
        <path
          d="M282 211h-20c-.56 0-1-.45-1-1 0-.56.44-1 1-1h20c.55 0 1 .44 1 1 0 .55-.45 1-1 1v0Zm-1 20c0 1.1-.9 2-2 2h-14c-1.11 0-2-.9-2-2v-18h18v18 0Zm-12-25c0-.56.44-1 1-1h4c.55 0 1 .44 1 1v1h-6v-1 0Zm14 1h-6v-2c0-1.11-.9-2-2-2h-6c-1.11 0-2 .89-2 2v2h-6c-1.11 0-2 .89-2 2v2c0 1.1.89 2 2 2v18c0 2.2 1.79 4 4 4h14c2.2 0 4-1.8 4-4v-18c1.1 0 2-.9 2-2v-2c0-1.11-.9-2-2-2v0Zm-11 24c.55 0 1-.45 1-1v-12c0-.56-.45-1-1-1 -.56 0-1 .44-1 1v12c0 .55.44 1 1 1v0Zm-5 0c.55 0 1-.45 1-1v-12c0-.56-.45-1-1-1 -.56 0-1 .44-1 1v12c0 .55.44 1 1 1v0Zm10 0c.55 0 1-.45 1-1v-12c0-.56-.45-1-1-1 -.56 0-1 .44-1 1v12c0 .55.44 1 1 1v0Z"
          transform="translate(-259-203)"
        ></path>
      </g>
    </svg>
  );
};
