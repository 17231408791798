import React from 'react';

const ReturnsViewPage = () => {
    return (
        <div>
            Returns View
        </div>
    );
};

export default ReturnsViewPage;
