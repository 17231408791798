import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";

// Action Constants
const DASHBOARD_STATISTICS_GET = 'sale_returns/return/GET_DASHBOARD_STATISTICS';

// Actions
export const dashboardStatisticsGet = createAsyncThunk(
    DASHBOARD_STATISTICS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.dashboardStatistics, null, token, store, store);
    }
);

// Combined thunks
const dashboardStatisticsResourceThunks = {
    get: dashboardStatisticsGet,
}

//initial state
const initialState = {
    statistics: restResourceInitialState,
    monthly_sales: restResourceInitialState,
    monthly_profit: restResourceInitialState,
    monthly_purchases: restResourceInitialState,
    product_performance: restResourceInitialState,
    annual_profit: restResourceInitialState,
}

//redux toolkit slice
const dashboardSlice = createSlice({
    'name': 'dashboard',
    initialState,
    reducers: {},
    extraReducers: {
        ...generateResourceExtraReducers(dashboardStatisticsResourceThunks, "statistics"),
    },
});

//exports
export default dashboardSlice.reducer;
