import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {productsProductPackagesGet} from "../../../../application/features/products";
import PropTypes from "prop-types";

export const UnitQuantitySpinner = ({product, onChange, name}) => {
    const [packages, setPackages] = useState([]);
    const [payload, setPayload] = useState({quantity: 0, factor: 1});
    const productPackages = useSelector(state => state.products.product_packages.data);
    const fetchingPackages = useSelector(state => state.products.product_packages.isFetching);
    const dispatch = useDispatch();

    useEffect(() => {

    }, []);

    useEffect(() => {
        console.log('Product changed', product);
        if (product && product.id) {
            setPackages([]);
            dispatch(productsProductPackagesGet({product_id: product.id}));
        }
    }, [product]);

    useEffect(() => {
        if (!productPackages || !productPackages.length || (!product)) return;
        const _packages = productPackages.filter(i => i.product_id === product.id);
        setPackages(_packages);
    }, [productPackages]);

    const unitChanged = (e) => {
        console.log(e.target.value);
        setPayload(prev => {
            const _payload = {...prev, factor: e.target.value};
            if (onChange) {
                onChange(name, _payload.quantity * _payload.factor)
            }
            return _payload;
        });
    }
    const quantityChanged = (e) => {
        setPayload(prev => {
            const _payload = {...prev, quantity: e.target.value};
            if (onChange) {
                onChange(name, _payload.quantity * _payload.factor)
            }
            return _payload;
        });

    }

    return (
        <div>
            <div className="input-group flex-nowrap">
                <span className=" small" id="spinner-addon">
                    <select disabled={fetchingPackages || (!product)} className={'form-control'} style={{padding: ".6rem 1rem"}}
                            onChange={(e) => unitChanged(e)}>
                        {
                            (product && product.unit) ?
                                <option value={1}>{product.unit.name}</option>
                                : ''
                        }
                        }
                        {
                            (packages && packages.length) ?
                                packages.filter(u => u).map((item, i) => (
                                    <option key={'opt' + i} value={item.unit_count ?? 1}>{item.name}</option>
                                ))
                                : ''
                        }
                    </select>
                </span>
                <input name={name} readOnly={fetchingPackages || (!product)} type="number" min={"0"} className="form-control"
                       onChange={(e) => quantityChanged(e)}
                       placeholder="Quantity" aria-label="Quantity"
                       aria-describedby="spinner-addon"/>
            </div>
        </div>
    )
}

UnitQuantitySpinner.propTypes = {
    product: PropTypes.object,
    onChange: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string
}
