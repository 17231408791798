import React from 'react';
import moment from "moment";

const DateFormat = ({date, format='D MMMM YYYY'}) => {
    return (
        <>{ moment(date).format(format)}</>
    );
};

export default DateFormat;
