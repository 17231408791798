import {ldsBars} from './bars-loader.module.scss';
export const BarsLoader = (props) => {
    const {color} = props;

    return (
        <div className={ldsBars}>
            <div style={(color) ? {background: color} : {}}></div>
            <div style={(color) ? {background: color} : {}}></div>
            <div style={(color) ? {background: color} : {}}></div>
        </div>
    )
}
