import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import CustomPageAlert from "../../../../components/notifications/page-alert";
import {GeneralErrorPage} from "../../../../components/templates/error-pages/general-error-page";

export const SalesEdit = () => {
    const {sale_id} = useParams();
    const dispatch = useDispatch();
    // redux
    const sales = useSelector(state => state.sales.sales.data);
    const sale = useSelector(state => state.sales.sales.data.find(e => e.id == sale_id));
    useEffect(() => {

    }, []);

    return (
       <GeneralErrorPage title={"Feature Unavailable!"} subTitle={'Sorry! you can not edit sales for now!'} icon={'noConnection'} />
    )
}
