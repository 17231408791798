import React from 'react'
import { NavLink } from 'react-router-dom'
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { IconCart } from '../../../atomic/icons/cart';
import { IconHome } from '../../../atomic/icons/home';
import { IconLayout } from '../../../atomic/icons/layout';
import styles from "./index.module.scss";

import NavigationGroupComponent from './widgets/group';
import NavigationItemComponent from './widgets/item';
import { IconShoppingBag } from '../../../atomic/icons/shopping-bag';
import {IconPurchaseTag} from "../../../atomic/icons/purchase-tag";
import IconBankSpending from "../../../atomic/icons/bank-spending";
import IconInventory from "../../../atomic/icons/inventory";
import IconCustomers from "../../../atomic/icons/customers";
import IconMessages from "../../../atomic/icons/messages";
import IconReports from "../../../atomic/icons/reports";
import IconReturns from "../../../atomic/icons/returns";
import IconUsers from "../../../atomic/icons/users";
import IconSettings from "../../../atomic/icons/settings";
import IconAccounts from "../../../atomic/icons/accounts";
import {useTranslation} from "react-i18next";
import GalaxyLogo from '$assets/img/bizpro_logo.png';

export const NavigationComponent = () => {
    const breadcrumbs = useReactRouterBreadcrumbs();
    const {i18n, t} = useTranslation();
    return (
        <div className={styles.navWrapper}>
            <div className={styles.navHeader}>
                <img src={GalaxyLogo} />
            </div>

            <div className='mt-2 px-3'>
                <NavigationGroupComponent>
                    <NavigationItemComponent icon={IconHome} title={t('Dashboard')} href="/main/dashboard" />
                    <NavigationItemComponent permissions={['View Sales', 'Create Sales', 'Delete Sales']} icon={IconCart} title={i18n.t('Sales')} href="/main/sales" />
                    <NavigationItemComponent permissions={['View Inventory', 'Create Inventory', 'Delete Inventory']} icon={IconInventory} title={t('Inventory')} href="/main/inventory" />
                    <NavigationItemComponent permissions={['View Purchases', 'Create Purchases', 'Delete Purchases']} icon={IconPurchaseTag} title={t('Purchases')} href="/main/purchases" />
                    <NavigationItemComponent permissions={['View Expenses', 'Create Expenses', 'Delete Expenses']} icon={IconBankSpending} title={t('Expenses')} href="/main/expenses" />
                    <NavigationItemComponent permissions={['View Returns', 'Create Returns', 'Delete Returns']} icon={IconReturns} title={t('Returns')} href="/main/returns" />
                    <NavigationItemComponent permissions={['View Products', 'Create Products', 'Delete Products']} icon={IconShoppingBag} title={t('Products')} href="/main/products" />
                    <NavigationItemComponent permissions={['View Customers', 'Create Customers', 'Delete Customers']} icon={IconCustomers} title={t('Customers')} href="/main/customers" />

                    {/*// NEXT VERSION*/}
                    {/*<NavigationItemComponent permissions={['View Messages', 'Create Messages', 'Delete Messages']} icon={IconMessages} title={t('Messaging')} href="/main/messages" />*/}

                    <NavigationItemComponent permissions={['View Users', 'Create Users', 'Delete Users']} icon={IconUsers} title={t('Users')} href="/main/users" />
                    <NavigationItemComponent permissions={['View Accounts', 'Create Accounts', 'Delete Accounts']} icon={IconAccounts} title={t('Accounts')} href="/main/accounts" />
                    <NavigationItemComponent permissions={['View Reports', 'Create Reports', 'Delete Reports']} icon={IconReports} title={t('Reports')} href="/main/reports" />
                    <NavigationItemComponent permissions={['View Settings', 'Create Settings', 'Delete Settings']} icon={IconSettings} title={t('Settings')} href="/main/settings" />

                    {/* <NavigationItemComponent icon={IconLayout} title="Layouts">
                        <NavigationItemComponent title="Vertical">
                            <NavigationItemComponent title="Light Sidebar" />
                            <NavigationItemComponent title="Component Sidebar" />
                            <NavigationItemComponent title="Icon Sidebar" />
                            <NavigationItemComponent title="Boxed Width" />
                            <NavigationItemComponent title="Pre Loader" />
                            <NavigationItemComponent title="Colored Sidebar" />
                            <NavigationItemComponent title="Scrollable" />
                        </NavigationItemComponent>
                        <NavigationItemComponent title="Horizontal">
                        <NavigationItemComponent title="Light Sidebar" />
                            <NavigationItemComponent title="Component Sidebar" />
                            <NavigationItemComponent title="Icon Sidebar" />
                            <NavigationItemComponent title="Boxed Width" />
                            <NavigationItemComponent title="Pre Loader" />
                            <NavigationItemComponent title="Colored Sidebar" />
                            <NavigationItemComponent title="Scrollable" />
                        </NavigationItemComponent>
                    </NavigationItemComponent> */}
                </NavigationGroupComponent>

                {/* <NavigationGroupComponent title="Menu">
                    <NavigationItemComponent icon={IconHome} title="Dashboard" />
                    <NavigationItemComponent icon={IconLayout} title="Layouts">
                        <NavigationItemComponent title="Vertical" />
                        <NavigationItemComponent title="Horizontal" />
                    </NavigationItemComponent>
                </NavigationGroupComponent> */}
            </div>
            
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav d-flex">
                    {/* <li className="nav-item">
                        <NavLink className="nav-link" to="/main/dashboard">Dashboard</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/main/sales">Sales</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/main/products">Products</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/main/purchases">Purchases</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/main/expenses">Expenses</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/main/inventory">Inventory</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/main/customers">Customers</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/main/messages">Messaging</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/main/reports">Reports</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/main/returns">Returns</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/main/users">Users</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/main/settings">Settings</NavLink>
                    </li> */}
                    {/* <li className="nav-item">
                        <a className="nav-link" href="#">Pricing</a>
                    </li> */}
                    {/* <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Dropdown link
                    </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                    </li> */}
                </ul>
            </div>
        


            {/* <div className="container-fluid mt-2">
                <div className="row">
                    <div className="col">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                {breadcrumbs.map(({ breadcrumb, match }) => <li key={match.pathname} className="breadcrumb-item" aria-current="page"><NavLink to={match.pathname}>{breadcrumb}</NavLink></li>)} 
                            </ol>
                        </nav>
                    </div>
                </div>
            </div> */}  
        </div>
    )
}


