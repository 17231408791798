import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translation_en from './translations/en/translation.json';
import translation_sw from './translations/sw/translation.json';

// the translations
const resources = {
    en: {translation: translation_en},
    'en-GB': {translation: translation_en},
    sw: {translation: translation_sw},
};

const LanguageDetector = {
    type: 'languageDetector',
    async: true, // If this is set to true, your detect function receives a callback function that you should call with your language, useful to retrieve your language stored in AsyncStorage for example
    init: function(services, detectorOptions, i18nextOptions) {
        /* use services and options */
    },
    detect: function(callback) { // You'll receive a callback if you passed async true
        /* return detected language */
        // callback('de'); if you used the async flag
        return 'sw';
    },
    cacheUserLanguage: function(lng) {
        /* cache language */
    }
};

// import Backend from 'i18next-http-backend';
 i18n
    // .use(LanguageDetector)
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources,
        lang: "sw"
    });
export default i18n;
