import React, {Children} from 'react';
import PropTypes from "prop-types";

const CustomModal = (props) => {
    const {show, handleClose, title, footer, children, size = 'modal-lg', bodyPadding = null, showHeader = false, showFooter = false} = props
    const arrayChildren = Children.toArray(children);
    return (
            <div className={show ? "modal fade show d-block" : "modal fade d-none"} tabIndex="-1">
                <div className={"modal-dialog modal-dialog-centered " + size}>
                    <div className="modal-content">
                        {(showHeader) && <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                            <button type="button" className="btn-close" onClick={handleClose} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>}
                        <div className="modal-body" style={{padding: bodyPadding ? bodyPadding : 'inherit'}}>
                            {arrayChildren}
                        </div>
                        {(showFooter) && <div className="modal-footer">
                            {footer}
                        </div>}
                    </div>
                </div>
            </div>
    );
};

CustomModal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    handleClose: PropTypes.func,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    size: PropTypes.string,
    bodyPadding: PropTypes.string,
}

export default CustomModal;
