import { createSlice } from '@reduxjs/toolkit';
import {login} from "./authentication";

//initial state
const initialState = {
    isSideMenuOpen: false,
    notifications: [], // Ui notifications
    alerts: [], // Sweet Alerts & other alerts
    network: {
        unauthorized: false,
        interrupt: null,
        connectionStatus: true,
        status: -1,
    }
}

//redux toolkit slice
const appSlice = createSlice({
    'name': 'app',
    initialState,
    reducers: {
        toggleSideMenu(state, action) {
            state.isSideMenuOpen = action.payload
        },
        showNotification(state, action) {
            state.notifications.push(action.payload);
        },
        dismissNotification(state, action) {
            state.notifications.shift();
        },
        showAlert(state, action) {
            state.alerts.push(action.payload);
        },
        dismissAlert(state, action) {
            state.alerts.shift();
        },
        updateNetworkUnauthorized(state, action) {
            state.network.unauthorized = action.payload;
        },
        handleInterrupt(state, action) {
            console.log('Interrupt: ', action);
            state.network.interrupt = (action.payload.data.code) ? action.payload.data.code : null;
        },
        clearInterrupt(state, action) {
            state.network.interrupt = null;
        },
        setConnectionStatus(state, action) {
            console.log('Updating connection Status', action);
            state.network.connectionStatus = action.payload.status ?? false;
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.network.unauthorized = false;
        },
    },
});

//exports
export const { toggleSideMenu, showAlert, dismissAlert, showNotification, dismissNotification, updateNetworkUnauthorized, handleInterrupt, clearInterrupt, setConnectionStatus } = appSlice.actions;
export default appSlice.reducer;
