import React from 'react';
import DatePicker from "react-datepicker";

const CustomDatePicker = (props) => {
    //props
    const { label, name, placeholder, onChange, value, error } = props;
    const dateProps = {
        showYearDropdown: true,
        dateFormat: "yyyy-MM-dd",
        // maxDate: new Date(),
    }
    //handlers
    const handleChange = (key, value) => {
        if (onChange) {
            const date = new Date(value);
            const formattedDate = new Date(date).toISOString().slice(0, 10).toString()
            onChange(key, date);
        }
    }


    return (

    <div className="form-group">
        {label &&<label htmlFor={name} className="form-label">{label}</label>}
        <DatePicker className={(error != null) ? "form-control input-sm is-invalid" : "form-control input-sm"}  id={name} placeholder={placeholder}  selected={value} onChange={(date) => handleChange(name, date)} {...dateProps}  />
        <div className={(error != null) ? 'invalid-feedback d-block' : 'valid-feedback d-none'}>{error}</div>
    </div>
    );
};

export default CustomDatePicker;
