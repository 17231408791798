import {BlankPage} from "../../../components/templates/layouts/blank-page";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../../../application/features/authentication";
import {useNavigate} from "react-router";
import {useEffect} from "react";

export const NoSubscription = () => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        console.log('Handling logout');
        dispatch(logout());
    }
    return (
        <BlankPage>
            <div>
                <h4>Not Subscribed!</h4>
                <p>Sorry, You are currently not subscribed to BizPro. <br />Please go to <a href={'https://softwaregalaxy.com'}>SoftwareGalaxy</a> and begin your subscription</p>
                <div>
                    <button className="btn btn-outline-primary" onClick={handleLogout}>
                        Logout
                    </button>
                </div>
            </div>
        </BlankPage>
    )
}
