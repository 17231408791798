import React, {useState} from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import moment from "moment";
import {IconSearch} from "../../atomic/icons/search";
import PropTypes from "prop-types";
import CustomInput from "../../atomic/input";
import i18n from "i18next";
import CustomSelectInput from "../../atomic/select";

const BackendContentFilter = ({onChange, onSubmit, startDate, endDate, formFields = ['dateRange']}) => {
    const [payload, setPayload] = useState({
        start_date: startDate ? new Date(startDate) : new Date(),
        end_date: endDate ? new Date(endDate) : new Date()
    });

    const handleChange = (key, data) => {
        const pay = {...payload}
        pay[key] = data;
        setPayload(pay);
        if (onChange) {
            onChange(convertDates({...pay}));
        }
    }

    const submit = () => {
        if (onSubmit) {
            onSubmit(convertDates({...payload}));
        }

    }

    const convertDates = (p) => {
        p.start_date = new moment(p.start_date).format('YYYY-MM-DD');
        p.end_date = new moment(p.end_date).format('YYYY-MM-DD');
        return p;
    }

    const dateProps = {
        showYearDropdown: true,
        dateFormat: "yyyy-MM-dd",
        maxDate: new Date(),
    }

    const rangePresets = [
        {id: 0, name: "Custom Selection"},
        {id: 1, name: "This Year"},
        {id: 2, name: "This Month"},
        {id: 3, name: "Last Year"},
        {id: 4, name: "Last Month"},
    ];
    return (
        <div className="content-filter form-group d-inline-flex flex-row-reverse">
            <div className="flex-grow-1">&nbsp;</div>
            <div className="input-group flex-grow-0" style={{flexWrap: 'unset'}}>
                {
                    (formFields.indexOf('dateRange') !== -1)
                        ?
                        <>
                            {/*<CustomSelectInput name={'range'} items={rangePresets}  />*/}
                            <DatePicker className="form-control input-sm" selected={payload.start_date}
                                        onChange={(date) => handleChange('start_date', date)} {...dateProps} />
                            <span className="input-group-text input-sm">to</span>
                            <DatePicker className="form-control input-sm" selected={payload.end_date}
                                        onChange={(date) => handleChange('end_date', date)} {...dateProps}  />
                        </>
                        : ''
                }
                {
                    (formFields.indexOf('search') !== -1)
                    ?
                        <>
                            <input type={'search'} className={'form-control input-sm'}  name={'search'} placeholder={i18n.t('Search')} onChange={(e) => handleChange('search', e.target.value)} />
                        </>
                        : ''
                }
                <button onClick={submit} className="btn btn-outline-secondary px-2" type="button" id="button-addon2">
                    <IconSearch width="20px"/></button>
            </div>


        </div>
    );
};
export default BackendContentFilter;

BackendContentFilter.propTypes = {
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    formFields: PropTypes.array,
}
