import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {customersCustomerGroupsGet} from "../../../../../application/features/customers";
import {useTranslation} from "react-i18next";
import {AddButton} from '../../../../components/buttons/add-button';
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import CustomerGroupsResource from "../../../../../application/resources/customer-groups";

const CustomerGroupsListPage = () => {
    // STATE
    const [deletingId, setDeletingId] = useState(null);

    //hooks
    const {t} = useTranslation();
    //redux
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customer_groups = useSelector(state => state.customers.customer_groups.data);

    //effects
    useEffect(() => {
        if (customer_groups && customer_groups.length) return;
        dispatch(customersCustomerGroupsGet());
    }, []);


    const showAddForm = () => {
        navigate('form');
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className='card-header'>
                        <div className="d-flex align-items-center">
                            <span className="card-title flex-grow-1">{t('Customer Groups')}</span>
                            <AddButton permissions={['Create Customers']} to="form" title="Add Customer Group"/>
                        </div>
                    </div>

                    <div className="card-body">
                        <GalaxyResourceTable deletable={(row) => false} resource={new CustomerGroupsResource()}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerGroupsListPage;
