
import { createAsyncThunk } from '@reduxjs/toolkit';
import BizManagerApi from '../../api/bizmanager';
import { customFetch } from '../../core/network';
import { createSlice } from '@reduxjs/toolkit';
import {generateRequestReducers, requestInitialState, restResourceInitialState} from '../../core/data-structures';

// ACTION CONSTANTS
const ONBOARDING_ONBOARDING_REGISTER = 'onboarding/register';
const ONBOARDING_CHECK_ONBOARDING = 'onboarding/check-onboarding';
const ONBOARDING_CREATE_ORGANIZATION = 'onboarding/create-organization';
const ONBOARDING_GET_BUSINESS_TYPES = 'onboarding/get-business-types';
const ONBOARDING_UPDATE_BUSINESS_TYPES = 'onboarding/update-business-types';
const ONBOARDING_CREATE_PASSWORD = 'onboarding/create-password';
const ONBOARDING_GET_PRODUCT_CATEGORIES = 'onboarding/get-product-categories';
const ONBOARDING_UPDATE_PRODUCT_CATEGORIES = 'onboarding/update-product-categories';
const ONBOARDING_ONBOARDING_FINISH = 'onboarding/finish';

// ACTIONS
export const onBoardingCheckStatus = createAsyncThunk(
    ONBOARDING_CHECK_ONBOARDING,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.checkOnboarding, null, token, store);
    }
);

export const onBoardingCreateOrganization = createAsyncThunk(
    ONBOARDING_CREATE_ORGANIZATION,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.createOrganization, data, token, store);
    }
);

export const onBoardingGetBusinessTypes = createAsyncThunk(
    ONBOARDING_GET_BUSINESS_TYPES,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.getBusinessTypes, null, token, store);
    }
);

export const onBoardingUpdateBusinessTypes = createAsyncThunk(
    ONBOARDING_UPDATE_BUSINESS_TYPES,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.updateBusinessType, data, token, store);
    }
);

export const onBoardingCreatePassword = createAsyncThunk(
    ONBOARDING_CREATE_PASSWORD,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.createPassword, data, token, store, store);
    }
);

export const onBoardingUpdateProductCategories = createAsyncThunk(
    ONBOARDING_UPDATE_PRODUCT_CATEGORIES,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.updateProductCategories, data, token, store);
    }
);

export const onBoardingGetProductCategories = createAsyncThunk(
    ONBOARDING_GET_PRODUCT_CATEGORIES,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.getProductCategories, data, token, store);
    }
);

export const onBoardingRegister = createAsyncThunk(
    ONBOARDING_ONBOARDING_REGISTER,
    async (data, store) => {
        const { token } = store.getState().authentication;
        console.log("just view the reducers");
        console.log(generateRequestReducers(onBoardingRegister, 'register'));
        return await customFetch('POST', BizManagerApi.register, data, token, store);
    }
);
export const onBoardingFinish = createAsyncThunk(
    ONBOARDING_ONBOARDING_FINISH,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.onboardingFinish, data, token, store);
    }
);

const initialState = {
    register: requestInitialState,
    check_onboarding: requestInitialState,
    create_organization: requestInitialState,
    get_business_types: requestInitialState,
    update_business_types: requestInitialState,
    create_password: restResourceInitialState,
    get_product_categories: requestInitialState,
    update_product_categories: requestInitialState,
    finish_onboarding: requestInitialState,
}



const onBoardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {},
    extraReducers: {
        ...generateRequestReducers(onBoardingRegister, 'register'),
        ...generateRequestReducers(onBoardingCheckStatus, 'check_onboarding'),
        ...generateRequestReducers(onBoardingCreateOrganization, 'create_organization'),
        ...generateRequestReducers(onBoardingGetBusinessTypes, 'get_business_types'),
        ...generateRequestReducers(onBoardingUpdateBusinessTypes, 'update_business_types'),
        ...generateRequestReducers(onBoardingCreatePassword, 'create_password'),
        ...generateRequestReducers(onBoardingGetProductCategories, 'get_product_categories'),
        ...generateRequestReducers(onBoardingUpdateProductCategories, 'update_product_categories'),
        ...generateRequestReducers(onBoardingFinish, 'finish_onboarding'),
    }
});

export default onBoardingSlice.reducer;
