
export const FullSpinner = (props) => {
    const {size = 50, color = '#999'} = props;
    return (
        <div className={'d-flex flex-row justify-content-center align-items-center'} style={{width: '100%', height: '99%', minHeight: 100}}>
            <div className="spinner-border" role="status" style={{width: size, height: size, color: color}}>
                <span className="visually-hidden" style={{color: color}}>Loading...</span>
            </div>
        </div>
    )
}
