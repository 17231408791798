import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";

// Action Constants
const EXPENSES_EXPENSES_GET = 'expenses/expense/GET_EXPENSES';
const EXPENSES_EXPENSE_CREATE = 'expenses/expense/CREATE_EXPENSE';
const EXPENSES_EXPENSE_RETRIEVE = 'expenses/expense/RETRIEVE_EXPENSE';
const EXPENSES_EXPENSE_UPDATE = 'expenses/expense/UPDATE_EXPENSE';
const EXPENSES_EXPENSE_DELETE = 'expenses/expense/DELETE_EXPENSE';

const EXPENSES_EXPENSES_TYPES_GET = 'expenses/expense/GET_EXPENSES_TYPES';
const EXPENSES_EXPENSE_TYPES_CREATE = 'expenses/expense/CREATE_EXPENSE_TYPES';
const EXPENSES_EXPENSE_TYPES_RETRIEVE = 'expenses/expense/RETRIEVE_EXPENSE_TYPES';
const EXPENSES_EXPENSE_TYPES_UPDATE = 'expenses/expense/UPDATE_EXPENSE_TYPES';
const EXPENSES_EXPENSE_TYPES_DELETE = 'expenses/expense/DELETE_EXPENSE_TYPES';

// Actions
export const expensesExpensesGet = createAsyncThunk(
    EXPENSES_EXPENSES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.expenses, data, token, store);
    }
);

export const expensesExpenseCreate = createAsyncThunk(
    EXPENSES_EXPENSE_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.expenses_create, data.payload, token, store);
    }
);

export const expensesExpenseUpdate = createAsyncThunk(
    EXPENSES_EXPENSE_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.expenses_update, data.payload, token, store);
    }
);

export const expensesExpenseRetrieve = createAsyncThunk(
    EXPENSES_EXPENSE_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.expense(data.id), data.payload, token, store);
    }
);

export const expensesExpenseDelete = createAsyncThunk(
    EXPENSES_EXPENSE_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.expense(data.id), data.payload, token, store);
    }
);

// Expense Types
export const expensesExpenseTypesGet = createAsyncThunk(
    EXPENSES_EXPENSES_TYPES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.expense_types, null, token, store);
    }
);

export const expensesExpenseTypesCreate = createAsyncThunk(
    EXPENSES_EXPENSE_TYPES_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.expense_types, data.payload, token, store);
    }
);

export const expensesExpenseTypesUpdate = createAsyncThunk(
    EXPENSES_EXPENSE_TYPES_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.expense_type(data.id), data.payload, token, store);
    }
);

export const expensesExpenseTypesRetrieve = createAsyncThunk(
    EXPENSES_EXPENSE_TYPES_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.expense_type(data.id), data.payload, token, store);
    }
);

export const expensesExpenseTypesDelete = createAsyncThunk(
    EXPENSES_EXPENSE_TYPES_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.expense_type(data.id), data.payload, token, store);
    }
);


// Combined thunks
const expensesResourceThunks = {
    create: expensesExpenseCreate,
    get: expensesExpensesGet,
    retrieve: expensesExpenseRetrieve,
    update: expensesExpenseUpdate,
    delete: expensesExpenseDelete,
}

const expenseTypesResourceThunks = {
    create: expensesExpenseTypesCreate,
    get: expensesExpenseTypesGet,
    retrieve: expensesExpenseTypesRetrieve,
    update: expensesExpenseTypesUpdate,
    delete: expensesExpenseTypesDelete,
}

//initial state
const initialState = {
    expenses: restResourceInitialState,
    expense_types: restResourceInitialState,
}

//redux toolkit slice
const expensesSlice = createSlice({
    'name': 'expenses',
    initialState,
    reducers: {
        clearExpensesValidationErrors: (state, action) => {
            state.validationErrors = {};
        }
    },
    extraReducers: {
        ...generateResourceExtraReducers(expensesResourceThunks, "expenses"),
        ...generateResourceExtraReducers(expenseTypesResourceThunks, "expense_types"),
    },
});

//exports
export const { clearExpensesValidationErrors } = expensesSlice.actions;
export default expensesSlice.reducer;
