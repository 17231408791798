import React from 'react'

export const CustomTable = ({children, isLoading = false, isEmpty = false, placeholder = 'No Data'}) => {
  return (isLoading)
      ?
      <div className="full-page-loader d-flex justify-content-center py-4">
          <div className="spinner-border text-black-50" role="status"><span className="visually-hidden">Loading...</span></div>
      </div>
      :
      (isEmpty) ?
          <div className="w-100 text-center p3 text-black-50">{placeholder ?? 'No Data'}</div>
          :
      <table className='table custom-table'>
        {children}
      </table>
}
