import React from 'react';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {messagesAutomatedMessagesGet, messagesMessagesGet} from "../../../../../application/features/messages";
import {CustomTable} from "../../../../atomic/table/table";
import { AddButton } from '../../../../components/buttons/add-button';

const AutomatedMessagesListPage = () => {
    //state
    const [filterPayload, setFilterPayload] = useState({start_date: null, end_date: null});
    //hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //redux
    const automated_messages = useSelector(state => state.messages.automated_messages.data);
    const isFetching = useSelector(state => state.messages.automated_messages.isFetching);


    useEffect(() => {
        // if(messages.length == 0 || true){
        dispatch(messagesAutomatedMessagesGet(filterPayload));
        // }
    }, []);

    useEffect(() => {
        console.log(automated_messages);
    }, [automated_messages]);

    const showAddForm = () => {
        navigate('form');
    }

    return (
        <div className="row">
            {/* <div className="col-12 text-end mb-2">
                <button className="btn btn-outline-primary" onClick={showAddForm}>Create Automation</button>
            </div> */}
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-center">
                            <div className="card-title flex-grow-1">Automated Messages</div>
                            <AddButton to="form" title="Create Automation" />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card">
                            <div className="card-body">
                                <CustomTable isLoading={isFetching}>
                                <thead>
                                <tr>
                                    <th width={30}>#</th>
                                    <th>Type</th>
                                    <th>Customer</th>
                                    <th>Phone Number</th>
                                    <th>Message</th>
                                    <th>Status</th>
                                    <th className="col-actions">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    automated_messages.filter(u => u).map((message, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{message.automation_factor ? message.automation_factor.name : ''}</td>
                                            <td>{message.customer ? message.customer.name : '-'}</td>
                                            <td>{message.numbers ?? ''}</td>
                                            <td>{message.message ?? ''}</td>
                                            <td><span
                                                className="badge bg-secondary">{message.active ? 'ACTIVE' : 'INACTIVE'}</span>
                                            </td>
                                            <td></td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </CustomTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AutomatedMessagesListPage;
