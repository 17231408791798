import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {
    clearPurchasesValidationErrors,
    purchasesPurchaseCreate,
    purchasesPurchaseUpdate
} from "../../../../../application/features/purchases";
import CustomTextArea from "../../../../atomic/text-area";
import CustomModal from "../../../../components/modals/modal";
import PurchaseItemForm from "./form/purchase-item-form";
import Currency from "../../../../atomic/formatting/currency";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {Spinner} from "../../../../atomic/spinner";

const PurchasesFormPage = () => {
    // State
    const [showForm, setShowForm] = useState(false);
    const [payload, setPayload] = useState({description: null, items: []});
    const [mounted, setMounted] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const { purchase_id } = useParams();
    const navigate = useNavigate();

    const purchases = useSelector(state => state.purchases.purchases.data);
    const isCreating = useSelector(state => state.purchases.purchases.isCreating);
    const isUpdating = useSelector(state => state.purchases.purchases.isUpdating);
    const created = useSelector(state => state.purchases.purchases.creatingSuccessful);
    const updated = useSelector(state => state.purchases.purchases.updatingSuccessful);
    const creatingError = useSelector(state => state.purchases.purchases.creatingError);
    const updatingError = useSelector(state => state.purchases.purchases.updatingError);
    const validationErrors = useSelector(state => state.purchases.purchases.validationErrors);

    const dispatch = useDispatch();

    const enableEditingItems = false; // TODO should be enabled after feature is implemented

    //effects
    useEffect(() => {
        setErrorMessage("");
        setMounted(true);
    }, [])

    useEffect(() => {
        console.log("Created effect triggered", created);
        if (!mounted) return;
        if (!created) return;
        navigate('../');
    }, [created]);

    useEffect(() => {
        console.log("Updated effect triggered", updated);
        if (!mounted) return;
        if (!updated) return;
        navigate('../');
    }, [updated]);

    useEffect(() => {
        if (!purchase_id || !purchases) return;
        const purchase = purchases.filter(u => u).find(u => u.id == purchase_id);
        if (!purchase) return;
        setPayload({date: purchase.date, description: purchase.description, items: purchase.items});
    }, [purchase_id, purchases]);

    useEffect(() => {
        if (!updatingError && !creatingError) return;
        console.log(creatingError);
        if (purchase_id && !updatingError) return;

        let error;
        if (purchase_id) {
            error =  updatingError;
        } else {
            error =  creatingError;
        }

        if (error && error.message) {
            setErrorMessage(error.message);
            setTimeout(() => setErrorMessage(""), 60000);
        }

    }, [updatingError, creatingError, validationErrors, purchase_id])


    const toggleForm = () => {
        setShowForm(!showForm);
    }

    const saveItem = (itemPayload) => {
        console.log(itemPayload);
        if(itemPayload){
            const p = {...payload};
            p.items = [...p.items, itemPayload];
            setPayload(p);
            setShowForm(false);
        }
        console.log(payload);
    }

    const removeItem = (item) => {
        const p = {...payload};
        p.items = p.items.filter((i) => i != item);
        setPayload(p);
        setShowForm(false);
    }

    const handleChange = (name, value) => {
      const p = {...payload};
      p[name] = value;
      setPayload(p);
      dispatch(clearPurchasesValidationErrors());
    }

    const submit = () => {
      if(true) {
          setErrorMessage("");
          if(!purchase_id){
              payload.date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
             const data = {payload: payload};
            dispatch(purchasesPurchaseCreate(data))
          } else {
              const data = {payload: {id: purchase_id, ...payload}};
              dispatch(purchasesPurchaseUpdate(data))
          }
      }
    }
    return (
        <div className={'card'}>
            <div className="card-body">
                {errorMessage && <div className="alert alert-danger mb-2">{errorMessage}</div>}
                <div>
                    <h5>{purchase_id ? "Update Purchase" : "Record a new Purchase"}</h5>
                </div>
                <div>
                    <CustomTextArea name={"description"} label="Description" value={payload.description} onChange={handleChange} error={(validationErrors.hasOwnProperty("description"))  ? validationErrors["description"][0] ?? null : null} placeHolder={"A general description of your purchase"} />
                    <div>
                        <div className="d-flex align-content-stretch mb-1 mt-3">
                            <div className="flex-grow-1">
                                Items
                                <div className="text-black-50 small">A list of individual items you included in your purchase</div>
                            </div>
                            <div className="flex-grow-0">
                                <button disabled={(purchase_id ? !enableEditingItems : false)} className="btn btn-outline-primary" onClick={() => setShowForm(true)}>+ Add Item</button>
                            </div>
                        </div>
                        <table className="table table-bordered table-sm">
                            <thead>
                            <tr>
                                <th width="30">#</th>
                                <th>Item</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Selling Price</th>
                                <th>Total</th>
                                <th width="25"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {payload.items.map((item, i) => (
                                <tr key={"tr-" + i}>
                                    <td>{i+1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.quantity}</td>
                                    <td className="text-end"><Currency value={item.unit_amount} /></td>
                                    <td className="text-end"><Currency value={item.selling_price} /></td>
                                    <td className="text-end"><Currency value={(item.quantity * item.unit_amount)} /></td>
                                    <td>
                                        {((purchase_id ? !enableEditingItems : true)) && <button onClick={() => removeItem(item)} className="btn btn-sm btn-danger">x</button> }
                                    </td>
                                </tr>
                            ))
                            }
                            </tbody>
                            <tfoot>
                            <tr>
                                <th colSpan={5}>Total</th>
                                <th colSpan={2} className="pr-4 text-end"><Currency value={payload.items.reduce((prev, current) => prev + (current.quantity * current.unit_amount), 0)} /></th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div>
                        <button disabled={isCreating || isUpdating} className="btn btn-primary" onClick={submit}>{(isCreating || isUpdating) ? <Spinner /> : (purchase_id ? "Update" : "Save")}</button>
                    </div>
                </div>
                <CustomModal size={'small'} bodyPadding={'1rem'} showHeader={true}  title="New Purchase Item" show={showForm} handleClose={() => setShowForm(false)} >
                    <PurchaseItemForm onSave={saveItem} />
                </CustomModal>
            </div>
        </div>
    );
};

export default PurchasesFormPage;
