import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    organizationsOrganizationSetActive,
    organizationsOrganizationsGet
} from "../../../../../application/features/organizations";
import {useNavigate} from "react-router-dom";
import {Spinner} from "../../../../atomic/spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import CustomConfirmAlert from "../../../../components/notifications/confirm-alert";
import React from "react";

export const UserOrganization = () => {
    const [previous, setPrevious] = useState(null);
    const [shouldSwitchTo, setShouldSwitchTo] = useState(null);
    const [confirmSwitchOrganization, setConfirmSwitchOrganization] = useState(false);
    const user = useSelector(state => state.authentication.user);
    const organization = useSelector(state => state.authentication.user.organization);
    const organizations = useSelector(state => state.organizations.organizations.data);
    const fetchingOrganizations = useSelector(state => state.organizations.organizations.isFetching);
    const updatingOrganizations = useSelector(state => state.organizations.organizations.isUpdating);
    const activatingOrganization = useSelector(state => state.organizations.activatingOrganization.isActivating);
    const activatingOrganizationError = useSelector(state => state.organizations.activatingOrganization.activatingError);
    const activatingOrganizationSuccessful = useSelector(state => state.organizations.activatingOrganization.activatingSuccessful);
    const navigate = useNavigate();

    // redux
    const dispatch = useDispatch();
    // effect
    useEffect(() => {
        dispatch(organizationsOrganizationsGet());
        setPrevious(organization.id);
    }, []);

    useEffect(() => {
        if(!organization) return;
        if(organization.id !== previous) {
            // TODO dispatch reset system
            setPrevious(organization.id);
        }
    }, [organization]);


    useEffect(() => {
        console.log('ORGANIZATIONS',organizations);
    }, [organizations]);

    const switchOrganization = (org) => {
        cancelSwitching();
        if(!org) return;
        if(org.id === organization.id) return;
        const payload = {
            organization_id: org.id
        }
        dispatch(organizationsOrganizationSetActive({payload}));
    }

    const handleSwitchOrganization = (org) => {
        setShouldSwitchTo(org);
        setConfirmSwitchOrganization(true);
    }

    const cancelSwitching = () => {
        setConfirmSwitchOrganization(false);
        setShouldSwitchTo(null);
    }

    if(organization == null) {
        return <div>
            <div className="alert p-2">
                No active organization selected!
            </div>
        </div>
    }
    return (
        <div className={"px-5 py-4"}>
            <div className="row">
                <h5 className="col-12">{organization.name ?? 'Unspecified'}</h5>
                <hr className={'text-muted'}/>
                <div className="col-12">{organization.address ?? 'No address'}</div>
            </div>
            <hr />
            <div className="row mt-4">
                {(fetchingOrganizations || updatingOrganizations || activatingOrganization) ? <div className={"text-center w-100"}><Spinner /></div> : ''}
                {
                    (organizations && organizations.length > 1) ?
                    <div >
                        <h5>Switch Organization</h5>

                        <div className="row">
                        {
                            organizations.filter(u => u).map((o, i) => (
                                <div key={i} className="col-12 col-md-4 col-xl-3 mb-3">
                                    <div style={{cursor: 'pointer'}} href={"#"} onClick={() => ((o.id == organization.id) ? {} : handleSwitchOrganization(o))} className="card shadow-hover">
                                        <div  className="card-body text-center">
                                            {o.name ?? 'Unnamed'}
                                            <div className={'text-black-50'}>{ (o.id == organization.id) ? 'Active' : 'Switch' }</div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                    </div> :
                        <div className={'w-100 p-2'}>
                            { (fetchingOrganizations) ? '' : <div className="text-black-50">You dont have multiple organizations</div>}
                        </div>
                }
            </div>
            <CustomConfirmAlert isShown={confirmSwitchOrganization && shouldSwitchTo} title="Switch Organization?" text={`Are you sure you want to switch to ` + (shouldSwitchTo ? shouldSwitchTo.name : '') + `?`} onConfirm={() => switchOrganization(shouldSwitchTo)} toggleAlert={cancelSwitching} />
        </div>

    )
}
