import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";

// Action Constants
const CUSTOMERS_CUSTOMERS_GET = 'customers/customer/GET_CUSTOMERS';
const CUSTOMERS_CUSTOMER_CREATE = 'customers/customer/CREATE_CUSTOMER';
const CUSTOMERS_CUSTOMER_RETRIEVE = 'customers/customer/RETRIEVE_CUSTOMER';
const CUSTOMERS_CUSTOMER_UPDATE = 'customers/customer/UPDATE_CUSTOMER';
const CUSTOMERS_CUSTOMER_DELETE = 'customers/customer/DELETE_CUSTOMER';

const CUSTOMERS_CUSTOMER_GROUPS_GET = 'customers/customer/GET_CUSTOMER_GROUPS';
const CUSTOMERS_CUSTOMER_GROUP_CREATE = 'customers/customer/CREATE_CUSTOMER_GROUP';
const CUSTOMERS_CUSTOMER_GROUP_RETRIEVE = 'customers/customer/RETRIEVE_CUSTOMER_GROUP';
const CUSTOMERS_CUSTOMER_GROUP_UPDATE = 'customers/customer/UPDATE_CUSTOMER_GROUP';
const CUSTOMERS_CUSTOMER_GROUP_DELETE = 'customers/customer/DELETE_CUSTOMER_GROUP';

// Actions
export const customersCustomersGet = createAsyncThunk(
    CUSTOMERS_CUSTOMERS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.customers, data, token, store);
    }
);

export const customersCustomerCreate = createAsyncThunk(
    CUSTOMERS_CUSTOMER_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.customers_create, data.payload, token, store);
    }
);

export const customersCustomerUpdate = createAsyncThunk(
    CUSTOMERS_CUSTOMER_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.customers_update, data.payload, token, store);
    }
);

export const customersCustomerRetrieve = createAsyncThunk(
    CUSTOMERS_CUSTOMER_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.customer(data.id), data.payload, token);
    }
);

export const customersCustomerDelete = createAsyncThunk(
    CUSTOMERS_CUSTOMER_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.customer(data.id), data.payload, token);
    }
);

/// Customer Groups
export const customersCustomerGroupsGet = createAsyncThunk(
    CUSTOMERS_CUSTOMER_GROUPS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.customer_groups, null, token, store);
    }
);

export const customersCustomerGroupCreate = createAsyncThunk(
    CUSTOMERS_CUSTOMER_GROUP_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.customer_groups, data.payload, token, store);
    }
);

export const customersCustomerGroupUpdate = createAsyncThunk(
    CUSTOMERS_CUSTOMER_GROUP_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.customer_group(data.id), data.payload, token, store);
    }
);

export const customersCustomerGroupRetrieve = createAsyncThunk(
    CUSTOMERS_CUSTOMER_GROUP_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.customer_group(data.id), data.payload, token, store);
    }
);

export const customersCustomerGroupDelete = createAsyncThunk(
    CUSTOMERS_CUSTOMER_GROUP_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.customer_group(data.id), data.payload, token, store);
    }
);





// Combined thunks
const customersResourceThunks = {
    create: customersCustomerCreate,
    get: customersCustomersGet,
    retrieve: customersCustomerRetrieve,
    update: customersCustomerUpdate,
    delete: customersCustomerDelete,
}


// Combined thunks
const customerGroupsResourceThunks = {
    create: customersCustomerGroupCreate,
    get: customersCustomerGroupsGet,
    retrieve: customersCustomerGroupRetrieve,
    update: customersCustomerGroupUpdate,
    delete: customersCustomerGroupDelete,
}

//initial state
const initialState = {
    customers: restResourceInitialState,
    customer_groups: restResourceInitialState,
}

//redux toolkit slice
const customersSlice = createSlice({
    'name': 'customers',
    initialState,
    reducers: {},
    extraReducers: {
        ...generateResourceExtraReducers(customersResourceThunks, "customers"),
        ...generateResourceExtraReducers(customerGroupsResourceThunks, "customer_groups"),
    },
});

//exports
export default customersSlice.reducer;
