import React from 'react'

export const IconPurchaseTag = (props) => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="currentColor" {...props}>
        <path d="M11.707,2.293C11.52,2.105,11.265,2,11,2H6C5.735,2,5.48,2.105,5.293,2.293l-3,3C2.105,5.48,2,5.734,2,6v5 c0,0.266,0.105,0.52,0.293,0.707l10,10C12.488,21.902,12.744,22,13,22s0.512-0.098,0.707-0.293l8-8 c0.391-0.391,0.391-1.023,0-1.414L11.707,2.293z M13,19.586l-9-9V6.414L6.414,4h4.172l9,9L13,19.586z"></path>
    <circle cx="8.353" cy="8.353" r="1.647"></circle>
</svg>
)
}
