import React from 'react'
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import { IconChevronLeft } from '../../../atomic/icons/chevron-left';

const OnBoardingStepLayout = (props) => {
    const { steps, step, children } = props;
    
    return (
        <div className=''>
            <div className={styles.toolbar}>
                <div className={styles.slot}>
                    <IconChevronLeft height="10px" width="10px" />
                </div>
                <div className={styles.steps}>
                    {[...Array(steps).keys()].map((item, i) => {
                        return <div key={`stp-${i}`} className={`${styles.step} ${(i + 1) <= step ? styles.active : null}`}></div>
                    })}
                </div>
                <div className={styles.slot}></div>
            </div>

            {children}
        </div>
    );
}

OnBoardingStepLayout.defaultProps = {
    steps: 3,
    step: 1,
}

OnBoardingStepLayout.propTypes = {
    steps: PropTypes.number,
    step: PropTypes.number,
}

export default OnBoardingStepLayout;
