import React from 'react';
import CustomBarChart from "../../../../components/charts/bar-chart";
import {BarsLoader} from "../../../../components/loaders/bars-loader/bars-loader";

const MonthlyPurchasesChart = ({monthlyPurchases}) => {

    if(!monthlyPurchases) {
        return <div className={'d-flex align-items-center justify-content-center w-100 h-100'} style={{minHeight: '270px'}}>
            <BarsLoader color={'#ddd'} />
        </div>
    }

    const series = [
        {
            name: "Purchases",
            data: Object.values(monthlyPurchases),
        }
    ];
    return (
        <CustomBarChart xAxis={Object.keys(monthlyPurchases)} series={series} />
    );
};

export default MonthlyPurchasesChart;
