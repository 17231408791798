import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {productsProductCategoriesGet, productsProductsGet} from '../../../../../application/features/products';
import CustomInput from "../../../../atomic/input";
import {
    saleAppendItemToPayload,
    saleClearSearchResults,
    saleRemoveItemFromPayload,
    salesProductSearch,
    salesSaleRecord,
    saleUpdateItemToPayload,
    saleUpdatePayload
} from "../../../../../application/features/sales";
import Currency from '../../../../atomic/formatting/currency';
import styles from "./index.module.scss";

import {DeleteButton} from '../../../../components/buttons/delete-button';
import BarLoader from 'react-bar-loader'
import {Spinner} from "../../../../atomic/spinner";
import {useTranslation} from "react-i18next";
import {customFetch} from "../../../../../core/network";
import Bizmanager from "../../../../../api/bizmanager";
import AsyncCreatableSelect from "react-select/async-creatable";
import SweetAlert from "react-bootstrap-sweetalert";
import {customersCustomerCreate} from "../../../../../application/features/customers";
import CustomModal from "../../../../components/modals/modal";
import {ProductSelector} from "../../../../components/form/product-selector/product-selector";
import {QuickCustomerForm} from "../../../../components/form/customer/quick-customer-form";
import CustomButton from "../../../../atomic/button";

export const SalesWindow = () => {
    //state
    const [total, setTotal] = useState(0);
    const [alert, setAlert] = useState(null);
    const [newSubmit, setNewSubmit] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [searchedValue, setSearchedValue] = useState("");
    const [customerOptions, setCustomerOptions] = useState([]);
    const [showNumberSwal, setShowNumberSwal] = useState(false);
    const [creatingCustomer, setCreatingCustomer] = useState(null);
    const [showProductSelector, setShowProductSelector] = useState(false);
    //redux
    const dispatch = useDispatch();
    const products = useSelector(state => state.sales.search.results);
    const items = useSelector(state => state.sales.payload.items);
    const payload = useSelector(state => state.sales.payload);
    const isSearching = useSelector(state => state.sales.search.isSearching);
    const isCreating = useSelector(state => state.sales.recordSale.isRecording);
    const creatingSuccessful = useSelector(state => state.sales.recordSale.recorded);
    const token = useSelector(state => state.authentication.token);
    const customers = useSelector(state => state.customers.customers.data);
    const createCustomerSuccessful = useSelector(state => state.customers.customers.creatingSuccessful);
    const isCreatingCustomer = useSelector(state => state.customers.customers.isCreating);

    const {i18n, t} = useTranslation();
    let throttlingId = null;
    //effects
    useEffect(() => {
        dispatch(productsProductsGet());
        dispatch(productsProductCategoriesGet());
        setMounted(true);
    }, []);

    useEffect(() => {

        if (!mounted) return;
        if (!creatingSuccessful) return;
        setAlert({type: "success", text: "Sale Created!"})
        setTimeout(() => {
            setAlert(null);
        }, 4000);
    }, [creatingSuccessful]);

    useEffect(() => {
        const total = items.reduce((prev, current) => prev + (current.quantity * current.selling_price), 0);
        setTotal(total);
        handlePayloadChange("total_amount", total);
    }, [items]);

    //handlers
    const handleSearchChange = (key, value) => {
        clearTimeout(throttlingId);
        throttlingId = setTimeout(()=> {
            setSearchedValue(value);
            const data = {
                params: {
                    name: value
                }
            }
            if (value.length === 0) {
                dispatch(saleClearSearchResults());
                return;
            }
            dispatch(salesProductSearch(data));
        }, 600)

    }

    useEffect(() => {
        if (createCustomerSuccessful) {
            console.log('Creating is successful');
            setCreatingCustomer(null);
        }
    }, [createCustomerSuccessful]);


    const handleSelectProduct = (product) => {
        if (!product) return;
        dispatch(saleAppendItemToPayload({
            ...product,
            max: product.quantity,
            name: product.product.name,
            stock_item_id: product.id,
            quantity: 1,
            unit_price: product.selling_price,
            discount: 0,
            tax_percentage: 0,
        }));
    }

    const handleDeleteProduct = (index) => {
        if (index == null || index == undefined) return;
        dispatch(saleRemoveItemFromPayload(index));
    }

    const handleIncrementQuantity = (index) => {
        if (items[index].quantity < items[index].max) {
            const data = {
                index: index,
                data: {
                    ...items[index],
                    quantity: items[index].quantity + 1
                }
            }
            dispatch(saleUpdateItemToPayload(data));
        }
    }

    const handleDecrementQuantity = (index) => {
        if (items[index].quantity > 1) {
            const data = {
                index: index,
                data: {
                    ...items[index],
                    quantity: items[index].quantity - 1
                }
            }
            dispatch(saleUpdateItemToPayload(data));
        }
    }

    const handlePayloadChange = (key, value) => {
        dispatch(saleUpdatePayload({
            key: key,
            value: value,
        }));

        if (key == "received_amount") {
            handlePayloadChange("returned_amount", value - total);
        }
    }

    const handleSaveSale = () => {
        if (payload.received_amount && payload.received_amount && payload.items.length > 0) {
            dispatch(salesSaleRecord());
        } else {
            // TODO Notify incomplete data
        }
    }

    /**
     * Search Customers
     */
    const searchCustomers = async (inputValue) => {
        if (inputValue.length == 0) {
            return true;
        }
        const _payload = {'search': inputValue}
        const res = await customFetch('POST', Bizmanager.customers_search, _payload, token);
        if (res && res.data) {
            const result = res.data.map((item) => ({value: item.id, label: item.name}));
            setCustomerOptions(result);
        } else {
            setCustomerOptions([]);
        }
        return customerOptions;
    }

    const handleSelectCustomerChange = (data) => {
        if (data != null && data.value) {
            handlePayloadChange('customer_id', data.value);
            handlePayloadChange('customer_name', data.label);
        } else {
            handlePayloadChange('customer_id', null);
        }
    }

    const handleCreateCustomer = (data) => {
        setCreatingCustomer(data);
        setShowNumberSwal(true);
    }

    const onReceivedCustomer = (data) => {
        const phoneNumber = data.number ?? '';
        if (creatingCustomer && phoneNumber && phoneNumber.length > 8) {
            // Create customer
            const payload = {
                name: creatingCustomer,
                phone: phoneNumber
            }
            dispatch(customersCustomerCreate({payload}));
            setShowNumberSwal(false);
        }
        onAddCustomerModalClosed();
    }

    const processProductSelectorResults = (items) => {
        if (items.length) {
            items.forEach((item) => {
                handleSelectProduct(item);
            });
        }
        dispatch(saleClearSearchResults());
        setShowProductSelector(false);
    }
    const onProductSelectorClosed = () => {
        dispatch(saleClearSearchResults());
        setShowProductSelector(false);
    }

    const onAddCustomerModalClosed = () => {
        setShowNumberSwal(false);
    }

    const showProductSelectorModal = () => {
        if(true) {
            dispatch(salesProductSearch({param : {}})) ;
        }
        setShowProductSelector(true);
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="row flex-row-reverse">
                    <div className="col-6 text-right" style={{width: "200px"}}>
                        <div style={{marginTop: "-55px", position: "relative", maxWidth: "200px", textAlign: "right"}}>
                            <CustomButton label={"Product Picker"} clasName={'btn-outline-primary'}
                                          onClick={() => showProductSelectorModal()}>
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                {alert &&
                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                        {alert ? (alert.text ?? '') : 'Done!'}
                        <button type="button" className="btn-close" onClick={() => setAlert(null)}
                                aria-label="Close">&nbsp;</button>
                    </div>
                }
            </div>
            <div className="col-lg-8">
                <div className="w-100 d-flex justify-content-between mb-1">
                    <label htmlFor="product">{i18n.t('Search Products')}</label>
                    <span className={"link text-muted"} style={{cursor: 'pointer'}}
                          onClick={() => setShowProductSelector(true)}>{i18n.t('Show Selector')}</span>
                </div>
                <CustomInput autocomplete={'off'} type="search" name="product" label=''
                             placeholder="Search Products" onChange={handleSearchChange}/>
                <div className={isSearching ? 'd-block' : 'd-none'}>
                    <BarLoader color="#1D8BF1" height="2"/>
                </div>
                {products.length > 0 ?
                    (
                        <div className="row bg-light pt-3 mx-2">
                            {products.filter(p => items.length > 0 ? !items.some(i => i && p && i.id == p.id) : true).map((p, i) => (
                                <div key={i} className="d-flex align-items-center"
                                     style={{borderBottom: '1px solid #ddd', cursor: 'pointer'}}
                                     onClick={() => handleSelectProduct(p)}>
                                    <div className="flex-grow-1">
                                        <span className="text-bold text-xs">{p.product.name}</span>
                                        <p className="small text-black-50">{p.quantity}{p.product.unit ? p.product.unit.abbreviation : ''} in
                                            stock</p>
                                    </div>
                                    <div className="flex-grow-0">
                                        <Currency value={p.selling_price}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) :
                    (searchedValue.length > 0) && <div className="row bg-light pt-3 mx-2">
                        {isSearching ? '' : <div className="py-1 px-2 text-muted text-center">{i18n.t('No Results')}</div>}
                    </div>
                }


                <table className="table mt-3">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{i18n.t('Product')}</th>
                        <th>{i18n.t('Unit')}</th>
                        <th>{i18n.t('quantity')}</th>
                        <th>{i18n.t('unit_price')}</th>
                        <th>{i18n.t('Total Amount')}</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>

                    <tbody>
                    {items.map((item, i) => {
                        if (!item) return;
                        return (
                            <tr>
                                <td>{i + 1}</td>
                                <td>{item.product.name}</td>
                                <td>Kg</td>
                                <td className={styles.quantity}>
                                    <div className="d-flex">
                                        <span onClick={() => handleDecrementQuantity(i)}>-</span>
                                        <div className="flex-grow-1 text-center">{item.quantity}</div>
                                        <span onClick={() => handleIncrementQuantity(i)}>+</span>
                                    </div>
                                </td>
                                {/* <td>
                                        <QuantitySpinner name="quantity" value={item.quantity} items={[
                                            {id: 1, name: "Kg", factor: 1},
                                            {id: 2, name: "25Kg Bag", factor: 25},
                                        ]} />
                                    </td> */}
                                <td>
                                    <Currency value={item.selling_price}/>
                                </td>
                                <td>
                                    <Currency value={item.quantity * item.selling_price}/>
                                </td>
                                <td>
                                    <DeleteButton onClick={() => handleDeleteProduct(i)}/>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>

            </div>

            <div className="col-lg-4">
                <div className="payment-methods">
                    {/*<div className="btn-group btn-group-sm mb-1 align-items-end">*/}
                    {/*    <input type="radio" className="btn-check" name="options" id="option4" autoComplete="off" />*/}
                    {/*    <label className="btn btn-secondary" htmlFor="option4">Cash</label>*/}
                    {/*    <input type="radio" className="btn-check" name="options" id="option3" autoComplete="off" />*/}
                    {/*    <label className="btn btn-secondary" htmlFor="option3">M-Lipa</label>*/}
                    {/*</div>*/}

                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="mb-1">
                            <label>{i18n.t('Customer')}</label>
                            <div>
                                <AsyncCreatableSelect
                                    isLoading={false}
                                    isClearable={true}
                                    cacheOptions
                                    defaultOptions={customerOptions}
                                    options={customerOptions}
                                    loadOptions={searchCustomers}
                                    onChange={handleSelectCustomerChange}
                                    onCreateOption={handleCreateCustomer}
                                />
                            </div>
                        </div>
                        <CustomInput name="customer_name" label={i18n.t('Customer Name')} placeholder="Customer Name"
                                     onChange={handlePayloadChange} value={payload.customer_name}/>
                        <div className="mt-2"></div>
                        <CustomInput isCurrency={true} name="received_amount" label={i18n.t('Received Amount')}
                                     placeholder="Received Amount" onChange={handlePayloadChange}
                                     value={payload.received_amount}/>
                        <div className="mt-2"></div>
                        <CustomInput isCurrency={true} name="returned_amount" label={i18n.t('Returned Amount')}
                                     placeholder="Returned Amount" readonly value={payload.returned_amount}/>

                        <div className="d-flex mt-3 align-items-end">
                            <p className="m-0 p-0 flex-grow-1">Total</p>
                            <p className="m-0 p-0 text-dark" style={{fontSize: '1.2rem'}}><Currency value={total}/></p>
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-2 justify-content-center">
                    <button disabled={isCreating || (items.length < 1) || (payload && payload.returned_amount < 0)}
                            className="btn btn-primary ml-auto w-100 py-3 py-md-3"
                            onClick={() => handleSaveSale()}>{isCreating ? <Spinner/> : i18n.t('Save')}</button>
                </div>
            </div>
            {
                <CustomModal size={'modal-sm'} showHeader={true} handleClose={onAddCustomerModalClosed}
                             show={showNumberSwal} bodyPadding={0} title={i18n.t('Enter phone number')}>
                    <QuickCustomerForm onSubmit={onReceivedCustomer}/>
                </CustomModal>
            }

            <CustomModal size={'modal-xl'} showHeader={true} handleClose={onProductSelectorClosed}
                         show={showProductSelector} bodyPadding={0} title={"Select Products"}>
                <ProductSelector onSelectionCompleted={(items) => processProductSelectorResults(items)}/>
            </CustomModal>
        </div>
    );
}
