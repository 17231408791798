import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {customersCustomerDelete, customersCustomersGet} from "../../../../../application/features/customers";
import {useTranslation} from "react-i18next";
import {AddButton} from '../../../../components/buttons/add-button';
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import CustomersResource from "../../../../../application/resources/customer";
import {EmptyList} from "../../../../components/templates/error-pages/empty-list";

export const CustomersListPage = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [deletingId, setDeletingId] = useState(null);

    //hooks
    const {i18n, t} = useTranslation();
    //redux
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customers = useSelector(state => state.customers.customers.data);
    const isFetching = useSelector(state => state.customers.customers.isFetching);
    const fetchingSuccessful = useSelector(state => state.customers.customers.fetchingSuccessful);

    //effects
    useEffect(() => {
        if (customers && customers.length) return;
        dispatch(customersCustomersGet());
    }, []);


    const showAddForm = () => {
        navigate('form');
    }

    //handlers
    const handleDelete = () => {
        if(deletingId) {
            const data = {id: deletingId};
            dispatch(customersCustomerDelete(data));
            setDeletingId(null);
            setShowAlert(false);
        }
    }

    return (
        <div className="row">
            <div className="col-12">

                {(customers && customers.length === 0 && fetchingSuccessful && !isFetching) ?
                    <EmptyList icon={'emptyCart'} subTitle={'There are currently no customers.'}>
                        <AddButton permissions={['Create Customers']} to="form" title={'Add your first customer'}/>
                    </EmptyList>
                    :
                    <div className="card">
                        <div className='card-header'>
                            <div className="d-flex align-items-center">
                                <span className="card-title flex-grow-1">{t('Customers')}</span>
                                <AddButton permissions={['Create Customers']} to="form" title={i18n.t('Add Customer')} />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="card-body">
                                        <GalaxyResourceTable deletable={(row) => false} resource={new CustomersResource()}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}
