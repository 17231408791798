import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import {IconSearch} from "./icons/search";
import { PropTypes } from 'prop-types';
import { useEffect } from 'react';

const QuantitySpinner = (props) => {
    //props
    const { label, name, type, placeholder, onChange, value, error, items } = props;

    //state
    const [scale, setScale] = useState(1);
    const [quantity, setQuantity] = useState(0);

    //effects
    useEffect(() => {
        setQuantity(value);
    }, [value]);

    //effects
    useEffect(() => {
        handleChange(name, scale * quantity);
    }, [scale, quantity]);

    // /handlers
    const handleChange = (key, value) => {
        console.log("Quantity Changed");
        console.log("Factor: ", scale);
        console.log("Quanity: ", quantity);
        console.log("Result: ", value);
        if (onChange) {
            onChange(key, value);
        }
    }

    const handleQuantityChange = (e) => {
        const _value = e.target.value;
        setQuantity(_value);
        // handleChange(name, scale * _value);
    }

    const handleScaleChange = (e) => {
        const _factor = e.target.value;
        setScale(_factor);
        // handleChange(name, _factor * quantity);
    }

    return (
        <div className="input-group mb-3">
            <input type="number" className="form-control" aria-label="Text input with dropdown button" value={quantity} onChange={handleQuantityChange} />
            <select name={'quantity_unit'} onChange={handleScaleChange}>
                {items.map(_scale => <option value={_scale.factor}>{_scale.name}</option>)}
            </select>
        </div>
    );
}

QuantitySpinner.defaultProps = {
    type: 'number',
    items: [],
}

QuantitySpinner.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        factor: PropTypes.number, // We have to allow anything to be key
        name: PropTypes.string
      })
    ).isRequired,  
    value: PropTypes.number,
    error: PropTypes.string
}

export default QuantitySpinner;