import React, { useEffect, useState } from 'react'

export const NumberSpinner = ({initialValue, max, onChange}) => {
    //state
    const [value, setValue] = useState(0);
    
    //effects
    useEffect(() => {
        if (!initialValue) return;
        if (!Number.isInteger(initialValue)) return;
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        if (onChange != null) {
            onChange(value);
        }
    }, [value])

    //handlers
    const handleDecrementQuantity = () => {
        if (value == 0) return;
        setValue(value - 1);
    }
    const handleIncrementQuantity = () => {
        let _max = parseInt(max);
        if (value >= _max) return;
        setValue(value + 1);
    }
    const spinButtonStyle = {
        padding: ".1rem .3rem",
        cursor: 'pointer',
        border: '1px solid #eaeaea'
    }
    const spinTextStyle ={
        'border-top': '1px solid #eaeaea',
        'border-bottom': '1px solid #eaeaea'
    }
    return (
        <div className="d-flex">
            <span style={spinButtonStyle} onClick={() =>  handleDecrementQuantity()}>-</span>
            <div style={spinTextStyle} className="flex-grow-1 text-center">{value}</div>
            <span style={spinButtonStyle}  onClick={() =>  handleIncrementQuantity()}>+</span>
        </div>
    );
}
