import React from 'react'
import { PropTypes } from 'prop-types';
import {Spinner} from "./spinner";

const CustomButton = (props) => {
    //props
    const { isLoading, onClick, label } = props;
    return (
        <button disabled={isLoading} className="btn btn-primary" onClick={() => {
            if (onClick) {
                onClick();
            }
        }}>{isLoading ? <Spinner /> : label}</button>
    );
}

CustomButton.defaultProps = {
    isLoading: false,
    onClick: () => console.log("button clicked"),
    label: "button",
}

CustomButton.propTypes = {
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.string,
}

export default CustomButton;
