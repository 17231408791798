import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import PageAlert from "../../../../components/notifications/page-alert";
import {productsProductUnitsGet} from "../../../../../application/features/products";

export const ProductUnitsViewPage = () => {
  //state
  const [unit, setUnit] = useState(null);

  //hooks
  const { unit_id } = useParams();

  //redux
  const dispatch = useDispatch();
  const units = useSelector(state => state.products.product_units.data);

  useEffect(() => {
    dispatch(productsProductUnitsGet());
  }, []);
  
  //effect
  useEffect(() => {
    if (!unit_id) return;
    const data = {
      id: unit_id,
    }
    setUnit(units.find(u => u.id == unit_id));
  }, [unit_id]);

  if (!unit) {
    return <PageAlert title={""} text="Ooops! Unit not found" />
  }

  return (
    <div>
      <div className="card">
        <div className="card-header">&nbsp;</div>
        <div className="card-body">
          <table className="table table-bordered">
            <tbody>
            <tr>
              <td width="30%">Name</td><td>{unit.name}</td>
            </tr>
            <tr>
              <td>Abbreviation</td><td>{unit.abbreviation}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
}
