import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {CustomTable} from "../../../../../atomic/table/table";
import CustomInput from "../../../../../atomic/input";
import CustomTextArea from "../../../../../atomic/text-area";
import CustomSelectInput from "../../../../../atomic/select";
import Currency from "../../../../../atomic/formatting/currency";
import {inventoryAddonsCreate, inventoryAddonsDelete} from "../../../../../../application/features/inventory";
import {Spinner} from "../../../../../atomic/spinner";
import {DeleteButton} from "../../../../../components/buttons/delete-button";
import CustomConfirmAlert from "../../../../../components/notifications/confirm-alert";

const InventoryItemAddons = ({inventoryItem, onAdded, onDeleted}) => {
    //state
    const [payload, setPayload] = useState({stock_item_id: inventoryItem.id, product_addon_id: null, additional_amount: null, description: null});
    const [showForm, setShowForm] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [deletingId, setDeletingId] = useState();
    const [showAlert, setShowAlert] = useState(false);

    //hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //redux
    const product_addons = useSelector(state => state.products.product_addons.data.filter(e => e.product_id == inventoryItem.product_id));
    const fetchingInventoryAddons = useSelector(state => state.inventory.addons.isFetching);
    const creatingAddon = useSelector(state => state.inventory.addons.isCreating);
    const deletingAddon = useSelector(state => state.inventory.addons.isDeleting);
    const deletingAddonSuccessful = useSelector(state => state.inventory.addons.deletingSuccessful);
    const addonCreated = useSelector(state => state.inventory.addons.creatingSuccessful);


    useEffect(() => {
        setMounted(true);
    }, []);


    useEffect(() => {
        if(!mounted) return;
        if(!addonCreated) return;
        setShowForm(false);
        setPayload({stock_item_id: inventoryItem.id, product_addon_id: null, additional_amount: null, description: null});
        if(onAdded){
            onAdded();
        }
    }, [creatingAddon, addonCreated]);



    useEffect(() => {
        if(!mounted) return;
        if(!deletingAddonSuccessful) return;
        if(onDeleted){
            onDeleted(deletingId);
        }
        setDeletingId(null);
    }, [deletingAddonSuccessful]);


    const handleChange = (key, value) => {
        const p = {...payload};
        p[key] = value;
        setPayload(p);
        if(true) {

        }
    }

    const toggleForm = () => {
        setShowForm(!showForm);
    }

    const submit = () => {
        console.log(payload);
        if(payload.product_addon_id) {
            dispatch(inventoryAddonsCreate({payload: payload}))
        }
    }

    const deleteAddon = (id) => {
      setDeletingId(id);
      setShowAlert(true);
    }

    const handleDelete = () => {
        if(deletingId) {
            const data = {id: deletingId};
            dispatch(inventoryAddonsDelete(data));
            // setDeletingId(null);
            setShowAlert(false);
        }
    }

    return (
        <div>
            <div className="w-100 d-flex">
                <div className="flex-grow-1">Addons</div>
                <div><button className="btn btn-sm btn-outline-primary" onClick={toggleForm}>{showForm ? "x Close Form" : "+ New Addon"}</button></div>
            </div>
            <div className="card m-1" style={{display: showForm ? 'block' : 'none'}}>
                <div className="card-body">
                    <form>
                        <CustomSelectInput name='product_addon_id' label="Product Addon" items={product_addons}
                                           value={payload.product_addon_id} onChange={handleChange}/>
                        <CustomTextArea name="description" label="Description" { ...((payload.description) ? {value: payload.description} : {})}
                                        onChange={handleChange}/>
                        <CustomInput name="additional_amount" label="Additional Amount" type="number"
                                     value={payload.additional_amount} onChange={handleChange}/>
                    </form>
                    <div className="mt-2">
                        <button className="btn btn-primary" onClick={submit}>{creatingAddon ? <Spinner /> : "Save Addon"}</button>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <CustomTable isLoading={fetchingInventoryAddons} >
                    <thead>
                        <tr>
                            <th width={30}>#</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Added Amount</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        (inventoryItem && inventoryItem.addons) ?
                        inventoryItem.addons.filter(u => u).map((addon, i) => (
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td>{addon.product_addon ? addon.product_addon.name : ''}</td>
                                <td>{addon.description}</td>
                                <td><Currency value={addon.additional_amount} /></td>
                                <td>
                                    <DeleteButton className="btn-sm" isDeleting={deletingAddon} onClick={() => deleteAddon(addon.id)}/>
                                </td>
                            </tr>
                        ))
                            :
                            "No addons"
                    }
                    </tbody>
                </CustomTable>
            </div>
            <CustomConfirmAlert isShown={showAlert} title="Remove Addon?" text="Remove this addon from the inventory item?" onConfirm={handleDelete} toggleAlert={setShowAlert} />
        </div>
    );
};

export default InventoryItemAddons;
