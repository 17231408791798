import React from 'react';
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
    expensesExpenseTypesCreate,
    expensesExpenseTypesGet, expensesExpenseTypesUpdate
} from "../../../../../application/features/expenses";
import CreateUpdateForm from "../../../../components/form/createUpdateForm";
import CustomInput from "../../../../atomic/input";
import CustomSelectInput from "../../../../atomic/select";
import i18n from "i18next";

const ExpenseTypeFormPage = () => {
    //state
    const [payload, setPayload] = useState({});
    const [errorMessage, setErrorMessage] = useState();
    const [mounted, setMounted] = useState(false);

    const { expense_type_id } = useParams();
    const expense_types = useSelector(state => state.expenses.expense_types);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isCreating = useSelector(state => state.expenses.expense_types.isCreating);
    const created = useSelector(state => state.expenses.expense_types.creatingSuccessful);
    const updated = useSelector(state => state.expenses.expense_types.updatingSuccessful);
    const creatingError = useSelector(state => state.expenses.expense_types.updatingError);
    const updatingError = useSelector(state => state.expenses.expense_types.updatingError);
    const units = useSelector(state => state.expenses.expense_types.data);


    useEffect(() => {
        console.log('init');
        dispatch(expensesExpenseTypesGet());
    }, []);


    return (
        <div>
            {errorMessage && <div className="alert alert-danger mb-2">{errorMessage}</div>}
            <CreateUpdateForm resource={expense_types}  title={"Category"} item_id={expense_type_id} createThunk={expensesExpenseTypesCreate} updateThunk={expensesExpenseTypesUpdate}>
                <CustomInput name='name' label='Name' placeholder={i18n.t('Expense Type name')}  required />
                <CustomInput name='description' label='Description' placeholder={i18n.t('Description')}  required />
                {/*<CustomSelectInput name="parent_category_id" items={[{name: 'No Parent', id: ''}, ...expense_types.data]} label={"Parent Category"} />*/}
            </CreateUpdateForm>
        </div>
    );
};

export default ExpenseTypeFormPage;
