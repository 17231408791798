import React from 'react'

export const IconEdit = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
        <g>
            <path d="M18.2 24H3.8c-2.1 0-3.8-1.7-3.8-3.8V5.8c0-2.1 1.7-3.8 3.8-3.8H11c.6 0 1 .4 1 1s-.4 1-1 1H3.8C2.8 4 2 4.8 2 5.8v14.3c0 1 .8 1.8 1.8 1.8h14.3c1 0 1.8-.8 1.8-1.8V13c0-.6.4-1 1-1s1 .4 1 1v7.2c.1 2.1-1.6 3.8-3.7 3.8Z"></path>
            <path d="M6 19c-.3 0-.5-.1-.7-.3 -.2-.2-.3-.6-.3-.9l1-5c0-.2.1-.4.3-.5l12-12c.4-.4 1-.4 1.4 0l4 4c.4.4.4 1 0 1.4l-12 12c-.1.1-.3.2-.5.3l-5 1c-.1-.001-.1-.001-.2-.001Zm1.9-5.5l-.6 3.2 3.2-.6L21.6 5 19 2.4 7.9 13.5Z"></path>
        </g>
    </svg>
  )
}
