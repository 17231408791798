import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";
import {login} from "./authentication";

// Action Constants
const ORGANIZATIONS_ORGANIZATIONS_GET = 'organizations/organizations/GET_ORGANIZATIONS';
const ORGANIZATIONS_ORGANIZATION_SET_ACTIVE = 'organizations/organizations/SET_ACTIVE_ORGANIZATIONS';

// Actions
export const organizationsOrganizationsGet = createAsyncThunk(
    ORGANIZATIONS_ORGANIZATIONS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.organizationsGet, null, token, store);
    }
);

export const organizationsOrganizationSetActive = createAsyncThunk(
    ORGANIZATIONS_ORGANIZATION_SET_ACTIVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.organizationSetActive, data.payload, token, store);
    }
);

// Combined thunks
const organizationsResourceThunks = {
    get: organizationsOrganizationsGet,
    setActive: organizationsOrganizationSetActive
}

//initial state
const initialState = {
    organizations: restResourceInitialState,
    activatingOrganization: {
        isActivating: false,
        activatingSuccessful: false,
        activatingError: null,
        validationErrors: {},
        data: [],
        links: [],
        meta: [],
        selected: -1,
    },
}

//redux toolkit slice
const organizationsSlice = createSlice({
    'name': 'organizations',
    initialState,
    reducers: {
    },
    extraReducers: {
        ...generateResourceExtraReducers(organizationsResourceThunks, "organizations"),
        [organizationsOrganizationSetActive.pending]: (state, action) => {
            state.activatingOrganization.isActivating = true;
            state.activatingOrganization.activatingSuccessful = false;
            state.activatingOrganization.activatingError = null;
        },
        [organizationsOrganizationSetActive.fulfilled]: (state, action) => {
            state.activatingOrganization.isActivating = false;
            state.activatingOrganization.activatingSuccessful = true;
            state.activatingOrganization.activatingError = null;
        },
        [organizationsOrganizationSetActive.rejected]: (state, action) => {
            state.activatingOrganization.isActivating = false;
            state.activatingOrganization.activatingSuccessful = false;
            state.activatingOrganization.activatingError = action.payload ?? action.error;
        },
    },
});

//exports
export default organizationsSlice.reducer;
