import React, { Children, cloneElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {Spinner} from "../../atomic/spinner";
import PropTypes from "prop-types";


const CreateUpdateFormComponent = ({ resource, item_id, children, title, createThunk, updateThunk, onFieldChange }) => {
    //props
    const arrayChildren = Children.toArray(children);

    //state
    const [payload, setPayload] = useState({});
    const [errorMessage, setErrorMessage] = useState();
    const [mounted, setMounted] = useState(false);

    //hooks
    const navigate = useNavigate();

    //redux
    const dispatch = useDispatch();
    const isCreating = resource.isCreating;
    const isUpdating = resource.isUpdating;
    const created = resource.creatingSuccessful;
    const updated = resource.updatingSuccessful;
    const creatingError = resource.creatingError;
    const updatingError = resource.updatingError;
    const items = resource.data;
    const validationErrors = resource.validationErrors ?? {};

    //effects
    useEffect(() => {
        setMounted(true);
    }, [])

    useEffect(() => {
        console.log("Created effect triggered", created);
        if (!mounted) return;
        if (!created) return;
        console.log('Navigating back');
        navigate('../');
    }, [created]);

    useEffect(() => {
        console.log("Updated effect triggered", updated);
        if (!mounted) return;
        if (!updated) return;
        navigate('../');
    }, [updated]);

    useEffect(() => {
        if (!item_id || !items) return;
        const item = items.filter(u => u).find(u => u.id == item_id);
        if (!item) return;
        setPayload(item);
    }, [item_id, items]);

    useEffect(() => {
        if (!updatingError && !creatingError) return;
        if (item_id && !updatingError) return;

        let error;
        if (item_id) {
            error =  updatingError;
        } else {
            error =  creatingError;
        }

        if (error && error.message) {
            setErrorMessage(error.message);
            setTimeout(() => setErrorMessage(""), 60000);
        }
    }, [updatingError, creatingError, item_id])

    //handlers
    const handleChange = (key, value) => {
        setPayload({...payload, [key]: value});
        if(onFieldChange) {
            onFieldChange(key, value ?? null);
        }
    }

    // TODO Implement later
    const validateForm = () => {
        return errors === {};
    };

    const handleSave = () => {
        const data = {payload: payload};
        // TODO RESET ERRORS
        if (item_id) {
            data["id"] = item_id;
            dispatch(updateThunk(data));
        } else {
            dispatch(createThunk(data))
        }

    }

    return (
        <div>
            {errorMessage && <div className="alert alert-danger mb-2">{errorMessage}</div>}
            <div className='card'>
                <div className="card-body">
                    <h5 className="card-title">{item_id ? 'Update' : 'Create'} {title ?? 'Resource'}</h5>
                    {Children.map(arrayChildren, (child, index) => {
                        return (<div className='mb-2'>
                            {cloneElement(child, {
                                onChange: handleChange,
                                value: payload[child.props.name],
                                error: ((validationErrors.hasOwnProperty(child.props.name))  ? validationErrors[child.props.name][0] ?? null : null )
                            })}
                        </div>)
                    })}
                    <div className="d-flex">
                        <button disabled={isCreating} className="btn btn-primary" onClick={() => handleSave()}>{(isCreating || isUpdating) ? <Spinner /> : 'Save'}</button>
                        <div className="mx-1"></div>
                        <button disabled={isCreating} className="btn btn-outline-primary" onClick={() => navigate(-1)} >Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
CreateUpdateFormComponent.propTypes = {
    resource: PropTypes.any,
    item_id: PropTypes.number,
    children: PropTypes.any,
    title: PropTypes.string,
    createThunk: PropTypes.any,
    updateThunk: PropTypes.any,
    onFieldChange: PropTypes.func
}
export default CreateUpdateFormComponent;
