import React from 'react';
import PropTypes from 'prop-types';

const IconBulb = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 32" className={props.className} height={props.height} width={props.width}>
        <g fill={props.fill}>
            <path d="M16 27H7c-.28 0-.5.22-.5.5 0 .27.22.5.5.5h9c.27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5Z"></path>
            <path d="M16.5 29.5c0-.28-.23-.5-.5-.5H7c-.28 0-.5.22-.5.5 0 .27.22.5.5.5h9c.27 0 .5-.23.5-.5Z"></path>
            <path d="M9 31c-.28 0-.5.22-.5.5 0 .27.22.5.5.5h5c.27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5H9Z"></path>
            <path d="M17 25.5v-.62c0-2.54.92-5.05 2.67-7.28 1.52-1.93 2.32-4.25 2.32-6.7 0-6.021-4.94-10.918-11-10.918 -.42 0-.84.023-1.26.06C4.85.57.81 4.45.1 9.254c-.495 3.37.56 6.7 2.91 9.15 1.91 1.99 2.96 4.46 2.96 6.96v.1c0 .27.22.5.5.5h10c.27 0 .5-.23.5-.5ZM9 14H7.25c-.97 0-1.75-.9-1.75-1.84 0-.95.78-1.779 1.75-1.779 .96 0 1.75.7 1.75 1.65v1.96Zm3 11h-2V15h2v10Zm4 0h-3V15h1.75c1.51 0 2.75-1.35 2.75-2.84 0-1.5-1.24-2.779-2.75-2.779 -1.52 0-2.75 1.15-2.75 2.65v1.96h-2v-1.97C10 10.52 8.76 9.3 7.25 9.3c-1.52 0-2.75 1.34-2.75 2.84 0 1.49 1.23 2.84 2.75 2.84H9v10H6.992c-.11-2.63-1.25-5.19-3.24-7.27C1.61 15.49.65 12.46 1.1 9.4 1.742 5.04 5.42 1.52 9.846 1.03c6.04-.68 11.146 4.012 11.146 9.854 0 2.22-.73 4.327-2.11 6.07 -1.9 2.404-2.9 5.13-2.9 7.89v.11Zm-3-11v-1.97c0-.95.78-1.718 1.75-1.718 .96 0 1.75.89 1.75 1.84 0 .94-.79 1.84-1.75 1.84H13Z"></path>
        </g>
    </svg>
  )
}

IconBulb.defaltProps = {
    fill: 'white',
}

IconBulb.propTypes = {
    fill: PropTypes.string,
    className: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
}

export default IconBulb;