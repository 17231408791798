import React from "react";
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import {ProductsResource} from "../../../../../application/resources/products";
import {AddButton} from "../../../../components/buttons/add-button";
import i18n from "i18next";
import {UnitQuantitySpinner} from "../../../../components/form/unit-spinner/unit-quantity-spinner";
import {useSelector} from "react-redux";
import {CustomQuantitySpinner} from "../../../../atomic/custom-quantity-spinner";


export const ProductListPage = () => {
    const products = useSelector(state => state.products.products.data);
    return (
        <div className="row mt-3">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-center">
                            <span className="card-title flex-grow-1">{i18n.t('Products List')}</span>
                            <AddButton permissions={['Create Products']} to="form" title={i18n.t('Add Products')} />
                        </div>
                    </div>
                    <div className="card-body">
                        <GalaxyResourceTable deletable={(row) => row.id !== 1} resource={new ProductsResource()}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
