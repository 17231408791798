import React from 'react';
import Currency from "../../atomic/formatting/currency";
import styles from './index.module.scss';
import IconBulb from '../icons/bulb';

function StatsCard({title, value, icon: Icon, color}) {
    return (
        <div className={`card ${styles.card}`}>
            <div className="card-body">
                <div className="d-flex">
                    <div className={styles.icon} style={{ backgroundColor: shadeColor(color ?? "#4C469C", 150) }}>
                        {Icon ? <Icon height="30px" width="30px" fill={ color ?? "#4C469C" } /> : <IconBulb height="30px" width="30px" fill={ color ?? "#4C469C" } />}
                    </div>
                    <div className="flex-grow-1">
                        <span className={styles.title}>{title}</span>
                        <div className={styles.value}><Currency value={value}/></div>
                    </div>
                </div>
            </div>

        </div>
    );
}

function shadeColor(color, percent) {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}

const getShade = (shade, color) => {
    color = hexToRgb(color);

    var max = Math.max(Math.max(color.r, Math.max(color.g,color.b)), 1);
    var step = 255 / (max * 10)
    newColor = {
        r: Math.round(color.r * step * shade),
        g: Math.round(color.g * step * shade),
        b: Math.round(color.b * step * shade)
    }
    console.log("debug", max, step, color, newColor, rgbToHex(newColor.r, newColor.g, newColor.b));
    return rgbToHex(newColor.r, newColor.g, newColor.b);
}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  
  function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

export default StatsCard;
