import React from 'react'
import PropTypes from 'prop-types';
import styles from './input-style.module.scss';

const CustomInput = (props) => {
    //props
    const { label, name, type, placeholder, onChange, onInput, value, error, min, max, autocomplete, isCurrency = false } = props;
    //handlers
    const handleChange = (key, e) => {
        if (onChange) {
            if (type == 'file') {
                onChange(key, e.target.files[0]);
            } else {
                if(isCurrency){
                    onChange(key, e.target.value.replaceAll(',', ''));
                } else {
                    onChange(key, e.target.value);
                }
            }
        }
    }
    const handleInput = (key, e) => {
        if (onInput) {
            if (type == 'file') {

            } else {
                console.log('Changed...');
            }
        }
    }
    const addSeparator = (value) => {
        const parts = value.toString().replaceAll(',','').split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(/[^0-9\-.]+/g, "").replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
    }
    return (
        <div className={styles.inputGroup}>
            {label &&<label htmlFor={name} className="form-label">{label}</label>}
            <input autoComplete={autocomplete ?? null}
                   min={min ?? null} max={max ?? null} type={type}
                   className={(error != null && error !== '') ? "form-control is-invalid" : "form-control"} id={name}
                   placeholder={placeholder} onChange={(e) => handleChange(name,  e)}
                   onInput={(e) => handleInput(name, e)}
                   value={ (isCurrency && value != null) ? (addSeparator(value)) : value} />
            <div className={(error != null && error !== '') ? 'invalid-feedback d-block' : 'valid-feedback d-none'}>{error}</div>
        </div>
    );
}

CustomInput.defaultProps = {
    type: 'text',
}

CustomInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    value: PropTypes.any,
    error: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    autocomplete: PropTypes.string,
    isCurrency: PropTypes.bool,
}

export default CustomInput;
