import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {purchasesPurchaseDelete, purchasesPurchasesGet} from "../../../../../application/features/purchases";
import CustomConfirmAlert from "../../../../components/notifications/confirm-alert";
import {AddButton} from "../../../../components/buttons/add-button";
import i18n from "i18next";
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import PurchasesResource from "../../../../../application/resources/purchases";
import {EmptyList} from "../../../../components/templates/error-pages/empty-list";

export const PurchasesListPage = () => {
    //state
    const [showAlert, setShowAlert] = useState(false);
    const [deletingId, setDeletingId] = useState();
    //redux

    const purchases = useSelector(state => state.purchases.purchases.data);
    const isFetching = useSelector(state => state.purchases.purchases.isFetching);
    const fetchingSuccessful = useSelector(state => state.purchases.purchases.fetchingSuccessful);

    const dispatch = useDispatch();

    //Effects
    useEffect(() => {
        dispatch(purchasesPurchasesGet())
    }, []);


    //handlers
    const handleDelete = () => {
        if (deletingId) {
            const data = {id: deletingId};
            dispatch(purchasesPurchaseDelete(data));
            setDeletingId(null);
            setShowAlert(false);
        }
    }

    return (
        <div>
            <div className="row mt-2">
                <div className="col-12">
                    {
                        (purchases && purchases.length === 0 && fetchingSuccessful && !isFetching) ?
                            <EmptyList icon={'tag'} subTitle={'You have not purchased anything yet.'}>
                                <AddButton permissions={['Create Purchases']} to="form"
                                           title={'Record your first purchase'}/>
                            </EmptyList>
                            :
                            <div className="card">
                                <div className='card-header'>
                                    <div className="d-flex align-items-center">
                                        <span className="card-title flex-grow-1">{i18n.t('Purchases')}</span>
                                        <AddButton permissions={['Create Purchases']} to="form"
                                                   title={i18n.t('Record a New Purchase')}/>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <GalaxyResourceTable deletable={(row) => false} resource={new PurchasesResource()}/>
                                </div>
                            </div>

                    }
                    <CustomConfirmAlert isShown={showAlert} title="Delete Product?"
                                        text="Are you sure you want to delete the product?" onConfirm={handleDelete}
                                        toggleAlert={setShowAlert}/>
                </div>
            </div>
        </div>
    )
}
