import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {reportsSalesGet} from "../../../../application/features/reports";
import BackendContentFilter from "../../../components/form/content-filter";
import moment from "moment";
import {CustomTable} from "../../../atomic/table/table";
import DateFormat from "../../../atomic/formatting/date-format";
import Currency from "../../../atomic/formatting/currency";
import {useTranslation} from "react-i18next";
import ReportExporter from "../../../components/form/report-exporter/report-exporter";
import bizmanager from "../../../../api/bizmanager";


const SalesReportPage = () => {
    //state
    // const [payload, setPayload] = useState({start_date: null, end_date: null});
    const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);

    //hooks
    const {i18n, t} = useTranslation();
    const dispatch = useDispatch();

    //redux
    const sales = useSelector(state => state.reports.sales_report.data);
    const isFetching = useSelector(state => state.reports.sales_report.isFetching);

    const downloadUrl = bizmanager.exportSalesReport;
    //effects
    useEffect(() => {
        dispatch(reportsSalesGet());
    }, []);


    useEffect(() => {
        if(!sales) return;
    }, [sales]);

    const fetchData = (payload = {}) => {
        dispatch(reportsSalesGet(payload));
    }

    return (
        <div className="card">
            <div className="card-header">{t('Sales Report')}</div>
            <div className="card-body">
                <div className="col-12  mb-2 d-flex flex-row justify-content-between align-items-center gap-1">
                    <div className="flex-grow-1"><h6>{t('Monthly Sales')}</h6></div>
                    <ReportExporter downloadUrl={downloadUrl} />
                    <BackendContentFilter startDate={moment().startOf('year').format('YYYY-MM-DD')}
                                          onSubmit={(data) => fetchData({payload: data})}/>
                </div>
                <CustomTable isLoading={isFetching}>
                    <thead>
                    <tr>
                        <th width={30}>#</th>
                        <th width={120}>{i18n.t('Date')}</th>
                        <th>{i18n.t('Name')}</th>
                        <th width={100} className={'text-end'}>{i18n.t('Quantity')}</th>
                        <th width={150} className={'text-end'}>{i18n.t('Amount')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        sales.map((sale, i) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td><DateFormat date={sale.date}/></td>
                                <td>{sale.name}</td>
                                <td className="text-end">{sale.quantity}<span
                                    className="text-black-50">{sale.unit}</span></td>
                                <td className="text-end"><Currency value={sale.amount}/></td>
                            </tr>
                        ))
                    }
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colSpan={4}>Total</th>
                        <th className="text-end"><Currency value={sales.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0)} /></th>
                    </tr>
                    </tfoot>
                </CustomTable>
            </div>
        </div>
    );
};

export default SalesReportPage;
