@import "$assets/scss/main.scss";

.wrapper {
    position: relative;
    display: flex;
    height: 100vh;
    overflow: hidden;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

.card {
    background: #FFFFFF;
    border-radius: 24px;
    padding: 36px;
    text-align: center;
}

.content {
    max-width: 450px;
    width: 90%;
    margin: 50px auto;
}
