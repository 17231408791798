import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {expensesExpenseDelete, expensesExpensesGet} from "../../../../../application/features/expenses";
import {useTranslation} from "react-i18next";
import CustomConfirmAlert from "../../../../components/notifications/confirm-alert";
import {AddButton} from "../../../../components/buttons/add-button";
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import ExpensesResource from "../../../../../application/resources/expenses";
import {GeneralErrorPage} from "../../../../components/templates/error-pages/general-error-page";
import {EmptyList} from "../../../../components/templates/error-pages/empty-list";

export const ExpensesListPage = () => {
    //state
    const [showAlert, setShowAlert] = useState(false);
    const [deletingId, setDeletingId] = useState();
    //redux
    const dispatch = useDispatch();
    const isFetching = useSelector(state => state.expenses.expenses.isFetching);
    const fetchingSuccessful = useSelector(state => state.expenses.expenses.fetchingSuccessful);
    const isDeleting = useSelector(state => state.expenses.expenses.isDeleting);
    const expenses = useSelector(state => state.expenses.expenses.data);
    const { t, i18n } = useTranslation();

    //effects
    useEffect(() => {
        // if (expenses && expenses.length) return;
        dispatch(expensesExpensesGet());
    }, []);

    //handlers
    const handleDelete = () => {
        if(deletingId) {
            const data = {id: deletingId};
            dispatch(expensesExpenseDelete(data));
            setDeletingId(null);
            setShowAlert(false);
        }
    }


    return (
        <div>
            <div className='row'>
                {/* <div className="col w-100 text-end">
                    <Link to="form" className='btn btn-outline-primary'>Record expense</Link>
                </div> */}
            </div>

            <div className="row mt-2">
                <div className="col-we">
                    {
                        (expenses && expenses.length === 0 && fetchingSuccessful && !isFetching) ?
                                <EmptyList icon={'emptyCart'} subTitle={'There are currently no expenses here.'}>
                                    <AddButton permissions={['Create Expenses']} to="form"
                                               title={'Record your first expense'}/>
                                </EmptyList>
                            :
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex align-items-center">
                                    <span className="card-title flex-grow-1">{i18n.t('Expenses')}</span>
                                    <AddButton permissions={['Create Expenses']} to="form"
                                               title={i18n.t("Add Expense")}/>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="card-body">
                                    <GalaxyResourceTable resource={new ExpensesResource()}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
