import React, { useEffect, useState } from "react";

import logo from "$assets/img/datavision-logo.png";
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from "../../../../application/features/authentication";
import { useNavigate } from 'react-router';
import { SplashLayout } from "../splash/layout";

export const PasswordResetPage = () => {
    //hooks
    const navigate = useNavigate();

    //state
    const [email, setEmail] = useState("");

    //store
    const dispatch = useDispatch();
    const emailSent = useSelector(state => state.authentication.forgot_password.isSuccessful);

    //effects
    useEffect(() => {
        if (!emailSent) return;
        navigate('../otp-confirm');
    }, [emailSent]);

    //handlers
    const handleForgotPassword = () => {
        dispatch(forgotPassword({email: email}));
    }

    return (
        <SplashLayout>
            <form>
                <p className="text-black-50 text-center ">Enter your email address to request a password reset</p>
                <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="email">Email Address</label>
                    <input type="email" id="email" className="form-control" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="text-center pt-1 mb-5 pb-1">
                    <button className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="button" onClick={handleForgotPassword} >Reset Password</button>
                </div>
            </form>
        </SplashLayout>
    );
}