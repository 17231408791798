import React from 'react';
import {NavLink, Routes, Navigate} from "react-router-dom";
import {Route} from "use-react-router-breadcrumbs";
import {GeneralSettingsPage} from "../settings/general-settings-page";
import TransactionsListPage from "./transactions/transactions";
import {useTranslation} from "react-i18next";
import {TopNavigation} from "../../../components/app/top-navigation/top-navigation";

const AccountsRoutes = () => {
    const {i18n, t} = useTranslation();
    const topLinks = [
        {
            title: t('Transactions List'),
            to: 'transactions'
        },
    ];
    return (
        <div>
            <h3>{i18n.t('Accounts')}</h3>
            <TopNavigation links={topLinks} basePathLevel={3} />
            <Routes>
                <Route path='transactions/*'>
                    <Route index element={<TransactionsListPage/>}/>
                </Route>
                <Route index element={<Navigate to="transactions" />}/>
            </Routes>
        </div>
    )
};

export default AccountsRoutes;
