import BizManagerApi from "../../api/bizmanager";
import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import DateFormat from "../../presentation/atomic/formatting/date-format";
import {
    inventoryStoresCreate,
    inventoryStoresDelete,
    inventoryStoresGet, inventoryStoresRetrieve,
    inventoryStoresUpdate
} from "../features/inventory";
import {
    productsProductUnitsCreate, productsProductUnitsDelete,
    productsProductUnitsGet,
    productsProductUnitsRetrieve,
    productsProductUnitsUpdate
} from "../features/products";
import i18n from "i18next";

/**
 * @inheritDoc
 */
export class  UnitsResource extends GalaxyResource {
    resourceActions = {
        fetch: productsProductUnitsGet,
        retrieve: productsProductUnitsRetrieve,
        create: productsProductUnitsCreate,
        update: productsProductUnitsUpdate,
        delete: productsProductUnitsDelete,
    };
    stateName = 'products.product_units';
    columns = [
        {
            name: '#',
            center: false,
            width: '40px',
            cell: (row, index) => (index + 1),
        },
        {
            name: i18n.t('Name'),
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: i18n.t('Abbreviation'),
            cell: (row) => <span>{row.location ?? ''}</span>,
            sortable: true,
        }
    ];
    permissions = {
        view: ['View Products'],
        create: ['Create Products'],
        update: ['Create Products'],
        delete: ['Delete Products'],
    }
}

export default UnitsResource;
