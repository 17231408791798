import {createSlice} from '@reduxjs/toolkit';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {customFetch} from '../../core/network';
import BizManagerApi from '../../api/bizmanager';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {organizationsOrganizationSetActive} from "./organizations";
import {businessTypesBusinessTypeSetActive} from "./busines-types";

//action contants
const AUTHENTICATION_LOGIN = 'authentication/LOGIN';
const AUTHENTICATION_GALAXY_LOGIN = 'authentication/GALAXY_LOGIN';
const AUTHENTICATION_FORGOT_PASSWORD = 'authentication/FORGOT_PASSWORD';
const AUTHENTICATION_VALIDATE_OTP = 'authentication/VALIDATE_OTP';
const AUTHENTICATION_RESET_PASSWORD = 'authentication/RESET_PASSWORD';
const AUTHENTICATION_AUTHENTICATE_REDIRECTED = 'authentication/AUTHENTICATE_REDIRECTED';
const AUTHENTICATION_UPDATE_PASSWORD = 'authentication/UPDATE_PASSWORD';

//actions
export const login = createAsyncThunk(
    AUTHENTICATION_LOGIN,
    async (data, store) => {
        return await customFetch('POST', BizManagerApi.authentication, data, null, store);
    }
);
export const galaxy_login = createAsyncThunk(
    AUTHENTICATION_GALAXY_LOGIN,
    async (data, store) => {
        return await customFetch('POST', BizManagerApi.login_with_galaxy, data, null, store);
    }
);

export const forgotPassword = createAsyncThunk(
    AUTHENTICATION_FORGOT_PASSWORD,
    async (data, store) => {
        return await customFetch('POST', BizManagerApi.forgotPassword, data, null, store);
    }
);

export const validateOtp = createAsyncThunk(
    AUTHENTICATION_VALIDATE_OTP,
    async (data, store) => {
        return await customFetch('POST', BizManagerApi.validateOTP, data, null, store);
    }
);

export const resetPassword = createAsyncThunk(
    AUTHENTICATION_RESET_PASSWORD,
    async (data, store) => {
        return await customFetch('POST', BizManagerApi.resetPassword, data, null, store);
    }
);

export const authenticateRedirected = createAsyncThunk(
    AUTHENTICATION_AUTHENTICATE_REDIRECTED,
    async (data, store) => {
        return await customFetch('POST', BizManagerApi.authenticateRedirected, data, null, store);
    }
);

export const updatePassword = createAsyncThunk(
    AUTHENTICATION_UPDATE_PASSWORD,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.changePassword, data, token, store);
    }
);


//initial state
const initialState = {
    token: null,
    user: null,
    stateHash: null,
    refresh_token: null,
    login: {
        isLoading: false,
        isSuccessful: false,
        error: null,
    },
    galaxy_login: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        login_url: null,
    },
    forgot_password: {
        isLoading: false,
        isSuccessful: false,
        message: "",
        error: null,
    },
    validate_otp: {
        isLoading: false,
        isSuccessful: false,
        message: "",
        error: null,
    },
    reset_password: {
        isLoading: false,
        isSuccessful: false,
        message: "",
        error: null,
    },
    authenticate_redirected: {
        isLoading: false,
        isSuccessful: false,
        message: "",
        error: null,
        data: null
    },
    update_password: {
        isUpdating: false,
        updatingSuccessful: false,
        message: "",
        error: null,
        data: null
    },
}

//redux toolkit slice
const authenticationSlice = createSlice({
        'name': 'authentication',
        initialState,
        reducers: {
            logout: (state, action) => {
                state.token = null;
                state.user = null;
                state.refresh_token = null;
            },
            updateUserToken: (state, action) => {
                console.log('Updating token ....', action);
                state.token = action.payload.token;
                state.user = action.payload.user ? action.payload.user : null;
                state.refresh_token = action.payload.refresh_token;
                console.log("New token: ", state.payload.token);
            },
            generateStateHash: (state, action) => {
                state.stateHash = generateUid(12);
            }
        },
        extraReducers: {
            [login.pending]: (state, action) => {
                state.login.isLoading = true;
                state.login.isSuccessful = false;
                state.login.error = null;
            },
            [login.fulfilled]: (state, action) => {
                state.login.isLoading = false;
                state.login.isSuccessful = true;
                state.token = (action.payload && action.payload.data) ? action.payload.data.access_token : null;
                state.user = (action.payload && action.payload.data && action.payload.data.user) ? (action.payload.data.user) : null;
                state.refresh_token = (action.payload && action.payload.data) ? action.payload.data.refresh_token : null;
            },
            [login.rejected]: (state, action) => {
                state.login.isLoading = false;
                state.login.error = action.payload ?? action.error;
            },
            [galaxy_login.pending]: (state, action) => {
                state.galaxy_login.isLoading = true;
                state.galaxy_login.isSuccessful = false;
                state.galaxy_login.error = null;
                state.galaxy_login.login_url = null;
            },
            [galaxy_login.fulfilled]: (state, action) => {
                state.galaxy_login.isLoading = false;
                state.galaxy_login.isSuccessful = true;
                state.galaxy_login.login_url = (action.payload && action.payload.data) ? action.payload.data.login_url : null;
            },
            [galaxy_login.rejected]: (state, action) => {
                state.galaxy_login.isLoading = false;
                state.galaxy_login.error = action.payload ?? action.error;
                state.galaxy_login.login_url = null;
            },
            [forgotPassword.pending]: (state, action) => {
                state.forgot_password.isLoading = true;
                state.forgot_password.isSuccessful = false;
                state.forgot_password.error = null;
                state.forgot_password.message = "";
            },
            [forgotPassword.fulfilled]: (state, action) => {
                state.forgot_password.isLoading = false;
                state.forgot_password.isSuccessful = true;
                state.forgot_password.error = null;
                state.forgot_password.message = (action.payload) ? action.payload.message : null;
            },
            [forgotPassword.rejected]: (state, action) => {
                state.forgot_password.isLoading = false;
                state.forgot_password.isSuccessful = true;
                state.forgot_password.error = action.error;
            },
            [validateOtp.pending]: (state, action) => {
                state.validate_otp.isLoading = true;
                state.validate_otp.isSuccessful = false;
                state.validate_otp.message = "";
                state.validate_otp.error = null;
            },
            [validateOtp.fulfilled]: (state, action) => {
                state.validate_otp.isLoading = false;
                state.validate_otp.isSuccessful = true;
                state.validate_otp.message = action.payload.message;
            },
            [validateOtp.fulfilled]: (state, action) => {
                state.validate_otp.isLoading = false;
                state.validate_otp.isSuccessful = false;
                state.validate_otp.error = action.error;
            },
            [resetPassword.pending]: (state, action) => {
                state.reset_password.isLoading = true;
                state.reset_password.isSuccessful = false;
                state.reset_password.error = null;
                state.reset_password.message = "";
            },
            [resetPassword.fulfilled]: (state, action) => {
                state.reset_password.isLoading = false;
                state.reset_password.isSuccessful = true;
                state.reset_password.message = action.payload.message;
            },
            [resetPassword.rejected]: (state, action) => {
                state.reset_password.isLoading = false;
                state.reset_password.error = action.error;
            },
            [authenticateRedirected.pending]: (state, action) => {
                state.authenticate_redirected.isLoading = true;
                state.authenticate_redirected.isSuccessful = false;
                state.authenticate_redirected.error = null;
                state.authenticate_redirected.message = "";
            },
            [authenticateRedirected.fulfilled]: (state, action) => {
                state.authenticate_redirected.isLoading = false;
                state.authenticate_redirected.isSuccessful = true;
                state.authenticate_redirected.message = action.payload.message;
                state.token = (action.payload && action.payload.data) ? action.payload.data.access_token : null;
                state.refresh_token = (action.payload && action.payload.data) ? action.payload.data.refresh_token : null;
                state.user = (action.payload && action.payload.data) ? action.payload.data.user : null;
            },
            [authenticateRedirected.rejected]: (state, action) => {
                state.authenticate_redirected.isLoading = false;
                state.authenticate_redirected.error = action.error;
            },
            [organizationsOrganizationSetActive.fulfilled]: (state, action) => {
                state.user.organization = action.payload ? action.payload.data : null;
            },
            [businessTypesBusinessTypeSetActive.fulfilled]: (state, action) => {
                state.user.organization = action.payload ? action.payload.data : null;
            },
            [updatePassword.pending]: (state, action) => {
                state.update_password.isUpdating = true;
                state.update_password.updatingSUccessful = false;
                state.update_password.error = null;
            },
            [updatePassword.fulfilled]: (state, action) => {
                state.update_password.isUpdating = false;
                state.update_password.updatingSUccessful = true;
                state.update_password.error = null;
            },
            [updatePassword.rejected]: (state, action) => {
                state.update_password.isUpdating = false;
                state.update_password.updatingSUccessful = false;
                state.update_password.error = action.error;
            },
        },
    })
;


function generateUid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

const authenticationPersistConfig = {
    key: 'authentication',
    storage: storage,
    whitelist: ['token', 'user', 'settings', 'refresh_token', 'stateHash', 'products.product_units'],
}

//exports
export const {logout, updateUserToken, generateStateHash} = authenticationSlice.actions;
export default persistReducer(authenticationPersistConfig, authenticationSlice.reducer);
