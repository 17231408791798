import QuantitySpinner from "./quantity-spinner";
import {UnitQuantitySpinner} from "../components/form/unit-spinner/unit-quantity-spinner";
import React, {useEffect} from "react";
import styles from "./input-style.module.scss";

export const CustomQuantitySpinner = (props) => {
    const {product, label, name, placeholder, onChange, value, error, min, max} = props;

    const handleChange = (key, value) => {
        if (onChange) {
            console.log('Resolved Quantity ', value);
            onChange(key, value);
        }
    }
    return (
        <div className={styles.inputGroup}>
            {label &&<label htmlFor={name} className="form-label">{label}</label>}
            <UnitQuantitySpinner placeholder={placeholder} name={name}
                                 onChange={handleChange}
                                 product={product}
                                 value={value}
                                 min={min}
                                 max={max}
            />
            <div className={(error != null) ? 'invalid-feedback d-block' : 'valid-feedback d-none'}>{error}</div>
        </div>

    )
}
