import React, {useState} from 'react';
import CustomInput from "../../../../../atomic/input";
import Currency from "../../../../../atomic/formatting/currency";
import CustomTextArea from "../../../../../atomic/text-area";
import {CustomValidator} from "../../../../../../core/validator";
import i18n from "i18next";

const ExpenseItemForm = (props) => {
    const {item, onSave} = props;
    const [payload, setPayload] = useState({name: null, description: null, quantity: null, unit_amount: null});
    const [errors, setErrors] = useState({});
    const validationRules = {
        name: ['required'],
        description: ['required'],
        quantity: [ 'required', 'numeric'],
        unit_amount: ['required', 'numeric']
    };

    const handleChange = (key, value) => {
        const payloadClone = {...payload};
        payloadClone[key] = value;
        setPayload({...payloadClone});
    }

    const validateForm = () => {
        setErrors({});
        const _errors = CustomValidator.validate({...payload}, validationRules);
        setErrors(_errors);
        console.log(_errors);
        return Object.keys(_errors).length === 0;
    };

    const handleSave = () => {
        console.log("Validating...");
        if (validateForm()) {
            console.log("Saving...");
            if (onSave) {
                onSave(payload)
            }
        } else {
            console.log("Validation failed")
        }

    }

    return (
        <div>
            <div className="row">

                <div className="col-12 mb-2">
                    <CustomInput name={"name"} placeholder={"Item Name"} value={payload.name ?? ''} label={"Item Name"}
                                 type={"text"} onChange={handleChange} error={errors.name ?? ''}/>
                </div>
                <div className="ol-12 mb-2">
                    <CustomTextArea name="description" placeholder={i18n.t('Description')} label={i18n.t('Description')} type={"text"}
                                    value={payload.description ?? ''} onChange={handleChange}
                                    error={errors.description ?? ''}/>
                </div>
                <div className="col-6 mb-2">
                    <CustomInput name="quantity" label={i18n.t('Quantity')} isCurrency={true} value={payload.quantity ?? ''}
                                 onChange={handleChange} error={errors.quantity ?? ''}/>
                </div>
                <div className="col-6 mb-2">
                    <CustomInput name="unit_amount" label={i18n.t('Unit Price')} value={payload.unit_amount ?? ''}
                                 isCurrency={true} onChange={handleChange} error={errors.unit_amount ?? ''} placeholder={i18n.t('Unit Price')}/>
                </div>
                <div className="d-flex col-8 mb-2 align-content-center">
                    Total
                </div>
                <div className="col-4  mb-2">
                    <div className="form-control text-end text-bold" style={{fontSize: '1.2rem'}}><Currency
                        value={(payload.unit_amount ?? 0) * (payload.quantity ?? 0)}/></div>
                </div>

            </div>
            <div className="d-flex align-content-end">
                <button onClick={handleSave} className={"btn btn-primary"}>Add Item</button>
            </div>
        </div>
    );
};

export default ExpenseItemForm;
