import React from "react";
import {useNavigate} from "react-router";
import {AddButton} from "../../../../components/buttons/add-button";
import i18n from "i18next";
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import ReturnsResource from "../../../../../application/resources/returns";
import {useSelector} from "react-redux";
import {EmptyList} from "../../../../components/templates/error-pages/empty-list";

export const ReturnsListPage = () => {

  const navigate = useNavigate();
  const returns = useSelector(state => state.returns.returns.data);
  const isFetching = useSelector(state => state.returns.returns.isFetching);
  const fetchingSuccessful = useSelector(state => state.returns.returns.fetchingSuccessful);

  return (
    <div>
        {

            (returns && returns.length === 0 && fetchingSuccessful && !isFetching) ?
                <EmptyList icon={'emptyCart'} subTitle={'You can record items that your customers returned after buying.'}>
                    <AddButton permissions={['Create Returns']} to="form" title={'Record your first Return'} style={{width: 'auto'}} />
                </EmptyList>
                :
            <div className="card">
                <div className="card-header">
                    <div className="d-flex align-items-center">
                        <span className="card-title flex-grow-1">{i18n.t('Sale Returns')}</span>
                        <AddButton permissions={['Create Returns']} to="form" title={i18n.t('Add Return')}/>
                    </div>
                </div>
                <div className="card-body">
                    <GalaxyResourceTable deletable={(item) => false} resource={new ReturnsResource()}/>
                </div>
            </div>
        }
    </div>
  );
};
