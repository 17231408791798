import BizManagerApi from "../../api/bizmanager";
import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import {
    productsProductCreate,
    productsProductDelete, productsProductRetrieve,
    productsProductsGet,
    productsProductUpdate
} from "../features/products";
import i18n from "i18next";

/**
 * @inheritDoc
 */
export class  ProductsResource extends GalaxyResource {
    resourceActions = {
        fetch: productsProductsGet,
        retrieve: productsProductRetrieve,
        create: productsProductCreate,
        update: productsProductUpdate,
        delete: productsProductDelete,
    };
    stateName = 'products.products';
    columns = [
        {
            name: '#',
            center: false,
            width: '40px',
            cell: (row, index) => (index + 1),
        },
        {
            name: i18n.t('Name'),
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: i18n.t('Unit'),
            cell: (row) => <span>{row.unit.name}</span>,
            sortable: true,
        },
        {
            name: i18n.t('Category'),
            cell: (row) => <span>{row.category.name}</span>,
            sortable: true,
        },
    ];
    permissions = {
        view: ['View Products'],
        create: ['Create Products'],
        update: ['Create Products'],
        delete: ['Delete Products'],
    }
}

export default ProductsResource;
