import React from 'react'
import {Routes, Route, NavLink, Navigate} from 'react-router-dom';
import {InventoryItemFormPage} from './items/form';
import {InventoryItemListPage} from './items/list';
import InventoryItemViewPage from "./items/view";
import InventoryAddonsListPage from "./addons/list";
import InventoryDisposalsListPage from "./disposals/list";
import StoresListPage from "./stores/list";
import StoresFormPage from "./stores/form";
import StoreViewPage from "./stores/view";
import InventoryDisposalViewPage from "./disposals/view";
import InventoryDisposalFormPage from "./disposals/form";
import {useTranslation} from "react-i18next";
import {TopNavigation} from "../../../components/app/top-navigation/top-navigation";

export const InventoryRoutes = () => {
    const {i18n, t} = useTranslation();
    const topLinks = [
        {
            title: t('Inventory Items'),
            to: 'items'
        },
        {
            title: t('Inventory Disposals'),
            to: 'disposals'
        },
        {
            title: t('Stores'),
            to: 'stores'
        },
    ];
    return (
        <div>
            <h3>{i18n.t('Inventory')}</h3>
            <TopNavigation links={topLinks} basePathLevel={3} />

            <Routes>
                <Route index element={<Navigate to='items'/>}/>
                <Route path='items/*'>
                    <Route path="form" element={<InventoryItemFormPage/>}/>
                    <Route path=":inventory_item_id" element={<InventoryItemViewPage/>}/>
                    <Route path="form/:inventory_item_id" element={<InventoryItemFormPage/>}/>
                    <Route index element={<InventoryItemListPage/>}/>
                </Route>
                <Route path='disposals/*'>
                    <Route path="form" element={<InventoryDisposalFormPage/>}/>
                    <Route path=":inventory_disposal_id" element={<InventoryDisposalViewPage/>}/>
                    <Route path="form/:inventory_disposal_id" element={<InventoryDisposalFormPage/>}/>
                    <Route index element={<InventoryDisposalsListPage/>}/>
                </Route>
                <Route path='stores/*'>
                    <Route path="form" element={<StoresFormPage/>}/>
                    <Route path=":store_id" element={<StoreViewPage/>}/>
                    <Route path="form/:store_id" element={<StoresFormPage/>}/>
                    <Route index element={<StoresListPage/>}/>
                </Route>
            </Routes>
        </div>
    )
}
