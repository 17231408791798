import axios from "axios";
import BizManagerApi from "../api/bizmanager";

export const customFetch2 = async (method, endpoint, data = [], token, thunkApi) => {
    const url = new URL(endpoint);

    if (method == 'GET' && data && data.length != 0) {
        url.search = new URLSearchParams(data).toString();
    }

    if ((method == 'POST' || method == 'PUT') && data) {
        var formData = jsonToFormData(data);
    } else {
        var formData = new FormData();
    }

    const response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Accept': 'application/json',
            'Authorization': token ? ('Bearer ' + token) : null,
            'Access-Control-Allow-Origin': "*",
            // 'Content-Type': 'application/json',
        },
        redirect: 'error', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: ((method == 'POST') && data) ? formData : ((method === 'PUT') ? JSON.stringify(data) : null), // body data type must match "Content-Type" header
    });


    if (response.status == 204) { // Special Case
        return response.body;
    }
    const results = await response.json();

    //////////////////////////////// AXIOS
    // const response = await axiosFetch(url, method, formData, token);
    // console.error('RESPONSE: ', response);
    // const results = response.data;
    ///////////////////////////////

    console.log("HTTP RESPONSE: ", results);
    if (response.status >= 200 && response.status < 300) {
        return results;
    }

    /**
     * If the Response is Unauthenticated then refresh token or redirect to Login
     */
    if (response.status == 401) {
        throw 'UNAUTHORIZED';
    }

    if (response.status == 303) {
        console.log('Interrupt fired');
        if (thunkApi != null) {
            return thunkApi.rejectWithValue(results);
        } else {
            if (results.interrupt) {
                throw results.data.code ?? JSON.stringify(results);
            }
            throw JSON.stringify(results);
        }
    }

    if (response.status == 422) {
        if (thunkApi != null) {
            return thunkApi.rejectWithValue(results);
        } else {
            throw JSON.stringify(results);
        }
    }


    //error handling
    if (response.status == 500) {
        throw "Server Error";
    }

    console.error(results);

    if (results.message) {
        throw results.message;
    }

    if (results.data && results.data.message) {
        throw results.data.message;
    }
    throw "Something went wrong";
}

export const downloadFile = async (url, token, format = 'pdf') => {
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': format === 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/' + format,
            'Authorization': token ? ('Bearer ' + token) : null,
        },
    })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `BizPro-export.${format === 'excel' ? 'xlsx' : format}`,
            );
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
}

// Converting json to FormData
function jsonToFormData(data) {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
}

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}

/**
 * Alternative Fetch method using Axios Library
 * @param method
 * @param endpoint
 * @param data
 * @param token
 * @param thunkApi
 * @returns {Promise<void>}
 */
export const customFetch = async (method, endpoint, data = [], token, thunkApi) => {
    const url = new URL(endpoint);
    if (method === 'GET' && data && data.length !== 0) {
        url.search = new URLSearchParams(data).toString();
    }
    let formData = new FormData();
    if ((method === 'POST' || method === 'PUT') && data) {
        formData = jsonToFormData(data);
    }
    const payload = ((method === 'POST') && data) ? formData : ((method === 'PUT') ? JSON.stringify(data) : null);
    const response = await axiosFetch(url, method, payload, token ? ('Bearer ' + token) : null);
    if(!response) {
        console.log('Response is null');
    }
    if (response.status === 204) { // Special Case
        return response.data;
    }
    const responseData = response.data;
    if (response.status >= 200 && response.status < 300) { // Clean
        return responseData;
    }
    if (response.status === 401) {  throw 'UNAUTHORIZED'; } // Unauthorized
    if (response.status === 303) { // Redirection from backend requested
        console.log('Interrupt fired');
        if (thunkApi != null) {
            return thunkApi.rejectWithValue(responseData);
        } else {
            if (responseData.interrupt) {
                throw responseData.data.code ?? JSON.stringify(responseData);
            }
            throw JSON.stringify(responseData);
        }
    }
    if (response.status === 422) { // Validation Errors
        if (thunkApi != null) {
            return thunkApi.rejectWithValue(responseData);
        } else {
            throw JSON.stringify(responseData);
        }
    }
    if (response.status === 500) { // Error on the server side
        throw response.message ?? "Server Error";
    }
    throw "Unknown HTTP Error";
}

/**
 * Send Fetch requests using AXIOS library (NOT USED YET)
 * @param url
 * @param method
 * @param data
 * @param token
 * @returns {Promise<*>}
 */
const axiosFetch = async (url, method, data, token) => {

    const response = await axios({
        method: method,
        url: url,
        data: data,
        headers: {
            'Accept': 'application/json',
            'Authorization': token ? ('Bearer ' + token) : null,
            'Access-Control-Allow-Origin': "*",
        },
        timeout: 15000,

    }).catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            return error.response;
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log('No response received: ', error.request);
            // return error.request;
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error ---', error.message);
        }
        console.log(error.config);
    });

    console.log('AXIOS Response: ', response);
    if(response) {
        return response;
    } else {
        console.log('Response is null/undefined', response);
        return {status: 500, message: 'CONNECTION_FAILED'}
    }
}
