import React, {useEffect} from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import CustomInput from "../../../../atomic/input";
import {Spinner} from "../../../../atomic/spinner";
import {
    productsProductCategoriesGet,
    productsProductCreate,
    productsProductUnitsGet,
    productsProductUpdate
} from "../../../../../application/features/products";
import CustomSelectInput from "../../../../atomic/select";
import CreateUpdateForm from "../../../../components/form/createUpdateForm";

const ProductsProductFormPage = () => {
    const { product_id } = useParams();
    const products_resource = useSelector(state => state.products.products);
    const product_categories = useSelector(state => state.products.product_categories.data);
    const validationErrors = useSelector(state => state.products.products.validationErrors);
    const product_units = useSelector(state => state.products.product_units.data);
    const isFetchingProducts = useSelector(state => state.products.products.isFetching);
    const isFetchingCategories = useSelector(state => state.products.product_categories.isFetching);
    const isFetchingUnits = useSelector(state => state.products.product_units.isFetching);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log('init');
        dispatch(productsProductCategoriesGet());
        dispatch(productsProductUnitsGet());
    }, []);

    useEffect(() => {
        console.log("Validation errors");
        console.log(validationErrors);
    }, [validationErrors]);


    return (
        (isFetchingProducts || isFetchingCategories || isFetchingUnits) ?
            <div className="d-flex justify-content-center"><Spinner /></div>
            :
        <CreateUpdateForm resource={products_resource}  title={"Product"} item_id={product_id} createThunk={productsProductCreate} updateThunk={productsProductUpdate} validationErrors={validationErrors}>
            <CustomInput name='name' label='Product Name' placeholder='Product name'  required />
            <CustomInput name='reorder_level' label='Reorder level' type="number" min="0" placeholder='Reorder Level'  />
            <CustomSelectInput name="product_category_id" items={product_categories} label={"Category"} />
            <CustomSelectInput name="unit_id" items={product_units} label={"Unit"}/>
        </CreateUpdateForm>
    );
};

export default ProductsProductFormPage;
