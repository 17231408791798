@import "$assets/scss/main.scss";

.wrapper {
    position: relative;
    display: flex;
    height: 100vh;
    overflow: hidden;

    .left {
        position: relative;
        aspect-ratio: 823/1037;
        height: 100vh;

        .img {
            height: 100%;
            width: 100%;
            object-position: top;
        }

        .logo {
            position: absolute;
            top: 15%;
            left: 20%;
            z-index: 3;
        }
    }

    .right {
        position: relative;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .art2 {
            position: absolute;
            height: 30%;
            left: 0;
            bottom: 0;
        }

        .art3 {
            position: absolute;
            height: 9%;
            left: 0;
            top: 0;
        }

        .art4 {
            position: absolute;
            height: 15%;
            top: 23%;
            right: 0;
        }

        .splashForm {
            position: absolute;
            height: 100vh;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .content {
            display: flex;
            flex-direction: column;
            width: 450px;
            align-items: center;

            h1 {
                font-weight: 600;
                font-size: 32px;
            }
        }
    }
}
