import BizManagerApi from "../../api/bizmanager";
import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import {
    purchasesPurchaseDelete,
    purchasesPurchasesGet, purchasesPurchaseCreate, purchasesPurchaseRetrieve, purchasesPurchaseUpdate,
} from "../features/purchases";
import DateFormat from "../../presentation/atomic/formatting/date-format";
import i18n from "i18next";
import Currency from "../../presentation/atomic/formatting/currency";
import {
    inventoryDisposalsCreate, inventoryDisposalsDelete,
    inventoryDisposalsGet,
    inventoryDisposalsRetrieve,
    inventoryDisposalsUpdate
} from "../features/inventory";

/**
 * @inheritDoc
 */
export class  InventoryDisposalResource extends GalaxyResource {
    resourceActions = {
        fetch: inventoryDisposalsGet,
        retrieve: inventoryDisposalsRetrieve,
        create: inventoryDisposalsCreate,
        update: inventoryDisposalsUpdate,
        delete: inventoryDisposalsDelete,
    };
    stateName = 'inventory.disposals';
    columns = [
        {
            name: '#',
            center: false,
            width: '40px',
            cell: (row, index) => (index + 1),
        },
        {
            name: i18n.t('Date'),
            grow: 0,
            minWidth: '20%',
            sortField: 'date',
            selector: (row) => <DateFormat date={row.date}/>,
            sortable: true,

        },
        {
            name: i18n.t('Description'),
            grow: 1,
            center: false,
            sortField: 'description',
            cell: (row) => <span>{row.description ?? ''}</span>,
            sortable: true,
        },
        {
            name: i18n.t('Items'),
            grow: 0,
            minWidth: "200px",
            right: true,
            cell: (row) => <div className={'text-end w-100'}>{row.items ? row.items.length : ""}</div>,
            sortable: true,
        },
    ];
    permissions = {
        view: ['View Inventory'],
        create: ['Create Inventory'],
        update: ['Create Inventory'],
        delete: ['Delete Inventory'],
    }
}

export default InventoryDisposalResource;
