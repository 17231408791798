import React from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";


const ExpenseTypeViewPage = () => {

    const { expense_type_id } = useParams();
    //redux
    const dispatch = useDispatch();
    const expense_type = useSelector(state => state.expenses.expense_types.data.find(u => u.id == expense_type_id));

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">Expense Type</div>
                    <div className="card-body">
                        <table className="table table-bordered">
                            <tbody>
                            <tr>
                                <td>Name</td>
                                <td>{expense_type.name}</td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td>{expense_type.description}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpenseTypeViewPage;
