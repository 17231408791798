import DateFormat from "../../../../atomic/formatting/date-format";
import Currency from "../../../../atomic/formatting/currency";
import React, {forwardRef} from "react";

export const SaleReceipt = forwardRef(function SaleReceipt({sale}, ref) {
    const pageStyle = "@page { size: A4 portrait; }";
    return (
        <div id={'printableSale'} ref={ref}>
            <style>
                {pageStyle}
            </style>
            <div className="mb-3">
                <table className="table table-bordered table-sm">
                    <tbody>
                    <tr>
                        <th width="30%">User:</th>
                        <td>{sale.user ? sale.user.name : 'N/A' }</td>
                    </tr>
                    <tr>
                        <th>Sale Date:</th>
                        <td><DateFormat value={sale.date}/></td>
                    </tr>
                    <tr>
                        <th>Customer:</th>
                        <td>{(sale.customer && sale.customer.name) ? sale.customer.name : sale.customer_name ?? 'N/A'}</td>
                    </tr>
                    </tbody>
                </table>
                <div></div>
                <div></div>
            </div>
            <table className='table table-bordered table-sm'>
                <thead>
                <tr>
                    <th width={30}>#</th>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Unit Amount</th>
                    <th>Total Amount</th>
                </tr>

                </thead>
                <tbody>
                {
                    (sale.items && sale.items.length) ?
                        sale.items.filter(u => u).map((item, i) => (
                            <tr key={'tr' + i}>
                                <td>{(i + 1)}</td>
                                <td>{item.name}</td>
                                <td>{item.quantity}</td>
                                <td><Currency value={item.unit_price}/></td>
                                <td className="text-end"><Currency
                                    value={item.unit_price * item.quantity}/></td>
                            </tr>
                        ))
                        :
                        ''
                }
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan="3"> Tax</td>
                    <td>-</td>
                    <td className="text-end"><Currency
                        value={(sale.items) ? (sale.items.reduce((prev, current) => prev + (current.tax_percentage * current.quantity * current.unit_price), 0)) : ''}/>
                    </td>
                </tr>
                <tr>
                    <td colSpan="3"> Offers</td>
                    <td>-</td>
                    <td className="text-end"><Currency value={0}/></td>
                </tr>
                <tr>
                    <td colSpan="3"> Discount</td>
                    <td>-</td>
                    <td className="text-end"><Currency
                        value={(sale.items) ? (sale.items.reduce((prev, current) => prev + (current.discount), 0)) : ''}/>
                    </td>
                </tr>
                <tr>
                    <th colSpan="4"> Total</th>
                    <th className="text-end"><Currency value={sale.total_amount}/></th>
                </tr>
                </tfoot>
            </table>
        </div>
    );

});