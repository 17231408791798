import React from 'react';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {expensesExpenseTypesDelete, expensesExpenseTypesGet} from "../../../../../application/features/expenses";
import {CustomTable} from "../../../../atomic/table/table";
import {ViewButton} from "../../../../components/buttons/view-button";
import {EditButton} from "../../../../components/buttons/edit-button";
import {DeleteButton} from "../../../../components/buttons/delete-button";
import CustomConfirmAlert from "../../../../components/notifications/confirm-alert";
import { AddButton } from '../../../../components/buttons/add-button';
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import ExpenseTypesResource from "../../../../../application/resources/expense-types";
import i18n from "i18next";

const ExpenseTypesListPage = () => {

    //state
    const [showAlert, setShowAlert] = useState(false);
    const [deletingId, setDeletingId] = useState(null);

    //redux
    const dispatch = useDispatch();
    const isFetching = useSelector(state => state.expenses.expense_types.isFetching);
    const isDeleting = useSelector(state => state.expenses.expense_types.isDeleting);
    const deletingSuccessful = useSelector(state => state.expenses.expense_types.deletingSuccessful);
    const expense_types = useSelector(state => state.expenses.expense_types.data);

    //hooks
    const navigate = useNavigate();

    //effects
    useEffect(() => {
        dispatch(expensesExpenseTypesGet());
    }, []);

    //effects
    useEffect(() => {
        if(deletingSuccessful == true) {
            dispatch(expensesExpenseTypesGet);
        }
    }, [deletingSuccessful]);

    //handlers
    const handleDelete = () => {
        if(deletingId) {
            const data = {id: deletingId};
            dispatch(expensesExpenseTypesDelete(data));
            setDeletingId(null);
            setShowAlert(false);
        }
    }


    function showAddForm() {
        navigate('form');
    }


    return (
        <div>
            {/* <div className="text-end">
                <button className="btn btn-outline-primary" onClick={showAddForm}>Add New Expense Type</button>
            </div> */}
            <div className="row mt-2">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="d-flex align-items-center">
                                <span className="card-title flex-grow-1">Expense Types</span>
                                <AddButton permissions={['Create Expenses']} to="form" title={i18n.t('Add Expense Type')} />
                            </div>
                        </div>
                        <div className="card-body">
                            <GalaxyResourceTable deletable={(item) => (item.system != 1)} resource={new ExpenseTypesResource()} />
                        </div>
                    </div>
                </div>
            </div>

            <CustomConfirmAlert isShown={showAlert} title="Delete Expense Type?" text="Are you sure you want to delete the Expense Type?" onConfirm={handleDelete} toggleAlert={setShowAlert} />
        </div>
    );
};

export default ExpenseTypesListPage;
