import React from 'react'
import styles from './index.module.scss';
import { useNavigate } from 'react-router';
import { SplashLayout } from './layout';
import { Link } from 'react-router-dom';

export const SplashPage = (props) => {
    const navigate = useNavigate();

    return (
      <SplashLayout>
        <div className={styles.content}>
              <h1>Welcome to BizPro</h1>
                <p className='text-center'>Take our sincerest welcome hugs! 🤗
                    We love you choose us, you will never regret that. Take the next few steps to get started!
                </p>
                <button className="btn btn-primary" onClick={() => navigate("/authentication/register")}>Sign Up</button>
                <p>Already Have an Account ? <Link to="/authentication/login">Log in</Link></p>
        </div>
      </SplashLayout>
    );
}

