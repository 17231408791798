import BizManagerApi from "../../api/bizmanager";
import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import DateFormat from "../../presentation/atomic/formatting/date-format";
import {
    inventoryStoresCreate,
    inventoryStoresDelete,
    inventoryStoresGet, inventoryStoresRetrieve,
    inventoryStoresUpdate
} from "../features/inventory";
import {
    returnsReturnCreate,
    returnsReturnDelete,
    returnsReturnRetrieve,
    returnsReturnsGet,
    returnsReturnUpdate
} from "../features/returns";
import {t} from "i18next";
import Currency from "../../presentation/atomic/formatting/currency";

/**
 * @inheritDoc
 */
export class  ReturnsResource extends GalaxyResource {
    resourceActions = {
        fetch: returnsReturnsGet,
        retrieve: returnsReturnRetrieve,
        create: returnsReturnCreate,
        update: returnsReturnUpdate(),
        delete: returnsReturnDelete,
    };
    stateName = 'returns.returns';
    columns = [
        {
            name: '#',
            center: false,
            width: '40px',
            cell: (row, index) => (index + 1),
        },
        {
            name: t('Date'),
            selector: (row) => <DateFormat date={row.date} />,
            sortable: true,
        },
        {
            name: t('Description'),
            cell: (row) => <span>{row.description ?? ''}</span>,
            sortable: true,
        },
        {
            name: t('Sale'),
            cell: (row) => <span>{row.sale? (row.sale.date + ' - ' + row.sale.total_amount) : ''}</span>,
            sortable: true,
        },
        {
            name: t('Amount'),
            cell: (row) => <span><Currency value={row.total ?? ''} /> </span>,
            sortable: true,
        }
    ];
    permissions = {
        view: ['View Returns'],
        create: ['Create Returns'],
        update: ['Create Returns'],
        delete: ['Delete Returns'],
    }
}

export default ReturnsResource;
