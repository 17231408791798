import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {salesSalesDelete, salesSalesGet} from "../../../../../application/features/sales";
import {CustomTable} from "../../../../atomic/table/table";
import DateFormat from "../../../../atomic/formatting/date-format";
import {ViewButton} from "../../../../components/buttons/view-button";
import {EditButton} from "../../../../components/buttons/edit-button";
import {DeleteButton} from "../../../../components/buttons/delete-button";
import CustomConfirmAlert from "../../../../components/notifications/confirm-alert";
import Currency from "../../../../atomic/formatting/currency";
import {NavLink} from "react-router-dom";
import { AddButton } from "../../../../components/buttons/add-button";
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import SalesResource from "../../../../../application/resources/sales";

export const SalesListPage = () => {
    //state
    const [showAlert, setShowAlert] = useState(false);
    const [deletingId, setDeletingId] = useState();
    //redux
    // const sales = useSelector(state => state.sales.sales.data);
    // const isFetching = useSelector(state => state.sales.sales.isFetching);
    // const isDeleting = useSelector(state => state.sales.sales.isFetching);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //Effects
    useEffect(() => {
        dispatch(salesSalesGet())
    }, []);

    const onSearch = (value) => {
        if(value && value.length){
            dispatch(salesSalesGet({search: value}))
        } else {
            dispatch(salesSalesGet())
        }
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="d-flex align-items-center">
                        <span className="card-title flex-grow-1">Sales</span>
                        <AddButton permission={['Create Sales']} to="/main/sales/window" title="Add Sale" />
                    </div>
                </div>
                <div className="card-body">
                    <div className="row flex-row-reverse">
                        <div className={'col-12 col-md-4 col-lg-3'}>
                            <input className={'form-control input-sm'} onInput={(e) => onSearch(e.target.value)} type={'search'} placeholder={"Search Sales"} name={'search'} />
                        </div>
                    </div>
                    <GalaxyResourceTable deletable={(row) => false} resource={new SalesResource()}/>
                </div>
            </div>
        </div>
    )
}
