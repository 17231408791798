import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {reportsMonthlySalesGet} from "../../../../application/features/reports";
import BackendContentFilter from "../../../components/form/content-filter";
import moment from "moment";
import {CustomTable} from "../../../atomic/table/table";
import DateFormat from "../../../atomic/formatting/date-format";
import Currency from "../../../atomic/formatting/currency";
import {useTranslation} from "react-i18next";
import {Spinner} from "../../../atomic/spinner";
import ReportExporter from "../../../components/form/report-exporter/report-exporter";
import bizmanager from "../../../../api/bizmanager";

const MonthlySalesReportPage = () => {
    //state
    const [sales, setSales] = useState([]);
    const [showChildren, setShowChildren] = useState(false);
    // const [payload, setPayload] = useState({start_date: null, end_date: null});

    //hooks
    const {i18n, t} = useTranslation();
    const dispatch = useDispatch();
    const downloadUrl = bizmanager.exportMonthlySalesReport;

    //redux
    const salesData = useSelector(state => state.reports.monthly_sales_report.data);
    const isFetching = useSelector(state => state.reports.monthly_sales_report.isFetching);

    //effects
    useEffect(() => {
        dispatch(reportsMonthlySalesGet());
    }, []);


    useEffect(() => {
        if(!salesData) return;
        setSales(salesData);
        console.log(sales);
    }, [salesData]);

    const fetchData = (payload = {}) => {
        dispatch(reportsMonthlySalesGet(payload));
    }

    const months = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    if(!sales) {
        return <Spinner />;
    }

    const toggleChildren = () =>{
        setShowChildren(!showChildren);
    }

    return (
        <div className="card">
            <div className="card-header">{t('Sales Report')} <span className="float-end"><button onClick={toggleChildren} className="btn btn-outline-secondary btn-sm m-0">Show Products</button></span> </div>
            <div className="card-body">
                <div className="col-12  mb-2 d-flex flex-row justify-content-between align-items-center">
                    <div className="flex-grow-1"><h6>{t('Monthly Sales')}</h6></div>
                    <ReportExporter downloadUrl={downloadUrl} />
                    <BackendContentFilter startDate={moment().startOf('year').format('YYYY-MM-DD')} onSubmit={(data) => fetchData({payload: data}) }/>
                </div>
                <CustomTable isLoading={isFetching}>
                    <thead>
                    <tr>
                        <th width={30}>#</th>
                        <th width={30}></th>
                        <th>{t('Product Name')}</th>
                        <th width={100} className={'text-end'}>{t('Quantity')}</th>
                        <th width={150} className={'text-end'}>{t('Amount')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        sales.map((sale, i) => (
                            <>
                            <tr>
                                <td>{i+1}</td>
                                <td colSpan={3}>{sale.month ? (months[(sale.month).toString().split('-')[1] ?? 0]) : ''} {sale.month.substring(0, 4)}</td>
                                <td className="text-end"><Currency value={sale.total} /></td>
                            </tr>
                                {
                                    showChildren ?
                                    sale.items.map((item, j) =>(
                                      <tr  key={i + "-" + j}>
                                          <td>{j+1}</td>
                                          <td></td>
                                          <td>{item.name}</td>
                                          <td className="text-end">{item.quantity}<span className="text-black-50">{item.unit}</span></td>
                                          <td className="text-end"><Currency value={item.amount} /></td>
                                      </tr>
                                    ))
                                        :
                                        ""
                                }
                            </>
                        ))
                    }
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colSpan={4}>Total</th>
                        <th className="text-end"><Currency value={sales.reduce((prev, cur) => prev + cur.total, 0)} /></th>
                    </tr>
                    </tfoot>
                </CustomTable>
            </div>
        </div>
    );
};

export default MonthlySalesReportPage;
