import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import styles from './product-selector.module.scss';
import Currency from "../../../atomic/formatting/currency";
import {salesProductSearch} from "../../../../application/features/sales";
import {FullSpinner} from "../../../atomic/full-spinner";
import CustomButton from "../../../atomic/button";
import CustomInput from "../../../atomic/input";
import {PropTypes} from "prop-types";
import PageAlert from "../../notifications/page-alert";
import i18n, {t} from "i18next";
import {productsProductCategoriesGet} from "../../../../application/features/products";

export const ProductSelector = (props) => {
    const {onSelectionCompleted} = props;
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showImages, setShowImages] = useState(false);

    const products = useSelector(state => state.sales.search.results);
    const items = useSelector(state => state.sales.payload.items);
    const categories = useSelector(state => state.products.product_categories.data);

    const productsMeta = useSelector(state => state.products.product_categories.meta);

    const isSearching = useSelector(state => state.sales.search.isSearching);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log('Opening...');
        setSelectedItems([]);
        if (categories == null || categories.length === 0) {
            dispatch(productsProductCategoriesGet());
        }
        if(selectedCategory == null){
            console.log('No Category selected...');
        }
    }, []);


    if (categories == null) {

    }

    const categoriesStyle = {
        width: 150,
        borderRight: '1px solid #dadada',
        ul: {
            listStyleType: 'none'
        }
    }

    const selectCategory = (category) => {
        setSelectedCategory(category);
        if (category && category.id !== null) {
            const data = {
                params: {
                    name: '',
                    category_id: category.id
                }
            }
            dispatch(salesProductSearch(data))
        } else {
            dispatch(salesProductSearch({param : {}})) ;
        }
    }
    const toggleSelectionModified = (item) => {
        const _selectedItems = [...selectedItems];
        const index = _selectedItems.findIndex(_item => _item.id == item.id);
        if (index > -1) {
            _selectedItems.splice(index, 1);
        } else {
            _selectedItems.push(item);
        }
        setSelectedItems(_selectedItems);
    }

    const selectionCompleted = () => {
        if (onSelectionCompleted !== undefined) {
            onSelectionCompleted(selectedItems);
        }
        setSelectedCategory(null);
    }

    const filterSearch = (field, value) => {
        if(value === '') {
            const _data = {
                params: {
                }
            }
            dispatch(salesProductSearch(_data));
            return;
        }
        if(value.length > 1) {
            const data = {
                params: {
                    name: value
                }
            }
            if(selectedCategory && selectedCategory.id){
                data.category_id =  selectedCategory.id ? selectedCategory.id : null
            }
            dispatch(salesProductSearch(data))
        }
    }

    return (
        <div>
            <div className={'product-selector d-flex flex-row align-items-stretch ' + styles.productSelector}>
                <div className={"d-none d-md-block"}>
                    <div className={styles.categories}>
                        <ul className={'productCategories ' + styles.listGroup}>
                            <li className={'item d-flex justify-content-between ' + ((selectedCategory === null) ? 'selected' : '')}
                                key={'x-all'}
                                onClick={() => selectCategory(null)}>
                                <div><span className={"fa fa-search-alt"}>&nbsp;</span> All</div>
                                <span>
                                                    {
                                                        (selectedCategory === null) ?
                                                            <span style={{
                                                                width: 10,
                                                                height: 20,
                                                                borderRadius: 5,
                                                                backgroundColor: "#eee",
                                                                padding: 2
                                                            }}>&nbsp;</span>
                                                            : ''
                                                    }
                                                </span>
                            </li>
                            {categories.filter(u => u).map((category, i) => (
                                <li className={'item d-flex justify-content-between ' + ((selectedCategory == category) ? 'selected' : '')}
                                    key={i}
                                    onClick={() => selectCategory(category)}>
                                    {category.name}
                                    <span>
                                                    {
                                                        (selectedCategory && category.id == selectedCategory.id) ?
                                                            <span style={{
                                                                width: 10,
                                                                height: 20,
                                                                borderRadius: 5,
                                                                backgroundColor: "#eee",
                                                                padding: 2
                                                            }}>&nbsp;</span>
                                                            : ''
                                                    }
                                                </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={'flex-grow-1 p-2'}>
                    <div className="row">
                        <div className="col-12 d-block d-md-none">
                            <div className={''} style={{margin: 0, padding: '.4rem', backgroundColor: "#f6f6f6"}}>
                                <ul className={styles.categoryChips}>
                                    <li className={'item d-flex justify-content-center text-center ' + ((selectedCategory === null) ? 'selected' : '')}
                                        key={'x-all'}
                                        onClick={() => selectCategory(null)}
                                        style={(selectedCategory === null) ? {borderColor: "#4C469C"} : {}}
                                    >
                                        All
                                    </li>
                                    {categories.filter(u => u).map((category, i) => (
                                        <li className={'item d-flex justify-content-center text-center ' + ((selectedCategory === category) ? 'selected' : '')}
                                            key={i}
                                            onClick={() => selectCategory(category)}
                                            style={(selectedCategory === category) ? {borderColor: "#4C469C"} : {}}
                                        >
                                            {category.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <hr className={"mt-1"}/>
                        </div>
                        <div className="col-12" style={{marginBottom: '1px solid #ddd'}}>
                            <div className={`d-flex flex-row mb-2 ${isSearching ? 'd-block' : ''}`}>
                                <div className="flex-grow-1 align-items-center">
                                    <CustomInput autocomplete={'off'} type="search" name={'item-search'} className={'input-sm'} onChange={filterSearch} placeholder={i18n.t('Search')} />
                                </div>
                                <div className="px-2">&nbsp;</div>
                                <div><CustomButton className={'btn-outline-primary'} label={"Add Selected"}
                                                   onClick={selectionCompleted}/></div>
                            </div>
                        </div>
                        {
                            (isSearching) ? <FullSpinner/> : ''
                        }
                        {
                            (isSearching) ? ''
                                :
                                products.filter(p => items.length > 0 ? !items.some(i => i && p && i.id == p.id) : true).map((p, i) => (
                                    <div key={i} className="col-6 col-md-4 col-lg-3 mb-3">
                                        <div
                                            className={`card ${styles.productCard} ${(selectedItems.findIndex(_item => _item.id == p.id) > -1) ? styles.selected : ''}`}
                                            onClick={() => toggleSelectionModified(p)}>
                                            <div className={"card-body"}>
                                                {
                                                    (showImages && p.product.pictures && p.product.pictures.length) ?
                                                        <img className={'img-fluid product-search-image'} src={p.product.pictures[0].image.path} alt=""/>
                                                        : ''
                                                }
                                                <div>

                                                </div>
                                                <div className={'title text-center w-100'}
                                                     style={{minHeight: "3rem"}}>{p.product.name}</div>
                                                <div className="small text-center">
                                                    <span className={'big'}
                                                          style={{fontSize: '1.2rem'}}>{p.quantity}</span>&nbsp;
                                                    <span
                                                        className={'small text-black-50'}>{p.product.unit ? p.product.unit.abbreviation : ''}</span>
                                                </div>
                                                <div className="flex-grow-0 text-center">
                                                    <strong><Currency value={p.selling_price}/></strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                        {
                            (!isSearching && products && products.length == 0) ?
                                <PageAlert title={t('No Results')}  /> : ''
                        }
                    </div>
                </div>
            </div>
            <div className="d-flex" style={{minHeight: '2rem', borderTop: '1px solid #ddd', padding: '.3rem'}}>
                {
                    selectedItems.filter(u => u).map((item, i) => (
                        <span key={'ii' + i} className="chip text-black-50 me-1">{item.product.name ?? 'Unnamed'}</span>
                    ))
                }
            </div>
        </div>
    )
}
ProductSelector.propTypes = {
    onSelectionCompleted: PropTypes.func
}
