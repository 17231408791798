import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
    productsProductPackagesCreate,
    productsProductPackagesGet,
    productsProductPackagesUpdate,
    productsProductsGet,
    productsProductUnitsGet
} from "../../../../../application/features/products";
import CreateUpdateForm from "../../../../components/form/createUpdateForm";
import CustomInput from "../../../../atomic/input";
import CustomSelectInput from "../../../../atomic/select";

const ProductPackageFormPage = () => {
    //state
    const [errorMessage, setErrorMessage] = useState();

    const { product_package_id } = useParams();
    const product_packages_resource = useSelector(state => state.products.product_packages);
    const products = useSelector(state => state.products.products.data);
    const product_units = useSelector(state => state.products.product_units.data);

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(productsProductPackagesGet());
        if(products == null || products.length == 0){
            dispatch(productsProductsGet());
        }
        if(product_units == null || product_units.length == 0){
            dispatch(productsProductUnitsGet());
        }
    }, []);


    return (
        <div>
            {errorMessage && <div className="alert alert-danger mb-2">{errorMessage}</div>}
            <CreateUpdateForm resource={product_packages_resource}  title={"Product Package"} item_id={product_package_id} createThunk={productsProductPackagesCreate} updateThunk={productsProductPackagesUpdate}>
                <CustomInput name='name' label='Package Name' placeholder='Package name'  required />
                <CustomSelectInput name="product_id" items={products} label={"Product"} />
                <CustomSelectInput name="unit_id" items={product_units} label={"Unit"} />
                <CustomInput name='unit_count' label='Unit Count' placeholder='Unit Count' type="number"  required />
            </CreateUpdateForm>
        </div>
    );
};

export default ProductPackageFormPage;
