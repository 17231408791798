import {ExcelIcon} from "../../../atomic/icons/excel";
import {PdfIcon} from "../../../atomic/icons/pdf";
import {Spinner} from "../../../atomic/spinner";
import * as PropTypes from "prop-types";
import {useState} from "react";
import {downloadFile} from "../../../../core/network";
import {useSelector} from "react-redux";

const ReportExporter = (props) => {
    const [downloadingExcel, setDownloadingExcel] = useState(false);
    const [downloadingPdf, setDownloadingPdf] = useState(false);

    const token = useSelector(state => state.authentication.token);

    const downloadExcel = () => {
        setDownloadingExcel(true);
        downloadFile(props.downloadUrl + "/excel", token, 'excel').then(() => setDownloadingExcel(false));
    }

    const downloadPdf = () => {
        setDownloadingPdf(true);
        downloadFile(props.downloadUrl + "/pdf", token, 'pdf').then(() => setDownloadingPdf(false));
    }

    return (
        <div className={'d-flex flex-row gap-1'}>
            <div>
                <button onClick={() => downloadExcel()} className={"btn p-0"}>{
                    (downloadingExcel) ? <Spinner/> :
                    <ExcelIcon color={"#777"}/>}</button>
            </div>
            <div>
                <button onClick={() => downloadPdf()} className={"btn p-0"}>{
                    (downloadingPdf) ? <Spinner/> :
                    <PdfIcon color={"#777"}/>}</button>
            </div>
        </div>
    )
}
ReportExporter.propTypes = {
    downloadUrl: PropTypes.string
};
export default ReportExporter;
