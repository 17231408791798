import {NavLink, Routes, Navigate} from "react-router-dom";
import {Route} from "use-react-router-breadcrumbs";
import React from "react";
import {SalesWindow} from "./sales-window/sales-window";
import {SalesListPage} from "./sales/sales-list-page";
import SaleViewPage from "./sales/sales-view-page";
import {useTranslation} from "react-i18next";
import {SalesEdit} from "./sales/sales-edit";
import {TopNavigation} from "../../../components/app/top-navigation/top-navigation";

export const SalesRoutes = () => {
    const {i18n, t} = useTranslation();
    const topLinks = [
        {
            title: t('Sales Window'),
            to: 'window'
        },
        {
            title: t('Sales'),
            to: 'sales'
        },
    ];
    return (
        <div>
            <h3>{i18n.t('Sales')}</h3>
            <TopNavigation links={topLinks} basePathLevel={3} />
            <Routes>
                <Route path="window" element={<SalesWindow />}/>
                <Route path="sales/*">
                    <Route path=":sale_id" element={<SaleViewPage />}/>
                    <Route path="form/:sale_id" element={<SalesEdit />}/>
                    <Route index element={<SalesListPage />}/>
                </Route>
                <Route index element={<Navigate to="window"/>}/>
            </Routes>
        </div>
    )
}
