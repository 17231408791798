import React from 'react';
import PropTypes from 'prop-types';

const IconView = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={props.className} height={props.height}
             width={props.width}>
            <g>
                <path fill={props.fill} d="M12 16c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4 -1.8 4-4 4Zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2 -.9-2-2-2Z"/>
                <path fill={props.fill} d="M12 20C4.5 20 .3 12.8.1 12.5c-.2-.3-.2-.7 0-1C.3 11.2 4.5 4 12 4c7.5 0 11.7 7.2 11.9 7.5 .2.3.2.7 0 1 -.2.3-4.4 7.5-11.9 7.5Zm-9.8-8c1 1.5 4.5 6 9.8 6 5.3 0 8.8-4.5 9.8-6 -1-1.5-4.5-6-9.8-6 -5.3 0-8.8 4.5-9.8 6Z"/>
            </g>
        </svg>

    )
}

IconView.defaltProps = {
    fill: 'white',
}

IconView.propTypes = {
    fill: PropTypes.string,
    className: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
}

export default IconView;
