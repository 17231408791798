import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import CustomConfirmAlert from "../../../../components/notifications/confirm-alert";
import {inventoryStoresDelete, inventoryStoresGet} from "../../../../../application/features/inventory";
import {AddButton} from '../../../../components/buttons/add-button';
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import StoresResource from "../../../../../application/resources/stores";

const StoresListPage = () => {
    // satate
    const [showAlert, setShowAlert] = useState(false);
    const [deletingId, setDeletingId] = useState(null);

    //redux
    const dispatch = useDispatch();
    const stores = useSelector(state => state.inventory.stores.data);

    //effects
    useEffect(() => {
        if (stores && stores.length) return;
        dispatch(inventoryStoresGet());
    }, []);

    //handlers
    const handleDelete = () => {
        if(deletingId) {
            const data = {id: deletingId};
            dispatch(inventoryStoresDelete(data));
            setDeletingId(null);
            setShowAlert(false);
        }
    }

    return (
        <div>
            <div className='row text-end'>
                {/* <div className="col">
                    <Link to="form" className='btn btn-outline-primary'>Add Store</Link>
                </div> */}
            </div>

            <div className="row mt-2">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="d-flex align-items-center">
                                <span className="card-title flex-grow-1">Stores/Warehouses</span>
                                <AddButton permissions={['Create Stores']} to="form" title="Add Store" />
                            </div>
                        </div>
                        <div className="card-body">

                            <GalaxyResourceTable deletable={(row) => (row.id > 1)} resource={new StoresResource()}/>
                        </div>
                    </div>
                </div>
            </div>
            <CustomConfirmAlert isShown={showAlert} title="Delete Unit?" text="Are you sure you want to delete the store?" onConfirm={handleDelete} toggleAlert={setShowAlert} />
        </div>
    );
};

export default StoresListPage;
