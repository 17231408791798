import BizManagerApi from "../../api/bizmanager";
import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import DateFormat from "../../presentation/atomic/formatting/date-format";
import {
    inventoryStoresCreate,
    inventoryStoresDelete,
    inventoryStoresGet, inventoryStoresRetrieve,
    inventoryStoresUpdate
} from "../features/inventory";
import i18n from "i18next";

/**
 * @inheritDoc
 */
export class  StoresResource extends GalaxyResource {
    resourceActions = {
        fetch: inventoryStoresGet,
        retrieve: inventoryStoresRetrieve(),
        create: inventoryStoresCreate,
        update: inventoryStoresUpdate(),
        delete: inventoryStoresDelete,
    };
    stateName = 'inventory.stores';
    columns = [
        {
            name: i18n.t('Name'),
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: i18n.t('Location'),
            cell: (row) => <span>{row.location ?? ''}</span>,
            sortable: true,
        },
        {
            name: i18n.t('Active'),
            cell: (row) => <span className={`badge bg-${row.active ? 'success' : 'danger'}`}>{row.active ? 'YES' : 'NO'}</span>,
            sortable: true,
        },
    ];
    permissions = {
        view: ['View Inventory'],
        create: ['Create Inventory'],
        update: ['Create Inventory'],
        delete: ['Delete Inventory'],
    }
}

export default StoresResource;
