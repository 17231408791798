import React from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import CreateUpdateForm from "../../../../components/form/createUpdateForm";
import {usersUserCreate, usersUserUpdate} from "../../../../../application/features/users";
import CustomInput from "../../../../atomic/input";

const UserFormPage = () => {
    //state
    //hooks
    const { user_id } = useParams();

    //redux
    const dispatch = useDispatch();

    const users_resource = useSelector(state => state.users.users);

    //effects
    useEffect(() => {

    }, [])

    return (
        <div>
            <CreateUpdateForm resource={users_resource}  title={"Product Unit"} item_id={user_id} createThunk={usersUserCreate} updateThunk={usersUserUpdate}>
                <CustomInput type="name" name='name' label='User Name' placeholder='User name' />
                <div className="mb-2"></div>
                <CustomInput type="email" name='email' label='User Email Address' placeholder='Email address' />
                <div className="mb-2"></div>
                <CustomInput type="password" name='password' label='Password' placeholder='Create password for the user' />
                <div className="mb-2"></div>
                <CustomInput name='phone' label='User Phone Number' placeholder='Phone number' />
                <div className="mb-2"></div>
            </CreateUpdateForm>
        </div>
    );
};

export default UserFormPage;
