@import '$assets/scss/main.scss';

body {
    background-color: #E5E5E5 !important;
}

.toolbar {
    background-color: white;
    height: 64px;
    display: flex;
    align-items: center;

    .slot {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .steps {
        display: flex;
        justify-content: center;
        gap: 5px;
        flex: 1;

        .step {
            width: 75px;
            height: 4px;
            background-color: #D3D6E4;
            border-radius: 100;

            &.active {
                background-color: $primary-color;
            }
        }
    }
}

.header {
    margin: 50px auto;
    max-width: 786px;
    text-align: center;
}

.content {
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-content: center;
    gap: 10px;
    @media all and (max-width: 767px) {
        grid-template-columns: auto auto;
    }
    @media all and (min-width: 768px) and (max-width: 1100px) {
        grid-template-columns: auto auto auto;
    }

    .card {
        position: relative;
        height: 180px;
        width: 180px;
        background: #FFFFFF;
        /* Primary Color/#1000 */
        border: 2px solid rgba($primary-color, 0);
        box-shadow: 0px 20px 44px rgba(229, 229, 229, 0.25);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in;

        &.active {
            border-color: rgba($primary-color, 1);
            transition: all 0.3s ease-out;
        }

        img {
            height: 63px;
            width: 63px;
            object-fit: contain;
        }

        p {
            font-weight: 600;
            font-size: 18px;
            line-height: 32px;
            /* identical to box height, or 133% */
            text-align: center;

            /* Base Color /Dark #1000 */
            color: #1E2034;
        }
    }
}

.actions {
    margin: 50px 0;
    display: flex;
    justify-content: center;
}