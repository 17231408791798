import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import CustomSelectInput from '../../../../../atomic/select';
import CustomInput from '../../../../../atomic/input';
import {inventoryItemsCreate, inventoryItemsUpdate} from '../../../../../../application/features/inventory';
import {productsProductsGet} from "../../../../../../application/features/products";
import {CustomQuantitySpinner} from "../../../../../atomic/custom-quantity-spinner";
import CreateUpdateForm from "../../../../../components/form/createUpdateForm";
import {t} from "i18next";


export const InventoryItemFormPage = () => {
    //hooks
    const [selectedProduct, setSelectedProduct] = useState(null);
    const {inventory_item_id} = useParams();
    const dispatch = useDispatch();
    //redux
    const inventory_item = useSelector(state => state.inventory.item);
    const products = useSelector(state => state.products.products.data);

    useEffect(() => {
        console.log(products);
        if (products == null || products.length == 0) {
            console.log('Dispatching');
            dispatch(productsProductsGet());
        }
    }, []);

    const handleFieldChanged = (name, value) => {
        if (name == 'product_id') {
            console.log('Product Changed');
            setSelectedProduct(products.find(u => (u.id == value)));
        }
        if(name == 'buying_price') {
            console.log(value);
        }
    }
    return (
        <div>
            <CreateUpdateForm resource={inventory_item} item_id={inventory_item_id} title="Stock Item"
                              createThunk={inventoryItemsCreate}
                              updateThunk={inventoryItemsUpdate}
                              onFieldChange={handleFieldChanged}
            >
                <CustomSelectInput name="product_id" label="Product" items={products}/>
                {/*<CustomInput name="quantity" label='Quantity' />*/}
                <CustomQuantitySpinner name="quantity" label='Quantity' product={selectedProduct}/>
                <CustomInput isCurrency={true} name="buying_price" label={t('Buying Price') + ` (per ${(selectedProduct && selectedProduct.unit ? selectedProduct.unit.name : 'unit')})`}/>
                <CustomInput isCurrency={true} name="selling_price" label={t('Selling Price') + ` (per ${(selectedProduct && selectedProduct.unit ? selectedProduct.unit.name : 'unit')})`}/>
            </CreateUpdateForm>
        </div>
    )
}
