import BizManagerApi from "../../api/bizmanager";
import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import DateFormat from "../../presentation/atomic/formatting/date-format";
import {
    inventoryStoresCreate,
    inventoryStoresDelete,
    inventoryStoresGet, inventoryStoresRetrieve,
    inventoryStoresUpdate
} from "../features/inventory";
import {
    productsProductCategoriesCreate, productsProductCategoriesDelete,
    productsProductCategoriesGet, productsProductCategoriesRetrieve, productsProductCategoriesUpdate,
    productsProductUnitsCreate, productsProductUnitsDelete,
    productsProductUnitsGet,
    productsProductUnitsRetrieve,
    productsProductUnitsUpdate
} from "../features/products";
import i18n from "i18next";

/**
 * @inheritDoc
 */
export class  ProductCategoriesResource extends GalaxyResource {
    resourceActions = {
        fetch: productsProductCategoriesGet,
        retrieve: productsProductCategoriesRetrieve,
        create: productsProductCategoriesCreate,
        update: productsProductCategoriesUpdate,
        delete: productsProductCategoriesDelete,
    };
    stateName = 'products.product_categories';
    columns = [
        {
            name: '#',
            center: false,
            width: '48px',
            cell: (row, index) => (index + 1),
        },
        {
            name: i18n.t('Name'),
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: i18n.t('Parent'),
            cell: (row) => <span>{row.parent_category ? row.parent_category.name : ''}</span>,
            sortable: true,
        }
    ];
    permissions = {
        view: ['View Products'],
        create: ['Create Products'],
        update: ['Create Products'],
        delete: ['Delete Products'],
    }
}

export default ProductCategoriesResource;
