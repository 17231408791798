
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { customFetch } from '../../core/network';
import BizManagerApi from '../../api/bizmanager';
import { generateResourceExtraReducers, resourceState, restResourceInitialState } from '../../core/data-structures';

//action contants
const INVENTORY_ITEMS_CREATE = 'inventory/items/CREATE_ITEM';
const INVENTORY_ITEMS_GET = 'inventory/items/GET_ITEMS';
const INVENTORY_ITEMS_RETRIEVE = 'inventory/items/RETRIEVE_ITEM';
const INVENTORY_ITEMS_UPDATE = 'inventory/items/UPDATE_ITEM';
const INVENTORY_ITEMS_DELETE = 'inventory/items/DELETE_ITEM';

const INVENTORY_DISPOSALS_CREATE = 'inventory/disposals/CREATE_DISPOSAL';
const INVENTORY_DISPOSALS_GET = 'inventory/disposals/GET_DISPOSALS';
const INVENTORY_DISPOSALS_RETRIEVE = 'inventory/disposals/RETRIEVE_DISPOSAL';
const INVENTORY_DISPOSALS_UPDATE = 'inventory/disposals/UPDATE_DISPOSAL';
const INVENTORY_DISPOSALS_DELETE = 'inventory/disposals/DELETE_DISPOSAL';

const INVENTORY_STORES_CREATE = 'inventory/stores/CREATE_STORE';
const INVENTORY_STORES_GET = 'inventory/stores/GET_STORES';
const INVENTORY_STORES_RETRIEVE = 'inventory/stores/RETRIEVE_STORE';
const INVENTORY_STORES_UPDATE = 'inventory/stores/UPDATE_STORE';
const INVENTORY_STORES_DELETE = 'inventory/stores/DELETE_STORE';

const INVENTORY_ADDONS_CREATE = 'inventory/addons/CREATE_ADDON';
const INVENTORY_ADDONS_GET = 'inventory/addons/GET_ADDONS';
const INVENTORY_ADDONS_RETRIEVE = 'inventory/addons/RETRIEVE_ADDON';
const INVENTORY_ADDONS_UPDATE = 'inventory/addons/UPDATE_ADDON';
const INVENTORY_ADDONS_DELETE = 'inventory/addons/DELETE_ADDON';

const INVENTORY_OFFERS_CREATE = 'inventory/offers/CREATE_OFFER';
const INVENTORY_OFFERS_GET = 'inventory/offers/GET_OFFERS';
const INVENTORY_OFFERS_RETRIEVE = 'inventory/offers/RETRIEVE_OFFER';
const INVENTORY_OFFERS_UPDATE = 'inventory/offers/UPDATE_OFFER';
const INVENTORY_OFFERS_DELETE = 'inventory/offers/DELETE_OFFER';

const INVENTORY_DISPOSE_STOCK = 'inventory/disposal/DISPOSE_STOCK';


//actions
export const inventoryItemsCreate = createAsyncThunk(
    INVENTORY_ITEMS_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.inventoryItems, data.payload, token, store);
    }
);

export const inventoryItemsGet = createAsyncThunk(
    INVENTORY_ITEMS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.inventoryItems, null, token, store);
    }
);

export const inventoryItemsRetrieve = createAsyncThunk(
    INVENTORY_ITEMS_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.inventoryItem(data.id), null, token, store);
    }
);

export const inventoryItemsUpdate = createAsyncThunk(
    INVENTORY_ITEMS_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.inventoryItem(data.id), data.payload, token, store);
    }
);

export const inventoryItemsDelete = createAsyncThunk(
    INVENTORY_ITEMS_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.inventoryItem(data.id), null, token, store);
    }
);


//Disposals Actions
export const inventoryDisposalsCreate = createAsyncThunk(
    INVENTORY_DISPOSALS_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.inventoryDisposals, data.payload, token, store);
    }
);

export const inventoryDisposalsGet = createAsyncThunk(
    INVENTORY_DISPOSALS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.inventoryDisposals, null, token, store);
    }
);

export const inventoryDisposalsRetrieve = createAsyncThunk(
    INVENTORY_DISPOSALS_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.inventoryDisposal(data.id), null, token, store);
    }
);

export const inventoryDisposalsUpdate = createAsyncThunk(
    INVENTORY_DISPOSALS_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.inventoryDisposal(data.id), data.payload, token, store);
    }
);

export const inventoryDisposalsDelete = createAsyncThunk(
    INVENTORY_DISPOSALS_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.inventoryDisposal(data.id), null, token, store);
    }
);



//Sores Actions
export const inventoryStoresCreate = createAsyncThunk(
    INVENTORY_STORES_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.inventoryStores, data.payload, token, store);
    }
);

export const inventoryStoresGet = createAsyncThunk(
    INVENTORY_STORES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.inventoryStores, null, token, store);
    }
);

export const inventoryStoresRetrieve = createAsyncThunk(
    INVENTORY_STORES_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.inventoryStore(data.id), null, token, store);
    }
);

export const inventoryStoresUpdate = createAsyncThunk(
    INVENTORY_STORES_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.inventoryStore(data.id), data.payload, token, store);
    }
);

export const inventoryStoresDelete = createAsyncThunk(
    INVENTORY_STORES_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.inventoryStore(data.id), null, token, store);
    }
);



//Addons Actions
export const inventoryAddonsCreate = createAsyncThunk(
    INVENTORY_ADDONS_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.inventoryAddons, data.payload, token, store);
    }
);

export const inventoryAddonsGet = createAsyncThunk(
    INVENTORY_ADDONS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.inventoryAddons, null, token, store);
    }
);

export const inventoryAddonsRetrieve = createAsyncThunk(
    INVENTORY_ADDONS_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.inventoryAddon(data.id), null, token, store);
    }
);

export const inventoryAddonsUpdate = createAsyncThunk(
    INVENTORY_ADDONS_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.inventoryAddon(data.id), data.payload, token, store);
    }
);

export const inventoryAddonsDelete = createAsyncThunk(
    INVENTORY_ADDONS_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.inventoryAddon(data.id), null, token, store);
    }
);


//OFFERS Actions
export const inventoryOffersCreate = createAsyncThunk(
    INVENTORY_OFFERS_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.inventoryOffers, data.payload, token, store);
    }
);

export const inventoryOffersGet = createAsyncThunk(
    INVENTORY_OFFERS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.inventoryOffers, null, token, store);
    }
);

export const inventoryOffersRetrieve = createAsyncThunk(
    INVENTORY_OFFERS_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.inventoryOffer(data.id), null, token, store);
    }
);

export const inventoryOffersUpdate = createAsyncThunk(
    INVENTORY_OFFERS_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.inventoryOffer(data.id), data.payload, token, store);
    }
);

export const inventoryOffersDelete = createAsyncThunk(
    INVENTORY_OFFERS_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.inventoryOffer(data.id), null, token, store);
    }
);

export const inventoryDisposeStock = createAsyncThunk(
    INVENTORY_DISPOSE_STOCK,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.inventoryDisposeStock, data, token, store);
    }
);


const itemResourceThunks = {
    create: inventoryItemsCreate,
    get: inventoryItemsGet,
    retrieve: inventoryItemsRetrieve,
    update: inventoryItemsUpdate,
    delete: inventoryItemsDelete,
}
const inventoryDisposalsResourceThunks = {
    create: inventoryDisposalsCreate,
    get: inventoryDisposalsGet,
    retrieve: inventoryDisposalsRetrieve,
    update: inventoryDisposalsUpdate,
    delete: inventoryDisposalsDelete,
}
const inventoryStoresResourceThunks = {
    create: inventoryStoresCreate,
    get: inventoryStoresGet,
    retrieve: inventoryStoresRetrieve,
    update: inventoryStoresUpdate,
    delete: inventoryStoresDelete,
}
const inventoryAddonsResourceThunks = {
    create: inventoryAddonsCreate,
    get: inventoryAddonsGet,
    retrieve: inventoryAddonsRetrieve,
    update: inventoryAddonsUpdate,
    delete: inventoryAddonsDelete,
}

//initial state
const initialState = {
    item: restResourceInitialState,
    disposals: restResourceInitialState,
    stores: restResourceInitialState,
    addons: restResourceInitialState,
    offers: restResourceInitialState,
    stock_disposal: {
        payload: {
            'date': null,
            'description': null,
            items: [],
        },
        isLoading: false,
        error: null,
        isSuccessful: false
    },
}

//redux toolkit slice
const inventorySlice = createSlice({
    'name': 'inventory',
    initialState,
    reducers: {},
    extraReducers: {
        ...generateResourceExtraReducers(itemResourceThunks, "item"),
        ...generateResourceExtraReducers(inventoryDisposalsResourceThunks, "disposals"),
        ...generateResourceExtraReducers(inventoryStoresResourceThunks, "stores"),
        ...generateResourceExtraReducers(inventoryAddonsResourceThunks, "addons"),
        [inventoryDisposeStock.pending]: (state, action) => {
            state.stock_disposal.isLoading = true;
            state.stock_disposal.error = null;
            state.stock_disposal.isSuccessful = false;
        },
        [inventoryDisposeStock.fulfilled]: (state, action) => {
            state.stock_disposal.isLoading = false;
            state.stock_disposal.isSuccessful = true;
        },
        [inventoryDisposeStock.rejected]:(state, action) => {
            state.stock_disposal.isLoading = false;
            state.stock_disposal.error = action.error;
        }
    },
});

//exports
export default inventorySlice.reducer;
