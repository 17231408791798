import React, {useEffect, useState} from 'react'

import { PropTypes } from 'prop-types';
import Select from "react-select";
import styles from './input-style.module.scss';
import Creatable, { useCreatable } from 'react-select/creatable';

const CustomSelectInput = (props) => {
    //props
    const { label, name, onChange, value, items, error } = props;
    const selectOptions = items.map(item => { return {value: item.id ?? '', label: item.name ?? item.label ?? ''};});

    useEffect(() => {

    }, [value]);

    //handlers
    const handleChange = (key, val) => {
        if (props.onChange) {
            props.onChange(key, val);
        }
    }
    const selectProps = {
        name: name,
        options: selectOptions,
        onChange: (val) => handleChange(name, val.value ?? ''),
    };
    if(value != null) {
        selectProps.value = selectOptions.find((item) => item.value.toString() == value.toString());
    }
    return (
        <div className={styles.inputGroup}>
            {label && <label htmlFor={name} className="form-label">{label}</label>}
            <Select styles={customStyles}  {...selectProps} />
            <div className={(error != null) ? 'invalid-feedback d-block' : 'valid-feedback d-none'}>{error}</div>
        </div>
    )
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        boxSizing: "border-box",
        /* Auto layout */
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 16px",
        gap: "301px",
        height: "44px",
        /* Primary Color/#100 */
        border: "1px solid #EDEDF5",
        borderRadius: "8px",
        /* Inside auto layout */
        flex: "none",
        order: 1,
        flexGrow: 0,
    }),
    placeholder: (provided, state) => ({
        ...provided,
        /* Body Text 14 px/H2 */
        fontFamily: 'Gilroy',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color:  "#A5A6AE",
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
}

CustomSelectInput.defaultProps = {
    type: 'text',
    items: [],
}

CustomSelectInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any, // We have to allow anything to be key
        name: PropTypes.string
      })
    ),  
    value: PropTypes.any,
    error: PropTypes.string
}

export default CustomSelectInput;
