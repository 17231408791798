import {ViewButton} from "../../buttons/view-button";
import {EditButton} from "../../buttons/edit-button";
import {DeleteButton} from "../../buttons/delete-button";
import {CustomSmartTable} from "../../table";
import React, {useEffect, useState} from "react";
import * as PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "../../../atomic/spinner";
import {GalaxyResource} from "../../../../application/resources/galaxy-resource";
import CustomConfirmAlert from "../../notifications/confirm-alert";

export const GalaxyResourceTable = (props) => {
    const [deletingId, setDeletingId] = useState(null);
    const [booting, setBooting] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState({title: "Delete Item", text: 'Are you sure you want to delete this item?'});

    const dispatch = useDispatch();

    const userPermissions = useSelector(state => state.authentication.user.rolePermissions);

    const data = useSelector(state => props.resource.selector(state, 'data'));
    const links = useSelector(state => props.resource.selector(state, 'links'));
    const meta = useSelector(state => props.resource.selector(state, 'meta'));

    const isFetching = useSelector(state => props.resource.selector(state, 'isFetching'));
    const fetchingSuccessful = useSelector(state => props.resource.selector(state, 'fetchingSuccessful'));
    const fetchingError = useSelector(state => props.resource.selector(state, 'fetchingError'));

    const isUpdating = useSelector(state => props.resource.selector(state, 'isUpdating'));
    const updatingSuccessful = useSelector(state => props.resource.selector(state, 'updatingSuccessful'));
    const updatingError = useSelector(state => props.resource.selector(state, 'updatingError'));

    const isRetrieving = useSelector(state => props.resource.selector(state, 'isRetrieving'));
    const retrievingSuccessful = useSelector(state => props.resource.selector(state, 'retrievingSuccessful'));
    const retrievingError = useSelector(state => props.resource.selector(state, 'retrievingError'));

    const isDeleting = useSelector(state => props.resource.selector(state, 'isDeleting'));
    const deletingSuccessful = useSelector(state => props.resource.selector(state, 'deletingSuccessful'));
    const deletingError = useSelector(state => props.resource.selector(state, 'deletingError'));

    const validationErrors = useSelector(state => props.resource.selector(state, 'validationErrors'));

    const resource = props.resource;
    const canView = () => props.resource.can('view', userPermissions);
    const canEdit = () => props.resource.can('update', userPermissions);
    const canDelete = () => props.resource.can('delete', userPermissions);


    //// EFFECTS
    useEffect(() => {
        if (booting) {
            if (props.resource.resourceActions.fetch != null) {
                dispatch(props.resource.resourceActions.fetch());
                setBooting(false);
            }
        }

    }, []);

    // Fetching
    useEffect(() => {
    }, [fetchingSuccessful]);

    useEffect(() => {
        if (data != null) {
        }
    }, [data]);

    // Deleting
    useEffect(() => {
        if(deletingSuccessful) {
        }
    }, [deletingSuccessful]);


    const isDeletable = (item) => {
        if (props.deletable != null) {
            return props.deletable(item);
        }
        return true;
    }
    const isEditable = (item) => {
        if (props.editable != null) {
            return props.editable(item);
        }
        return true;
    }

    const markForDelete = (id) => {
        setDeletingId(id);
        setShowAlert(true);
    }

    const handleDelete = (row) => {
        if (props.beforeDelete) {
            props.beforeDelete(row);
        }
        if (deletingId) {
            const data = {id: deletingId};
            dispatch(props.resource.delete(data));
            setDeletingId(null);
            setShowAlert(false);
        }
    }


    const paginateTo = (page) => {
        dispatch(props.resource.fetch({page: page}));
    }
    if (isFetching) {
        return <div className={"d-flex flex-row justify-content-center"}>
            <Spinner/>
        </div>
    }

    const pages = (parseInt(meta.last_page) > 1) ? Object.keys([...Array(parseInt(meta.last_page))]).map(u => parseInt(u) + 1) : [];
    let beforePages = [];
    let afterPages = [];
    if(pages.length > 1){
        const eachSide = 3;
        const currentPage = meta.current_page;
         const beforeCurrent = (pages.length >= meta.current_page) ? pages.slice(0, currentPage - 1) : [];
         const afterCurrent = (pages.length > meta.current_page) ? pages.slice(currentPage ) : [];
          beforePages = beforeCurrent.slice(beforeCurrent.length - 1 - eachSide);
          afterPages = afterCurrent.slice(0, eachSide);
    }

    return (
        <>
            <CustomSmartTable
                columns={[
                    ...props.resource.columns,
                    {
                        cell: (row) => (
                            <div className="d-flex" role="group" aria-label="Button group with nested dropdown">
                                {(canView()) && <><ViewButton to={`${row.id}`} alt={"View"}/>
                                    <div className="px-1"></div>
                                </>}
                                {( isEditable(row) && canEdit()) && <><EditButton to={`form/${row.id}`} alt={"Edit"}/>
                                    <div className="px-1"></div>
                                </>}
                                {(isDeletable(row) && canDelete()) &&
                                    (
                                        (isDeleting && deletingId == row.id) ? (<Spinner className={'small'} />)
                                            : <DeleteButton isDeleting={isDeleting && deletingId == row.id} onClick={() => markForDelete(row.id)}/>
                                    )
                                }
                            </div>
                        ),
                        allowOverflow: true,
                        button: true,
                        width: "100px",
                        name: "Actions"
                    },
                ]}
                data={data}
            />

            {(links != null && meta != null && (links.next != null || links.prev != null)) &&
                <div>
                    <nav aria-label="Pagination"
                         className={"w-100 d-flex flex-row justify-content-between align-items-center mt-2"}>
                        <div className={"small text-muted"}>Showing {meta.from} to {meta.to} of {meta.total}</div>
                        <ul className="pagination pagination-sm">
                            <li className={(meta.current_page == 1) ? "page-item  disabled" : "page-item"}><a
                                className="page-link" onClick={() => paginateTo(meta.current_page - 1)}>Previous</a>
                            </li>
                            {
                                (beforePages.length && beforePages[0] != 1) ?
                                    <li className={(meta.current_page == 1) ? "page-item  disabled" : "page-item"}><a className="page-link">...</a></li>
                                    : ''
                            }
                            {
                                beforePages.map((item, i) => (
                                    <li className={"page-item"}><a
                                        className="page-link" href="#" onClick={() => paginateTo(item)}>{item}</a></li>
                                ))
                            }
                            <li className={"page-item  active"}><a
                                className="page-link" href="#" onClick={() => paginateTo(meta.current_page)}>{meta.current_page}</a></li>
                            {
                                afterPages.filter(u => parseInt(u)).map((item, i) => (
                                    <li className={"page-item"}><a
                                        className="page-link" href="#" onClick={() => paginateTo((item))}>{(item)}</a></li>
                                ))
                            }

                            {
                                (afterPages.length && afterPages[afterPages.length - 1] != meta.last_page) ?
                                    <li className={(meta.current_page == 1) ? "page-item  disabled" : "page-item"}><a className="page-link">...</a></li>
                                    : ''
                            }

                            {/*<li className={(meta.last_page == meta.current_page) ? "page-item  active" : "page-item"}><a*/}
                            {/*    className="page-link" href="#"*/}
                            {/*    onClick={() => paginateTo(meta.last_page)}>{meta.last_page}</a></li>*/}
                            <li className={(meta.current_page == meta.last_page) ? "page-item  disabled" : "page-item"}>
                                <a className="page-link" onClick={() => paginateTo(meta.current_page + 1)}>Next</a></li>
                        </ul>
                    </nav>
                </div>
            }
            <CustomConfirmAlert
                isShown={showAlert}
                title={alertData.title}
                text={alertData.text}
                onConfirm={handleDelete}
                toggleAlert={setShowAlert}
            />
        </>
    )
}
GalaxyResourceTable.propTypes = {
    resource: PropTypes.instanceOf(GalaxyResource),
    deletable: PropTypes.func,
    editable: PropTypes.func,
    beforeDelete: PropTypes.func
};
