import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";

function CustomConfirmAlert({isShown, toggleAlert,onConfirm, title, text, confirmBtnText}) {
    return (
        (isShown) ? <SweetAlert
            warning
            showCancel
            confirmBtnText={confirmBtnText ?? "Yes"}
            confirmBtnBsStyle="danger"
            title={title ?? ''}
            onConfirm={() => {onConfirm()}}
            onCancel={() => {toggleAlert(false)}}
            focusCancelBtn
        >
            {text ?? ''}
        </SweetAlert> : ''
    );
}

export default CustomConfirmAlert;