import React, {useState} from 'react';
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {productsProductsGet} from "../../../../../../application/features/products";
import CustomInput from "../../../../../atomic/input";
import CustomSelectInput from "../../../../../atomic/select";
import Currency from "../../../../../atomic/formatting/currency";
import CustomTextArea from "../../../../../atomic/text-area";

const PurchaseItemForm = (props) => {
    const { item, onSave } = props;

    const [payload, setPayload] = useState({name: null, description: null, product_id: null, quantity: null, unit_amount: null, selling_price: null});
    const [selectedProduct, setSelectedProduct] = useState();
    const [errors, setErrors] = useState({});


    const dispatch = useDispatch();
    const products = useSelector(state => state.products.products.data);


    useEffect(() => {
        if(products == null || products.length == 0) {
            console.log('Should load products...');
            dispatch(productsProductsGet());
        }
    }, []);


    useEffect(() => {
        if (!selectedProduct) return;
        setPayload({...payload, "name": selectedProduct.name});
    }, [selectedProduct])

    const handleChange = (key, value) => {
      const payloadClone = {...payload};
      payloadClone[key] = value;
      setPayload({...payloadClone});
      if(key === "product_id") {
          console.log('Updating');
          setSelectedProduct(products.find((p) => p.id == value));
          console.log(selectedProduct);
      }
    }

    const resetForm = () => {
        console.log('Reseting form...');
      setPayload({name: null, description: null, product_id: null, quantity: null, unit_amount: null, selling_price: null});
    }

    const validateForm = () => {
        setErrors({});
        if(!payload.name || payload.name.length < 1){
            const err = {...errors};
            err['name'] = "Please enter a valid name";
            setErrors(err);
        }
        console.log(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSave = () => {
        console.log("Validating...");
        if(validateForm()) {
            console.log("Saving...");
            if(onSave) {
                onSave(payload)
            }
        } else {
            console.log("Validation failed")
        }

    }


    return (
        <div>
            {/*<div className="">*/}
            {/*    <button className={'btn btn-outline-primary btn-sm float-end'} onClick={resetForm}>Clear Form</button>*/}
            {/*</div>*/}
            <div className="row">
                <div className="col-12 mb-2">
                    <CustomSelectInput name="product_id" label="Product" items={products} onChange={handleChange}/>
                </div>
                <div className="col-12 mb-2">
                    <CustomInput name={"name"} placeholder={"Item Name"} label={"Item Name"} type={"text"} onChange={handleChange} value={payload.name} error={errors.name ?? null}  />
                </div>
                <div className="ol-12 mb-2">
                    <CustomTextArea name="description" placeholder={"Description"} label={"Description"} type={"text"} onChange={handleChange} />
                </div>
                <div className="col-6 mb-2">
                    <CustomInput name="quantity" label="Quantity" type={'number'} onChange={handleChange} />
                </div>
                <div className="col-6 mb-2">
                    <CustomInput name="unit_amount" label="Unit Buying Price" type={'number'} onChange={handleChange} />
                </div>
                <div className="col-12 mb-2">
                    <CustomInput name="selling_price" label="Unit Selling Price" type={'number'} onChange={handleChange} />
                </div>
                <div className="d-flex col-8 mb-2 align-content-center">
                    Total
                </div>
                <div className="col-4  mb-2">
                    <div className="form-control text-end text-bold"><Currency value={(payload.unit_amount ?? 0) * (payload.quantity ?? 0)} /></div>
                </div>
            </div>
            <div className="d-flex flex-row align-content-end">
                <button onClick={handleSave} className={"btn btn-primary"}>Add Item</button>

                {/*<span className={'float-end'}><a onClick={resetForm}>Reset</a></span>*/}
            </div>
        </div>
    );
};


export default PurchaseItemForm;
