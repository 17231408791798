import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {productsProductUnitsGet,} from "../../../../../application/features/products";
import {AddButton} from '../../../../components/buttons/add-button';
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import UnitsResource from "../../../../../application/resources/units";

const columns = [
    {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true
    },
    {
        name: 'Abbreviation',
        selector: (row) => row.abbreviation,
        sortable: true
    },
];

export const ProductUnitsListPage = () => {
    // satate
    //redux
    const dispatch = useDispatch();
    const units = useSelector((state) => state.products.product_units.data);

    //effects
    useEffect(() => {
        if (units && units.length) return;
        dispatch(productsProductUnitsGet());
    }, []);


    return (
        <div className="row mt-3">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-center">
                            <span className="card-title flex-grow-1">List of Units</span>
                            <AddButton permissions={['Create Products']} to="form" title={"Add a new Unit"}/>
                        </div>
                    </div>
                    <div className="card-body">
                        <GalaxyResourceTable deletable={(row) => false} resource={new UnitsResource()}/>
                    </div>
                </div>
            </div>
        </div>

    );
};
