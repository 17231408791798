import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import Currency from "../../../../atomic/formatting/currency";

const PurchaseViewPage = () => {

    const{ purchase_id } = useParams();

    // redux
    const purchase = useSelector(state => state.purchases.purchases.data.find(e => e.id == purchase_id))


    useEffect(() => {
        // setPurchase(purchases.find(e => e.id === purchase_id));
    }, []);

    if(!purchase) {
        return "Not found";
    }


    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">&nbsp;</div>
                    <div className="card-body">
                        <div className="mb-3">
                            <table className="table table-bordered table-sm">
                                <tbody>
                                <tr>
                                    <th width="30%">User:</th><td></td>
                                </tr><tr>
                                    <th>Purchase Date:</th><td>{purchase.date}</td>
                                </tr><tr>
                                    <th>Description:</th><td>{purchase.description}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div></div>
                            <div></div>
                        </div>
                        <table className='table table-bordered table-sm'>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Unit Amount</th>
                                <th>Amount</th>
                            </tr>

                            </thead>
                            <tbody>
                            {purchase.items.filter(u => u).map((item, i) => (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.unit_amount}</td>
                                    <td className="text-end"><Currency value={item.amount} /></td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th colSpan="5"> Total</th>
                                <th className="text-end"><Currency value={purchase.total} /></th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseViewPage;
