import React from 'react';
import { Provider } from 'react-redux';

import 'regenerator-runtime/runtime'

import { I18nextProvider } from 'react-i18next'
import i18n from './src/i18n/i18n';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './src/application/store';
import App from './src/app';
// import 'bootstrap/dist/css/bootstrap.css';
import './src/assets/scss/main.scss';
// import { createRoot } from 'react-dom/cjs/react-dom.development';
import { createRoot } from 'react-dom/client';
// import { createRoot } from 'react-dom/cjs/react-dom.production.min';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<I18nextProvider i18n = { i18n } >
		<Provider store = { store } >
			<PersistGate loading={<p>Loading State...</p>} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider> 
	</I18nextProvider>
);
