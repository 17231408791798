import React, {useEffect, useState} from 'react'
import {NavigationComponent} from './navigation/navigation'
import styles from "./index.module.scss";
import {AppHeader} from './header';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {useDispatch} from 'react-redux';
import {dismissNotification, toggleSideMenu} from '../../../application/features/app';
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertComponent from "../notifications/sweet-alert-component";
import {useNavigate} from 'react-router';
import {logout} from "../../../application/features/authentication";
import {Splash} from "../templates/splash/splash";
import {settingsMetadataGet} from "../../../application/features/settings";
import i18n from "i18next";
import {ConnectionStatus} from "../notifications/connection-status";

export const AppLayoutComponent = ({children}) => {
    //state
    const [splashText, setSplashText] = useState(i18n.t('Getting things ready'));

    //hooks
    const location = useLocation();
    const navigate = useNavigate();

    //redux
    const dispatch = useDispatch();
    const isSideMenuOpen = useSelector(state => state.app.isSideMenuOpen);
    const notifications = useSelector(state => state.app.notifications) ?? null;
    const token = useSelector(state => state.authentication.token);
    const metadata = useSelector(state => state.settings.metadata.data);
    const metadataFetching = useSelector(state => state.settings.metadata.isFetching);
    const metadataSuccessful = useSelector(state => state.settings.metadata.fetchingSuccessful);
    const metadataError = useSelector(state => state.settings.metadata.fetchingError);
    const unauthorized = useSelector(state => state.app.network.unauthorized);
    const interrupt = useSelector(state => state.app.network.interrupt);
    const connectionStatus = useSelector(state => state.app.network.connectionStatus);

    //effect
    useEffect(() => {
        if (!metadata || !metadata.loaded) {
            setTimeout(function () {
                dispatch(settingsMetadataGet());
            }, 2000);
        }
    }, []);

    useEffect(() => {
        if (!metadata || !metadata.language) return;
        i18n.changeLanguage(metadata.language, () => {
            console.log('Change language');
        }).then((res) =>  {
            console.log('Change language done');
        });
        console.log('Passed');
    }, [metadata]);

    useEffect(() => {
        console.log('Metadata Error: ' + metadataError);
        if(metadataError) {
            console.log('Got an error');
            setSplashText("Please check your network connection and try again.");
        }
    }, [metadataError]);


    useEffect(() => {
        if (isSideMenuOpen) {
            dispatch(toggleSideMenu(false));
        }
    }, [location]);

    useEffect(() => {
        if (metadata) {
            setSplashText('Almost there...');
        }
    }, [metadata]);

    useEffect(() => {
        if (token) return;
        console.log('Token removed');
        navigate('/authentication/login');
    }, [token]);

    useEffect(() => {
        if (!unauthorized) return;
        console.log('About to logout....');
        dispatch(logout());
    }, [unauthorized]);

    /**
     * Handle redirections in case of backend interrupt
     */
    useEffect(() => {
        if (!interrupt) return;
        console.log('Processing interrupt ' + interrupt);
        if (interrupt == 'NO_ACTIVE_ORGANIZATION') {
            navigate('/choose-organization');
        }
        if (interrupt == 'ON_BOARDING_INCOMPLETE') {
            navigate('/onboarding');
        }
        if (interrupt == 'SUBSCRIPTION_EXPIRED') {
            navigate('/subscription-expired');
        }
        if (interrupt == 'NO_SUBSCRIPTION') {
            navigate('/no-subscription');
        }
    }, [interrupt]);


    const dismiss = () => {
        dispatch(dismissNotification());
    }

    if (!token) {
        return null
    }
    return (
        <div className={styles.wrapper}>
            {isSideMenuOpen &&
                <div className={styles.sideMenuOverlay} onClick={() => dispatch(toggleSideMenu(false))}></div>}
            <div className={`${styles.nav} ${isSideMenuOpen && styles.showSideMenu}`}>
                <NavigationComponent/>
            </div>

            <div className={styles.content}>
                <AppHeader/>
                <div className="container py-3">
                    {children}
                </div>
            </div>

            {
                (!metadata || !metadata.loaded) ?
                    (<Splash text={splashText} isLoading={metadataFetching ?? false} icon={metadataError ? 'noConnection' : null} title={metadataError ? 'Failed to connect to BizPro!' : ''} />)
                    : ''
            }
            {
                (connectionStatus === false) ?
                    <ConnectionStatus />
                    : ''
            }
            {(notifications != null && notifications.length > 0) ?
                <SweetAlert success title={notifications[0].title ?? ''}
                            onConfirm={dismiss}>  {notifications[0].text}</SweetAlert> : ''}
        </div>
    );
}
