import React from 'react';

const CustomPageAlert = (props) => {
    const {title, text} = props;
    return (
        <div className="jumbotron jumbotron-fluid text-center">
            <div>{title}</div>
            <p>{text}</p>
        </div>
    );
};

export default CustomPageAlert;
