import {useState} from "react";
import CustomInput from "../../../atomic/input";
import PropTypes from "prop-types";

export const QuickCustomerForm = ({onSubmit}) => {
    const [payload, setPayload] = useState({name: '', 'phone': ''});
    const [errors, setErrors] = useState({name: '', 'phone': ''});

    const submit = () => {
        console.log('PAYLOAD', payload);
        if(payload.number === '' || payload.number.length < 9) {
            setErrors({...errors, phone: 'Invalid number'});
            return;
        }
        if(onSubmit) {
            onSubmit(payload);
        }
    }

    const handleChange = (key, value) => {
        setPayload({...payload, [key]: value});
        setErrors({...errors, [key]: ''});
    }

    return (
        <div className={"card-body"}>
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <CustomInput type="text" className="form-control" onChange={handleChange} id="name" placeholder="Enter phone number" label={"Phone Number"}  name={'number'} error={errors['phone'] ?? null}/>
                    </div>
                </div>
                <div className="col-12 mt-3 text-center">
                    <button className={"btn btn-primary btn-block"} onClick={submit}>Create Customer</button>
                </div>
            </div>
        </div>
    )
}

QuickCustomerForm.propTypes = {
    onSubmit: PropTypes.func
}
