import React from 'react';
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import PageAlert from "../../../../components/notifications/page-alert";
import {customersCustomerGroupsGet} from "../../../../../application/features/customers";

const CustomersGroupViewPage = () => {
    //state
    const [group, setCustomer] = useState(null);

    //hooks
    const { customer_id } = useParams();

    //redux
    const dispatch = useDispatch();
    const customer_groups = useSelector(state => state.customers.customer_groups.data);

    useEffect(() => {
        dispatch(customersCustomerGroupsGet());
    }, []);

    //effect
    useEffect(() => {
        if (!customer_id) return;
        const data = {
            id: customer_id,
        }
        setCustomer(customer_groups.find(u => u.id == customer_id));
    }, [customer_id]);

    if (!group) {
        return <PageAlert title={""} text="Ooops! Customer not found" />
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">&nbsp;</div>
                <div className="card-body">
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <td width="30%">Name</td><td>{group.name}</td>
                        </tr>
                        <tr>
                            <td>Description</td><td>{group.description ?? ''}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
};

export default CustomersGroupViewPage;
