import React from 'react'
import {Navigate} from "react-router-dom";
import { DashboardPage } from './dashboard/dashboard-page';
import { Routes, Route } from 'react-router-dom';
import { InventoryRoutes } from './inventory/inventory-routes';
import { ProductRoutes } from './products/product-routes';
import { AppLayoutComponent } from '../../components/app';
import {MessagesRoutes} from "./messages/messages-routes";
import {CustomersRoutes} from "./customers/customers-routes";
import {ExpensesRoutes} from "./expenses/expenses-routes";
import {PurchasesRoutes} from "./purchases/purchases-routes";
import {ReportsRoutes} from "./reports/reports-routes";
import {ReturnsRoutes} from "./returns/returns-routes";
import {SalesRoutes} from "./sales/sales-routes";
import {SettingsRoutes} from "./settings/settings-routes";
import {UsersRoutes} from "./users/users-routes";
import AccountsRoutes from "./accounts/accounts-routes";
import {UserOrganization} from "./users/organization/organization";
import {UserProfile} from "./users/profile";
import {NotificationsList} from "./notifications/notifications/notifications-list";
import {NotFoundPage} from "../not-found";
import {About} from "./system/about";

export const MainRoutes = () => {
  return (
    <AppLayoutComponent>
        <Routes>
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="inventory/*" element={<InventoryRoutes />} />
            <Route path="products/*" element={<ProductRoutes />} />
            <Route path="messages/*" element={<MessagesRoutes />} />
            <Route path="customers/*" element={<CustomersRoutes />} />
            <Route path="expenses/*" element={<ExpensesRoutes />} />
            <Route path="purchases/*" element={<PurchasesRoutes />} />
            <Route path="reports/*" element={<ReportsRoutes />} />
            <Route path="returns/*" element={<ReturnsRoutes />} />
            <Route path="sales/*" element={<SalesRoutes />} />
            <Route path="settings/*" element={<SettingsRoutes />} />
            <Route path="accounts/*" element={<AccountsRoutes />} />
            <Route path="users/*" element={<UsersRoutes />} />
            <Route path="organization" element={<UserOrganization />} />
            <Route path="profile" element={<UserProfile />} />
            <Route path="notifications" element={<NotificationsList />} />
            <Route path="about" element={<About />} />
            <Route index element={<Navigate to="dashboard" />} />
            <Route path='*' element={<NotFoundPage />} />
        </Routes>
    </AppLayoutComponent>
  )
}


export const mainRoutes = {
    dashboard: ''
}
