import React from 'react'
import DataTable from 'react-data-table-component';
import * as PropTypes from "prop-types";

export const CustomSmartTable = (props) => {
    const { columns, data, striped, isLoading, selectableRows } = props;
    return (
        <DataTable
            selectableRows={selectableRows ?? false}
            selectableRowsHighlight={false}
            selectableRowSelected={(row)=>{}}
            progressPending={isLoading ?? false}
            striped={striped ?? false}
            columns={columns}
            data={data}
         />
    );
}
CustomSmartTable.propTypes = {
    columns: PropTypes.arrayOf(Object),
    data: PropTypes.arrayOf(Object),
    striped: PropTypes.bool,
    isLoading: PropTypes.bool,
    selectableRows: PropTypes.bool
};
