export const About = () => {
    return (
        <div className={'d-flex align-items-center justify-content-center'} style={{minHeight: '80vh'}}>
            <div className={'text-black-50 text-center'}>
                <h5><span style={{color: '#92F'}}>BizPro</span> Solution</h5>
                <div><small>Version: 1.0.0</small></div>
                <div><small>2022 &copy; Software Galaxy</small></div>
            </div>
        </div>
    )
}
