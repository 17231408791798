import React from 'react';
import PropTypes from 'prop-types';

const IconArrowBack = (props) => {
    return (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={props.className} height={props.height} width={props.width}>
            <path fill={props.fill} d="M21 11H6.414l5.29-5.293 -1.42-1.42 -7.71 7.707 7.7 7.7 1.41-1.42 -5.3-5.3H20.97Z"/>
        </svg>
    )
}

IconArrowBack.defaltProps = {
    fill: 'white',
}

IconArrowBack.propTypes = {
    fill: PropTypes.string,
    className: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
}

export default IconArrowBack;
