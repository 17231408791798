@import "$assets/scss/main.scss";

.signup {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 360px;
    
    p {
        text-align: center;
    }
}