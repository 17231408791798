import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  inventoryDisposalsDelete,
  inventoryDisposalsGet,
} from "../../../../../application/features/inventory";
import { Link } from "react-router-dom";
import { CustomTable } from "../../../../atomic/table/table";
import { ViewButton } from "../../../../components/buttons/view-button";
import { EditButton } from "../../../../components/buttons/edit-button";
import { DeleteButton } from "../../../../components/buttons/delete-button";
import CustomConfirmAlert from "../../../../components/notifications/confirm-alert";
import DateFormat from "../../../../atomic/formatting/date-format";
import { AddButton } from "../../../../components/buttons/add-button";
import PurchasesResource from "../../../../../application/resources/purchases";
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import InventoryDisposalResource from "../../../../../application/resources/inventory-disposal";

const InventoryDisposalsListPage = () => {
  // state
  const [showAlert, setShowAlert] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  //redux
  const dispatch = useDispatch();
  const isFetching = useSelector(
    (state) => state.inventory.disposals.isFetching
  );
  const isDeleting = useSelector(
    (state) => state.inventory.disposals.isDeleting
  );
  const disposals = useSelector((state) => state.inventory.disposals.data);

  //effects
  useEffect(() => {
    dispatch(inventoryDisposalsGet());
  }, []);

  //handlers
  const handleDelete = () => {
    if (deletingId) {
      const data = { id: deletingId };
      dispatch(inventoryDisposalsDelete(data));
      setDeletingId(null);
      setShowAlert(false);
    }
  };

  return (
    <div>
      <div className="row text-end">
        <div className="col">
          {/* <Link to="form" className="btn btn-outline-primary">
            Dispose Stock
          </Link> */}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          <div className="card">
            <div className="card-header">
                <div className="d-flex align-items-center">
                    <span className="card-title flex-grow-1">Disposals</span>
                    <AddButton permissions={['Create Inventory']} to="form" title="Add Disposal" />
                </div>
            </div>
            <div className="card-body table-responsive">
              <GalaxyResourceTable deletable={(row) => false} editable={(row) => false} resource={new InventoryDisposalResource()}/>
            </div>
          </div>
        </div>
      </div>
      <CustomConfirmAlert
        isShown={showAlert}
        title="Delete Disposal?"
        text="Are you sure you want to delete the disposal?"
        onConfirm={handleDelete}
        toggleAlert={setShowAlert}
      />
    </div>
  );
};

export default InventoryDisposalsListPage;
