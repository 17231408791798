import BizManagerApi from "../../api/bizmanager";
import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import {
    expensesExpenseDelete,
    expensesExpensesGet, expensesExpenseCreate,
    expensesExpenseUpdate, expensesExpenseRetrieve,
} from "../features/expenses";
import DateFormat from "../../presentation/atomic/formatting/date-format";
import Currency from "../../presentation/atomic/formatting/currency";
import i18n from "i18next";

/**
 * @inheritDoc
 */
export class  ExpensesResource extends GalaxyResource {
    resourceActions = {
        fetch: expensesExpensesGet,
        retrieve: expensesExpenseRetrieve,
        create: expensesExpenseCreate,
        update: expensesExpenseUpdate(),
        delete: expensesExpenseDelete,
    };
    stateName = 'expenses.expenses';
    columns = [
        {
            name: i18n.t('Date'),
            selector: (row) => <DateFormat date={row.date}/>,
            sortable: true,
        },
        {
            name: i18n.t('Description'),
            cell: (row) => <span>{row.description ?? ''}</span>,
            sortable: true,
        },
        {
            name: i18n.t('Type'),
            cell: (row) => <span>{row.expense_type ? row.expense_type.name : ''}</span>,
            sortable: true,
        },
        {
            name: i18n.t('Amount'),
            cell: (row) => <span className={"w-100 text-end"}><Currency value={row.items.reduce((prev, current) => prev + (current.quantity * current.unit_amount), 0)}/></span>,
            sortable: true,
        }
    ];
    permissions = {
        view: ['View Expenses'],
        create: ['Create Expenses'],
        update: ['Create Expenses'],
        delete: ['Delete Expenses'],
    }
}

export default ExpensesResource;
