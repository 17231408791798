import BizManagerApi from "../../api/bizmanager";
import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import {
    customersCustomerDelete,
    customersCustomersGet,
    customersCustomerCreate, customersCustomerUpdate, customersCustomerRetrieve,
} from "../features/customers";
import DateFormat from "../../presentation/atomic/formatting/date-format";

/**
 * @inheritDoc
 */
export class CustomersResource extends GalaxyResource {
    resourceActions = {
        fetch: customersCustomersGet,
        retrieve: customersCustomerRetrieve,
        create: customersCustomerCreate,
        update: customersCustomerUpdate,
        delete: customersCustomerDelete,
    };
    stateName = 'customers.customers';
    columns = [
        {
            name: '#',
            center: false,
            width: '40px',
            cell: (row, index) => (index + 1),
        },
        {
            name: "Name",
            selector: (row) => <span>{row.name}</span>,
            sortable: true,
        },
        {
            name: "Birth Date",
            cell: (row) => <span>{row.birth_date ?? ''}</span>,
            sortable: true,
        },
        {
            name: "Phone",
            cell: (row) => <span>{row.phone ?? ''}</span>,
            sortable: true,
        }
    ];
    permissions = {
        view: ['View Customers'],
        create: ['Create Customers'],
        update: ['Create Customers'],
        delete: ['Delete Customers'],
    }
}

export default CustomersResource;
