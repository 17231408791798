import {BarsLoader} from "../../loaders/bars-loader/bars-loader";
import i18n from "i18next";
import noConnection from '$assets/img/illustrations/no_connection.svg';
import fourZeroFour from '$assets/img/illustrations/404.svg';
import React from "react";
import CustomButton from "../../../atomic/button";
import PropTypes from "prop-types";
import {ExcelIcon} from "../../../atomic/icons/excel";
import BizProLogo from "../../../../assets/img/bizpro.svg"

export const Splash = (props) => {
    const {text, icon, isLoading, title} = props;
    const baseStyle = {
        position: 'fixed',
        top: 0, bottom: 0,
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#dadada',
        zIndex: 100
    }

    const imageStyle = {
        maxHeight: "200px"
    }

    const icons = {
        fourZeroFour,
        noConnection,
    }

    const contentStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
    return (
        <div style={baseStyle}>
            <div className="content" style={contentStyle}>
                {
                    (icon != null) ?
                        <div className="p-4">
                            <img src={props.icon ? icons[props.icon] : fourZeroFour} style={imageStyle} alt="Page Not Found" className="img-fluid"/>
                        </div>
                        :
                        <>
                            <div className={'mb-2'}>
                                <img src={BizProLogo} width={100}className={'img-fluid'} alt="BizPro"/>
                            </div>
                            <BarsLoader color={'#4f4a9f'} />
                        </>

                }
                {
                    (title) ? <h5>{title}</h5> : ''
                }
                <div className={"mb-2"}>{text ?? i18n.t('Getting things ready') + '...'}</div>
                {
                    (icon != null) ?
                        <CustomButton className={"btn mt-3"} label={"Retry"} isLoading={isLoading ?? false} onClick={() => {document.location = document.location}} />
                        : ''
                }
            </div>
        </div>
    )
}

Splash.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    isLoading: PropTypes.bool,
    title: PropTypes.string
}
