import React from 'react';
import PropTypes from "prop-types";

const CustomTextArea = (props) => {
    //props
    const { label, name, id, cols, rows, value, placeHolder, onChange, error } = props;

    //handlers
    const handleChange = (key, value) => {
        if (onChange) {
            onChange(key, value);
        }
    }
    return (
        <div className="form-group">
            <label htmlFor={name} className="form-label">{label}</label>
            <textarea className={(error != null && error !== '') ? "form-control is-invalid" : "form-control"}
                      name={name ?? ''} id={id ?? ''} cols={cols ?? 30} rows={rows ?? null}
                      placeholder={placeHolder ?? ''} value={value}
                      onChange={(e) => handleChange(name, e.target.value)}></textarea>
            <div className={(error != null && error !== '') ? 'invalid-feedback d-block' : 'valid-feedback d-none'}>{error}</div>
        </div>
    );
};

CustomTextArea.defaultProps = {

}

CustomTextArea.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    cols: PropTypes.number,
    rows: PropTypes.number,
    value: PropTypes.string,
    placeHolder: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.string,
}

export default CustomTextArea;
