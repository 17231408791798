import React from 'react';
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import Currency from "../../../../atomic/formatting/currency";
import DateFormat from "../../../../atomic/formatting/date-format";

const ExpenseViewPage = () => {

    const{ expense_id } = useParams();

    // redux
    const expenses = useSelector(state => state.expenses.expenses.data);
    const expense = useSelector(state => state.expenses.expenses.data.find(e => e.id == expense_id))


    useEffect(() => {
        // setExpense(expenses.find(e => e.id === expense_id));
    }, []);

    if(!expense) {
        return "Not found";
    }


    return (
        <div className="row">
            <div className="col-12 col-xl-9">
                <div className="card">
                    <div className="card-header">&nbsp;</div>
                    <div className="card-body">
                        <div className="mb-3">
                            <table className="table table-bordered table-sm">
                                <tbody>
                                <tr>
                                    <th width="30%">User:</th><td>{expense.user_name ?? ''}</td>
                                </tr><tr>
                                    <th>Expense Date:</th><td><DateFormat value={expense.date} /></td>
                                </tr><tr>
                                    <th>Type:</th><td>{expense.expense_type ? expense.expense_type.name : ''}</td>
                                </tr><tr>
                                    <th>Description:</th><td>{expense.description}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div></div>
                            <div></div>
                        </div>
                        <div>Items</div>
                        <table className='table table-bordered table-sm'>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th className={'text-end'}>Quantity</th>
                                <th className={'text-end'}>Unit Amount</th>
                                <th className={'text-end'}>Amount</th>
                            </tr>

                            </thead>
                            <tbody>
                            {expense.items.filter(u => u).map((item, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <td className={'text-end'}>{item.quantity}</td>
                                    <td className={'text-end'}>{item.unit_amount}</td>
                                    <td className="text-end"><Currency value={item.amount} /></td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th colSpan="5"> Total</th>
                                <th className="text-end"><Currency value={expense.items.reduce((prev, current) => (prev + current.amount), 0)} /></th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpenseViewPage;
