import React from "react";
import logo from "$assets/img/datavision-logo.png";
import { SplashLayout } from "../splash/layout";

export const RequestOtpPage = () => {
    return (
        <SplashLayout>
            <form>
                <p>Enter your OTP Verification Code</p>
                <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="email">OTP Code</label>
                    <input type="number" id="otp" className="form-control" placeholder="Enter OTP"/>
                </div>
                <div className="text-center pt-1 mb-5 pb-1">
                    <button className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="button">Submit
                    </button>
                </div>

            </form>
        </SplashLayout>
    );
}