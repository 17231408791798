.header {
    margin: 50px auto;
    max-width: 786px;
    text-align: center;
}

.content {
    max-width: 450px;
    margin: 0 auto;
}

.card {
    background: #FFFFFF;
    border-radius: 24px;
    padding: 36px;
}

.grid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
}