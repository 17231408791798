import React from 'react'
import styles from '../index.module.scss';

const NavigationGroupComponent = (props) => {
    const { title, children } = props;

    return (
        <div className='mb-4'>
            {title && <span className={styles.groupMenuTitle}>{title}</span>}

            {children}
        </div>
    );
}

export default NavigationGroupComponent;