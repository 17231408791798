import React from 'react';
import Chart from "react-apexcharts";

const CustomBarChart = ({xAxis, series }) => {
    const currency = 'TZS';
    const options = {
        chart: {
            id: "bar-x"
        },
        xaxis: {
            categories:  xAxis
        },
        color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
                [0, '#003399'],
                [1, '#3366AA']
            ]
        },
        stroke: {
            width: 1
        },
        tooltip: {
            y: {
                formatter: (val, opts) => { return `${currency} ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            }
        },
        theme: {
            mode: 'light'
        },
        yaxis: {
            labels: {
                formatter: (val, opts) => { return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            }
        },
        dataLabels: {
            offsetY: -3,
            background: {
                enabled:false,
            },
            style: {
                fontWeight: 200,
                colors: ['#aaa'],
            },
            formatter: (val, opts) => { return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
        }
    }
    return (
        <div className="app">
            <div className="row">
                <div className="mixed-chart">
                    <Chart
                        options={options}
                        series={series}
                        type="area"
                    />
                </div>
            </div>
        </div>
    );
}

export default CustomBarChart;
