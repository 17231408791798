import React from 'react'
import styles from './index.module.scss';
import art from '$assets/img/registration_artwork_1.png';
import art2 from '$assets/img/registration_artwork_2.png';
import art3 from '$assets/img/registration_artwork_3.png';
import art4 from '$assets/img/registration_artwork_4.png';
import logo from '$assets/img/bizpro_logo_white.png';
import {ConnectionStatus} from "../../../components/notifications/connection-status";
import {useSelector} from "react-redux";
import patterBg from '$assets/img/patterns.svg';

export const SplashLayout = (props) => {

    const connectionStatus = useSelector(state => state.app.network.connectionStatus);

    const { children } = props;
    const bgStyle = {
        background: `url(${art})`,
        backgroundPosition: 'bottom',
        padding: '2rem 2rem',
        height: '100vh'
    };
    const formContentStyle = {
        position: "absolute",
        float: 'left',
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        width: "inherit",
    }
    return (
        <div className="d-flex flex-row row">
            <div  className={styles.left + ` d-none d-lg-block col-12 col-lg-6`} style={bgStyle}>
                <img src={logo} className={styles.logo} alt="" />
            </div>
            <div className={styles.right + " col-12 col-lg-6"} style={{}}>
                <div style={{float: 'left', width: '100%'}}>
                    <img src={art2} alt="" className={styles.art2}/>
                    <img src={art3} alt="" className={styles.art3}/>
                    <img src={art4} alt="" className={styles.art4}/>
                </div>

                <div style={formContentStyle }>{children}</div>
            </div>

            {
                (connectionStatus === false) ?
                    <ConnectionStatus />
                    : ''
            }
        </div>
    );
}
