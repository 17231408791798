import {NavLink, useNavigate} from "react-router-dom";
import IconReports from "../../../atomic/icons/reports";
import i18n from "i18next";

export const ReportsListPage = () => {
    const navigate = useNavigate();
    return (
        <div>

            <div className="row">
                <div className="col-6 col-md-4 col-lg-3 mb-4">
                    <div className="card shadow-hover" onClick={() => navigate('/main/reports/monthly-sales')}>
                        <div className="card-body text-center">
                            <div className={'mb-2'}><IconReports height={50} fill={"#DDDDDD"} /></div>
                            <div style={{color: '#777'}}>{i18n.t('Monthly Sales')}</div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-4 col-lg-3 mb-4">
                    <div className="card shadow-hover" onClick={() => navigate('/main/reports/purchases')}>
                        <div className="card-body text-center">
                            <div className={'mb-2'}><IconReports height={50} fill={"#DDDDDD"} /></div>
                            <div style={{color: '#777'}}>{i18n.t('Purchases')}</div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-4 col-lg-3 mb-4">
                    <div className="card shadow-hover" onClick={() => navigate('/main/reports/expenses')}>
                        <div className="card-body text-center">
                            <div className={'mb-2'}><IconReports height={50} fill={"#DDDDDD"} /></div>
                            <div style={{color: '#777'}}>{i18n.t('Expenses')}</div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-4 col-lg-3 mb-4">
                    <div className="card shadow-hover" onClick={() => navigate('/main/reports/returns')}>
                        <div className="card-body text-center">
                            <div className={'mb-2'}><IconReports height={50} fill={"#DDDDDD"} /></div>
                            <div style={{color: '#777'}}>{i18n.t('Returns')}</div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-4 col-lg-3 mb-4">
                    <div className="card shadow-hover" onClick={() => navigate('/main/reports/monthly-waste')}>
                        <div className="card-body text-center">
                            <div className={'mb-2'}><IconReports height={50} fill={"#DDDDDD"} /></div>
                            <div style={{color: '#777'}}>{i18n.t('Monthly Waste')}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
