import React, {useEffect, useState} from 'react';
import {CustomTable} from "../../../../atomic/table/table";
import {useDispatch, useSelector} from "react-redux";
import {accountsTransactionsGet} from "../../../../../application/features/accounts";
import DateFormat from "../../../../atomic/formatting/date-format";
import Currency from "../../../../atomic/formatting/currency";
import BackendContentFilter from "../../../../components/form/content-filter";
import moment from "moment";
import {useTranslation} from "react-i18next";
import bizmanager from "../../../../../api/bizmanager";
import ReportExporter from "../../../../components/form/report-exporter/report-exporter";

const TransactionsListPage = () => {

    const {i18n, t} = useTranslation();
    const downloadUrl = bizmanager.exportTransactions;
    //state
    const [filterPayload, setFilterPayload] = useState({start_date: null, end_date: null});


    //redux
    const transactions = useSelector(state => state.accounts.transactions.data);
    const isFetching = useSelector(state => state.accounts.transactions.isFetching);

    //hooks
    const dispatch = useDispatch();

    useEffect(() => {
        fetchData(filterPayload);
    }, []);

    const fetchData = (filters) => {
        dispatch(accountsTransactionsGet(filters));
    }


    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="col-12  mb-2 d-flex flex-row justify-content-between align-items-center">
                                <div className="flex-grow-1"><h6>{t('Transactions List')}</h6></div>
                                <ReportExporter downloadUrl={downloadUrl} />
                                <BackendContentFilter startDate={moment().startOf('year').format('YYYY-MM-DD')} onSubmit={(data) => fetchData({payload: data}) }/>
                            </div>
                            <div className={'table-responsive'}>
                                <CustomTable isLoading={isFetching}>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{i18n.t('Date')}</th>
                                        <th>{i18n.t('Details')}</th>
                                        <th>{i18n.t('Type')}</th>
                                        <th>{i18n.t('Account')}</th>
                                        <th>DR</th>
                                        <th>CR</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        transactions.filter(u => u).map((transaction, i) => (
                                            <>
                                                <tr key={i}>
                                                    <td rowSpan={transaction.ledger_entry_items.length}>{i + 1}</td>
                                                    <td rowSpan={transaction.ledger_entry_items.length}><DateFormat
                                                        value={transaction.transaction_date}/></td>
                                                    <td rowSpan={transaction.ledger_entry_items.length}>{transaction.details}</td>
                                                    <td rowSpan={transaction.ledger_entry_items.length}>{transaction.transaction_type}</td>
                                                    <td>{transaction.ledger_entry_items[0].account.name}</td>
                                                    <td className="text-end"><Currency value={transaction.ledger_entry_items[0].dr} /></td>
                                                    <td className="text-end"><Currency value={transaction.ledger_entry_items[0].cr} /></td>
                                                    <td rowSpan={transaction.ledger_entry_items.length}>{transaction.total}</td>
                                                </tr>
                                                {transaction.ledger_entry_items.slice(1).map((item, i2) => (
                                                    <tr key={`${i}-${i2}`}>
                                                        <td>{item.account.name}</td>
                                                        <td className="text-end"><Currency value={item.dr} /></td>
                                                        <td className="text-end"><Currency value={item.cr} /></td>
                                                    </tr>
                                                ))

                                                }
                                            </>
                                        ))
                                    }
                                    <tr key="totals">
                                        <th colSpan={5}>Total</th>
                                        <th className="text-end"><Currency value={transactions.reduce((prev, cur) => (prev + cur.amount * 1), 0)} /></th>
                                        <th className="text-end"><Currency value={transactions.reduce((prev, cur) => (prev + cur.amount * 1), 0)} /></th>
                                        <th></th>
                                    </tr>
                                    </tbody>
                                </CustomTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionsListPage;
