import React from 'react'
import { IconMenu } from '../../../atomic/icons/menu';
import styles from "./index.module.scss";

import { AppHeaderSearchInput } from './search';
import { AppHeaderUser } from './user/index';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toggleSideMenu } from '../../../../application/features/app';
import { IconNotification } from '../../../atomic/icons/notification';
import {useNavigate} from "react-router-dom";

export const AppHeader = () => {
    //redux
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isSideMenuOpen = useSelector(state => state.app.isSideMenuOpen)

    return (
        <div className={styles.wrapper}>
            <div className="container h-100">
                <div className={styles.content}>
                    <div className='mr-3 d-md-none' onClick={() => dispatch(toggleSideMenu(!isSideMenuOpen))}>
                        <IconMenu height="25px" width="25px" />
                    </div>
                    <div>
                        <AppHeaderSearchInput />
                    </div>
                    <div className="flex-grow-1"></div>
                    <div>
                        <div className="dropdown d-inline-block" onClick={() => navigate('/main/notifications')}>
                            <IconNotification />
                        </div>
                    </div>
                    <AppHeaderUser />
                </div>
            </div>
        </div>
    );
}
