import {
    generateCleaningReducers,
    generateResourceExtraReducers,
    restResourceInitialState
} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";

// Action Constants
const PURCHASES_PURCHASES_GET = 'purchases/customer/GET_PURCHASES';
const PURCHASES_PURCHASE_CREATE = 'purchases/customer/CREATE_PURCHASE';
const PURCHASES_PURCHASE_RETRIEVE = 'purchases/customer/RETRIEVE_PURCHASE';
const PURCHASES_PURCHASE_UPDATE = 'purchases/customer/UPDATE_PURCHASE';
const PURCHASES_PURCHASE_DELETE = 'purchases/customer/DELETE_PURCHASE';

// Actions

export const purchasesPurchasesGet = createAsyncThunk(
    PURCHASES_PURCHASES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.purchases, data, token, store);
    }
);

export const purchasesPurchaseCreate = createAsyncThunk(
    PURCHASES_PURCHASE_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.purchase_create, data.payload, token, store);
    }
);

export const purchasesPurchaseUpdate = createAsyncThunk(
    PURCHASES_PURCHASE_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.purchase_update, data.payload, token, store);
    }
);

export const purchasesPurchaseRetrieve = createAsyncThunk(
    PURCHASES_PURCHASE_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.purchase(data.id), data.payload, token, store);
    }
);

export const purchasesPurchaseDelete = createAsyncThunk(
    PURCHASES_PURCHASE_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.purchase(data.id), data.payload, token, store);
    }
);

// Combined thunks
const purchasesResourceThunks = {
    create: purchasesPurchaseCreate,
    get: purchasesPurchasesGet,
    retrieve: purchasesPurchaseRetrieve,
    update: purchasesPurchaseUpdate,
    delete: purchasesPurchaseDelete,
}

//initial state
const initialState = {
    purchases: restResourceInitialState,
}

//redux toolkit slice
const purchasesSlice = createSlice({
    'name': 'purchases',
    initialState,
    reducers: {
        clearPurchasesValidationErrors: (state, action) => {
            state.validationErrors = {};
        },
    },
    extraReducers: {
        ...generateResourceExtraReducers(purchasesResourceThunks, "purchases"),
    },
});

//exports
export const { clearPurchasesValidationErrors } = purchasesSlice.actions;
export default purchasesSlice.reducer;
