.card {
    background: #FFFFFF;
    border-radius: 24px;
    padding: 36px;
    text-align: center;
}

.content {
    max-width: 450px;
    margin: 50px auto;
}