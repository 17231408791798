import {NavLink, Routes} from "react-router-dom";
import {Route} from "use-react-router-breadcrumbs";
import React from "react";
import {GeneralSettingsPage} from "./general-settings-page";
import {useTranslation} from "react-i18next";
import {TopNavigation} from "../../../components/app/top-navigation/top-navigation";

export const SettingsRoutes = () => {
    const {i18n, t} = useTranslation();
    const topLinks = [
        {
            title: t('General Settings'),
            to: ''
        },
    ];
    return (
        <div>
            <h3>Settings</h3>
            <TopNavigation links={topLinks} basePathLevel={3} />

            <Routes>
                <Route index element={<GeneralSettingsPage/>}/>
                <Route path='messages/*'>
                    <Route index element={<GeneralSettingsPage/>}/>
                </Route>
            </Routes>
        </div>
    )
}
