.notifications {
  list-style-type: none;
  padding: 0;

  & li {
    border: 1px solid #eaeaea;
    border-radius: .4rem;
    padding: .2rem;
    align-items: center;
    margin-bottom: .5rem;

    & .icon {
      width: 3rem;
      border-radius: .4rem;
      margin-right: .5rem;
      flex-grow: 0;
    }

    & .content {
      flex-grow: 1;
    }

    & .action {
      flex-grow: 0;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: rgba(220, 220, 220, 0.4);
    }
  }
}
