import {BlankPage} from "../../../components/templates/layouts/blank-page";
import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import {useEffect} from 'react';
import {
    organizationsOrganizationSetActive,
    organizationsOrganizationsGet
} from '../../../../application/features/organizations';
import {useNavigate} from 'react-router-dom';
import {clearInterrupt} from "../../../../application/features/app";

export const ChooseOrganization = () => {
    // state
    const [error, setError] = useState(null);
    const [booted, setBooted] = useState(false);
    const [selecting, setSelecting] = useState(null);

    // routes
    const navigate = useNavigate();

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const organizations = useSelector(state => state.organizations.organizations.data);
    const updatingSuccessful = useSelector(state => state.organizations.organizations.updatingSuccessful);
    const isUpdating = useSelector(state => state.organizations.organizations.isUpdating);
    const isFetching = useSelector(state => state.organizations.organizations.isFetching);
    const fetchingError = useSelector(state => state.organizations.organizations.fetchingError);

    // effect
    useEffect(() => {
        dispatch(organizationsOrganizationsGet());
    }, []);

    useEffect(() => {
        if(!user) return;
        if(user.organization && user.organization.id) {
            dispatch(clearInterrupt())
            setTimeout(() => {
                navigate('/main');
            }, 100);
        }
    }, [user]);

    useEffect(() => {
        console.log('Updating', updatingSuccessful);
        if (!updatingSuccessful) return;
        if(updatingSuccessful === true) {
            setSelecting(null);
        }
    }, [updatingSuccessful]);


    useEffect(() => {
        if (!organizations.fetchingError) return;
        setError("Failed to connect the backend");
        setSelecting(null);
        setTimeout(() => {
            setError(null);
        }, 5000);
    }, [fetchingError]);



    //handlers
    const handleSetActiveOrganization = (id) => {
        if(isUpdating || isFetching) {
            return false;
        }
        setSelecting(id);
        const _data = {
            payload: {
                organization_id: id
            },
        };
        dispatch(organizationsOrganizationSetActive(_data));
    }

    const logout = () => {
        dispatch(logout());
    }

    if(organizations === undefined || organizations === []) {

    }

    return (
        <BlankPage>
            <h4>Choose Organization</h4>
            <p>Select the organization you want to work on today</p>
            {error && <p>Failed to fetch organization</p>}
            {isFetching &&
                <div>
                    <p>Fetching Organizations...</p>
                </div>
            }
            <div>
                <div className="row mt-2">
                    {(organizations && organizations.length) ?
                        organizations.map((organization, i) => {
                        return (
                            <div key={i} className="col-12 col-xl-6 mb-2" >
                                <div className={`card ${((selecting !== null) ? ((selecting === organization.id) ? 'selecting' : 'd-none') : '')}`}
                                     onClick={() => handleSetActiveOrganization(organization.id)}
                                     style={{cursor: 'pointer', boxShadow: '0px 0px 12px 4px rgba(200, 200, 200, 0.4)'}}
                                >
                                    <div className="card-body">
                                        { isUpdating ? 'Switching Organization...' : organization.name}
                                    </div>
                                </div>
                            </div>
                        )
                    }) : '...'
                    }
                </div>
            </div>
            <div className="mt-3">
                <a href="#" onClick={logout}>logout</a>
            </div>
        </BlankPage>
    )
}
