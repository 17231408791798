import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";

// Action Constants
const MESSAGES_MESSAGES_GET = 'messages/messages/GET_MESSAGES';
const MESSAGES_MESSAGE_CREATE = 'messages/messages/CREATE_MESSAGE';
const MESSAGES_MESSAGE_RETRIEVE = 'messages/messages/RETRIEVE_MESSAGE';
const MESSAGES_MESSAGE_UPDATE = 'messages/messages/UPDATE_MESSAGE';
const MESSAGES_MESSAGE_DELETE = 'messages/messages/DELETE_MESSAGE';

const MESSAGES_AUTOMATED_MESSAGES_GET = 'messages/messages/GET_AUTOMATED_MESSAGES';
const MESSAGES_AUTOMATED_MESSAGE_CREATE = 'messages/messages/CREATE_AUTOMATED_MESSAGE';
const MESSAGES_AUTOMATED_MESSAGE_RETRIEVE = 'messages/messages/RETRIEVE_AUTOMATED_MESSAGE';
const MESSAGES_AUTOMATED_MESSAGE_UPDATE = 'messages/messages/UPDATE_AUTOMATED_MESSAGE';
const MESSAGES_AUTOMATED_MESSAGE_DELETE = 'messages/messages/DELETE_AUTOMATED_MESSAGE';

const MESSAGES_AUTOMATION_FACTORS_GET = 'messages/automation/GET_AUTOMATION_FACTORS';

//Actions
export const messagesMessagesGet = createAsyncThunk(
    MESSAGES_MESSAGES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.messages_get, null, token);
    }
);

export const messagesMessageCreate = createAsyncThunk(
    MESSAGES_MESSAGE_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.messages_send, data.payload, token, store);
    }
);

export const messagesMessageRetrieve = createAsyncThunk(
    MESSAGES_MESSAGE_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.messages(data.id), data.payload, token, store);
    }
);


//
// export const messagesMessageUpdate = createAsyncThunk(
//     MESSAGES_MESSAGE_UPDATE,
//     async(data, store) => {
//         const { token } = store.getState().authentication;
//         return await customFetch('PUT', BizManagerApi.message(data.id), data.payload, token, store);
//     }
// );
//

// export const messagesMessageDelete = createAsyncThunk(
//     MESSAGES_MESSAGE_DELETE,
//     async(data, store) => {
//         const { token } = store.getState().authentication;
//         return await customFetch('DELETE', BizManagerApi.message(data.id), data.payload, token, store);
//     }
// );




//Automated
export const messagesAutomatedMessagesGet = createAsyncThunk(
    MESSAGES_AUTOMATED_MESSAGES_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.automated_messages_get, null, token, store);
    }
);

export const messagesAutomatedMessageCreate = createAsyncThunk(
    MESSAGES_AUTOMATED_MESSAGE_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.automated_messages_create, data.payload, token, store);
    }
);

export const messagesAutomatedMessageRetrieve = createAsyncThunk(
    MESSAGES_AUTOMATED_MESSAGE_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.automated_messages(data.id), data.payload, token, store);
    }
);



export const messagesAutomatedMessageUpdate = createAsyncThunk(
    MESSAGES_AUTOMATED_MESSAGE_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.automated_message(data.id), data.payload, token, store);
    }
);


export const messagesAutomatedMessageDelete = createAsyncThunk(
    MESSAGES_AUTOMATED_MESSAGE_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.automated_message(data.id), data.payload, token, store);
    }
);


// Factors
export const messagesAutomationFactorsGet = createAsyncThunk(
    MESSAGES_AUTOMATION_FACTORS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.automation_factors_get, null, token, store);
    }
);




// Combined thunks
const messagesMessagesResourceThunks = {
    create: messagesMessageCreate,
    get: messagesMessagesGet,
    retrieve: null,
    update: null,
    delete: null,
}
const messagesAutomatedMessagesResourceThunks = {
    create: messagesAutomatedMessageCreate,
    get: messagesAutomatedMessagesGet,
    retrieve: messagesAutomatedMessageRetrieve,
    update: messagesAutomatedMessageUpdate,
    delete: messagesAutomatedMessageDelete,
}
const messagesAutomationFactorsResourceThunks = {
    create: null,
    get: messagesAutomationFactorsGet,
    retrieve: null,
    update: null,
    delete: null,
}

//initial state
const initialState = {
    messages: restResourceInitialState,
    automated_messages: restResourceInitialState,
    automation_factors: restResourceInitialState,
}

//redux toolkit slice
const messagesSlice = createSlice({
    'name': 'messages',
    initialState,
    reducers: {},
    extraReducers: {
        ...generateResourceExtraReducers(messagesMessagesResourceThunks, "messages"),
        ...generateResourceExtraReducers(messagesAutomatedMessagesResourceThunks, "automated_messages"),
        ...generateResourceExtraReducers(messagesAutomationFactorsResourceThunks, "automation_factors"),
    },
});

//exports
export default messagesSlice.reducer;
