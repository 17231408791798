import {generateResourceExtraReducers, restResourceInitialState} from "../../core/data-structures";
import BizManagerApi from "../../api/bizmanager";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {customFetch} from "../../core/network";
import {login} from "./authentication";
import {setingsRolesSynchronize} from "./settings";

// Action Constants
const USERS_USERS_GET = 'users/user/GET_USERS';
const USERS_USER_CREATE = 'users/user/CREATE_USER';
const USERS_USER_RETRIEVE = 'users/user/RETRIEVE_USER';
const USERS_USER_UPDATE = 'users/user/UPDATE_USER';
const USERS_USER_DELETE = 'users/user/DELETE_USER';


const USERS_PERMISSIONS_GET = 'users/permissions/GET_PERMISSIONS';
const USERS_PERMISSIONS_UPDATE = 'users/permissions/UPDATE_PERMISSIONS';
const USER_ROLES_SYNCHRONIZE = 'users/roles/ROLES_SYNCHRONIZE';

// Actions
export const usersUsersGet = createAsyncThunk(
    USERS_USERS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.users_get, data, token, store);
    }
);

export const usersUserCreate = createAsyncThunk(
    USERS_USER_CREATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.user_create, data.payload, token, store);
    }
);

export const usersUserUpdate = createAsyncThunk(
    USERS_USER_RETRIEVE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', BizManagerApi.user(data.id), data.payload, token, store);
    }
);

export const usersUserRetrieve = createAsyncThunk(
    USERS_USER_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.user(data.id), data.payload, token, store);
    }
);

export const usersUserDelete = createAsyncThunk(
    USERS_USER_DELETE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', BizManagerApi.user(data.id), data.payload, token, store);
    }
);

export const usersPermissionsGet = createAsyncThunk(
    USERS_PERMISSIONS_GET,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', BizManagerApi.get_user_permissions, data, token, store);
    }
);

export const usersPermissionsUpdate = createAsyncThunk(
    USERS_PERMISSIONS_UPDATE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.update_user_permissions, data.payload, token, store);
    }
);

export const userRolesSynchronize = createAsyncThunk(
    USER_ROLES_SYNCHRONIZE,
    async(data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', BizManagerApi.sync_user_roles, data.payload, token, store);
    }
);

// Combined thunks
const usersResourceThunks = {
    create: usersUserCreate,
    get: usersUsersGet,
    retrieve: usersUserRetrieve,
    update: usersUserUpdate,
    delete: usersUserDelete,
}
const permissionsResourceThunks = {
    get: usersPermissionsGet(),
    retrieve: null,
    update: usersPermissionsUpdate(),
}


//initial state
const initialState = {
    users: restResourceInitialState,
    permissions: restResourceInitialState,
    roles: restResourceInitialState,
}

//redux toolkit slice
const usersSlice = createSlice({
    'name': 'users',
    initialState,
    reducers: {},
    extraReducers: {
        ...generateResourceExtraReducers(usersResourceThunks, "users"),
        ...generateResourceExtraReducers(permissionsResourceThunks, "permissions"),
        [userRolesSynchronize.pending]: (state, action) => {
            state.roles.isUpdating = true;
            state.roles.isSuccessful = false;
            state.roles.error = null;
        },
        [userRolesSynchronize.rejected]: (state, action) => {
            state.roles.isUpdating = true;
            state.roles.isSuccessful = false;
            state.roles.error = null;
        },
        [userRolesSynchronize.fulfilled]: (state, action) => {
            state.roles.isUpdating = true;
            state.roles.isSuccessful = false;
            state.roles.error = null;
            state.roles.data = (action.payload && action.payload.data) ? action.payload.data : null;
            if(action.payload && action.payload.data) {
                console.log('State Users', state.users.data);
                state.users.data = [...state.users.data].map(u => { return (u.id == action.payload.data.id ? action.payload.data : u)});
            }
        },
    },
});

//exports
export default usersSlice.reducer;
