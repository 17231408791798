import React from 'react'
import {Navigate, Routes} from 'react-router-dom';
import {Route} from 'use-react-router-breadcrumbs';
import {ProductListPage} from './products/list';
import {ProductUnitsListPage} from './units/list';
import {ProductUnitsFormPage} from './units/form';
import {ProductUnitsViewPage} from './units/view';
import ProductsProductFormPage from "./products/form";
import ProductViewPage from "./products/view";
import * as PropTypes from "prop-types";
import ProductCategoriesPage from "./product-categories/list";
import ProductCategoryFormPage from "./product-categories/form";
import ProductCategoryViewPage from "./product-categories/view";
import ProductPackageFormPage from "./product-packages/form";
import ProductPackagesListPage from "./product-packages/list";
import ProductPackageViewPage from "./product-packages/view";
import {useTranslation} from "react-i18next";
import {TopNavigation} from "../../../components/app/top-navigation/top-navigation";

Navigate.propTypes = {to: PropTypes.string};


export const ProductRoutes = () => {
    const {t} = useTranslation();
    const topLinks = [
        {
            title: t('Products'),
            to: "products",
        },
        {
            title: t('Units'),
            to: "units",
        },
        {
            title: t('Categories'),
            to: "categories",
        },
        {
            title: t('Product Packages'),
            to: "packages",
        },

    ];
    return (
        <div>
            <h3>Product Details</h3>
            <TopNavigation links={topLinks} basePathLevel={3}  />

            <Routes>
                <Route path='units/*'>
                    <Route path="form" element={<ProductUnitsFormPage/>}/>
                    <Route path="form/:unit_id" element={<ProductUnitsFormPage/>}/>
                    <Route path=":unit_id" index element={<ProductUnitsViewPage/>}
                           breadcrumb={(param) => <span>ME</span>}/>
                    <Route index element={<ProductUnitsListPage/>}/>
                </Route>
                <Route path='products/*'>
                    <Route path="form" element={<ProductsProductFormPage/>}/>
                    <Route path="form/:product_id" element={<ProductsProductFormPage/>}/>
                    <Route path=":product_id" index element={<ProductViewPage/>}
                           breadcrumb={(param) => <span>ME</span>}/>
                    <Route path="products" element={<ProductListPage/>}/>
                    <Route index element={<ProductListPage/>}/>
                </Route>
                <Route path='categories/*'>
                    <Route path="form" element={<ProductCategoryFormPage/>}/>
                    <Route path="form/:product_category_id" element={<ProductCategoryFormPage/>}/>
                    <Route path=":product_category_id" index element={<ProductCategoryViewPage/>}
                           breadcrumb={(param) => <span>ME</span>}/>
                    <Route index element={<ProductCategoriesPage/>}/>
                </Route>
                <Route path='packages/*'>
                    <Route path="form" element={<ProductPackageFormPage/>}/>
                    <Route path="form/:product_package_id" element={<ProductPackageFormPage/>}/>
                    <Route path=":product_package_id" index element={<ProductPackageViewPage/>}
                           breadcrumb={(param) => <span>ME</span>}/>
                    <Route index element={<ProductPackagesListPage/>}/>
                </Route>
                {/*<Route path='addons/*'>*/}
                {/*    <Route path="form" element={<ProductAddonFormPage />} />*/}
                {/*    <Route path="form/:product_addon_id" element={<ProductAddonFormPage />} />*/}
                {/*    <Route path=":product_addon_id" index element={<ProductAddonViewPage />} breadcrumb={(param) => <span>ME</span>}  />*/}
                {/*    <Route index element={<ProductAddonsListPage />} />*/}
                {/*</Route>*/}
                <Route index element={<Navigate to="products"/>}/>
            </Routes>
        </div>
    )
}
