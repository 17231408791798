import React, {useEffect} from 'react'
import { IconChevronLeft } from '../../../atomic/icons/chevron-left';
import styles from './index.module.scss';
import small from '$assets/img/small.png';
import medium from '$assets/img/medium.png';
import large from '$assets/img/large.png';
import CustomInput from '../../../atomic/input';
import { useState } from 'react';
import OnBoardingStepLayout from './layout';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {businessTypesBusinessTypesGet, businessTypesBusinessTypeSetActive} from "../../../../application/features/busines-types";
import {Spinner} from "../../../atomic/spinner";
import CustomButton from "../../../atomic/button";

export const OnBoardingChooseBusinessTypePage = () => {
    const [active, setActive] = useState(1);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(state => state.authentication.user);
    const businessTypes = useSelector(state => state.businessTypes.businessTypes.data);
    const isFetching = useSelector(state => state.businessTypes.businessTypes.isFetching);
    const isUpdating = useSelector(state => state.businessTypes.businessTypes.isUpdating);

    useEffect(() => {
        dispatch(businessTypesBusinessTypesGet());
    }, []);

    useEffect(() => {
        if(!user.organization) return;
        if(!user.organization.business_type_id) return;
        setTimeout(() => {
            navigate('../categories')
        }, 100)
    }, [user]);

    const handleSubmit = () => {
        if(!active) return;
        if(!(user && user.organization)) return;
        const payload = {
            organization_id: user.organization.id,
            business_type_id: active
        }
        dispatch(businessTypesBusinessTypeSetActive({payload}));
    }
    
    return (
        <OnBoardingStepLayout steps={3} step={1}>
            <div className={styles.header}>
                <h1>Choose Your Business Type</h1>
                <p>Choose a type of business that fits your organization. If you don't fit in any , just pick 'Other'</p>
            </div>
            {
                isFetching && <div className="text-center"><Spinner /></div>
            }
            <div className={styles.content}>
                {
                    (businessTypes) ? businessTypes.filter(u => u).map((type, i) => (
                        <div key={i} onClick={() => setActive(type.id)} className={`${styles.card} ${active == type.id ? styles.active : null}`}>
                            <img src={small} alt="" />
                            <p>{type.name}</p>
                        </div>
                    )) : '...'
                }
            </div>

            <div className={styles.actions}>
                <button disabled={isUpdating} className="btn btn-primary" onClick={handleSubmit} >{ isUpdating ? <Spinner /> :' Next '}</button>
            </div>
        </OnBoardingStepLayout>
    );
}
