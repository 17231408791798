import React from "react";

export const IconAdd = (props) => {
  return (
    <svg
      viewBox="0 0 15 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6068 0.333435C12.8735 0.333435 14.1668 1.61343 14.1668 3.88677V10.1134C14.1668 12.3734 12.8802 13.6668 10.6135 13.6668H4.38683C2.1135 13.6668 0.833496 12.3734 0.833496 10.1134V3.88677C0.833496 1.61343 2.1135 0.333435 4.38683 0.333435H10.6068ZM7.4935 4.00677C7.18683 4.00677 6.94016 4.25343 6.94016 4.5601V6.4401H5.0535C4.90683 6.4401 4.76683 6.5001 4.66016 6.6001C4.56016 6.70677 4.50016 6.8461 4.50016 6.99343C4.50016 7.3001 4.74683 7.54677 5.0535 7.55343H6.94016V9.4401C6.94016 9.74677 7.18683 9.99343 7.4935 9.99343C7.80016 9.99343 8.04683 9.74677 8.04683 9.4401V7.55343H9.94016C10.2468 7.54677 10.4935 7.3001 10.4935 6.99343C10.4935 6.68677 10.2468 6.4401 9.94016 6.4401H8.04683V4.5601C8.04683 4.25343 7.80016 4.00677 7.4935 4.00677Z"
      />
    </svg>
  );
};
