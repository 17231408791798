import {Link} from "react-router-dom";
import React from "react";
import trashIcon from '$assets/icons/trash.svg';
import styles from './index.module.scss';
import { IconDelete } from "../../atomic/icons/delete";

export const DeleteButton = ({onClick, alt, isDeleting = false}) => {
    return (
        <button disabled={isDeleting} type="button" className={styles.deleteButton} onClick={() => onClick()}>
            <IconDelete height="12px" width="12px" fill="#EB5757" />
        </button>
    )
}