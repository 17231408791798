import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {settingsPermissionsGet, settingsRolesGet} from "../../../../../application/features/settings";
import {usersPermissionsGet} from "../../../../../application/features/users";
import {Spinner} from "../../../../atomic/spinner";

export const UserPermissions = () => {

    const dispatch = useDispatch();

    const roles = useSelector(state => state.settings.roles.data)
    const permissions = useSelector(state => state.settings.permissions.data);
    const isFetchingRoles = useSelector(state => state.settings.roles.isFetching);

    useEffect(() => {
        dispatch(settingsRolesGet());
        dispatch(settingsPermissionsGet());
    }, []);

    useEffect(() => {
        console.log('Permissions ', permissions);
    }, [permissions]);


    useEffect(() => {
        if(!roles) return;
        console.log(roles);
    }, [roles]);

    if(isFetchingRoles) {
        return <div className={"text-center px-2 py-4"}><Spinner /></div>
    }
    return (
        <div>

            <div className="table-responsive">
                <table className={"table"}>
                    <thead>
                        <tr>
                            <th width={20}>#</th>
                            <th>Permission</th>
                            {
                                (roles) ?
                                    [...roles].map((role, i) => (
                                        <th className={'text-center'} key={'th' + i}>{role.name}</th>
                                    ))
                                    :
                                    <th></th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                    {
                        (permissions) ?
                            permissions.filter(u => u).map((permission, i) =>  (
                                <tr key={'pr' + i}>
                                    <td>{i+1}</td>
                                    <td>{permission.name}</td>
                                    {
                                        (roles) ?
                                            [...roles].map((role, j) => (
                                                <td className={'text-center'} key={'rth' + j}>
                                                    {(role.permissions.map(u => u.name).indexOf(permission.name) > -1) ? '✓' : ' '}
                                                </td>
                                            ))
                                            :
                                            <th></th>
                                    }
                                </tr>
                            ))
                            :
                            <div>No Data</div>
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
