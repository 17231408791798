import React, {useEffect, useState} from 'react';
import CustomTextArea from "../../../../atomic/text-area";
import CustomInput from "../../../../atomic/input";
import {useDispatch, useSelector} from "react-redux";
import CustomSelectInput from "../../../../atomic/select";
import {customersCustomersGet} from "../../../../../application/features/customers";
import {messagesMessageCreate} from "../../../../../application/features/messages";
import {useNavigate} from "react-router-dom";

const SendSmsPage = () => {
    const [payload, setPayload] = useState({customer_id: null, phone_number: null, text: null});
    const [customer, setCustomer] = useState();
    const [mounted, setMounted] = useState(false);

    const customers = useSelector(state => state.customers.customers.data);
    const isCreating = useSelector(state => state.customers.customers.isCreating);
    const created = useSelector(state => state.customers.customers.creatingSuccessful);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setMounted(true);
        dispatch(customersCustomersGet());
    }, [])

    useEffect(() => {
        if (!mounted) return;
        if (!created) return;
        navigate('../');
    }, [created]);


    const handleChange = (key, value) => {
        const p = {...payload};
        p[key] = value;
        if(key == 'customer_id'){
            const c = customers.find(c => c.id = value);
            if(c){
                setCustomer(c);
                p.phone_number = c.phone;
            }
        }
        setPayload(p);
    }

    const submit = () => {
        console.log(payload);
        dispatch(messagesMessageCreate({payload: payload}));
    }

    return (
        <div className="row">
           <div className="col-12 col-lg-8">
               <div className="card">
                   <div className="card-body">
                       <div>
                           <CustomSelectInput label="Customer" name="customer_id"  items={[{id: '', name: 'None'},...customers]}  onChange={handleChange} />
                           <CustomInput label="Phone Number" value={customer ? customer.phone : null} name="phone_number"  onChange={handleChange} />
                           <CustomTextArea label="Message" name="text"   onChange={handleChange}></CustomTextArea>
                       </div>
                       <div className="mt-3">
                           <button disabled={isCreating} className="btn btn-primary" onClick={submit}>{ isCreating ? <Spinner /> : "Send SMS"}</button>
                       </div>
                   </div>
               </div>
           </div>
           <div className="col-12 col-lg-4">

           </div>
        </div>
    );
};

export default SendSmsPage;
