import React from 'react'
import avatar from '$assets/img/avatar.svg';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {logout} from '../../../../../application/features/authentication';
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import i18n from "i18next";

export const AppHeaderUser = () => {
    //state
    const [userAvatar, setUserAvatar] = useState(avatar);
    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    const user = useSelector(state => state.authentication.user);

    //store
    const dispatch = useDispatch();

    // effects
    useEffect(() => {
        if(user && user.avatar) {
            setUserAvatar(user.avatar);
        }
    }, []);

    //handlers
    const handleLogout = () => {
        console.log('Handle Logout called...');
        setShow(false);
        dispatch(logout());
    }

    const showOrganization = () => {
        setShow(false);
        navigate('/main/organization');
    }

    const lockAccount = () => {
        setShow(false);
        // TODO implement
    }


    const aboutPage = () => {
        navigate('/main/about');
    }


    return (
        <div className="dropdown d-inline-block">
            <button type="button" className={`btn header-item waves-effect ${show && 'show'}`}
                    id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    onClick={() => setShow(!show)}>
                <img className="rounded-circle header-profile-user mr-2" height="36px" src={userAvatar}
                     alt="Header Avatar"/>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div className={`dropdown-menu dropdown-menu-end ${show && 'show'}`} style={{right: '.1rem'}}>
                {
                    (user && user.organization) &&
                    <a className="dropdown-item" onClick={showOrganization}>
                        <i className="bx bx-user font-size-16 align-middle me-1"></i>
                        <span key="t-profile">{user.organization.name ?? ''}</span>
                    </a>
                }
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#" onClick={() => {navigate('/main/profile'); setShow(false)}}><i className="bx bx-user font-size-16 align-middle me-1"></i>
                    <span key="t-profile">{i18n.t('Profile')}</span></a>
                <a className="dropdown-item d-block" href="#" onClick={() => {navigate("settings"); setShow(false)}}>
                    {/*<span className="badge bg-success float-end">11</span>*/}
                    <i className="bx bx-wrench font-size-16 align-middle me-1"></i>
                    <span key="t-settings">{i18n.t('Settings')}</span>
                </a>
                <a className="dropdown-item" href="#" onClick={aboutPage}>
                    <i className="bx bx-lock-open font-size-16 align-middle me-1"></i>
                    <span key="t-lock-screen">{i18n.t('About')} BizPro</span></a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item text-danger" onClick={handleLogout}><i
                    className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span
                    key="t-logout">Logout</span></a>
            </div>
        </div>
    )
}
