import React from 'react'
import { Routes, NavLink, Navigate } from 'react-router-dom';
import { Route } from 'use-react-router-breadcrumbs';
import {MessagesListPage} from "./messages/messages-list-page";
import SendSmsPage from "./messages/send";
import AutomatedMessagesListPage from "./automated-messages/list";
import AutomatedMessageFormPage from "./automated-messages/form";
import AutomatedMessageViewPage from "./automated-messages/view";
import {useTranslation} from "react-i18next";
import {TopNavigation} from "../../../components/app/top-navigation/top-navigation";

export const MessagesRoutes = () => {
    const {i18n, t} = useTranslation();
    const topLinks = [
        {
            title: t('Messages List'),
            to: 'messages'
        },
        {
            title: t('Send Message'),
            to: 'send'
        },
        {
            title: t('Automated Message'),
            to: 'automated'
        },
    ];
    return (
        <div>
            <h3>Messages</h3>
            <TopNavigation links={topLinks} basePathLevel={3} />

            <Routes>
                <Route path='messages/*'>
                    <Route index element={<MessagesListPage/>}/>
                </Route>
                <Route path='automated/*'>
                    <Route path="form" element={<AutomatedMessageFormPage/>}/>
                    <Route path="form/:automated_message_id" element={<AutomatedMessageFormPage/>}/>
                    <Route path=":automated_message_id" element={<AutomatedMessageViewPage/>}/>
                    <Route index element={<AutomatedMessagesListPage/>}/>
                </Route>
                <Route path="send"  element={<SendSmsPage/>}/>
                <Route index element={<Navigate to="messages"/>}/>
            </Routes>
        </div>
    );
}
