import React from 'react'
import { GeneralErrorPage } from '../../components/templates/error-pages/general-error-page';


export const NotFoundPage = () => {
  return (
    <div style={{height: '100%'}}>
      <GeneralErrorPage title="Not Found" subTitle="Oops! We are unable to find this page on our app!" fullPage={true}/>
    </div>
  )
}
