export class CustomValidator {

    static validate = (payload, rules) => {
        let errors = {};
        Object.keys(rules).forEach((key, i) => {
                const value = rules[key];
                for (let j = 0; j < value.length; j++) {
                    const rule = value[j];
                    if(errors[key] && errors[key] !== '') continue; // STOP AT FIRST ERROR
                    // required
                    if (rule === 'required') {
                        if (!payload[key] || payload[key] == null || payload['key'] == '') {
                            errors[key] = this.rulesMap(key, rule);
                        }
                    } else if (rule === 'numeric') {
                        if (payload[key] && isNaN(payload[key])) {
                            errors[key] = this.rulesMap(key, rule);
                        }
                    }
                }
            }
        );
        return Object.fromEntries(Object.entries(errors).filter(([key, value]) => (value !== '')));
    }

    static rulesMap = (field, rule) => {
        const map = {
            required: "{FIELD} is required",
            numeric: "{FIELD} should be a number",
        }
        return map[rule].replaceAll('{FIELD}', (field.replaceAll('_', ' ')));
    }

}
