.header {
    margin: 50px auto;
    max-width: 786px;
    text-align: center;
}

.content {
    margin: 0 auto;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}