import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {productsProductCategoriesGet} from "../../../../../application/features/products";
import {AddButton} from "../../../../components/buttons/add-button";
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import ProductCategoriesResource from "../../../../../application/resources/product-categories";
import i18n from "i18next";

const columns = [
    {
        name: "Name",
        selector: (category) => category.name,
        sortable: true,
    },
    {
        name: "Parent",
        selector: (category) => category.parent_category ? category.parent_category.name : '',
        sortable: true,
    },
];

const ProductCategoriesPage = () => {
    //state

    //redux
    const dispatch = useDispatch();
    const deletingSuccessful = useSelector(state => state.products.product_categories.deletingSuccessful);

    //hooks
    const navigate = useNavigate();

    //effects
    useEffect(() => {
        dispatch(productsProductCategoriesGet());
    }, []);

    //effects
    useEffect(() => {
        if (deletingSuccessful == true) {
            dispatch(productsProductCategoriesGet);
        }
    }, [deletingSuccessful]);


    function showAddForm() {
        navigate('form');
    }

    return (
        <div className="row mt-3">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-center">
                            <span className="card-title flex-grow-1">{i18n.t('Product Categories')}</span>
                            <AddButton permissions={['View Products']} to="form" title={"Add a new Category"}/>
                        </div>
                    </div>
                    <div className="card-body">
                        <GalaxyResourceTable deletable={(row) => (row.id > 1)} resource={new ProductCategoriesResource()}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCategoriesPage;
