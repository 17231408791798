import React from 'react';
import CustomBarChart from "../../../../components/charts/bar-chart";
import {BarsLoader} from "../../../../components/loaders/bars-loader/bars-loader";

const MonthlySalesChart = ({monthlySales}) => {
    if(!monthlySales || monthlySales.length == 0) {
        return <div className={'d-flex align-items-center justify-content-center w-100 h-100'} style={{minHeight: '270px'}}>
            <BarsLoader color={'#ddd'} />
        </div>
    }
    const series = [
        {
            name: "Sales",
            data: Object.values(monthlySales),
        }
    ];
    return (
        <CustomBarChart xAxis={Object.keys(monthlySales)} series={series} />
    );
};

export default MonthlySalesChart;
