import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {reportsExpensesGet} from "../../../../application/features/reports";
import BackendContentFilter from "../../../components/form/content-filter";
import moment from "moment";
import {CustomTable} from "../../../atomic/table/table";
import DateFormat from "../../../atomic/formatting/date-format";
import Currency from "../../../atomic/formatting/currency";
import ReportExporter from "../../../components/form/report-exporter/report-exporter";
import bizmanager from "../../../../api/bizmanager";

const ExpensesReportPage = () => {
    //state
    // const [payload, setPayload] = useState({start_date: null, end_date: null});

    //hooks
    const {i18n, t} = useTranslation();
    const dispatch = useDispatch();
    const downloadUrl = bizmanager.exportExpensesReport;

    //redux
    const expenses = useSelector(state => state.reports.expenses_report.data);
    const isFetching = useSelector(state => state.reports.expenses_report.isFetching);

    //effects
    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        if(!expenses) return;
    }, [expenses]);

    const fetchData = (payload = {}) => {
        dispatch(reportsExpensesGet(payload));
    }

    return (
        <div className="card">
            <div className="card-header">{t('Expenses Report')}</div>
            <div className="card-body">
                <div className="col-12  mb-2 d-flex flex-row justify-content-between align-items-center">
                    <div className="flex-grow-1"><h6>{t('Expenses Report')}</h6></div>
                    <ReportExporter downloadUrl={downloadUrl} />
                    <BackendContentFilter startDate={moment().startOf('year').format('YYYY-MM-DD')} onSubmit={(data) => fetchData({payload: data}) }/>
                </div>
                <CustomTable isLoading={isFetching}>
                    <thead>
                    <tr>
                        <th width={30}>#</th>
                        <th width={120}>{t('Date')}</th>
                        <th>Name</th>
                        <th width={100} className={'text-end'}>{t('Quantity')}</th>
                        <th width={150} className={'text-end'}>{t('Amount')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        expenses.map((expense, i) => (
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td><DateFormat date={expense.date} /></td>
                                <td>{expense.name}</td>
                                <td className="text-end">{expense.quantity}<span className="text-black-50">{expense.unit}</span></td>
                                <td className="text-end"><Currency value={expense.amount} /></td>
                            </tr>
                        ))
                    }
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colSpan={4}>Total</th>
                        <th className="text-end"><Currency value={expenses.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.amount), 0)} /></th>
                    </tr>
                    </tfoot>
                </CustomTable>
            </div>
        </div>
    );
};

export default ExpensesReportPage;
