@import '$assets/scss/main.scss';

.navWrapper {
    position: relative;
    background-color: $surface-dark;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow-y: scroll;

    color: $text-light;
    // padding: 30px 20px;

    .navHeader {
        position: relative;
        text-align: center;
        height: 70px !important;
        width: 100%;
        border-bottom: 1.5px solid #EDEDF5;
        background-color: $surface-dark;
        z-index: 1;
        // border: 2px solid pink;
        // padding: 18px 0;

        img {
            height: 100%;
            object-fit: contain;
        }
    }
}

//widgets

//group
.groupMenuTitle {
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    font-size: 11px;
    text-transform: uppercase;
    color: #EDEDF5;
    font-weight: 600;
}

//item component
.itemWrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 10px;

    font-size: 14px;

    padding: 10px 18px;

    z-index: 0;

    &:hover {
        cursor: pointer;
        color: #A5A2CE;

        a, .inactiveColor, .itemChevron {
            color: #A5A2CE !important;
        }
    }

    &.active {
        background-color: #EDEDF5;
        border-radius: 8px;
    }
}

.itemTitle {
    flex: 1;
    color: #EDEDF5;
    
    a {
        all: unset;
        &.active {
            color: white !important;
        }
    }

    &.active {
        color: $primary-color;
    }
}

.inactiveColor {
    color: #EDEDF5;

    &.active {
        color: $primary-color;
    }
}

.itemChevron {
    color: #EDEDF5;
    transition: all 0.3s ease-in;
}

.itemChevron.active {
    color: white;
    transition: all 0.3s ease-out;
    transform: rotate(180deg);
}

.itemChildren {
    overflow: hidden;
    transition: all 0.3s ease-in;
}

.marginRight {
    margin-right: 9px;
}