import {Navigate, Routes} from "react-router-dom";
import {Route} from "use-react-router-breadcrumbs";
import React from "react";
import {PurchasesListPage} from "./purchases/purchases-list-page";
import PurchaseViewPage from "./purchases/purchase-view";
import PurchasesFormPage from "./purchases/purchases-form";
import {useTranslation} from "react-i18next";
import {TopNavigation} from "../../../components/app/top-navigation/top-navigation";

export const PurchasesRoutes = () => {
    const {i18n, t} = useTranslation();
    const topLinks = [
        {
            title: t('Purchases List'),
            to: 'purchases'
        }
    ];
    return (
        <div>
            <h3>{i18n.t('Purchases')}</h3>
            <TopNavigation links={topLinks} basePathLevel={3} />

            <Routes>
                <Route path='purchases/*'>
                    <Route path=":purchase_id" element={<PurchaseViewPage/>}/>
                    <Route path="form" element={<PurchasesFormPage/>}/>
                    <Route path="form/:purchase_id" element={<PurchasesFormPage/>}/>
                    <Route index element={<PurchasesListPage />}/>
                </Route>
                <Route index element={<Navigate to="purchases" />}/>
            </Routes>
        </div>
    )
}
