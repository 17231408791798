import React from 'react'

export const IconHome = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
        <g>
            <path d="M3 13h1v2 5c0 1.1.89 2 2 2h12c1.1 0 2-.9 2-2v-5 -2h1c.4 0 .77-.25.92-.62 .15-.38.06-.81-.22-1.09l-9-9c-.391-.391-1.03-.391-1.42 0l-9 9c-.29.28-.38.71-.22 1.09 .15.37.52.61.92.61Zm9-8.586l6 6V15v0l0 5H5.99v-5 -3 -1.59l6-6.01Z"></path>
            <path d="M12 17c2.2 0 4-1.8 4-4 0-2.21-1.8-4-4-4 -2.21 0-4 1.79-4 4 0 2.2 1.79 4 4 4Zm0-6c1.1 0 2 .89 2 2 0 1.1-.9 2-2 2 -1.11 0-2-.9-2-2 0-1.11.89-2 2-2Z"></path>
        </g>
    </svg>
  )
}
