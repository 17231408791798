import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {productsProductPackagesGet} from "../../../../../application/features/products";
import i18n from "i18next";
import {AddButton} from "../../../../components/buttons/add-button";
import {GalaxyResourceTable} from "../../../../components/templates/list-page/galaxy-resource-table";
import ProductPackagesResource from "../../../../../application/resources/product-packages";

function ProductPackagesListPage(props) {
    //state

    //redux
    const dispatch = useDispatch();
    const deletingSuccessful = useSelector(state => state.products.product_packages.deletingSuccessful);

    //hooks

    //effects
    useEffect(() => {
        dispatch(productsProductPackagesGet());
    }, []);

    //effects
    useEffect(() => {
        if(deletingSuccessful == true) {
            dispatch(productsProductPackagesGet);
        }
    }, [deletingSuccessful]);


    return (
        <div className="row mt-3">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-center">
                            <span className="card-title flex-grow-1">{i18n.t('Product Packages')}</span>
                            <AddButton permissions={['View Products']} to="form" title={"Add a new Package"}/>
                        </div>
                    </div>
                    <div className="card-body">
                        <GalaxyResourceTable deletable={(row) => (row.id > 1)} resource={new ProductPackagesResource()}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductPackagesListPage;
