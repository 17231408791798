import BizManagerApi from "../../api/bizmanager";
import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import {
    salesSalesDelete,
    salesSalesGet, salesSaleRecord,
} from "../features/sales";
import DateFormat from "../../presentation/atomic/formatting/date-format";
import i18n from "i18next";
import Currency from "../../presentation/atomic/formatting/currency";

/**
 * @inheritDoc
 */
export class  SalesResource extends GalaxyResource {
    resourceActions = {
        fetch: salesSalesGet,
        retrieve: null,
        create: salesSaleRecord,
        update: null,
        delete: salesSalesDelete,
    };
    stateName = 'sales.sales';
    columns = [
        {
            name: '#',
            center: false,
            width: '50px',
            cell: (row, index) => (index + 1),
        },
        {
            name: i18n.t('Date'),
            selector: (row) => <DateFormat date={row.date}/>,
            sortable: true,
        },
        {
            name: i18n.t('Customer'),
            cell: (row) => <span>{row.customer ? row.customer.name : row.customer_name ?? ''}</span>,
            sortable: true,
        },
        {
            name: i18n.t('Amount'),
            cell: (row) => <span className={'w-100 text-end'}><Currency value={row.total_amount} /></span>,
            sortable: true,
        }
    ];
    permissions = {
        view: ['View Sales'],
        create: ['Create Sales'],
        update: ['Create Sales'],
        delete: ['Delete Sales'],
    }
}

export default SalesResource;
