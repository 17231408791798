{
  "Date": "Tarehe",
  "Description": "Maelezo",
  "Type": "Aina",
  "Amount": "Kiasi",
  "Actions": "Vitendo",
  "Dashboard": "Dashbodi",
  "Sales": "Mauzo",
  "Products": "Bidhaa",
  "Purchases": "Manunuzi",
  "Expenses": "Matumizi",
  "Inventory": "Hifadhi",
  "Returns":  "Marejesho",
  "Customers": "Wateja",
  "Messaging":  "Jumbe",
  "Users": "Watumiaji",
  "Accounts":  "Uhasibu",
  "Reports": "Ripoti",
  "Settings":  "Mpangilio",
  "System Users": "Watumiaji wa Mfumo",
  "General Settings": "Mpangilio wa Jumla",
  "Sales Window": "Dirisha la Mauzo",
  "Sale Returns": "Marejesho ya Mauzo",
  "System Reports": "Ripoti za Mfumo",
  "Monthly Sales": "Mauzo ya Mwezi",
  "Monthly Waste": "Taka za Mwezi",
  "Purchases List": "Orodha ya Manunuzi",
  "Units": "Vipimo",
  "Categories": "Kategoria",
  "Product Packages": "Vifurushi vya Bidhaa",
  "Messages List": "Orodha ya Jumbe",
  "Send Message": "Tuma Ujumbe",
  "Automated Message": "Jumbe Zinazojituma",
  "Inventory Items": "Bidhaa Zilizopo",
  "Inventory Disposals": "Utupaji wa Bidhaa",
  "Stores": "Stoo",
  "Expenses List": "Orodha ya Matumizi",
  "Expenses Types": "Aina za Matumizi",
  "Sales vs Expenses graph": "Grafu ya Mauzo na Matumizi",
  "Product Performance list of 10 items": "Orodha ya utendaji wa bidhaa (10)",
  "Proﬁt in less than 10 months": "Faida ndani ya Miezi 10",
  "Customers List": "Orodha ya Wateja",
  "Customer Groups": "Makundi ya Wateja",
  "Transactions List": "Orodha ya Miamala",
  "Monthly Purchases": "Manunuzi ya Mwezi",
  "Monthly Profit": "Faida kwa Mwezi",
  "Monthly Sales Graph": "Grafu ya mauzo kwa mwezi",
  "Product Name": "Jina la Bidhaa",
  "Quantity Sold": "Idadi Iliyouzwa",
  "Month": "Mwezi",
  "Profit": "Faida",
  "Sales Report": "Ripoti ya Mauzo",
  "Name": "Jina",
  "Quantity": "Idadi",
  "Purchases Report": "Ripoti ya Manunuzi",
  "quantity": "Idadi",
  "unit_price": "Unit Price",
  "user_permissions": "User Permissions",
  "role_permissions": "Role Permissions",
  "System General Settings": "Mpangilio wa jumla wa mfumo",
  "Lock screen": "Lock screen",
  "Profile": "Profaili",
  "Details": "Details",
  "Account": "Account",
  "Add Customer": "Ongeza Mteja",
  "Customer name": "Jina la Mteja",
  "Phone Number": "Namba ya Simu",
  "Customer Name": "Jina na Mteeja",
  "Customer": "Mteja",
  "Email": "Email",
  "Phone": "Simu",
  "Products List": "Orodha ya Bidhaa",
  "Add Products": "Ongeza Bidhaa",
  "Unit": "Kipimo",
  "Category": "kategoria",
  "Sale": "Sale",
  "Add Return": "Ongeza Rejesho",
  "Record a New Purchase": "Rekodi Manunuzi Mapya",
  "Add Item": "Ongenza Mpya",
  "Buying Price": "Bei ya Kununua",
  "Selling Price": "Bei ya Kuuza",
  "No Results": "Hakuna Majibu",
  "Product": "Bidhaa",
  "Total Amount": "Jumla",
  "Received Amount": "Kiasi Kilichopokelewa",
  "Returned Amount": "Kiasi Kilichorudishwa",
  "Save": "Hifadhi",
  "Enter phone number": "Ingiza namba ya Simu",
  "About": "Kuhusu",
  "Product Categories": "Kategoria za Bidhaa",
  "Search Products": "Tafuta Bidhaa",
  "Show Selector": "Onesha Machaguo",
  "Items": "Bidhaa",
  "Qty": "Qty",
  "Reason": "Sababu",
  "Available Quantity": "Idadi Iliyopo",
  "Add Expense": "Ongeza Matumizi",
  "Add Expense Type": "Ongeza Aina ya Matumizi",
  "Expense Type name": "Jina la aina ya matumizi",
  "Total": "Jumla",
  "Parent": "Parent",
  "Unit Count": "Unit Count",
  "Location": "Eneo",
  "Active": "Inatumika",
  "Abbreviation": "Kifupi",
  "Update": "Sasisha",
  "Getting things ready": "Inaandaa vitu..."
}
