import React from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {customersCustomerCreate, customersCustomerUpdate} from "../../../../../application/features/customers";
import CreateUpdateForm from "../../../../components/form/createUpdateForm";
import CustomInput from "../../../../atomic/input";
import DatePicker from "react-datepicker";
import CustomDatePicker from "../../../../atomic/datapicker";
import i18n from "i18next";

const CustomerFormPage = () => {
    const { customer_id } = useParams();
    const customers = useSelector(state => state.customers.customers);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log('init');
    }, []);

    return (
        <CreateUpdateForm resource={customers}  title={i18n.t('Customer')} item_id={customer_id} createThunk={customersCustomerCreate} updateThunk={customersCustomerUpdate}>
            <CustomInput name='name' label={i18n.t('Customer Name')} placeholder={i18n.t('Customer name')}  required />
            {/*<CustomInput name='birth_date' label='Date of Birth' min="0" placeholder='Date of Birth'  />*/}
            <CustomDatePicker  autocomplete={'false'}  name={'birth_date'} label={i18n.t('Date of Birth')} placeholder={i18n.t('Date of Birth')} />
            <CustomInput name='phone' type="number" label={i18n.t('Phone Number')} min={0} placeholder={i18n.t('Phone Number')}  />
        </CreateUpdateForm>
    );
};

export default CustomerFormPage;
