class BizManagerApi {
    static base_url = 'https://backend.bizpro.softwaregalaxy.com/api'
    // static base_url = 'http://bizmanager.local/api'

    // Authentications
    static authentication = `${this.base_url}/login`;
    static forgotPassword = `${this.base_url}/forgot-password`;
    static validateOTP = `${this.base_url}/validate-otp`;
    static resetPassword = `${this.base_url}/reset-password`;

    // On Boarding
    static register = `${this.base_url}/on-boarding-module/register`;
    static checkOnboarding = `${this.base_url}/on-boarding-module/check`;
    static createOrganization = `${this.base_url}/on-boarding-module/create-organization`;
    static getBusinessTypes = `${this.base_url}/on-boarding-module/get-business-types`;
    static updateBusinessType = `${this.base_url}/on-boarding-module/update-business-type`;
    static createPassword = `${this.base_url}/on-boarding-module/create-password`;
    static getProductCategories = `${this.base_url}/on-boarding-module/get-product-categories`;
    static updateProductCategories = `${this.base_url}/on-boarding-module/update-product-categories`;
    static onboardingFinish = `${this.base_url}/on-boarding-module/finish`;

    // Users
    static users_get = `${this.base_url}/user-management-module/get-users`;
    static user_create = `${this.base_url}/user-management-module/create-user`;
    static user_update = `${this.base_url}/user-management-module/update-user`;
    static users = `${this.base_url}/users`;
    static user = (user_id) => `${this.users}/${user_id}`

    static notifications = `${this.base_url}/notifications/`;
    static notifications_edit = (id) => `${this.base_url}/notifications/${id}`;

    static get_user_permissions = `${this.base_url}/user-management-module/get-user_permissions`;
    static get_permissions = `${this.base_url}/user-management-module/get-permissions`;
    static update_user_permissions = `${this.base_url}/user-management-module/update_user_permissions`;

    // Inventory Items
    static inventoryItems = `${this.base_url}/stock-items`;
    static inventoryItem = (inventory_item_id) => `${this.base_url}/stock-items/${inventory_item_id}`;

    static inventoryDisposals = `${this.base_url}/stock-disposals`;
    static inventoryDisposal = (inventory_disposal_id) => `${this.base_url}/stock-disposals/${inventory_disposal_id}`;
    static inventoryDisposeStock = `${this.base_url}/stock-management-module/dispose-stock`;

    static inventoryStores = `${this.base_url}/stores`;
    static inventoryStore = (store_id) => `${this.base_url}/stores/${store_id}`;

    static inventoryAddons = `${this.base_url}/stock-item-product-addons`;
    static inventoryAddon = (addon_id) => `${this.base_url}/stock-item-product-addons/${addon_id}`;

    static inventoryOffers = `${this.base_url}/stock-item-offers`;
    static inventoryOffer = (offer_id) => `${this.base_url}/stock-item-offers/${offer_id}`;

    // Product Units
    static units = `${this.base_url}/units`;
    static unit = (unit_id) => `${this.base_url}/units/${unit_id}`;

    // Products
    static products = `${this.base_url}/products`;
    static products_get = `${this.base_url}/product-management-module/get-products`;
    static product = (product_id) => `${this.base_url}/products/${product_id}`;
    static product_upload_image = `${this.base_url}/product-management-module/upload-product-picture`;
    static product_update = `${this.base_url}/product-management-module/update-product`;
    static delete_product_picture = (id) => `${this.base_url}/product-pictures/${id}`;

    // Product Categories
    static product_categories = `${this.base_url}/product-categories`;
    static product_category = (product_category_id) => `${this.base_url}/product-categories/${product_category_id}`;

    // Product Packages
    static product_packages = `${this.base_url}/product-packages`;
    static product_package = (product_package_id) => `${this.base_url}/product-packages/${product_package_id}`;

    // Product Addon
    static product_addons = `${this.base_url}/product-addons`;
    static product_addon = (product_addon_id) => `${this.base_url}/product-addons/${product_addon_id}`;

    // Sales
    static sales = `${this.base_url}/sales/`;
    static salesProductSearch = `${this.base_url}/stock-management-module/search-stock`;
    static salesRecordSale = `${this.base_url}/sales-module/record-sale`;
    static salesGetSales = `${this.base_url}/sales-module/get-sales`;
    static sale = (sale_id) => `${this.base_url}/sales/${sale_id}`;


    // Purchases
    static purchases_get =`${this.base_url}/purchases-module/get-purchases`;
    static purchase_update =`${this.base_url}/purchases-module/update-purchase`;
    static purchase_create =`${this.base_url}/purchases-module/record-purchase`;
    static purchases = `${this.base_url}/purchases`;
    static purchase = (purchase_id) => `${this.base_url}/purchases/${purchase_id}`;

    // Expenses
    static expenses = `${this.base_url}/expenses`;
    static expenses_create = `${this.base_url}/expenses-module/record-expense`;
    static expenses_update = `${this.base_url}/expenses-module/update-expense`;
    static expense = (expense_id) => `${this.base_url}/expenses/${expense_id}`;

    // Expense Type
    static expense_types = `${this.base_url}/expense-types`;
    static expense_type = (expense_id) => `${this.base_url}/expense-types/${expense_id}`;

    // Returns
    static returns = `${this.base_url}/returns`;
    static returnSale = `${this.base_url}/sales-module/return-sale-item`;
    static return = (return_id) => `${this.base_url}/returns/${return_id}`;

    // Customer
    static customers = `${this.base_url}/customers`;
    static customers_search = `${this.base_url}/customer-management-module/search-customers`;
    static customer = (customer_id) => `${this.base_url}/customers/${customer_id}`;
    static customer_groups = `${this.base_url}/customer-groups`;
    static customer_group = (customer_group_id) => `${this.base_url}/customer-groups/${customer_group_id}`;

    static customers_get = `${this.base_url}/customer-management-module/get-customers`;
    static customers_create = `${this.base_url}/customer-management-module/create-customer`;
    static customers_update = `${this.base_url}/customer-management-module/update-customer`;
    static customer_groups_get = `${this.base_url}/customer-management-module/get-customer-groups`;
    static customer_groups_create = `${this.base_url}/customer-management-module/create-customer-group`;

    // accounts
    static accounts_get = `${this.base_url}/accounts-module/get-accounts`;
    static transactions_get = `${this.base_url}/accounts-module/get-transactions`;
    static transactions = `${this.base_url}/ledger-entries`;

    // Messages
    static messages_get = `${this.base_url}/sms-module/get-messages`;
    static messages_send = `${this.base_url}/sms-module/send-message`;
    static messages = `${this.base_url}/messages`;
    static message = (message_id) => `${this.base_url}/messages/${message_id}`;

    // Automated Messages
    static automated_messages_get = `${this.base_url}/sms-module/get-automated-messages`;
    static automated_messages_create = `${this.base_url}/sms-module/create-automated-message`;
    static automated_messages = `${this.base_url}/automated-messages`;
    static automated_message = (message_id) => `${this.base_url}/automated-messages/${message_id}`;

    static automation_factors_get = `${this.base_url}/sms-module/get-automation-factors`;

    // Settings
    static settings = `${this.base_url}/settings`;
    static settings_get = `${this.base_url}/settings-module/get-settings`;
    static settings_update = `${this.base_url}/settings-module/update-settings`;

    static roles_get = `${this.base_url}/settings-module/get-roles`;
    static sync_user_roles = `${this.base_url}/user-management-module/sync-roles`;

    // Dashboard
    static dashboardStatistics = `${this.base_url}/dashboard/get-data`;

    // reports
    static salesReport = `${this.base_url}/reports-module/sales`;
    static monthlySalesReport = `${this.base_url}/reports-module/monthly-sales`;
    static purchasesReport = `${this.base_url}/reports-module/purchases`;
    static expensesReport = `${this.base_url}/reports-module/expenses`;

    // orginanization
    static organizationsGet = `${this.base_url}/user-management-module/user-organizations`;
    static organizationSetActive = `${this.base_url}/user-management-module/set-active-organization`;
    
    // orginanization
    static businessTypesGet = `${this.base_url}/on-boarding-module/get-business-types`;
    static businessTypeSetActive = `${this.base_url}/on-boarding-module/update-business-type`;

    // Galaxy Auth Function
    static authenticateRedirected = `${this.base_url}/authenticate-redirected`;
    static login_with_galaxy = `${this.base_url}/login-with-galaxy`;
    static changePassword = `${this.base_url}/user-management-module/change-password`;
    static getMetaData = `${this.base_url}/settings-module/app-metadata`;
    static exportTransactions =  `${this.base_url}/export/transactions`;
    static exportSalesReport =  `${this.base_url}/export/sales`;
    static exportPurchasesReport =  `${this.base_url}/export/purchases`;
    static exportExpensesReport =  `${this.base_url}/export/expenses`;
    static exportMonthlySalesReport =  `${this.base_url}/export/monthly-sales`;
    static exportMonthlyWasteReport =  `${this.base_url}/export/monthly-waste`;
    static exportReturnsReport =  `${this.base_url}/export/returns`;

}

export default BizManagerApi;

import React, {Component} from 'react';
import PropTypes from 'prop-types';

