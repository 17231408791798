import React from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {productsProductCategoriesGet} from "../../../../../application/features/products";

const ProductCategoryViewPage = () => {
    //state

    //hooks
    const { product_category_id } = useParams();

    //redux
    const dispatch = useDispatch();
    const product_category = useSelector(state => state.products.product_categories.data.find(u => u.id == product_category_id));

    //effect
    useEffect(() => {
        //TODO: call retrieve product method/api
        if (!product_category_id) return;
        const data = {
            id: product_category_id,
        }
        dispatch(productsProductCategoriesGet(data));
    }, [product_category_id]);

    if (!product_category) {
        return <div>{'Ooops! Unit not found'}</div>
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">&nbsp;</div>
                <div className="card-body">
                    <table className="table table-bordered">
                        <tbody>
                        <tr>
                            <td width="30%">Name</td><td>{product_category.name}</td>
                        </tr>
                        <tr>
                            <td>Parent</td><td>{product_category.parent_category ? product_category.parent_category.name : 'None'}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
};

export default ProductCategoryViewPage;
