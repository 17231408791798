
.productSelector {
  min-height: 65vh;

  .categories {
    border-right: 1px solid #eaeaea;
    padding: 0 1rem 0 0;
    margin: 0;
    overflow-y: scroll;
    align-items: stretch;
    min-width: 200px;

    .listGroup {
      list-style-type: none;
      padding: 0;

      li {
        border-bottom: 1px solid #eaeaea;
        padding: 0 1rem;
        min-height: 3rem;
        line-height: 3rem;
        cursor: pointer;
      }

      &.selected {
        color: #4C469C;
        background-color: rgba(200, 200, 200, 0.1);
      }

      li:hover {
        background-color: rgba(200, 200, 200, 0.1);
      }
    }
  }

  .productCard {
    min-height: 110px;
    cursor: pointer;

    &.selected {
      border: 1px solid #5566dd;
      box-shadow: 1px 3px 8px 5px rgba(220, 220, 220, 0.2);
    }
  }

  .categoryChips {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      display: flex;
      flex-direction: row;
      border: 1px solid #ddd;
      border-radius: 30px;
      margin: .1rem .3rem;
      padding: 0 .4rem;
      cursor: pointer;
      min-width: 3rem;

      &.selected {
        border-color: #4C469C !important;
      }

    }

  }

}

