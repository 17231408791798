import {createAsyncThunk} from "@reduxjs/toolkit";
import {customFetch} from "./network";
import BizManagerApi from "../api/bizmanager";

export const restResourceInitialState = {
    isFetching: false,
    isRetrieving: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,

    fetchingSuccessful: false,
    retrievingSuccessful: false,
    creatingSuccessful: false,
    updatingSuccessful: false,
    deletingSuccessful: false,

    fetchingError: null,
    retrievingError: null,
    creatingError: null,
    updatingError: null,
    deletingError: null,

    validationErrors: {},

    data: [],
    links: [],
    meta: [],
    selected: -1,
}

export const resourceThunks = {
    create: null,
    get: null,
    retrieve: null,
    update: null,
    delete: null,
}

export const generateResourceExtraReducers = (resourceThunks=resourceThunks, stateKey) => {
    const extraReducers = {};
    
    if (resourceThunks.create) {
        extraReducers[resourceThunks.create.pending] = (state, action) => {
            state[stateKey].isCreating = true;
            state[stateKey].creatingSuccessful = false;
            state[stateKey].creatingError = null;
            state[stateKey].validationErrors = {};
        }
        extraReducers[resourceThunks.create.fulfilled] = (state, action) => {
            state[stateKey].isCreating = false;
            state[stateKey].creatingSuccessful = true;
            if (action.payload.data) {
                state[stateKey].data.unshift(action.payload.data);
            } else {
                //
            }
        }
        extraReducers[resourceThunks.create.rejected] = (state, action) => {
            state[stateKey].isCreating = false;
            if(action.payload != undefined && action.payload.errors != null) {
                state[stateKey].creatingError = action.payload;
                state[stateKey].validationErrors = action.payload.errors;
                console.log(action.payload);
                console.log(action.payload.errors);
            } else {
                state[stateKey].creatingError = action.error;
                state[stateKey].validationErrors = {};
            }
        }
    }

    if (resourceThunks.get) {
        extraReducers[resourceThunks.get.pending] = (state, action) => {
            state[stateKey].isFetching = true;
            state[stateKey].fetchingSuccessful = false;
            state[stateKey].fetchingError = null;
            state[stateKey].validationErrors = {};
        }
        extraReducers[resourceThunks.get.fulfilled] = (state, action) => {
            state[stateKey].isFetching = false;
            state[stateKey].fetchingSuccessful = true;
            state[stateKey].data = action.payload.data ? action.payload.data : null;
            state[stateKey].links = action.payload.links ? action.payload.links : [];
            state[stateKey].meta = action.payload.links ? action.payload.meta : [];
        }
        extraReducers[resourceThunks.get.rejected] = (state, action) => {
            state[stateKey].isFetching = false;
            state[stateKey].fetchingError = action.error;
        }
    }

    if (resourceThunks.retrieve) {
        extraReducers[resourceThunks.retrieve.pending] = (state, action) => {
            state[stateKey].isRetrieving = true;
            state[stateKey].retrievingSuccessful = false;
            state[stateKey].retrievingError = null;
            state[stateKey].validationErrors = {};
        }
        extraReducers[resourceThunks.retrieve.fulfilled] = (state, action) => {
            state[stateKey].isRetrieving = false;
            state[stateKey].retrievingSuccessful = true;
            const index = state[stateKey].data.findIndex(x => x.id == action.meta.arg.id);
            if (index > -1) {
                if(action.payload.data){
                    state[stateKey].data[index] =  action.payload.data;
                }
            } else {
                state[stateKey].data[state[stateKey].data.length] =  action.payload.data;
            }
        }
        extraReducers[resourceThunks.retrieve.rejected] = (state, action) => {
            state[stateKey].isRetrieving = false;
            state[stateKey].retrievingError = action.error;
        }
    }

    if (resourceThunks.update) {
        extraReducers[resourceThunks.update.pending] = (state, action) => {
            state[stateKey].isUpdating = true;
            state[stateKey].updatingSuccessful = false;
            state[stateKey].updatingError = null;
            state[stateKey].validationErrors = {};
        }
        extraReducers[resourceThunks.update.fulfilled] = (state, action) => {
            state[stateKey].isUpdating = false;
            state[stateKey].updatingSuccessful = true;

            const index = state[stateKey].data.filter(d => d).findIndex(x => x.id == action.meta.arg.id);
            if (index > -1) {
                state[stateKey].data[index] = action.payload.data;
            }
        }
        extraReducers[resourceThunks.update.rejected] = (state, action) => {
            state[stateKey].isUpdating = false;
            if(action.payload !== undefined && action.payload.errors != null) {
                state[stateKey].updatingError = action.payload;
                state[stateKey].validationErrors = action.payload.errors;
            } else {
                state[stateKey].updatingError = action.error;
                state[stateKey].validationErrors = {};
            }
        }
    }

    if (resourceThunks.delete) {
        extraReducers[resourceThunks.delete.pending] = (state, action) => {
            state[stateKey].isDeleting = true;
            state[stateKey].deletingSuccessful = false;
            state[stateKey].deletingError = null;
            state[stateKey].validationErrors = {};
        }

        extraReducers[resourceThunks.delete.fulfilled] = (state, action) => {
            state[stateKey].isDeleting = false;
            state[stateKey].deletingSuccessful = true;
            state[stateKey].data = state[stateKey].data.filter(x => x.id != action.meta.arg.id);
        }
        extraReducers[resourceThunks.delete.rejected] = (state, action) => {
            state[stateKey].isDeleting = false;
            state[stateKey].deletingSuccessful = false;
            state[stateKey].deletingError = action.error;
        }
    }

    return extraReducers;
}

/**
 * TODO make it generate all actions for clearing errors
 */
export const generateCleaningReducers = (stateKey) => {
    const reducers = {
        resetFetchingError: (state, action) => {
            state[stateKey].fetchingError = null;
        },
        resetCreatingError: (state, action) => {
            state[stateKey].creatingError = null;
        },
        resetUpdatingError: (state, action) => {
            state[stateKey].updatingError = null;
        },
        resetDeletingError: (state, action) => {
            state[stateKey].deletingError = null;
        },
        resetRetrievingError: (state, action) => {
            state[stateKey].retrievingError = null;
        }
    }
    return reducers;
}

/**
 * Experimental: trying to centralize thunk action creation
 * @param actionConstant
 * @param  apiEndpoint: function
 * @param method
 * @param passData
 * @returns
 */
export const restThunk = (actionConstant, apiEndpoint , method = 'GET', passData = false) => {
  return  createAsyncThunk(
      actionConstant,
        async(data, store) => {
            const { token } = store.getState().authentication;
            return await customFetch(method, apiEndpoint(data), (passData ? data.payload : null), token, store);
        }
    );
}

/**
 * Experimental: trying to centralize a single request lifecycle
 */

 export const requestInitialState = {
    isLoading: false,
    isSuccessful: false,
    error: null,
    data: null, 
}

const _requestPendingReducer = (state, action) => {
    state.isLoading = true;
    state.isSuccessful = false;
    state.error = null;
    state.data = null;
}

const _requestSuccessReducer = (state, action) => {
    console.log('DS ', action)
    state.isLoading = false;
    state.isSuccessful = true;
    state.data = (action.payload && action.payload.data) ? action.payload.data : null;
}

const _requestErrorReducer = (state, action) => {
    state.isLoading = false;
    state.isSuccessful = false;
    state.error = action.payload ?? action.error;
}

export const generateRequestReducers = (thunk, stateKey) => {
    const reducers = {};

    reducers[thunk.pending] = (state, action) => _requestPendingReducer(state[stateKey], action);
    reducers[thunk.fulfilled] = (state, action) => _requestSuccessReducer(state[stateKey], action);
    reducers[thunk.rejected] = (state, action) => _requestErrorReducer(state[stateKey], action);

    return reducers;
}
