import React from 'react'
import { Link } from 'react-router-dom';
import CustomInput from '../../../atomic/input';
import { SplashLayout } from '../splash/layout';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Spinner } from '../../../atomic/spinner';
import { useState } from 'react';
import { onBoardingCreatePassword, onBoardingRegister } from '../../../../application/features/onboarding';
import SweetAlert from 'react-bootstrap-sweetalert';

export const RegisterPage = (props) => {
    // hooks
    const navigate = useNavigate();

    //state
    const [payload, setPayload] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [showRegistrationSuccess, setShowRegistrationSuccess] = useState(true);

    // redux
    const dispatch = useDispatch();
    const isRegistering = useSelector(state => state.onboarding.register.isLoading);
    const isSuccessful = useSelector(state => state.onboarding.register.isSuccessful);
    const registerError = useSelector(state => state.onboarding.register.error);
    const response = useSelector(state => state.onboarding.register.data);

    const isUpdatingPassword = useSelector(state => state.onboarding.create_password.isLoading);
    const isUpdatingPasswordSuccessful = useSelector(state => state.onboarding.create_password.isSuccessful);
    const updatingPasswordError = useSelector(state => state.onboarding.create_password.error);
    const updatingPasswordResponse = useSelector(state => state.onboarding.create_password.data);

    // effects
    useEffect(() => {
      if (isSuccessful) {
        setShowRegistrationSuccess(false);
      }
    }, []);

    useEffect(() => {
      if (!registerError) return;
      if (!registerError.errors) setErrors({});

      setErrorMessage(registerError.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000)

      let _errors = {};
      console.log(registerError.errors);
      for (let key in registerError.errors) {
        _errors[key] = registerError.errors[key][0]
      }
      setErrors(_errors);
    }, [registerError]);

    // effects
    useEffect(() => {
      if (!updatingPasswordError) return;
      if (!updatingPasswordError.errors) setErrors({});

      setErrorMessage(updatingPasswordError.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000)

      let _errors = {};
      console.log(updatingPasswordError.errors);
      for (let key in updatingPasswordError.errors) {
        _errors[key] = updatingPasswordError.errors[key][0]
      }
      setErrors(_errors);
    }, [updatingPasswordError]);

    // handlers
    const handleChange = (name, value) => {
      setPayload({...payload, [name]: value});
    }

    const handleRegister = () => {
      setErrors({});
      dispatch(onBoardingRegister(payload));
    }

    const handleUpdatePassword = () => {
      setErrors({});
      dispatch(onBoardingCreatePassword({...payload, "user_id": response.data.id}));
    }

    const registrationForm = () => (
      <div className={styles.signup}>
          {errorMessage && (
            <div className="alert alert-danger">
              <p>{errorMessage}</p>
            </div>
          )}
          <h1>Sign Up</h1>
          <CustomInput label="Full Name" placeholder="Enter your full name" name="name" value={payload.name} onChange={handleChange} error={errors.name} />
          <CustomInput label="Email" placeholder="Enter your full name" name="email" value={payload.email} onChange={handleChange} error={errors.email} />
          <CustomInput label="Phone" placeholder="Enter your full name" name="phone" value={payload.phone} onChange={handleChange} error={errors.phone} />
          <button onClick={handleRegister} className="btn btn-primary" disabled={isRegistering}>Next  {isRegistering && <Spinner />}</button>
          <p>Already Have an Account? <Link to="../login">Log in</Link></p>
        </div>
    );

    const passwordUpdateForm = () => (
      <div className={styles.signup}>
          {errorMessage && (
            <div className="alert alert-danger">
              <p>{errorMessage}</p>
            </div>
          )}
          <h1>Sign Up</h1>
          <CustomInput label="OTP" placeholder="Enter your OTP" name="otp" value={payload.otp ?? ""} onChange={handleChange} error={errors.otp} />
          <CustomInput label="Password" type="password" placeholder="Enter your Password" name="password" value={payload.password ?? ""} onChange={handleChange} error={errors.password} />
          <CustomInput label="Password Confirm" type="password" placeholder="Confirm your Password" name="password_confirmed" value={payload.password_confirmed ?? ""} onChange={handleChange} error={errors.password_confirmed} />
          <button onClick={handleUpdatePassword} className="btn btn-primary" disabled={isUpdatingPassword}>Next  {isUpdatingPassword && <Spinner />}</button>
        </div>
    );

    return (
      <SplashLayout>
        {!isSuccessful ? registrationForm() : passwordUpdateForm()}

        {isSuccessful && (
          <SweetAlert
            show={showRegistrationSuccess}
            success
            title="Successful"
            onConfirm={() => {
              console.log('confirm');
              setShowRegistrationSuccess(false);
            }}
          >
            You have successfully created an account
        </SweetAlert>
        )}
      </SplashLayout>
    );
}
