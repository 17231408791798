import BizManagerApi from "../../api/bizmanager";
import React from "react";
import {GalaxyResource} from "./galaxy-resource";
import {
    purchasesPurchaseDelete,
    purchasesPurchasesGet, purchasesPurchaseCreate, purchasesPurchaseRetrieve, purchasesPurchaseUpdate,
} from "../features/purchases";
import DateFormat from "../../presentation/atomic/formatting/date-format";
import i18n from "i18next";
import Currency from "../../presentation/atomic/formatting/currency";

/**
 * @inheritDoc
 */
export class  PurchasesResource extends GalaxyResource {
    resourceActions = {
        fetch: purchasesPurchasesGet,
        retrieve: purchasesPurchaseRetrieve,
        create: purchasesPurchaseCreate,
        update: purchasesPurchaseUpdate,
        delete: purchasesPurchaseDelete,
    };
    stateName = 'purchases.purchases';
    columns = [
        {
            name: '#',
            center: false,
            width: '40px',
            cell: (row, index) => (index + 1),
        },
        {
            name: i18n.t('Date'),
            grow: 0,
            minWidth: '20%',
            sortField: 'date',
            selector: (row) => <DateFormat date={row.date}/>,
            sortable: true,

        },
        {
            name: i18n.t('Description'),
            grow: 1,
            center: false,
            sortField: 'description',
            cell: (row) => <span>{row.description ?? ''}</span>,
            sortable: true,
        },
        {
            name: i18n.t('Amount'),
            grow: 0,
            minWidth: "120px",
            right: true,
            cell: (row) => <div className={'text-end w-100'}><Currency currency={(row.items[0].currency) ? (row.items[0].currency.code ?? '') : ''} value={row.total} /></div>,
            sortable: true,
        },
    ];
    permissions = {
        view: ['View Purchases'],
        create: ['Create Purchases'],
        update: ['Create Purchases'],
        delete: ['Delete Purchases'],
    }
}

export default PurchasesResource;
