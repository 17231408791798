@import "$assets/scss/main.scss";

.wrapper {
    position: sticky;
    top: 0;
    height: 70px;
    background-color: white;

    box-shadow: 0 1px 5px 2px rgba(150, 150, 150, $alpha: 0.4);
    z-index: $topper;
}

.row {
    display: flex;
    align-items: center;
    height: 100%;
}

.content {
    display: flex;
    align-items: center;
    height: 100%;
}