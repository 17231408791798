import React, {useEffect} from 'react'
import OnBoardingStepLayout from '../choose-business-type/layout'
import styles from './index.module.scss';
import success_img from '$assets/img/onboarding_success.png';
import { useNavigate } from 'react-router-dom';
import {useState} from "react";
import {onBoardingFinish, onBoardingUpdateProductCategories} from "../../../../application/features/onboarding";
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "../../../atomic/spinner";
import {clearInterrupt} from "../../../../application/features/app";

export const OnBoardingSuccessPage = () => {

    const [payload, setPayload] = useState({categories: []});
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    const isUpdating = useSelector(state => state.onboarding.update_product_categories.isFetching);

    const isSuccess = useSelector(state => state.onboarding.finish_onboarding.isSuccessful);
    const isLoading = useSelector(state => state.onboarding.finish_onboarding.isLoading);
    const fetchError = useSelector(state => state.onboarding.finish_onboarding.error);
    const updatedOrganization = useSelector(state => state.onboarding.finish_onboarding.data);


    useEffect(() => {
        if(updatedOrganization) {
            console.log('Back ', updatedOrganization);
            navigate("/main")
        }
    }, [updatedOrganization]);

    useEffect(() => {
        console.log('SUCCESS', updatedOrganization)
        if(isSuccess) {
            dispatch(clearInterrupt());
            navigate("/main")
        }
    }, [isSuccess]);


    useEffect(() => {
        if(fetchError) {
            setError(fetchError.message);
            setTimeout(() => {
                setError(null);
            }, 4000)
        }
    }, [fetchError]);

  //hooks
  const navigate = useNavigate();

    const handleSubmit = () => {
        if(updatedOrganization && updatedOrganization.on_boarding_completed == 1){
            navigate("/authentication/login");
        }
        dispatch(onBoardingFinish());
    }
  return (
    <OnBoardingStepLayout step={3}>
        <div className={styles.content}>
            <div className={styles.card}>
                {error && <div className="alert alert-danger my-2">{error}</div>}
                <img src={success_img} alt="" />

                <h1 className="py-3">Success</h1>
                <p>Thanks for staying this far. Your account is ready for you. cLick next to start using BizPro</p>

                <button className="btn btn-primary mt-3" onClick={handleSubmit} disabled={isLoading}>
                    <span className="px-3">{isLoading ? <Spinner /> : 'Finish'}</span>
                </button>
            </div>
        </div>
    </OnBoardingStepLayout>
  )
}
