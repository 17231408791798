import {NavLink, Routes, Navigate} from "react-router-dom";
import {Route} from "use-react-router-breadcrumbs";
import React from "react";
import {ReportsListPage} from "./reports-list-page";
import PurchasesReportPage from "./purchases-report";
import ReturnsReportPage from "./returns-report";
import ExpensesReportPage from "./expenses-report";
import {useTranslation} from "react-i18next";
import SalesReportPage from "./sales-report";
import MonthlyWasteReportPage from "./monthly-waste-report";
import MonthlySalesReportPage from "./monthly-sales-report";
import {TopNavigation} from "../../../components/app/top-navigation/top-navigation";

export const ReportsRoutes = () => {
    const {i18n, t} = useTranslation();

    const topLinks = [
        {
            title: t('System Reports'),
            to: 'reports'
        },
        {
            title: t('Sales'),
            to: 'sales'
        },
        {
            title: t('Purchases'),
            to: 'purchases'
        },
        {
            title: t('Expenses'),
            to: 'expenses'
        },
        {
            title: t('Returns'),
            to: 'returns'
        },
        {
            title: t('Monthly Sales'),
            to: 'monthly-sales'
        },
        {
            title: t('Monthly Waste'),
            to: 'monthly-waste'
        },
    ];
    return (
        <div>
            <h3>Reports</h3>
            <TopNavigation links={topLinks} basePathLevel={3} />

            <Routes>
                <Route path="sales" element={<SalesReportPage/>}/>
                <Route path="monthly-sales" element={<MonthlySalesReportPage/>}/>
                <Route path="purchases" element={<PurchasesReportPage/>}/>
                <Route path="expenses" element={<ExpensesReportPage/>}/>
                <Route path="returns" element={<ReturnsReportPage/>}/>
                <Route path="monthly-waste" element={<MonthlyWasteReportPage/>}/>
                <Route path='reports/*'>
                    <Route index element={<ReportsListPage/>}/>
                </Route>
                <Route index element={<Navigate to="reports"/>}/>
            </Routes>
        </div>
    )
}
