import {NavLink, Routes, Navigate} from "react-router-dom";
import {Route} from "use-react-router-breadcrumbs";
import React from "react";
import {ReturnsListPage} from "./returns/returns-list-page";
import ReturnsFormPage from "./returns/returns-form";
import ReturnsViewPage from "./returns/returns-view";
import {useTranslation} from "react-i18next";
import {TopNavigation} from "../../../components/app/top-navigation/top-navigation";

export const ReturnsRoutes = () => {
    const {i18n, t} = useTranslation();
    const topLinks = [
        {
            title: t('Sale Returns'),
            to: 'returns'
        },
    ];
    return (
        <div>
            <h3>{i18n.t('Returns')}</h3>
            <TopNavigation links={topLinks} basePathLevel={3} />

            <Routes>
                <Route index element={<Navigate to="returns"/>}/>
                <Route path='returns/*'>
                    <Route path="returns" element={<ReturnsListPage/>}/>
                    <Route path="form" element={<ReturnsFormPage/>}/>
                    <Route path="form/:return_id" element={<ReturnsFormPage/>}/>
                    <Route path=":return_id" element={<ReturnsViewPage/>}/>
                    <Route index element={<ReturnsListPage/>}/>
                </Route>
            </Routes>
        </div>
    )
}
